/* Common Components */
$ace-booknow-component: '.booking-engine .ace-booknow-component';
$cmp-breadcrumb: '.breadcrumb .cmp-breadcrumb';
/* callouts */
$ace-callout-component-dafault: '.cmp-teaser .ace-callout-component .ace-callout-component-default';
$cmp-teaser: '.cmp-teaser';
$ace-callout-container-filters: '..ace-callout-container-filters';
$ace-callout-container: '.callout-container .ace-callout-container';
$callout-section: '.callout-section';
$ace-callout-section: '.ace-callout-section';
$cmp-teaser__image: '.cmp-teaser__image';
/* core-booking-engine */
$core-booking-engine: '.core-booking-engine';
$ace-core-booking-engine: '.ace-core-booking-engine';
$ace-core-booking-engine-options: '.ace-core-booking-engine__options';
$ace-date-component: '.ace-date-component';
$duet-date-picker: '.duet-date-picker';
$duet-date__input: '.duet-date__input';

$country-language-selector: '.country-language-selector .country-region';
$duet-date__dialog: '.duet-date__dialog-content';
$cmp-ace-faq-component: '.cmp-ace-faq';
$cmp-accordion: '.cmp-accordion';
$footer-component: 'footer';
$ace-headingpagehero-headline: '.ace-headingpagehero .ace-headingpagehero--fullWidth';
$ace-headingoffer: '.offer .ace-headingpagehero__content';
$ace-image: '.ace-image';
$ace-loyalty: '.loyalty .ace-loyalty';
$cmp-ace-message: '.cmp-ace-message';
$ace-myaccount-component: '.myaccount .ace-myaccount-component';
/* Navigation */
$header-component: 'header';
$ace-header-component: '.ace-header';
$navbar-component: '.navbar';
$navbar-content: '.navbarContent';
$navigation-component: '.navigation';
$navigation-panel--container: '.navigation-panel--container';
$navigationpanel: '#navigationpanel';
/* Quick Access */
$ace-quick-access-component: '.quick-access .ace-quick-access-component';
$cmp-filter: '.cmp-filter';
$cmp-ace-anchor-navigation: '.cmp-ace-anchor-navigation';
/* Shared-Footer */
$shared-footer-component: '.shared-footer';
$shared-navigation-component: 'shared-navigation';
$ace-subscription-container: '.ace-subscription-container .ace-subscription';
$ace-switchertable-component: '.ace-switcher-table';

/* CONTENT COMPONENTS */

$ace-all-safe-component: '.ace-all-safe';
/* Back Button */
$nav-item__backButton: '.nav-item__backButton';
$cmp-back-link: '.cmp-back-link';
/* Billboard */
$ace-billboard-component: '.ace-billboard';
$ace-billboard__fullwidth: '.ace-billboard__fullwidth';
$ace-billboard__withimage: '.ace-billboard__withimage';
$ace-billboard__fullwidth__content: '.ace-billboard__fullwidth__content';
$ace-billboard__fullwidth__button: '.ace-billboard__fullwidth__button';

$ace-calltoaction: '.ace-calltoaction';
$ace-card-editorial: '.ace-card-editorial';
$ace-carousel-component: '.ace-carousel-component';
$ace-carousel: '.ace-carousel';
/* Highlight */
$ace-highlight-component: '.ace-highlight';
$ace-highlight__image: '.ace-highlight__image';
$ace-highlight--large: '.ace-highlight--large';
$ace-highlight_mediumlink: '.ace-highlight_mediumlink';
$ace-highlight__content: '.ace-highlight__content';
$ace-highlight-right-version: '.ace-highlight.right-version';
$ace-highlight-left-version: '.ace-highlight.left-version';
$ace-hub-component: '.ace-hub';
/* Icon List */
$ace-icon-list-component: '.ace-icon-list-component';
$ace-footer-icon: '.ace-footer-icon';
$ace-linkgallery: '.ace-linkgallery';
$ace-list: '.ace-list';
$ace-modal: '.ace-modal';
/* Nav-Footer-List */
$ace-navfooterlist-component: '.ace-navfooterlist-component';
$nav-footer-list: '.nav-footer-list';
$ace-heading-list: '.ace-heading-list';
$ace-nested-list: 'ace-nested-list';
/* Paragraph */
$ace-paragraph: '.ace-paragraph';
$ace-seperator-component: '.ace-seperator-component';
$ace-separator: '.ace-separator';
$ace-slideshow: '.ace-slideshow';
$cmp-slideshow: '.cmp-slideshow';
/* Button */
$ace-button: '.ace-button';
$cmp-button: '.cmp-button';
/*Dropdown */
$ace-dropdown: '.ace-dropdown';
/*chip*/
$ace-chip: '.ace-chip';
