.card-container {
    .ace-callout-container.product-variation {
        .cmp-teaser__content {
            position: absolute !important;
            height: calc(100% - rem(16px));
            width: calc(100% - rem(32px));

            @include media('>=md') {
                height: calc(100% - rem(24px));
                width: calc(100% - rem(48px));
            }

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: rem(4px);
            flex: 1 0 0;
            align-self: stretch;


            .cmp-teaser__kicker {
                margin: 0;
                @include ui-label-01;
                color: $color-background;
                text-transform: capitalize !important;
                white-space: normal;
            }

            .cmp-teaser__pretitle {
                @include expressive-heading-06;
                color: $color-background;
                text-transform: uppercase;

                a {
                    @include expressive-heading-06;
                    color: $color-background;
                    text-transform: uppercase;

                    &::after {
                        inset: 0;
                    }
                }
            }

            .ace-button {
                display: none;
            }
        }

        &.top {
            .cmp-teaser__image.cmp-teaser__imageSet {
                margin-bottom: 0 !important;
            }

            .cmp-teaser__content {
                margin: 0;
                padding: rem(16px) rem(16px) 0 rem(16px);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 56.91%);

                @include media('>=md') {
                    margin: 0;
                    padding: rem(24px) rem(24px) 0 rem(24px);
                }

                justify-content: flex-start;
                align-items: flex-start;

                .cmp-teaser__kicker,
                .cmp-teaser__pretitle {
                    margin-bottom: 0 !important;
                }
            }
        }

        &.bottom {
            .cmp-teaser__image.cmp-teaser__imageSet {
                margin-bottom: 0 !important;
            }

            .cmp-teaser__content {
                margin: 0;
                padding: 0 rem(16px) rem(16px) rem(16px);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 42.55%, rgba(0, 0, 0, 0.50) 100%);

                @include media('>=md') {
                    padding: 0 rem(24px) rem(24px) rem(24px);
                    margin: 0;
                }

                justify-content: flex-end;
                align-items: flex-start;

                .cmp-teaser__kicker,
                .cmp-teaser__pretitle {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    // US ACDAA-10981 Offer Variation styling started
    .ace-callout-container.offer-variation {
        .callout {
            padding: 0;
        }

        ul {
            padding: 0;
            margin: 0;
        }

        .cmp-teaser {
            margin-bottom: rem(32px) !important;

            @include media('<md') {
                margin-bottom: rem(16px) !important;
            }

            .cmp-teaser__image {
                margin-bottom: 0;
            }

            .cmp-teaser__content {
                position: absolute !important;
                margin: rem(24px);
                gap: rem(32px);
                height: calc(100% - rem(48px));
                width: calc(100% - rem(48px));
                box-sizing: border-box;

                .ace-card-heading {
                    border: rem(4px) solid color(neutral, white);
                    border-bottom-width: 0;
                    box-sizing: border-box;
                    padding: rem(24px) rem(24px) 0;
                    background: radial-gradient(261.25% 141.42% at 0% 0%, rgba(0, 0, 0, 0.50) 51.45%, rgba(0, 0, 0, 0.00) 100%);
                    width: 100%;

                    .cmp-teaser__pretitle {
                        color: color(neutral, white);
                        padding-bottom: rem(24px);
                        margin: 0;
                        font-weight: 700 !important;
                        line-height: 2.25rem !important;
                        font-size: 1.5rem !important;
                        @include media('<lg') {
                            font-size: rem(20px) !important;
                        }
                    }

                    &::after {
                        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="41" height="34" viewBox="0 0 41 34" fill="none"><path d="M4 23.7108C2.62128 26.5905 1.52142 29.8368 0.71167 33.4757L0.709503 33.4854L0 33.4757V0.777863H4V15.9294C6.3134 12.7795 9.07433 10.1259 12.2803 7.95984C19.0594 3.37976 27.4599 1.23062 37 0.777832C39.5 0.777832 41 0.777832 41 0.777832V4.77783C23.2394 4.77783 10.5106 10.1118 4 23.7108Z" fill="white"/></svg>');
                        position: absolute;
                        left: 0;
                        right: auto;
                        bottom: auto;
                        background: radial-gradient(ellipse at bottom right, transparent 42px, transparent 0px, transparent 0px, rgba(0, 0, 0, 0.30) 20px);
                        background-position-y: rem(0.8px);
                        transform: translateY(rem(-0.8px));
                    }

                    .ace-card-heading-bar {
                        position: absolute;
                        width: calc(100% - rem(40px));
                        height: rem(4px);
                        right: 0;
                        background: color(neutral, white);
                    }
                }

                .ace-button {
                    margin-top: auto;
                    box-sizing: border-box;

                    .cmp-button--secondary.btn-inverse {
                        background: color(neutral, white);
                        color: $text-brand;
                        margin-left: 0.25rem;
                        margin-bottom: 0.25rem;

                        .cmp-button__text {
                            color: $text-brand;
                        }

                        &:hover {
                            background: $card-offervariation-hover;
                        }


                        &:focus {
                            background: $bg-neutral-primary;
                            border: none;
                            outline: rem(2px) solid $border-brand;
                            outline-offset: rem(-2px);
                            border-width: rem(2px);
                            .cmp-button__text {
                                color: $text-brand;
                            }
                        }

                        &:active {
                            background: $card-offervariation-visited;
                        }
                    }
                }
            }
        }

        .ace-carousel-component-v2 {
            .ace-carousel__content {
                .ace-carousel__item {
                    @include media('>=md','<lg') {
                        padding: 0 rem(12px);
                    }
                }
            }
        }
    }
    // End of US ACDAA-10981 offer variation
}
