.duet-date__dialog-content {
    box-sizing: border-box;
    padding: rem(16px);
    border: rem(2px) solid $border-focus;
    border-radius: rem(6px);
    box-shadow: 0 rem(8px) rem(16px) rgba(37, 35, 57, 0.1);
    @include media(">=md") {
      min-width: rem(290px) !important;
      max-width: rem(310px) !important;
    }
  }

  .duet-date__input {
    &:disabled {
      background: color(neutral-black, 600);
    }
  }

  .duet-date__day {
    @include ui-body-01;

    color: color(neutral-black, 800);
    font-style: normal;

    &:hover {
      box-sizing: border-box;
      border: rem(2px) solid $border-interactive-selected;
      border-radius: rem(200px);
      text-decoration-color: $border-focus;
    }

    &.is-outside:disabled {
      color: color(neutral-black, 500);
      text-decoration: line-through;
    }
  }

  .duet-date__select,
  .duet-date__mobile-heading {
    @include ui-heading-03;

    font-style: normal;
  }

  .duet-date__table-header {
    @include ui-body-02;

    color: color(neutral-black, 600);
    font-style: normal;
    text-transform: lowercase;
  }

  .duet-date__table > thead > tr {
    border-bottom: rem(1px) solid color(neutral-black, 200);
  }

  .duet-date__dialog.is-active {
    border: none;
  }

  :root {
    --duet-color-primary: #3470df;
    --duet-color-text: #333;
    --duet-color-text-active: #fff;
    --duet-color-placeholder: #666;
    --duet-color-button: #f5f5f5;
    --duet-color-surface: #fff;
    --duet-color-overlay: rgba(0, 0, 0, 0.8);
    --duet-color-border: #333;
    --duet-font-normal: 400;
    --duet-font-size: 16px;
    --duet-radius: 4px;
    --duet-z-index: 600;
  }

  // duet picker booking engine styles goes here
  duet-date-picker {
    .duet-date__day.is-today {
      box-shadow: unset;

      &::before {
        background-color: unset;
      }
    }

    .duet-date__nav button,
    .duet-date__toggle {
      &:focus {
        outline: rem(2px) solid $border-focus;
        outline-offset: rem(1px);
        box-shadow: unset;
      }

      &:hover {
        color: color(neutral, white);
        background-color: $bg-brand-secondary;
      }

      &:active {
        color: color(neutral, white);
        background-color: $bg-brand-secondary-hover;
      }

      &:disabled {
        color: color(neutral-black, 600);
        font-weight: bold;
        background-color: color(neutral-black, 200);
        border: color(neutral-black, 200);
        pointer-events: none;

        &:focus {
          outline: unset;
        }
      }
    }

    .duet-date__day[tabindex="0"][aria-pressed="true"] {
      z-index: 200;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: rem(2px) solid $border-interactive-selected;
      border-radius: rem(200px);
      box-shadow: 0 0 rem(5px) var(--duet-color-primary);
      -webkit-text-decoration-color: #3470df;
      text-decoration-color: #3470df;
    }

    .duet-date__input:disabled {
      background-color: color(neutral-black, 200);
      border: color(neutral-black, 200);
      pointer-events: none;
    }

    .duet-date__day:not(.is-month) {
      text-decoration: line-through;
    }

    .duet-date__header {
      justify-content: center;
    }

    .duet-date__day[aria-pressed="true"],
    .duet-date__day:focus {
      color: color(neutral, white);
      background-color: $bg-accent-primary;
    }

    .duet-date__input {
      display: -webkit-inline-box;
      box-sizing: border-box;
      width: 100%;
      height: rem(48px);
      margin-top: rem(8px);
      padding: padding(03) padding(04);
      color: color(neutral-black, 800);
      font-size: 1rem;
      border: rem(1px) solid color(neutral, 500);
      border-radius: rem(6px);

      @include ui-body-01;
    }

    .duet-date__toggle {
      top: rem(8px);
      max-height: rem(48px);
      background: transparent;
    }

    @include media(">=md", "<lg") {
      .duet-date__dialog {
        position: absolute;

        &.is-active {
          position: relative;
        }
      }
    }

    @include media("<md") {
      duet-date-picker .is-active .duet-date__dialog-content {
        padding: 0 rem(30px);
      }

      .duet-date__toggle {
        z-index: 0;
      }

      .is-active .duet-date__dialog-content {
        padding: 0 padding(06);
        background-color: color(neutral, white);
      }

      .duet-date__dialog {
        top: auto;
      }

      .duet-date__dialog.is-active {
        z-index: 100;
        height: 100vh;
      }
    }

    .duet-date__select select {
      color: color(neutral-black, 900);

      @include ui-heading-03;
    }

    @include media(">560px", "<767px")  {
      .duet-date__dialog.is-active {
        height: unset;
      }

      .is-active .duet-date__dialog-content {
        padding: padding(04);
      }
    }
  }
