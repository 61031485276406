.heading-with-card {
    .ace-card-slideshow {
     .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section  {
        background-color: $bg-brand-primary;
        max-width: 100%;
        left: auto;
        padding: rem(48px);

        @include media('>=md','<lg') {
          top: rem(-165px);
          margin-left: calc(62.5% - 50vw);
          margin-right: calc(62.5% - 50vw);
        }

        @include media('>=sm','<md') {
          width: 81.5%;
          padding: rem(32px);
        }

        .cmp-slideshow__callouts .cmp-slideshow__callout-item .cmp-slideshow__callout-heading .cmp-slideshow__callout-title {
          margin-top: rem(8px);
          margin-bottom: rem(8px);
        }
      }

      &.full-width.single-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item {
          @include media('>=lg') {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          }
          a {
            @include media('>=lg') {
              display: flex;
              flex-direction: row;
              margin-left: 0;
              margin-right: auto;
            }
          }

        .cmp-slideshow__callout-heading {
          align-items: flex-start;
          @include media('>=sm','<lg') {
            text-align: left;
          }
          @include media('>=lg') {
            display: flex;
            flex-direction: column;
          }

          .slider-kicer {
            @include media('>=lg') {
              width: 31.12rem;
            }
          }

          .cmp-slideshow__callout-description {
            @include media('>=lg') {
              width: rem(498px);
            }
          }
        }
      }
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section  {
          align-items: flex-start;
          @include media('>=lg') {
            width: 49.5%;
            margin-right: calc(56% - 50vw);
            position: relative;
            inset: auto 0 11rem;
            margin-left: 32.33rem;
          }
        }
      }

      &.full-width.multi-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section {
          @include media('>=lg') {
            inset: auto 0 10rem 0;
            position: relative;
          }
        }
      }

      &.full-width-wide.single-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item .cmp-slideshow__callout-heading {
          align-items: flex-start;
          @include media('>=sm','<lg') {
            text-align: left;
          }
          .slider-kicer {
            @include media('>=lg') {
              width: 42.25rem;
            }
          }
        }
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section  {
          align-items: center;
          @include media('>=lg') {
            margin-left: calc(66.5% - 50vw);
            margin-right: calc(66.5% - 50vw);

          }
        }
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item:only-child {
          align-items: flex-start;
          @include media('>=lg') {
            flex-direction: row;
            justify-content: center;
            align-items: center !important;
          }
        }
      }

      &.fullgrid.single-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item {
          @include media('>=lg') {
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          }

         .cmp-slideshow__callout-heading {
          align-items: flex-start;
          text-align: left;
          @include media('>=lg') {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
          }
          .slider-kicer {
            @include media('>=lg') {
              width: 24.68rem;
            }
          }
          .cmp-slideshow__callout-title {
            text-align: left;
          }
          .cmp-slideshow__callout-description {
            @include media('>lg') {
              width: rem(395px);
            }
            text-align: left;
          }
        }

        a {
          @include media('>=lg') {
            display: flex;
            flex-direction: row;
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section  {
          align-items: flex-start;
          @include media('>=lg') {
            width: 39.5%;
            margin-right: 0;
            position: relative;
            inset: auto 0 11rem;
            margin-left: 32.13rem;
          }
        }
      }

      &.fullgrid.multi-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section {
          @include media('>=lg') {
            inset: auto 0 10rem 0;
            position: relative;
          }
        }
      }

      &.multi-content {
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item  {
          display: none;
          align-items: center !important;
          &.active {
            display: flex;
          }
          .cmp-slideshow__callout-heading {
              @include media('<lg') {
                align-items: center !important;
              }
              .slider-kicer {
                @include media('>=lg') {
                  width: 42.25rem;
                }
              }

            .cmp-slideshow__callout-description {
              @include media('>=sm','<md') {
                display: none !important;
              }
            }
          }
        }
        .cmp-slideshow .aem-Grid>.aem-GridColumn .cmp-slideshow__callout-section  {
          align-items: center;

          @include media('>=md','<lg') {
            top: rem(-195px);
          }
        }
        .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item:only-child {
          align-items: center;
        }
      }

      .slider-kicer {
        @include ui-label-01;
        font-style: normal;
        letter-spacing: 0.07em;
        color: color(neutral, white);
        opacity: 0.8;
        width: 17.43rem;
        @include media('>=md','<lg') {
          width: 30.37rem;
        }
      }

      .ui-body-01 .cmp-slideshow__callout-description {
        @include ui-body-01;
        font-style: normal;
        color: color(neutral, white);
      }

      .cmp-slideshow__callout-title .expressive-heading-03 {
        font-style: normal;
        color: color(neutral, white);

        @include media('>=lg') {
            @include expressive-heading-03;
        }
        @include media('>=md','<lg') {
            @include expressive-heading-04;
        }
        @include media('<md') {
            @include expressive-heading-05;
        }
      }

      .cmp-slideshow__actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .cmp-slideshow__action-content {
        white-space: nowrap;
        text-align: center;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(20px);
        color: color(neutral, white);
        html[dir='rtl'] & {
          direction: ltr;
        }
        @include media('>=md') {
          text-align: left;
          html[dir='rtl'] & {
            text-align: right;
          }
        }
      }

      hr {
        width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .cmp-slideshow__action {
        outline: none;
        background: transparent;
        border-radius: rem(40px);
        width: rem(40px);
        height: rem(40px);
        flex: 0 0 auto;
        display: flex;
        place-items: center;
        padding: 0;
        cursor: pointer;
        box-sizing: border-box;
        border: rem(1px) solid color(neutral, white);
        html[dir='rtl'] & {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        .icon {
          font-size: rem(24px);
          margin: auto;
        }
        &:focus {
          /* Safari 11+ */
          @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) and
              (stroke-color: transparent) {
              & {
                box-shadow: 0 0 0 rem(2px) rgba(255, 255, 255, 1),0 0 0 rem(4px) #3470df !important;
              }
            }
          }
        }
        &--disabled {
          background: color(neutral, white);
          pointer-events: none;
          * {
            pointer-events: none;
          }
        }
    }

    ol {
      &.cmp-slideshow__indicators {
        width: rem(29px);
        height: rem(20px);
        position: absolute;
        pointer-events: none;
        opacity: 0;
      }
    }

      li {
        &.cmp-slideshow__indicator {
          font-weight: 400;
          font-size: rem(14px);
          line-height: rem(20px);
          color: color(neutral, white);
        }
      }


      a {
        &.cmp-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: rem(60px);
          padding: rem(12px) rem(20px);
          width: rem(247px);
          height: rem(48px);

          &.btn-inverse--primary{
            &.btn-white {
              background: color(neutral, white);
              color: $color-primary;
              height: rem(24px);
              @include media ('<md') {
                width: auto !important;
              }
            }
          }

          &.btn-inverse--tertiary{
            &.btn-transparent {
              border: rem(1px) solid color(neutral, white);
              border-color: color(neutral-black, 100);
              background-color: $bg-brand-primary;
              color: color(neutral, white);
              height: rem(24px);
              @include media ('<md') {
                width: auto !important;
                max-width: 100%;
            }
            @include media('>=md','<lg') {
              margin-left: 0 !important;
            }
            }
          }

          &.cmp-button--secondary {
            border-color: color(neutral-black, 100);
            background-color: $bg-brand-primary;
            color: color(neutral, white);
            @include media ('<md') {
              width: auto;
              max-width: 100%;
            }
          }

          @include media('>=xl') {
            padding: rem(16px) rem(20px);
            width: rem(138px);
            height: rem(56px);
          }

          @include media('>=lg') {
            padding: rem(16px) rem(20px);
            width: rem(138px);
            height: rem(56px);
          }

          @include media('>=md', '<lg') {
            padding: rem(16px) rem(20px);
            width: rem(138px);
            height: rem(48px);
            align-content: center;
          }
        }
      }
      &.fullgrid {
        .slider-kicer {
          @include media('>=sm','<md') {
            width: 15.43rem;
          }
        }
      }
    }
    &.fullgrid {
      .slider-kicer {
        @include media('>=sm','<md') {
          width: 15.43rem;
        }
      }
    }
  }
  .ace-card-slideshow .cmp-slideshow .aem-Grid > .aem-GridColumn .cmp-slideshow__callout-section .cmp-slideshow__callouts .cmp-slideshow__callout-item .cmp-slideshow__callout-heading .cmp-slideshow__callout-description {
    display: block !important;
  }

// Heading with Card Component
.ace-heading-card-component {
    position: relative;

    @include media('>=lg') {
        margin-top: rem(88px);
    }
    @include media('>=md','<lg') {
        margin-top: rem(55px);
    }
    @include media('<md') {
        width: 100vw;
        margin-left: calc(50% - 51vw);
    }
    &__image {
        width: 100vw;
        margin-left: calc(50% - 50vw);
        @include media('<md') {
            display: flex;
        }
        .ace-image {
            line-height: 0;
            @include media('<md') {
                width: 100vw;
            }
            img {
                @include media('<md') {
                    width: 100%;
                }
            }
        }
    }
    &__content {
        padding: 0;
        border-radius: rem(6px);
        background-color: $bg-brand-primary;
        position: absolute;
        z-index: 1;
        @include media('>=lg') {
            top: rem(-88px);
            width:29%;
            padding: rem(48px);
        }
        @include media('>=md','<lg') {
            width: 44.5%;
            top: rem(-60px);
            margin-left: rem(12px);
            padding: rem(32px);
        }
       @include media('<md') {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem(32px);
        z-index: 999;
        width: 92%;
        margin-left: calc(50% - 45vw);
        margin-right: calc(50% - 50vw);
        position: absolute;
        margin-top: rem(-48px);
       }
        .ace-heading-card-component {
            &__kicker {
                color: color(neutral, white);
                @include ui-label-01;
                margin: 0 0 rem(40px) 0;
                display: flex;
                justify-content: center;

                @include media('<lg') {
                    margin: 0 0 rem(27px);
                }
            }
            &__logo {
                margin-bottom: rem(40px);
                position: relative;
                left: 21.42%;
                width: rem(161.96px);
                height: rem(55.37px);
                @include media('>=md','<lg') {
                    margin-bottom: rem(26px);
                    width: rem(94.47px);
                    left: rem(90px);
                    height: rem(32.3px);
                }
                @include media('<md') {
                  left: rem(2px);
                  width: rem(94.47px);
                  height: rem(32.3px);
                  margin-bottom: rem(26px);
                  width: rem(94.47px);
                  height: rem(32.3px);
                }
            }
            &__text {
                @include expressive-subheading-01;
                display: flex;
                justify-content: center;
                text-align: center;
                font-style: italic;
                color: color(neutral, white);
                margin: 0;
            }
        }
    }
}
