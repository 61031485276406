// padding spacing
$padding-list: (
  00: rem(0px),
  01: rem(4px),
  02: rem(8px),
  03: rem(12px),
  04: rem(16px),
  05: rem(24px),
  06: rem(32px),
  07: rem(40px),
  08: rem(48px),
  09: rem(64px),
);

// margin spacing
$margin-list: (
  00: rem(0px),
  01: rem(16px),
  02: rem(24px),
  03: rem(32px),
  04: rem(48px),
  05: rem(64px),
  06: rem(80px),
  07: rem(96px),
  08: rem(128px),
);

.padding-00 {
  @include media('>=sm') {
    padding-top: padding(00);
  }
}

.padding-01 {
  @include padding-01;
}

.padding-02 {
  @include padding-02;
}

.padding-03 {
  @include padding-03;
}

.padding-04 {
  @include padding-04;
}

.padding-05 {
  @include padding-05;
}

.padding-06 {
  @include padding-06;
}

.padding-07 {
  @include padding-07;
}

.padding-08 {
  @include padding-08;
}

.padding-09 {
  @include padding-09;
}

// margin spacing

.margin-00 {
  @include margin-00;
}

.margin-01 {
  @include margin-01;
}

.margin-02 {
  @include margin-02;
}

.margin-03 {
  @include margin-03;
}

.margin-04 {
  @include margin-04;
}

.margin-05 {
  @include margin-05;
}

.margin-06 {
  @include margin-06;
}

.margin-07 {
  @include margin-07;
}

.margin-08 {
  @include margin-08;
}

// spacing rules

footer {
  @include margin-06;
  &.margin-topzero{
    margin-top: 0;
  }
}
  main,#main-content {
    > .cmp-container > .aem-Grid > div {
  .footer-container > .cmp-container > .aem-Grid > div {
    @include media('>=sm') {
      margin-top: rem(40px);
    }

    @include media('>=md') {
      margin-top: rem(48px);
    }

    @include media('>=lg') {
      margin-top: rem(64px);
    }
  }
}
}

    main,#main-content {
      > .cmp-container > .aem-Grid > div {
        @include margin-07;
    }
  }


main, #main-content{
  > .cmp-container
  > .aem-Grid
  > div:is(.message, .booking-engine, .quick-access:not(.core-booking-engine), .slideshow, .sharedNavigation, .shared-footer, main.editable-container-layout > .cmp-container > .aem-Grid > div.responsivegrid) {
  @include margin-00;
}
}

main, #main-content {
  > .cmp-container > .aem-Grid > div.breadcrumb {
  &:first-child {
    margin-top: 0;
  }
  & + .heading {
    margin-top: rem(32px);
    @include media('>=md') {
      @include margin-06;
    }
  }
}
}

main.editable-container-layout, #main-content.editable-container-layout {
  > .cmp-container > .aem-Grid > div.responsivegrid > .cmp-container > .aem-Grid > div {
  @include margin-07;
}

}

main.editable-container-layout #main-content.editable-container-layout{
  > .cmp-container > .aem-Grid > div.responsivegrid {
  padding: 0 !important;
  .core-booking-engine {
    padding: rem(12px);
  }
}
}

main, #main-content {

> .cmp-container > .aem-Grid > div.dynamicmedia {
    @include margin-07;
}
}
main, #main-content{
  >.cmp-container>.aem-Grid>div {
  @include media('>=lg') {
  margin-top: 6rem;
}
@include media('>=md','<lg') {
  margin-top: 5rem;
}
@include media('<md') {
  margin-top: 4rem;
}
}
}
footer .footercontainer>.cmp-container>.aem-Grid>div {
    @include media('>=lg') {
    margin-top: 4rem;
    }
    @include media('>=md','<lg') {
      margin-top: 3rem;
    }
  @include media('<md') {
    margin-top: 2.5rem;
  }
}


.heading-hero {
    @include margin-07;
}
