.countdown-container {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(0px);
    gap: rem(8px);
    position: absolute;
    width: auto;
    height: auto;
    left: rem(16px);
    top: rem(92px);

    @include media('>=md', '<xl') {
        margin-left: rem(16px);
    }

    @include media('>=xl') {
        margin-left: rem(47px);
    }

}

.countdown-container.componentShow {
    display: flex;
}

.countdown-container.componentHide {
    display: none;
}

.countdown-categorytext {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem(0px);
    gap: rem(5px);

    width: auto;
    height: rem(40px);

    background-color: $bg-offer-secondary;

    border-radius: rem(4px);

    flex: none;
    order: 0;
    flex-grow: 0;

    @include media(">=md") {
        width: auto
    }
}

.countdown-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: rem(8px);
    padding-right: rem(8px);
    gap: rem(5px);

    width: auto;
    height: auto;

    background-color: $bg-offer-primary;
    border-radius: rem(4px);

    flex: none;
    order: 1;
    flex-grow: 0;
}

.countdown-value.valueHide {
    visibility: hidden;
}


.countdown-seperator {

    width: rem(18px);
    height: rem(0px);

    border: rem(1px) solid #FFFFFF;
    transform: rotate(90deg);
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: rem(-15px);
    margin-right: rem(-14px);
}

.countdown-seperator.seperatorHide {
    display: none
}

.countdown-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: rem(10px);

    width: auto;
    height: 100%;

    border-radius: rem(0px) rem(4px) rem(4px) rem(0px);
    flex: none;
    flex-grow: 0;

}

.countdown-label.labelHide {
    display: none
}

.countdown-typeofoffer {



    width: auto;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);

    color: $text-offer;


    flex: none;
    order: 0;
    flex-grow: 0;
}

.countdown-offercategory {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: rem(8px);
    gap: rem(10px);

    width: auto;
    height: rem(28px);

    border-radius: rem(4px) rem(0px) rem(0px) rem(4px);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.countdown-offercategory.offercategoryHide {
    display: 'none';

}

.countdown-labeltext {

    width: auto;
    height: auto;
    padding: rem(2px) 0;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(24px);

    color: $color-background;

    flex: none;
    order: 0;
    flex-grow: 0;

    @include media(">=md") {
        font-size: rem(18px);
    }
}

.countdown-labeltext.labelTextHide {
    display: none;
}

.countdown-labeltextended {

    width: auto;
    height: auto;
    padding: rem(2px) 0;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(24px);

    color: $color-background;


    flex: none;
    order: 0;
    flex-grow: 0;

    @include media(">=md") {
        font-size: rem(18px);
    }
}

.countdown-labeltextended.labeltextendedHide {
    display: none;
}

.countdown-valuetext {
    margin-right: rem(-5px);
    height: auto;
    padding: rem(2px) 0;
    display: flex;
    flex-direction: row;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: 100%;

    color: $color-background;


    flex: none;
    flex-grow: 0;

    @include media(">=md") {
        font-size: rem(18px);
    }
}

.countdown-valuetext.valuetextHide {
    display: none;
}

.countdown-days {
    display: flex;
    flex-direction: row;
    padding: rem(0px) rem(5px) rem(0px) rem(0px);

}

.countdown-days.daysHide {
    display: none
}

.countdown-hours {
    display: flex;
    flex-direction: row;
    padding: rem(0px) rem(5px) rem(0px) rem(0px);
}

.countdown-minutes {
    display: flex;
    flex-direction: row;
    padding: rem(0px) rem(5px) rem(0px) rem(0px);
}

.countdown-seconds {
    display: flex;
    flex-direction: row;
    padding: rem(0px) rem(5px) rem(0px) rem(0px);
}

#countdowndays {
    margin-right: rem(5px);
}

#countdownhours {
    margin-right: rem(5px);
}

#countdownminutes {
    margin-right: rem(5px);
}

.countdown-labeltextendedmobile {
    @include media(">md") {
        display: none;
    }
}

.countdown-labeltextendedmobile {
    width: auto;
    height: rem(24px);
    padding-top: rem(2px);

    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(24px);

    color: $color-background;



    flex: none;
    order: 0;
    flex-grow: 0;
    display: none;
}

.countdown-container {
    .countdown-value {
        .countdown-valuetext {
            p {
                margin: 0;
                line-height: rem(24px);
                font-size: rem(18px);
            }
        }

        .countdown-valuetext.valueTextHide {
            display: none;
        }
    }
}