$namespace: '.ace-core-booking-engine';
#{$namespace} {
  &__guests {
    @include media('<md') {
        margin-top: rem(24px);
    }
    .ui-body-01 {
      display: block;
      color: $text-body-primary;
    }
    fieldset {
      margin: 0;
      padding: 0;
      border: 0;
    }
    position: relative;
    &-title {
      margin: 0 0 rem(8px) 0;
    }
    .input {
      @include input-overide;
      width: 100%;
      text-align: initial;
      margin: 0;
      cursor: pointer;
      @include ellipsis;
      &.invalid:focus {
        border: rem(1px) solid #ba1f1a !important;
        box-shadow: 0 0 0 rem(1px) #ba1f1a !important;
      }
    }
    // dropdown
    #{$namespace}__dropdown {
      display: none;
      position: absolute;
      padding: rem(24px);
      width: 100%;
      background: color(neutral, white);
      border: rem(2px) solid $border-focus;
      box-sizing: border-box;
      box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 0.1);
      border-radius: rem(6px);
      margin-top: rem(12px);
      z-index: 99;
      &.show {
        display: block;
      }
      @include media('>=lg') {
        margin-top: rem(12px);
        width: rem(370px);
        margin-left: -12.125rem;
      }
      &:focus {
        outline: rem(2px) solid $border-focus;
      }

      &--content {
        width: 100%;
        &--auto {
          min-height: auto;
          @include media('<lg') {
            max-height: unset;
          }
        }
        &--visible {
          @include media('>=lg') {
            display: flex;
            overflow: visible;
            min-height: rem(430px);
            height: auto;
          }
        }
      }
      &--footer {
        margin-top: rem(16px);
        button {
          color: $color-link;
          text-decoration: underline;
          border: 0;
          background-color: unset;
          cursor: pointer;
        }
      }
    }
    // counter
    .ace-counter {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      input {
        width: rem(9px);
        appearance: none;
        border: 0;
        text-align: center;
        padding: 0;
        margin: 0 rem(12px);
        pointer-events: none;
        &:focus {
          outline: 0;
          border: 0;
        }
      }
      &__buttons {
        display: flex;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: rem(16px);
          height: rem(32px);
          width: rem(32px);
          border: rem(1px) solid $border-brand;
          box-sizing: border-box;
          border-radius: rem(54px);
          background-color: color(neutral, white);
          cursor: pointer;
          padding: 0 rem(6px);

          & * {
            pointer-events: none;
          }
          &:not(.disabled):focus {
            box-shadow: 0 0 0 rem(1px) color(neutral, white), 0 0 0 rem(3px) $border-focus !important;
          }
          &:focus {
            outline-offset: rem(1px);
          }
          &:hover {
            background-color: $bg-brand-secondary;
            .icon::before {
              color: color(neutral, white);
            }
          }
          &:active {
            background-color: $bg-brand-secondary-hover;
            .icon::before {
              color: color(neutral, white);
            }
          }
          &.disabled {
            border: $border-interactive-disabled;
            pointer-events: none;
            background-color: $bg-disabled-primary;
            font-weight: bold;
            .icon::before {
              color: $text-body-secondary;
            }
            &:focus {
              outline: unset;
            }
          }
        }
      }
      &__heading {
        pointer-events: none;

        &-title {
          display: block;
          color: #3e3d48;
          margin-bottom: 0;
        }
      }
    }
    // room cantainer
    .ace-room {
      display: flex;
      border-top: rem(1px) solid $border-decorative;
      padding-top: rem(24px);
      gap: rem(24px);
      flex-direction: column;
      margin-top: rem(24px);
      &.hidden {
        display: none;
      }
    }
  }
}

//Add And Delete Buttons

.ace-counter__buttons.buttonColumn{
  flex-direction: column;
  display: flex;
  width:100%;
}
.ace-counter__minus.deleteButton{
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-family: $font-family-roboto;
  color: $color-link;
  text-decoration: underline;
  cursor: pointer;
  width: 100% !important;
  outline: none;
  justify-content: flex-end !important;
}
.ace-counter__plus.addButton{
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-family: $font-family-roboto;
  color: $color-link !important;
  cursor: pointer;
  width: 100% !important;
  outline: none;
  justify-content: flex-start !important;
}
.deleteWrapper {
  border-bottom: 0.0625rem solid $border-decorative;
  padding-bottom: rem(24px);
  font-family: $font-family-roboto;
font-size: rem(16px);
font-style: normal;
font-weight: 400;
line-height: rem(24px);
text-decoration-line: underline;
}
.deleteWrapper.removePadding{
  padding-bottom: 0;
}
.addWrapper {
  padding-top: rem(24px);
font-family: $font-family-roboto;
font-size: rem(16px);
font-style: normal;
font-weight: 400;
line-height: rem(24px);
}

fieldset.ace-room.removeBorder {
  border: none;
}

.ace-core-booking-engine__guests .ace-room{
  border-bottom: 0.0625rem solid $border-decorative;
  padding-bottom: 1.5rem;
  border-top: none;
    padding-top: 0;
}
.ace-counter .deleteWrapper .ace-counter__minus.disabled{
  display:none;
}
.ace-core-booking-engine__guests .ace-room:first-child {
  margin-top: 0;
}
.addWrapper .ace-counter__plus.addButton.disabled {
  display:none;
}
#core-booking-engine-room-counter-12345 {
  display:none !important;
}
.ace-core-booking-engine__guests .ace-counter__buttons {
  .addWrapper button{
  border:none !important;
}
.deleteWrapper button{
  border:none !important;
}
}
.addWrapper .icon-plus-24icon {
  display: flex;
  align-items: center;
  &::before {
    color: $color-link !important;
  }
}
.addWrapper .icon-plus-24icon:before {
  height: rem(24px);
  display: flex;
  width: rem(24px);
  align-items: center;
  justify-content: center;
}
