$bg-color: #544D84;

.ace-button {
  width: auto;
  display: flex;
}

.call-to-action.ace-button--flex {
  .ace-button {
    display: flex;
  }
}

a.cmp-button,
button.cmp-button {
  &.cmp-button--primary:focus {
    background-color: $bg-brand-secondary;
    outline: rem(2px) solid $border-focus;
    color: color(neutral, white);
    border: 0.0625rem solid color(neutral, white);
  }

  &.cmp-button--inverse:focus {
    background-color: color(neutral, white);
    outline: rem(2px) solid $border-focus;
    color: color(brand, 900);
  }

  &.cmp-button--secondary:focus {
    background-color: color(neutral, white);
    color: $text-brand;
    outline: rem(1px) solid color(neutral, white);
    box-shadow: 0 0 0 rem(3px) $border-focus;
  }

  &.cmp-button--accent:focus {
    background-color: $bg-accent-primary;
    outline: rem(2px) solid $border-focus;
    color: color(neutral, white);
    border: 0.0625rem solid color(neutral, white);
  }
}

.cmp-button:disabled,
.cmp-button[disabled] {
  background-color: color(neutral-black, 200) !important;
  color: color(neutral-black, 600) !important;
  border-radius: rem(60px);
  padding: rem(12px) rem(24px);
  border-color: color(neutral-black, 200) !important;

  span[data-placement='left'] {
    display: block;
    order: 0;
    width: rem(13px);
    top: rem(4px);

    path {
      fill: color(neutral-black, 600);
    }

    &::before {
      display: none;
    }
  }

  span[data-placement='right'] {
    display: block;
    order: 1;
    width: rem(13px);
    top: rem(4px);

    path {
      fill: color(neutral-black, 600);
    }

    &::before {
      display: none;
    }
  }

  &:hover {
    .cmp-button__text {
      text-decoration: none;
    }
  }

  &:active {
    background-color: color(neutral-black, 200);
  }

  &:focus {
    outline: none;
    color: color(neutral-black, 600);
    background-color: color(neutral-black, 200);
    box-shadow: none;
  }
}

.cmp-button {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: rem(14px) rem(24px);
  justify-content: center;
  transition: all 2ms ease-in;

  span[data-placement='left'] {
    color: color(neutral, white);
    width: rem(13px);
    top: rem(4px);
    margin-right: rem(6.5px);

    path {
      fill: color(neutral, white);
    }

    &::before {
      display: none;
    }
  }

  span[data-placement='right'] {
    display: block;
    order: 1;
    width: rem(13px);

    path {
      fill: color(neutral, white);
    }

    top: rem(4px);
    margin-left: rem(6.5px);

    &::before {
      display: none;
    }
  }

  &__text {
    @include ui-body-01;
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    text-decoration-line: none;
    white-space: nowrap;
  }

  &:hover {
    text-decoration: none;
  }

  &--primary {
    background-color: $bg-brand-secondary;
    color: color(neutral, white);
    text-decoration: none;
    border-radius: rem(60px);
    padding: rem(12px) rem(24px);

    &[default],
    &:default {
      background-color: $bg-brand-secondary;
      color: color(neutral, white);
    }

    &:hover {
      .cmp-button__text {
        text-decoration: none;
      }

      background-color: $bg-brand-secondary-hover;
    }

    &:active {
      background-color: $bg-brand-secondary-pressed;
    }

    &:focus {
      outline: rem(2px) solid $border-focus;
      color: color(neutral, white);
    }
  }

  &--inverse,
  &.inverse {
    background-color: color(neutral, white);
    color: color(brand, 900);
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .cmp-button__text {
        text-decoration: none;
      }
    }
  }

  &--secondary {
    background-color: color(neutral, white);
    border: rem(1px) solid $border-brand;
    box-sizing: border-box;
    color: $text-brand;
    text-decoration: none;
    border-radius: rem(60px);
    padding: rem(11px) rem(23px);

    &[default],
    &:default {
      background-color: color(neutral, white);
      border: rem(1px) solid $border-brand;
    }

    span[data-placement='left'] {
      path {
        fill: $color-secondary;
      }

      &::before {
        display: none;
      }
    }

    span[data-placement='right'] {
      path {
        fill: $color-secondary;
      }

      &::before {
        display: none;
      }
    }

    &:hover {
      .cmp-button__text {
        text-decoration: none;
      }

      background-color: $bg-brand-secondary;
      color: color(neutral, white);

      span[data-placement='left'] {
        path {
          fill: color(neutral, white);
        }

        &::before {
          display: none;
        }
      }

      span[data-placement='right'] {
        path {
          fill: color(neutral, white);
        }

        &::before {
          display: none;
        }
      }
    }

    &:active {
      background-color: $bg-brand-secondary-hover;
      color: color(neutral, white);
    }

    &:focus {
      outline: rem(2px) solid color(neutral, white);
      color: $text-brand;

      span[data-placement='left'] {
        path {
          fill: $color-secondary;
        }

        &::before {
          display: none;
        }
      }

      span[data-placement='right'] {
        path {
          fill: $color-secondary;
        }

        &::before {
          display: none;
        }
      }
    }

    a#{&}:hover {
      text-decoration: none !important;
    }

    &__icon {
      @include ui-body-02;
      font-weight: bold;
      font-size: (20px);
      height: rem(12px);
      display: flex;
      align-items: center;
      width: rem(18px);

      &::before {
        font-size: rem(18px);
      }
    }
  }

  &--accent {
    background-color: $bg-accent-primary;
    border-color: color(neutral, white);
    box-sizing: border-box;
    color: color(neutral, white);
    text-decoration: none;
    border-radius: rem(60px);
    padding: rem(12px) rem(24px);

    &[default],
    &:default {
      background-color: $bg-accent-primary;
      color: color(neutral, white);
    }

    &:hover {
      .cmp-button__text {
        text-decoration: none;
      }

      background-color: $bg-accent-primary-hover;

      span[data-placement='left'] {
        &::before {
          color: color(neutral, white);
        }
      }

      span[data-placement="right"] {
        &::before {
          color: color(neutral, white);
        }
      }
    }

    &:active {
      background-color: $bg-accent-primary-pressed;
    }

    &:focus {
      outline: rem(2px) solid $border-focus;
      color: color(neutral, white);
    }

    a#{&}:hover {
      text-decoration: none !important;
    }

    &__icon {
      @include ui-body-02;
      font-weight: bold;
      font-size: (20px);
      height: rem(12px);
      display: flex;
      align-items: center;
      width: rem(18px);

      &::before {
        font-size: rem(18px);
      }
    }
  }

  /*Tertiary button*/
  &--tertiary {
    box-sizing: border-box;
    text-decoration: none;
    border-radius: rem(60px);
    padding: rem(11px) rem(23px);
    background-color: transparent;
    color: $text-brand;
    border: rem(1px) solid $border-brand;

    &:hover {
      background: color(stratos-blue, 1000);

      span {
        color: color(neutral, white);
      }

      svg path {
        fill: color(neutral, white);
      }
    }

    &:active {
      background-color: $bg-brand-secondary-hover;

      span {
        color: color(neutral, white);
      }

      svg path {
        fill: $text-inverse-body-primary;
      }
    }

    &:focus {
      background-color: transparent;
      color: $text-brand;
      outline: rem(2px) solid color(neutral, white);
      box-shadow: 0 0 0 rem(3px) color(royal-blue, 500);
    }

    &:disabled,
    &[disabled] {
      background-color: color(neutral-black, 200) !important;

      span {
        color: $text-disabled;
      }

      svg path {
        fill: $text-disabled;
      }
    }
  }
}

/*Button Inverse Variation*/
a.cmp-button,
button.cmp-button {
  &.cmp-button {
    &--primary {
      &.btn-inverse {
        background-color: color(neutral, white);
        color: $text-brand;

        &:hover {
          background: rgba(255, 255, 255, 0.9);
        }

        &:active {
          background: rgba(255, 255, 255, 0.8);
        }

        &:focus {
          background-color: color(neutral, white);
          outline: rem(2px) solid $border-brand;
          box-shadow: 0 0 0 rem(3px) color(neutral, white);
        }

        &:disabled,
        &[disabled] {
          background-color: color(neutral-black, 200) !important;

          span {
            color: color(neutral-black, 500);
          }
        }
      }
    }

    &--secondary {
      background-color: transparent;

      &:hover {
        background-color: $bg-brand-secondary;
      }

      &:active {
        background-color: $bg-brand-secondary-hover;
        border-color: $border-brand-hover;
      }

      &:focus {
        background-color: transparent;
      }

      &.btn-inverse {
        background-color: transparent;
        border-color: color(neutral, white);

        span {
          color: color(neutral, white);
        }

        &:hover {
          background: color(neutral, white);

          span {
            color: $text-brand;
          }
        }

        &:active {
          background: rgba(255, 255, 255, 0.9);

          span {
            color: $text-brand;
          }
        }

        &:focus {
          background: transparent;
          outline: none;
          box-shadow: none;
          border: rem(4px) double color(neutral, white);

          span {
            color: color(neutral, white);
          }
        }

        &:disabled,
        &[disabled] {
          background-color: color(neutral-black, 200) !important;

          span {
            color: color(neutral-black, 500);
          }
        }
      }
    }

    &--accent {
      &.btn-inverse {
        &:focus {
          border: rem(2px) solid $border-brand;
          outline: rem(2px) solid color(neutral, white);
        }
      }
    }

    &--tertiary {
      &.btn-inverse {
        background-color: transparent;
        border-color: color(neutral, white);
        color: color(neutral, white);

        &:hover {
          background-color: color(neutral, white);
          border-color: color(neutral, white);

          span {
            color: $text-brand;
          }
        }

        &:active {
          background: rgb(255, 255, 255);
          background: rgba(255, 255, 255, 0.9);
          border-color: color(neutral, white);

          span {
            color: $text-brand;
          }
        }

        &:focus {
          background-color: transparent;
          outline: none;
          border: rem(4px) double color(neutral, white);
          box-shadow: none;

          span {
            color: color(neutral, white);
          }
        }

        &:disabled,
        &[disabled] {
          background-color: color(neutral-black, 200) !important;

          span {
            color: color(neutral-black, 500);
          }
        }
      }
    }
  }
}

button.cmp-button {

  &[disabled="disabled"],
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    border: none;
  }
}

.call-to-action {
  @include media('>=lg') {
    padding-left: rem(6px);
  }

  @include media('>=md') {
    text-align: center;
  }

  .ace-button {
    display: inline-block;
    height: auto;

    @include media('>=md') {
      padding-right: rem(16px);
    }

    @include media('<md') {
      display: flex;
      flex-direction: column;
      padding-bottom: rem(16px);
      margin-left: calc(55% - 50vw);
      margin-right: calc(55% - 50vw);
    }

    .cmp-button.cmp-button--secondary {
      background-color: color(stratos-blue, 1000);
      color: color(neutral, white);
      padding: rem(12px) rem(24px);
      height: rem(48px);

      &:hover {
        background-color: $bg-brand-secondary-hover;
        color: color(neutral, white);
      }

      &:active {
        background-color: $bg-color;
      }

      &:focus {
        background-color: $bg-brand-secondary;
        outline: rem(1px) solid color(neutral, white);
      }
    }

    .cmp-button.cmp-button--tertiary {
      padding: rem(12px) rem(24px);
      height: rem(48px);
    }

    .call-to-action__link {
      color: $color-link;
      display: flex;
      justify-content: center;
      @include ui-body-01;
      text-decoration: underline;

      @include media('>=lg') {
        margin-bottom: rem(24px);
      }

      @include media('<lg') {
        margin-bottom: rem(16px);
      }

      @include media('<md') {
        display: flex;
        justify-content: center;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $color-link-hover;
      }

      &:focus {
        background: $bg-focus;
        color: color(neutral, white);
        text-decoration: underline;
      }

      &:visited {
        color: $text-link-visited;
        text-decoration: underline;
      }
    }
  }

  .ace-button {
    display: inline-block;

    @include media('<md') {
      display: flex;
      flex-direction: column;
      padding-bottom: rem(16px);
    }

    &:last-child {
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

//link variation //

@mixin link {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $color-link;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24px);
  text-decoration: underline;
  cursor: pointer;

  &:active {
    color: $text-body-secondary;

    span::before {
      color: $text-body-secondary;
    }
  }

  &:focus,
  &:focus-visible {
    background-color: $color-link;
    color: $color-text-inverted  !important;

    span,
    .icon::before {
      background-color: $color-link;
      color: $color-text-inverted  !important;
    }
  }

  &:hover {
    color: $text-link-hover  !important;

    span,
    .icon::before {
      color: $text-link-hover  !important;
    }
  }

  &:visited {
    color: $text-link-visited;

    span,
    .icon::before {
      color: $text-link-visited;
    }
  }
}

@mixin link-inverse {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  color: $color-text-inverted;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24px);
  text-decoration: underline;

  &:hover,
  &:active,
  &:visited {
    color: $headinghero-cta-visited;

    span,
    span::before,
    span::after {
      color: $headinghero-cta-visited;
    }
  }

  &:focus,
  &:focus-visible {
    background-color: $color-background;
    color: $color-link;

    span,
    span::before,
    span::after {
      background-color: $color-background;
      color: $color-link  !important;
    }
  }
}

@mixin link-icon {
  @include link;
  text-decoration: none;
  gap: rem(4px);

  span,
  .icon {
    color: $color-link  !important;
    position: relative;
    top: rem(2px);

    &::before {
      color: $color-link  !important;
    }
  }

  .icon {
    width: 1.5rem;
    height: auto;
  }
}

@mixin link-icon-inverse {
  @include link-inverse;
  text-decoration: none;

  &::after {

    &:focus,
    &:focus-visible {
      color: $color-link;
    }
  }
}

.cmp-link {
  @include link;

  &.cmp-link {
    &--inverse {
      @include link-inverse;
    }

    &--icon {
      @include link-icon;
    }

    &--icon-inverse {
      @include link-icon-inverse;
    }
  }
}

