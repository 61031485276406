@include media('<md') {
  .callout-section {
    .aem-GridColumn {
      padding: 0;
    }
    .cmp-teaser {
      &.ace-callout-component {
        margin: 0;
      }
    }
    .aem-Grid.aem-Grid--12 > .aem-GridColumn {
      width: 100%;
      padding-left: rem(8px);
      padding-right: rem(8px);
    }
  }
}
.cmp-teaser {
  width: 100%;
  &:hover {
    text-decoration: none;
    h3,a {
      text-decoration: none;
    }
    &.cmp-teaser__image__zoom {
      img {
        transform: scale(1.1);
      }
    }
  }
  &:focus {
    background: transparent !important;
    outline: rem(2px) solid $border-focus;
    outline-offset: rem(-1px);
    display: block;
  }
  &__tagline {
    text-decoration: none;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: rem(16px);
    background: color(neutral, white);
    padding: rem(8px) rem(12px);
    &-link {
      color: color(brand, 900);
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: rem(32px);

    @include media('<=sm') {
      margin-bottom: rem(24px);
    }
    .cmp-ace-image {
      width: 100%;
      display: flex;
      overflow: hidden;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0;
    }
    > div {
      @include media('>md') {
        width: 100%;
      }
      display: flex;
    }
    img {
      width: 100%;
      margin-bottom: 0;
      transition: transform 0.7s;
      height: auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    a:not(.ace-callout-component-rating__link):after, a:not(.ace-callout-component-default__link):after {
      bottom: 0.125rem;
      content: '';
      left: 0.375rem;
      position: absolute;
      right: 0.375rem;
      top: 0;
      z-index: 1;
    }

    a.ace-callout-component-rating__link, a.ace-callout-component-default__link {
      position: relative;
      z-index: 2;
      display: flex;
      color: $color-link;

      &:visited {
        color: $text-link-visited;
      }

      &:hover {
        color: $color-link-hover;
      }

      &:focus {
        background-color: $bg-focus;
        color: color(neutral, white);
      }
    }

    .cmp-teaser__reviews + a.ace-callout-component-rating__link , .cmp-teaser__reviews + a.ace-callout-component-default__link{
      margin-top: rem(8px);
    }
    a:focus {
      outline-offset: rem(-2px);
    }
  }
  &__pretitle {
    color: $text-heading;
    padding: 0;
    margin: rem(0px) 0 rem(8px) 0;
    width: 100%;
  }
  &__title {
    text-decoration: none;
    color: color(brand, 900);
    text-transform: capitalize;
    margin: 0 !important;
    padding: 0;
    white-space: normal;
  }
  &__title-link {
    text-decoration: none;
    color: color(brand, 900);
    margin: 0;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: '';
    }
  }
  &__description {
    @include ui-body-01;

    &:empty {
      display: none;
    }
    margin-bottom: 0;
    min-height: rem(20px);
    overflow: hidden;
    color: $text-body-secondary;
    width: 100%;
    white-space: normal;
    p {
      color: $text-body-secondary;
      margin: 0;
      white-space: normal;
    }

    @include media('>md') {
      max-width: 100%;
    }

    margin-top: 0;
  }
  &__action--link {
    &:after {
      position: absolute;
      top: rem(0px);
      right: rem(6px);
      bottom: rem(2px);
      left: rem(6px);
      z-index: 1;
      content: '';
    }
    &:focus {
      background: none;
      color: #8c7343;
      text-decoration: underline;
      &:after {
        outline: rem(8px) ridge rgba(52, 112, 223, 0.5);
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__action-link {
    font-size: 0.875rem;
    display: inline-block;
    border: rem(1px) solid color(brand, 900);
    padding: 0 rem(24px);
    color: color(brand, 900);
    line-height: 2.875rem;
    text-decoration: none;
    margin-top: rem(24px);
    font-weight: 400;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: rem(2px) solid $border-focus;
      color: color(brand, 900);
      background: transparent;
      outline-offset: rem(-2px);
    }
  }
  &__image-tag {
    position: absolute;
    bottom: 0;
    padding: 2rem 2rem;
    display: block;
    align-items: center;
    justify-content: flex-start;
  }
  &__image-tag-link {
    display: inline-block;
    padding: 0 rem(24px);
    color: color(brand, 900);
    text-decoration: underline;
    z-index: map-get-deep($z-index, main, callout);
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: rem(768px)) {
    &__image-tag {
      padding: 1rem;
    }
  }
  .one-line-clamp {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .two-line-clamp {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .three-line-clamp {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}
.ace-callout {
  margin-top: rem(96px);
  position: relative;
  overflow: hidden;
  .cmp-teaser {
    position: relative;
    overflow: hidden;
  }

  @include media('<=md') {
    width: 100% !important;
  }
  &__link {
    &:hover {
      text-decoration: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    h2,span {
      pointer-events: none;
    }
  }
}
.ace-carousel {
  .ace-callout {
    margin-top: 0;

    @include media('<md') {
      margin-top: 0;
    }
  }
}
.ace-roomcarousel {
  .cmp-teaser {
    &__description {
      @include media('>md') {
        max-width: 100%;
      }
    }
  }
}

@include media('>md') {
  .mov-callout {
    &.ace-callout {
      padding-left: 0;
      .cmp-teaser {
        margin-left: 0;
      }
    }
    + .mov-callout.ace-callout {
      padding-right: 0;
      .cmp-teaser {
        margin-left: rem(8px);
      }
    }
  }
}
html {
  -webkit-font-smoothing: antialiased;
}
.cmp-teaser {
  &__logo-slot {
    position: absolute;
    background-color: color(neutral, white);
    left: rem(16px);
    top: rem(16px);
    border-radius: rem(6px);
    padding: 0;
    z-index: 1;
    width: rem(70px);
    height: rem(40px);
    display: flex;
    align-content: center;
    justify-content: center;

    img {
      width: 100%;
      height: rem(40px);
    }
  }
  &.ace-callout-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    &.hidden {
      display: none;
    }
  }
  &__cell {
    border-radius: rem(6px);
  }
  &__gallery {
    position: absolute;
    bottom: rem(16px);
    left: rem(16px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    color: $color-link;
    background-color: color(neutral, white);
    border: none;
    border-radius: rem(6px);
  }
  &__bedge {
    position: absolute;
    right: rem(16px);
    bottom: rem(16px);
    background-color: color(neutral, white);
  }
  &__imageSet {
    margin-bottom: rem(12px);
    .ace-image {
      margin-top: 0;
    }
  }
  &__pretitle {
    margin-bottom: rem(8px);
    color: $text-heading;
    white-space: break-spaces;
  }
  &__reviews {
    display: flex;
    flex-direction: row;
    margin: 0 0 0.5rem 0;
    align-items: center;
    .cmp-teaser__rating-logo {
        display: flex;
        margin-left:rem(6px);
      }
    .cmp-teaser__rating-star {
        display: flex;
        margin: 0 rem(2px);
      }
    .cmp-teaser__rating-fill {
        width: rem(12px);
        height: rem(12px);
        margin-right: rem(2px);
        border-radius: rem(4px);
        border: rem(1.5px) solid $border-brand;
        background: linear-gradient(to right, color(neutral, white) 100%);
      }
  }
  &__rating-score {
    color: $text-body-primary;
  }
  &__reviews-text {
    a {
      color: $color-link;
    }
  }
  &__rating-eyes {
    display: flex;
  }
  &__kicker {
    color: $text-body-secondary;
  }
}
.ace-callout-section {
  @include media('>=md', '<lg') {
    &.aem-Grid.aem-Grid--12 {
      > .aem-GridColumn {
        &.aem-GridColumn--default--3,
        &.aem-GridColumn--default--4 {
          width: 50%;
        }
      }
    }
  }
}

.ace-callout-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .ace-button {
    padding: 0;
    border-radius: rem(60px);

    @include media('<md') {
      width: 100%;
    }

    .cmp-button__text {
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 81rem;
    }
  }

  .ace-callout-component {
    &.ace-callout-component-rating {
      margin-bottom: rem(50px);

      @include media('<md') {
        margin: 0 0 rem(32px) 0;
      }
    }

    &.ace-callout-component-default {
      margin-bottom: rem(32px);

      @include media('<md') {
        margin: 0 0 rem(32px) 0;
      }

    }
  }

  .ace-callout-section li:last-child {
    .ace-callout-component-default {
      @include media('<md') {
        margin: 0;
      }
    }
  }
}

.callout-section {
    li {
      list-style: none;
    }
  }
.callout-container {
  @include media('>md') {
    padding: rem(0px);
  }

  .cmp-container {
    width: 100%;

    .callout-section {
      .ace-callout-section {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
/*Fix for Callout spacing issue*/
.ace-callout-container {
  & > .cmp-container .callout-section:last-child {
    margin-bottom: rem(-24px);

    @include media('<md') {
      margin-bottom: 0;
    }
  }

  .ace-loyalty__inner.ace-button {
    margin-top: 2rem;

    @include media('>=md') {
      margin-top: 3.125rem;
    }
  }
}

/* Callout List Blue Variation */
.cmp-teaser {
  &.ace-callout-list-blue {
    background-color: $bg-loyalty-secondary;
  }

  &.ace-callout-list-blue,
  &.ace-callout-list-vcard {
    display: flex;
    flex-direction: row;
    border-radius: rem(6px);
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;

    @include media('<md') {
      margin-bottom: rem(16px) !important;
      height: auto;
    }

    @include media('>=md','<lg') {
      margin-bottom: rem(16px) !important;
      height: 100%;
    }

    .cmp-teaser {
      &__logo-slot {
        display: none;
      }

      &__image {
        padding: 1.5rem;
        overflow: visible;

        @include media('<md') {
          padding: rem(16px);
        }

        img {
          border: 0;
          padding: rem(4px);
          &::before {
            font-size: rem(32px);
          }

          @include media('<md') {
            padding: 0;
          }
        }

        &.cmp-teaser__imageSet {
          margin: rem(24px);
          padding: 0;
          .ace-image{
            justify-content: center;
            align-items: center;
          }
        }
      }

      &__content {
        padding: 1.5rem;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;

        @include media('<md') {
          padding: rem(16px);
          padding-left: 0;
        }
      }

      &__pretitle {
        color: $text-heading;
        padding: 0;
        margin: 0 0 rem(8px) 0;
        width: 100%;
        white-space: break-spaces;
        display: flex;
        @include ui-heading-03;
      }

      &__kicker {
        @include ui-body-01;
        color: $text-body-secondary;
        margin: 0 0 rem(8px) 0;
      }

      &__description {
        p {
          @include ui-body-01;
          color: $text-body-primary;
        }

        @include media('>md') {
          max-width: 100%;
        }
      }
    }
  }
}

.ace-callout-section-blue-card {
  li {
    margin-bottom: rem(24px);
    @include media('<md') {
      margin-bottom: 0;
      padding: 0 1rem !important;
    }

    &.hidden {
      display: none;
    }
  }
}

.cmp-button {
  &--secondary {
    &.ace-guest-viewmore {
      &:hover {
        background: color(stratos-blue, 1000);
        color: color(neutral, white);
      }

      &:focus {
        outline: rem(1px) solid color(neutral, white);
        box-shadow: 0 0 0 rem(3px) $border-focus;
      }

      &.hidden {
        display: none;
      }
    }
  }
}
/* Callout Clickable Variation */
.cmp-teaser {
  &.ace-callout-clickable {
    display: flex;
    flex-direction: row;
    border-radius: rem(6px);
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
    border: rem(1px) solid $border-decorative;

    @include media('<md') {
      margin-bottom: rem(16px) !important;
      height: auto;
    }
    .cmp-teaser {
      &__logo-slot {
        display: none;
      }

      &__image {
        padding: 1.5rem;
        overflow: visible;
        @include media('<md') {
          padding: 1rem;
        }

        img {
          width: rem(96px) !important;
          height: rem(56px) !important;
          border-radius: rem(6px);
          display: flex;
          padding: rem(5px) 0;
          justify-content: center;
          align-items: center;
          @include media('<md') {
            width: rem(76px) !important;
            height: rem(43px) !important;
            padding: 0;
          }

          &::before {
            font-size: rem(32px);
          }
        }

        &.cmp-teaser__imageSet {
          margin: rem(24px);
          padding: 0;
        }
      }

      &__content {
        padding: 1.5rem;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        @include media('<md') {
          padding: 1rem 1rem 1rem 0;
        }
      }

      &__pretitle {
        color: $text-heading;
        padding: 0;
        margin: 0 0 rem(8px) 0;
        width: 100%;
        white-space: break-spaces;
        display: flex;
        @include ui-heading-03;
      }

      &__kicker {
        @include ui-body-01;
        color: $text-accent;
        margin: 0 0 rem(4px) 0;
      }

      &__description {
        p {
          @include ui-body-01;
          color: $text-body-secondary;
          max-width: 100%;
        }

        @include media('>md') {
          max-width: 100%;
        }
      }
    }
  }
}

.ace-callout-container[data-callout-type='iconslot'] {
  .callout-section {
    .ace-callout-section {
      @include media('>md') {
        margin-bottom: 0;
      }

      margin-bottom: rem(-36px);
    }
  }
}
/*Callout list*/
.callout-list {
  .ace-callout-section {
    &.aem-Grid.aem-Grid--12 {
      .aem-GridColumn.aem-GridColumn--default--4 {
        @include media('>=md', '<lg') {
          width: 33.33%;
          margin-bottom: 0;
        }
      }
    }
  }
}

.callout_list {
  .aem-GridColumn {
    &:last-child {
      .callout_list__item {
        margin-bottom: 0;
      }
    }
  }
  &__item {
    position: relative;
    margin-bottom: rem(48px);

    @include media('>=md') {
      margin-bottom: 0;
    }
    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      width: rem(36px);
      margin-right: 1.5rem;
      img {
        width: 100%;
        height: rem(36px);
        object-fit: contain;
        border: 0 !important;
      }
    }
    &__content {
      margin-left: rem(60px);
      &__title {
        h3,a {
          @include ui-heading-03;
          margin-top: 0;
          margin-bottom: 0.5rem;
          min-height: 1.5rem;
          display: block;
        }

        a:hover {
          text-decoration: none;
        }

        a:hover {
          text-decoration: none;
        }
      }
      &__desc {
        p {
          @include ui-body-01;
          margin: 0;
          word-break: break-all;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ace-callout {
    &-container-filters {
      &__filter-counter {
        right: auto;
        left: 0;
      }
    }
  }

  .cmp-teaser__logo-slot {
    right: rem(16px);
  }

  .cmp-accordion__button {
    text-align: right !important;
  }
}
/*Fix for ACDAA-3869*/
.ace-callout-container {
  > .cmp-container {
    > .callout-section {
      margin-bottom: -2rem;
      @include media('>=md') {
        margin-bottom: -1.5rem;
      }
    }
  }
  &[data-callout-type="rating"] {
    > .cmp-container {
      > .callout-section {
        margin-bottom: -2rem;

        @include media('>=md') {
          margin-bottom: -3.125rem;
        }
      }
    }
  }
}

.ace-callout-container[data-callout-type='iconslot'] {
  > .cmp-container {
    > .callout-section {
      margin-bottom: 0;
      ul {
        margin-bottom: 0;
      }
    }
  }
  .ace-loyalty__inner {
    margin-top: 0;
  }
}

.ace-guest-viewmore {
  margin-top: rem(38px);

  @include media('>=md') {
    margin-top: rem(64px);
  }

  @include media('>=lg') {
    margin-top: rem(48px);
  }
}
.cmp-teaser {
  &.ace-callout-list-vcard {
    .cmp-teaser__imageSet {
      margin: 1.5rem;
      width: rem(56px) !important;
      height: rem(56px) !important;
      border: 0;
      border-radius: rem(6px);
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;
    }
  }
}

.offerpagelink {
  display: flex;
  align-items: center;
  .ace-highlight__icon {
    margin-left: rem(5px);
  }
}

main,#main-content {
  .cmp-container {
    .aem-Grid {
      .callout-container {
        &.responsivegrid {
          margin-top: 6rem;
          @include media('<md') {
            margin-top: 4rem;
          }
          @include media('>=md', '<lg') {
            margin-top: 5rem;
          }
        }
      }
    }
  }
}
.cmp-teaser__content {
  .ace-callout-component-default__link {
    margin:0 0 0.5rem 0;
    color: $text-accent;
    &.show{
        display: block;
      }
      &.hide{
        visibility: hidden;
        opacity: 0;
        display: block;
        position: absolute;
      }
  }
}
.cmp-teaser__content {
  .cmp-teaser__description {
    max-width: 100%;
    margin-bottom: rem(8px);
    ol {
      padding-left:rem(26px);
      li {
        list-style: inherit;
        &::marker {
            color: inherit;
          }
      }
    }
    ul{
        padding-left:rem(26px);
        li {
            list-style: disc;
            &::marker {
                color: inherit;
              }
          }
    }
  }
}
.ace-callout-component{
  .cmp-teaser__content{
    .cmp-teaser__kicker{
      margin-bottom: rem(8px);
    }
  }
}
.cmp-button{
  span.cmp-button__text{
text-align: center;
white-space: normal;
  }
}

.cmp-button{
  span.cmp-button__text{
    white-space: normal;
    text-align: center;
  }
}
.ace-callout-container {
    &[data-callout-type="rating"] {
        > .cmp-container {
          > .callout-section {
            margin-bottom: 0rem;
            @include media(">=md") {
              margin-bottom: 0rem;
            }
          }
        }
      }
  div.ace-callout-component{
  &.ace-callout-component-rating{
    margin-bottom: rem(82px);
    @include media("<md") {
      margin-bottom: rem(66px);
    }
  }
  }
  }

  .cmp-teaser__content{
    h2,h3,h4,h5, .cmp-teaser__pretitle{
     white-space:normal !important;
       a{
         color: $text-heading;
         font-weight: 500;
       }
     }
   }

   .callout-section {
    .cmp-teaser {
      &__reviews {
        .cmp-teaser__rating-score {
          @include ui-label-01;
          letter-spacing:rem(0.84px) !important;
          }

          &-text {
            @include ui-caption-01;
            color: $text-body-primary;
          }
        }
      }
    }

// Added CTA for Callout
.callout-section {
  .cmp-teaser {
    &:last-child {
      margin-bottom: rem(32px);
    }
    &__link  {
      color: $color-link;
      &:hover {
        text-decoration: none;
        color: $color-link-hover;
        .cmp-teaser__icon {
          &::before {
            text-decoration: none;
            color: $color-link-hover;
          }
        }
      }
      &:focus {
        color: color(neutral, white);
        margin-left: 0.056rem;
        background-color: $bg-focus;
        .cmp-teaser__icon {
          &::before {
            color: color(neutral, white);
            background-color: $bg-focus;
          }
        }
      }
      &:visited {
        color: $text-link-visited;
        .cmp-teaser__icon {
          &::before {
            color: $text-link-visited;
          }
        }
      }
      span  {
        &.cmp-teaser__icon {
          &::before {
            color: $color-link;
          }
          width: 1.5rem;
          height: auto;
          display: flex;
          align-items: center;
          margin-left: rem(4px);
          color: $color-link;
        }
      }
    }
    .ace-button {
      @include ui-body-01;
      .cmp-button {
        margin-top: rem(8px);
        padding: rem(12px) rem(24px);
        @include media('<md') {
          width: 100%;
        }
        &.cmp-button--primary {
          color: color(neutral, white);
          background-color: $bg-accent-primary;

          &:hover {
            color: color(neutral, white);
            background-color: $bg-accent-primary-hover;
          }
          &:active {
            color: color(neutral, white);
            background-color: $bg-accent-primary-pressed;
          }
          &:focus {
            color: color(neutral, white);
            background-color: $bg-accent-primary;
            margin-left: 0.25rem;
            margin-bottom: 0.25rem;
            outline-offset: rem(2px);
            border: 0.0625rem solid $border-interactive-selected;
            outline: 0.125rem solid $border-focus;
          }
        }
        &.cmp-button--secondary {
          color: color(neutral, white);
          background-color: color(stratos-blue, 1000);

          &:hover {
            color: color(neutral, white);
            background-color: $bg-brand-secondary-hover;
          }
          &:active {
            color: color(neutral, white);
            background-color: $callout-secondary-active;
          }
          &:focus {
            color: color(neutral, white);
            background-color: $bg-brand-secondary;
            margin-left: 0.25rem;
            margin-bottom: 0.25rem;
            box-shadow: none;
            outline-offset: rem(2px);
            border: 0.0625rem solid $border-brand;
            outline: 0.125rem solid $border-focus;
          }
        }
        &.cmp-button--tertiary {
          border: 0.0625rem solid $border-brand;
          box-sizing: border-box;
          text-decoration: none;
          padding: 0.75rem 1.5rem;
          border-radius: 3.75rem;
          color: $text-brand;
          &:hover {
            color: color(neutral, white);
            background-color: color(stratos-blue, 1000);
          }
          &:active {
            color: color(neutral, white);
            background-color: $bg-brand-secondary-hover;
          }
          &:focus {
            color: $text-brand;
            margin-left: 0.25rem;
            margin-bottom: 0.25rem;
            outline-offset: rem(2px);
            box-shadow: none;
            border: 0.0625rem solid $border-brand;
            outline: 0.125rem solid $border-focus;
          }
        }
      }
    }
  }
}

.callout-container {
  .ace-loyalty__inner.ace-button {
    margin: 0;
    padding-top: 3rem ;
    @include media('<md') {
      padding-top: 2rem;
    }

    .cmp-button.ace-guest-viewmore {
      margin-top: 0;
      padding: rem(12px) rem(24px);
    }
  }
}

//ACDAA-14728
.section-left {
  padding: 0;
  &.callout-section {
    margin-bottom: rem(24px) !important;
    @include media('>=md'){
      margin-bottom: 0 !important;
    }
  }
}
.section-right{
  padding: 0;
  &.callout-section {
    .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--6{
      @include media('<md'){
        width: 50%;
      }
    }
    .ace-callout-component.ace-callout-component-default {
        margin-bottom: 1.5rem;
    }
  }
}