/*
 this file used for only importing the file which is in current directory and importing in  main.scss file
 note : Don't write any code here used for only importing things
*/
@import 'functions';
@import 'mixins';

$font-size: rem(16px);
$font-height: 1.5;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-unna: 'Unna', serif;
$color-background: #ffffff;
$breakpoints: (
        sm: rem(320px),
        md: rem(768px),
        lg: rem(1024px),
        xl: rem(1280px),
       xxl: rem(1440px),
);

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

@import 'mediaquery';
@import 'grid';
@import 'image';
@import 'shadow';
@import 'spacing';
@import 'typography';
@import 'utility';
@import 'icon';
@import 'zindex';
@import 'border';
@import 'corner-radius';
@import 'article-template';
@import '../../resources/fonts/icomoon/style.scss';
@import 'component-variables';
