.tertiary {
  .cmp-button {
    background-color: $mainBgColor;
    border-color: $buttonBgColor;

    &:hover {
      background-color: $buttonBgColor;
      border-color: $buttonBgColor;

      .cmp-button__text {
        color: $color-light;
      }
    }

    .cmp-button__text {
      color: $buttonBgColor;
    }
  }
}
