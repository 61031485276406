.ace-article-details {
  display: flex;
  gap: rem(24px);
  position: relative;

  &__title {
    @include ui-body-01-strong;
  }
  &__kicker {
    @include ui-body-01;
  }
}
