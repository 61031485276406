.ace-shorttitle{
  display: flex;
  align-items: baseline;
  @include media('<md') {
    flex-direction: column;
  }
  &__heading{
    padding-left: 0;
    @include media('>=md','<lg') {
      padding-left: rem(12px);
    }
    h2 {
      @include media('<md') {
        margin: 0 0 rem(24px);
      }
      @include media('>=sm','<md'){
        padding-left: 0;
      }
      @include media('>md'){
        padding-left: 0;
      }
      @include expressive-heading-03;
      color: $shorttitle-title-clr;
    }
  }
  &__description  {
    @include ui-body-01;
    color: $text-body-primary;
    margin: rem(32px) 0 0;
    p {
      margin: 0;
      @include ui-body-01;
      color: $text-body-primary;
      @include media('<md') {
        margin-bottom: rem(24px);
      }
    }
  }
  &__link{
    @include media('<md') {
      padding: 0;
      justify-content: flex-start;
    }
    @include media('>=sm','<md'){
      padding-right: 0;
    }
    @include media('>md'){
      padding-right: 0;
    }
    display: flex;
    justify-content: flex-end;
    @include media('<md') {
      justify-content: flex-start;
      padding: 0;
    }
    &--text{
      @include ui-body-01;
      color: $color-link;

      &:hover {
          color: $color-link-hover;

          .icon {
              &::before {
                  color: $color-link-hover;
              }
          }
      }
      &:visited {
          color: $text-link-visited;

          .icon {
              &::before {
                  color: $text-link-visited;
              }
          }
      }
      &:focus {
          color: color(neutral, white);
          background-color: color(royal-blue, 500);

          .icon {
              &::before {
                  color: color(neutral, white);
                  background-color: color(royal-blue, 500);

              }
          }
      }
    }
    &--icon{
      margin-left: rem(4px);
      vertical-align: middle;
      width: rem(24px);
      height: rem(24px);
    }
    &--icon::before{
      color: $color-link;
    }
  }
}
