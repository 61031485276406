@import 'variables';

.icon {
  font-weight: normal;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-esparking {
  &::before {
    content: $icon-esparking;
  }
}

.icon-star {
  &::before {
    content: $icon-star;
  }
}

.icon-star1 {
  &::before {
    content: $icon-star1;
  }
}

.icon-stars {
  &::before {
    content: $icon-stars;
  }
}

.icon-stars1 {
  &::before {
    content: $icon-stars1;
  }
}

.icon-stars2 {
  &::before {
    content: $icon-stars2;
  }
}

.icon-stars3 {
  &::before {
    content: $icon-stars3;
  }
}

.icon-7-dining {
  &::before {
    content: $icon-7_dining;
  }
}

.icon-360 {
  &::before {
    color: #8c713b;
    content: $icon-360;
  }
}

.icon-account-24icon {
  &::before {
    color: #1e1852;
    content: $icon-account-24icon;
  }
}

.icon-account-connected-24icon .path1 {
  &::before {
    color: rgba(6, 132, 132);
    content: $icon-account-connected-24icon-path1;
  }
}

.icon-account-connected-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-account-connected-24icon-path2;
  }
}

.icon-account-connected-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-account-connected-24icon-path3;
  }
}

.icon-air-conditionning {
  &::before {
    content: $icon-air_conditionning;
  }
}

.icon-airport-shuttle {
  &::before {
    content: $icon-airport_shuttle;
  }
}

.icon-all-inclusive {
  &::before {
    content: $icon-all_inclusive;
  }
}

.icon-american-express-24icon .path1 {
  &::before {
    color: rgba(0, 111, 207);
    content: $icon-american-express-24icon-path1;
  }
}

.icon-american-express-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-24icon-path2;
  }
}

.icon-american-express-ccv-24icon .path1 {
  &::before {
    color: rgba(0, 111, 207);
    content: $icon-american-express-ccv-24icon-path1;
  }
}

.icon-american-express-ccv-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(178, 212, 241);
    content: $icon-american-express-ccv-24icon-path2;
  }
}

.icon-american-express-ccv-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-ccv-24icon-path3;
  }
}

.icon-american-express-ccv-24icon .path4 {
  &::before {
    margin-left: -1em;
    color: rgba(0, 111, 207);
    content: $icon-american-express-ccv-24icon-path4;
  }
}

.icon-american-express-ccv-24icon .path5 {
  &::before {
    margin-left: -1em;
    color: rgba(253, 52, 60);
    content: $icon-american-express-ccv-24icon-path5;
  }
}

.icon-american-express-ccv-24icon .path6 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-ccv-24icon-path6;
  }
}

.icon-american-express-ccv-24icon .path7 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-ccv-24icon-path7;
  }
}

.icon-american-express-ccv-24icon .path8 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-ccv-24icon-path8;
  }
}

.icon-american-express-ccv-24icon .path9 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-american-express-ccv-24icon-path9;
  }
}

.icon-aparthotel {
  &::before {
    content: $icon-aparthotel;
  }
}

.icon-appartment {
  &::before {
    content: $icon-appartment;
  }
}

.icon-arrow-down-24icon {
  &::before {
    color: #1e1852;
    content: $icon-arrow-down-24icon;
  }
}

.icon-arrow-left-24icon {
  &::before {
    color: #1e1852;
    content: $icon-arrow-left-24icon;
  }
}

.icon-arrow-right-24icon {
  &::before {
    color: #1e1852;
    content: $icon-arrow-right-24icon;
  }
}

.icon-arrow-up-24icon {
  &::before {
    color: #1e1852;
    content: $icon-arrow-up-24icon;
  }
}

.icon-attach-24icon {
  &::before {
    color: #1e1852;
    content: $icon-attach-24icon;
  }
}

.icon-automatic-wake-up-call {
  &::before {
    color: #1e1852;
    content: $icon-automatic-wake-up-call;
  }
}

.icon-baby {
  &::before {
    content: $icon-baby;
  }
}

.icon-baby-bath {
  &::before {
    content: $icon-baby_bath;
  }
}

.icon-baby-crib {
  &::before {
    content: $icon-baby_crib;
  }
}

.icon-baby-sitting {
  &::before {
    content: $icon-baby_sitting;
  }
}

.icon-balcony {
  &::before {
    color: #1e1852;
    content: $icon-balcony;
  }
}

.icon-bar {
  &::before {
    content: $icon-bar;
  }
}

.icon-bath {
  &::before {
    content: $icon-bath;
  }
}

.icon-beach-water-sports {
  &::before {
    content: $icon-beach_water_sports;
  }
}

.icon-beauty-product {
  &::before {
    content: $icon-beauty_product;
  }
}

.icon-bed {
  &::before {
    content: $icon-bed;
  }
}

.icon-breakfast {
  &::before {
    content: $icon-breakfast;
  }
}

.icon-breakfast-included {
  &::before {
    content: $icon-breakfast_included;
  }
}

.icon-bungalow {
  &::before {
    content: $icon-bungalow;
  }
}

.icon-bus-park {
  &::before {
    content: $icon-bus_park;
  }
}

.icon-bus-park-es {
  &::before {
    content: $icon-bus_park_es;
  }
}

.icon-business-center {
  &::before {
    content: $icon-business_center;
  }
}

.icon-calendar-24icon {
  &::before {
    color: #1e1852;
    content: $icon-calendar-24icon;
  }
}

.icon-car-park {
  &::before {
    content: $icon-car_park;
  }
}

.icon-car-park-es {
  &::before {
    content: $icon-car_park_es;
  }
}

.icon-ccv-24icon .path1 {
  &::before {
    color: rgba(255, 255, 255);
    content: $icon-ccv-24icon-path1;
  }
}

.icon-ccv-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-ccv-24icon-path2;
  }
}

.icon-ccv-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-ccv-24icon-path3;
  }
}

.icon-ccv-24icon .path4 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-ccv-24icon-path4;
  }
}

.icon-ccv-24icon .path5 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-ccv-24icon-path5;
  }
}

.icon-ccv-24icon .path6 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-ccv-24icon-path6;
  }
}

.icon-check-24icon {
  &::before {
    color: #1e1852;
    content: $icon-check-24icon;
  }
}

.icon-chevron-down-24icon {
  &::before {
    color: #1e1852;
    content: $icon-chevron-down-24icon;
  }
}

.icon-chevron-left-24icon {
  &::before {
    color: #1e1852;
    content: $icon-chevron-left-24icon;
  }
}

.icon-chevron-right-24icon {
  &::before {
    color: #1e1852;
    content: $icon-chevron-right-24icon;
  }
}

.icon-chevron-up-24icon {
  &::before {
    color: #1e1852;
    content: $icon-chevron-up-24icon;
  }
}

.icon-child-facilities {
  &::before {
    content: $icon-child_facilities;
  }
}

.icon-circle-container-24icon {
  &::before {
    content: $icon-circle-container-24icon;
  }
}

.icon-cleaning-services {
  &::before {
    content: $icon-cleaning_services;
  }
}

.icon-clear-24icon {
  &::before {
    color: #1e1852;
    content: $icon-clear-24icon;
  }
}

.icon-close-24icon {
  &::before {
    color: #1e1852;
    content: $icon-close-24icon;
  }
}

.icon-coffee-machine {
  &::before {
    content: $icon-coffee_machine;
  }
}

.icon-concierge {
  &::before {
    content: $icon-concierge;
  }
}

.icon-convention-center {
  &::before {
    content: $icon-convention_center;
  }
}

.icon-converter-24icon {
  &::before {
    color: #1e1852;
    content: $icon-converter-24icon;
  }
}

.icon-copier-printer-available {
  &::before {
    content: $icon-copier_printer_available;
  }
}

.icon-corner-room {
  &::before {
    color: #1e1852;
    content: $icon-corner-room;
  }
}

.icon-credit-card-24icon .path1 {
  &::before {
    color: rgba(255, 255, 255);
    content: $icon-credit-card-24icon-path1;
  }
}

.icon-credit-card-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path2;
  }
}

.icon-credit-card-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path3;
  }
}

.icon-credit-card-24icon .path4 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path4;
  }
}

.icon-credit-card-24icon .path5 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path5;
  }
}

.icon-credit-card-24icon .path6 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path6;
  }
}

.icon-credit-card-24icon .path7 {
  &::before {
    margin-left: -1em;
    color: rgba(30, 24, 82);
    content: $icon-credit-card-24icon-path7;
  }
}

.icon-default {
  &::before {
    color: #1e1852;
    content: $icon-default;
  }
}

.icon-delete-24icon {
  &::before {
    color: #1e1852;
    content: $icon-delete-24icon;
  }
}

.icon-desk-24 {
  &::before {
    content: $icon-desk_24;
  }
}

.icon-doors {
  &::before {
    content: $icon-doors;
  }
}

.icon-download-24icon {
  &::before {
    color: #1e1852;
    content: $icon-download-24icon;
  }
}

.icon-duplex {
  &::before {
    color: #1e1852;
    content: $icon-duplex;
  }
}

.icon-early-check-in-late-check-out {
  &::before {
    content: $icon-early_check-in_late_check-out;
  }
}

.icon-edit-24icon {
  &::before {
    color: #1e1852;
    content: $icon-edit-24icon;
  }
}

.icon-electric-charging-terminal {
  &::before {
    content: $icon-electric_charging_terminal;
  }
}

.icon-enlarge-24icon {
  &::before {
    color: #1e1852;
    content: $icon-enlarge-24icon;
  }
}

.icon-error-24icon {
  &::before {
    color: #1e1852;
    content: $icon-error-24icon;
  }
}

.icon-executive-floor {
  &::before {
    content: $icon-executive_floor;
  }
}

.icon-export-24icon {
  &::before {
    color: #1e1852;
    content: $icon-export-24icon;
  }
}

.icon-external-24icon {
  &::before {
    color: #1e1852;
    content: $icon-external-24icon;
  }
}

.icon-facebook-24icon {
  &::before {
    color: #1e1852;
    content: $icon-facebook-24icon;
  }
}

.icon-favorite-24icon {
  &::before {
    content: $icon-favorite-24icon;
  }
}

.icon-filter-24icon {
  &::before {
    color: #1e1852;
    content: $icon-filter-24icon;
  }
}

.icon-fireplace {
  &::before {
    color: #1e1852;
    content: $icon-fireplace;
  }
}

.icon-fitness-center {
  &::before {
    content: $icon-fitness_center;
  }
}

.icon-flat-screen-tv {
  &::before {
    color: #1e1852;
    content: $icon-flat-screen-tv;
  }
}

.icon-full-board {
  &::before {
    content: $icon-full_board;
  }
}

.icon-golf {
  &::before {
    content: $icon-golf;
  }
}

.icon-half-board {
  &::before {
    content: $icon-half_board;
  }
}

.icon-hammam {
  &::before {
    content: $icon-hammam;
  }
}

.icon-hearing-room {
  &::before {
    content: $icon-hearing_room;
  }
}

.icon-help-24icon {
  &::before {
    color: #1e1852;
    content: $icon-help-24icon;
  }
}

.icon-help-stroke-24icon {
  &::before {
    color: #1e1852;
    content: $icon-help-stroke-24icon;
  }
}

.icon-hotel {
  &::before {
    content: $icon-hotel;
  }
}

.icon-iconplaceholder-24icon {
  &::before {
    color: #f4f4f5;
    content: $icon-IconPlaceholder-24icon;
  }
}

.icon-info-24icon .path1 {
  &::before {
    color: rgba(30, 24, 82);
    content: $icon-info-24icon-path1;
  }
}

.icon-info-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-info-24icon-path2;
  }
}

.icon-info-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-info-24icon-path3;
  }
}

.icon-instagram-24icon {
  &::before {
    color: #1e1852;
    content: $icon-instagram-24icon;
  }
}

.icon-iron {
  &::before {
    content: $icon-iron;
  }
}

.icon-jacuzzi {
  &::before {
    content: $icon-jacuzzi;
  }
}

.icon-kitchen {
  &::before {
    content: $icon-kitchen;
  }
}

.icon-languages {
  &::before {
    content: $icon-languages;
  }
}

.icon-linkedin-24icon {
  &::before {
    color: #1e1852;
    content: $icon-linkedin-24icon;
  }
}

.icon-list-24icon {
  &::before {
    color: #1e1852;
    content: $icon-list-24icon;
  }
}

.icon-listing-24icon {
  &::before {
    color: #1e1852;
    content: $icon-listing-24icon;
  }
}

.icon-loan-smart-car {
  &::before {
    content: $icon-loan_smart_car;
  }
}

.icon-lockdown-24icon {
  &::before {
    color: #1e1852;
    content: $icon-lockdown-24icon;
  }
}

.icon-lockout-24icon {
  &::before {
    color: #1e1852;
    content: $icon-lockout-24icon;
  }
}

.icon-luggage {
  &::before {
    content: $icon-luggage;
  }
}

.icon-mail-24icon {
  &::before {
    color: #1e1852;
    content: $icon-mail-24icon;
  }
}

.icon-massage {
  &::before {
    content: $icon-massage;
  }
}

.icon-mastercard-24icon .path1 {
  &::before {
    color: rgb(0, 0, 0);
    content: $icon-mastercard-24icon-path1;
  }
}

.icon-mastercard-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-mastercard-24icon-path2;
  }
}

.icon-mastercard-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 95, 0);
    content: $icon-mastercard-24icon-path3;
  }
}

.icon-mastercard-24icon .path4 {
  &::before {
    margin-left: -1em;
    color: rgba(235, 0, 27);
    content: $icon-mastercard-24icon-path4;
  }
}

.icon-mastercard-24icon .path5 {
  &::before {
    margin-left: -1em;
    color: rgba(247, 158, 27);
    content: $icon-mastercard-24icon-path5;
  }
}

.icon-meeting-room {
  &::before {
    content: $icon-meeting_room;
  }
}

.icon-menu-dots-24icon {
  &::before {
    color: #1e1852;
    content: $icon-menu-dots-24icon;
  }
}

.icon-minus-24icon {
  &::before {
    color: #1e1852;
    content: $icon-minus-24icon;
  }
}

.icon-news-24icon {
  &::before {
    color: #1e1852;
    content: $icon-news-24icon;
  }
}

.icon-non-smoking {
  &::before {
    content: $icon-non_smoking;
  }
}

.icon-notification-24icon {
  &::before {
    color: #1e1852;
    content: $icon-notification-24icon;
  }
}

.icon-occupant-adult {
  &::before {
    content: $icon-occupant-adult;
  }
}

.icon-occupant-child {
  &::before {
    color: #1e1852;
    content: $icon-occupant-child;
  }
}

.icon-online-check-in-fast-check-out {
  &::before {
    content: $icon-online-check-in_fast-check-out;
  }
}

.icon-other-sports-activities {
  &::before {
    content: $icon-other_sports_activities;
  }
}

.icon-other-24icon {
  &::before {
    color: #1e1852;
    content: $icon-other-24icon;
  }
}

.icon-paid-amenity {
  &::before {
    content: $icon-paid_amenity;
  }
}

.icon-parking {
  &::before {
    content: $icon-parking;
  }
}

.icon-password-display-24icon {
  &::before {
    color: #1e1852;
    content: $icon-password-display-24icon;
  }
}

.icon-password-hide-24icon {
  &::before {
    color: #1e1852;
    content: $icon-password-hide-24icon;
  }
}

.icon-path-24icon {
  &::before {
    color: #1e1852;
    content: $icon-path-24icon;
  }
}

.icon-paypal-24icon .path1 {
  &::before {
    color: rgba(244, 244, 245);
    content: $icon-paypal-24icon-path1;
  }
}

.icon-paypal-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(23, 155, 215);
    content: $icon-paypal-24icon-path2;
  }
}

.icon-paypal-24icon .path3 {
  &::before {
    margin-left: -1em;
    color: rgba(34, 45, 101);
    content: $icon-paypal-24icon-path3;
  }
}

.icon-paypal-24icon .path4 {
  &::before {
    margin-left: -1em;
    color: rgba(37, 59, 128);
    content: $icon-paypal-24icon-path4;
  }
}

.icon-pet-welcome {
  &::before {
    content: $icon-pet_welcome;
  }
}

.icon-phone-24icon {
  &::before {
    color: #1e1852;
    content: $icon-phone-24icon;
  }
}

.icon-pillow-menu {
  &::before {
    color: #1e1852;
    content: $icon-pillow-menu;
  }
}

.icon-pin-24icon {
  &::before {
    color: #1e1852;
    content: $icon-pin-24icon;
  }
}

.icon-pinterest-24icon {
  &::before {
    color: #1e1852;
    content: $icon-pinterest-24icon;
  }
}

.icon-play-24icon {
  &::before {
    color: #fff;
    content: $icon-play-24icon;
  }
}

.icon-player-pause-24icon {
  &::before {
    color: #1e1852;
    content: $icon-player-pause-24icon;
  }
}

.icon-player-play-24icon {
  &::before {
    color: #1e1852;
    content: $icon-player-play-24icon;
  }
}

.icon-plus-24icon {
  &::before {
    color: #1e1852;
    content: $icon-plus-24icon;
  }
}

.icon-pool {
  &::before {
    content: $icon-pool;
  }
}

.icon-print-24icon {
  &::before {
    content: $icon-print-24icon;
  }
}

.icon-private-bathroom {
  &::before {
    content: $icon-private_bathroom;
  }
}

.icon-resort {
  &::before {
    content: $icon-resort;
  }
}

.icon-restaurant {
  &::before {
    content: $icon-restaurant;
  }
}

.icon-room-service {
  &::before {
    content: $icon-room_service;
  }
}

.icon-satellite-cable-colour-tv {
  &::before {
    color: #1e1852;
    content: $icon-satellite-cable-colour-tv;
  }
}

.icon-sauna {
  &::before {
    content: $icon-sauna;
  }
}

.icon-search-24icon {
  &::before {
    color: #1e1852;
    content: $icon-search-24icon;
  }
}

.icon-self-service-catering {
  &::before {
    content: $icon-self-service_catering;
  }
}

.icon-settings-24icon {
  &::before {
    color: #1e1852;
    content: $icon-settings-24icon;
  }
}

.icon-share-24icon {
  &::before {
    color: #1e1852;
    content: $icon-share-24icon;
  }
}

.icon-shared-room {
  &::before {
    content: $icon-shared_room;
  }
}

.icon-shoe-polisher {
  &::before {
    color: #1e1852;
    content: $icon-shoe-polisher;
  }
}

.icon-shuttle {
  &::before {
    content: $icon-shuttle;
  }
}

.icon-size {
  &::before {
    content: $icon-size;
  }
}

.icon-ski {
  &::before {
    content: $icon-ski;
  }
}

.icon-slippers {
  &::before {
    color: #1e1852;
    content: $icon-slippers;
  }
}

.icon-sort-24icon {
  &::before {
    color: #1e1852;
    content: $icon-sort-24icon;
  }
}

.icon-spa {
  &::before {
    content: $icon-spa;
  }
}

.icon-space-calculator-24icon {
  &::before {
    color: #1e1852;
    content: $icon-space-calculator-24icon;
  }
}

.icon-star-filled-24icon {
  &::before {
    content: $icon-star-filled-24icon;
  }
}

.icon-stores {
  &::before {
    content: $icon-stores;
  }
}

.icon-succes-outline-24icon {
  &::before {
    color: #1e1852;
    content: $icon-succes-outline-24icon;
  }
}

.icon-tea-machine {
  &::before {
    content: $icon-tea_machine;
  }
}

.icon-tennis {
  &::before {
    content: $icon-tennis;
  }
}

.icon-terrace {
  &::before {
    color: #1e1852;
    content: $icon-terrace;
  }
}

.icon-thalasso {
  &::before {
    content: $icon-thalasso;
  }
}

.icon-time-24icon {
  &::before {
    content: $icon-time-24icon;
  }
}

.icon-top-asset {
  &::before {
    content: $icon-top_asset;
  }
}

.icon-tripadvisor-24icon {
  &::before {
    color: #1e1852;
    content: $icon-tripadvisor-24icon;
  }
}

.icon-turn-down-service {
  &::before {
    color: #1e1852;
    content: $icon-turn-down-service;
  }
}

.icon-twitter-24icon {
  &::before {
    color: #1e1852;
    content: $icon-twitter-24icon;
  }
}

.icon-valet-parking {
  &::before {
    content: $icon-valet_parking;
  }
}

.icon-view {
  &::before {
    content: $icon-view;
  }
}

.icon-villa {
  &::before {
    content: $icon-villa;
  }
}

.icon-visa-24icon .path1 {
  &::before {
    color: rgb(26, 31, 113);
    content: $icon-visa-24icon-path1;
  }
}

.icon-visa-24icon .path2 {
  &::before {
    margin-left: -1em;
    color: rgba(255, 255, 255);
    content: $icon-visa-24icon-path2;
  }
}

.icon-warning-24icon {
  &::before {
    color: #1e1852;
    content: $icon-warning-24icon;
  }
}

.icon-web-corner-mac {
  &::before {
    content: $icon-web_corner_mac;
  }
}

.icon-weibo-24icon {
  &::before {
    color: #1e1852;
    content: $icon-weibo-24icon;
  }
}

.icon-welcome-gift {
  &::before {
    color: #1e1852;
    content: $icon-welcome-gift;
  }
}

.icon-well-being {
  &::before {
    content: $icon-well_being;
  }
}

.icon-wheelchair {
  &::before {
    content: $icon-wheelchair;
  }
}

.icon-wifi {
  &::before {
    content: $icon-wifi;
  }
}

.icon-wine-bar {
  &::before {
    content: $icon-wine_bar;
  }
}

.icon-youtube-24icon {
  &::before {
    color: #1e1852;
    content: $icon-youtube-24icon;
  }
}
