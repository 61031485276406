.ace-modal {
  background-color: rgba(46,46,46,.6);
    display: none;
    left: 0;
    margin-bottom: auto;
    margin-top: auto;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 9005;
    height: 100%;
    outline: 0;
    width: 100%;
    &__dialog {
      background-color: #fff;
      border: rem(1px) solid #d8d8d8;
      -webkit-box-shadow: 0 rem(8px) 1pc rgb(37 35 57 / 10%);
      box-shadow: 0 rem(8px) 1pc rgb(37 35 57 / 10%);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      // height: 80%;
      margin: rem(65px) rem(16px) rem(24px) rem(16px) !important;

      @include media('>=md') {
        margin: rem(94px) rem(65px) rem(94px) rem(65px) !important;
        max-height: calc(100vh - rem(188px));
      }
      overflow-y: initial!important;
      max-height: calc(100vh - rem(89px));
    }

    &__content {
      max-height: inherit;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__close-button {
      background-color: transparent;
      text-align: end;
      width: 100%;
      height: rem(64px);
      button {
        background-color: #fff;
        border: none;
        cursor: pointer;
        padding: 0;
        svg {
          padding: rem(16px) rem(16px);
          @include media('>=md') {
            padding: rem(24px) rem(24px) rem(16px) rem(24px);
          }
        }
      }
    }

    &__head {
      color: #3C3C3C;
      &h1,h2,h3 {
        margin: 0;
      }
    }

    &__body {
      padding: 0 rem(16px) 0 rem(16px);
      @include media('>=md') {
        padding: 0 rem(24px) 0 rem(24px);
      }
      overflow-y: auto;
      overflow-x: hidden;
      // height: 50vh;
    }

    &__footer {
      padding-bottom: rem(30px);
      @include media('>=md') {
        padding-bottom: rem(24px);
      }
    }

    &__description {
      h1 {
        color: #3C3C3C;
        margin: 0;
        @include ui-heading-01;
      }

      h2,
      h3,
      h4 {
        color: #3C3C3C;
        margin: 0;
        @include ui-heading-03;
        padding-top: rem(16px);
      }
      p {
        @include ui-body-01;
        padding: rem(8px) 0;
        color: color(neutral, grey);
        margin: 0;
      }
      p:last-child {
        @include ui-body-01;
        padding: rem(8px) 0 0 0;
        color: color(neutral, grey);
        margin: 0;
      }
    }
}

body {
  &.disable-scroll-modal {
    overflow-y: hidden !important;
    height: 100vh;
    position: relative;
  }
}
.page.basicpage.disable-scroll-modal {
  position: fixed;
  left: 0;
  right: 0;
}

.call-to-action {
    .ace-modal-component {
      .ace-modal__body {
        .ace-modal__description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .ace-button {
          display: flex !important;
          justify-content: flex-end;
        }
    }
  }
}

.call-to-action {
  .ace-modal-component {
    .ace-modal__close-button {
        button {
          svg {
            path {
              fill: $color-secondary;
            }
          }
        }
      }
    .ace-modal__body {
      .ace-modal__close-button {
        button {
          svg {
            path {
              fill: $color-secondary;
            }
          }
        }
      }
      .ace-modal__description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .ace-button {
        margin-top: rem(48px);
        display: flex !important;
        justify-content: flex-end;

        @include media('<md') {
          padding-left: rem(16px);
          padding-right: rem(16px) !important;
          margin-top: rem(32px) !important;
        }

        .cmp-button.cmp-button--secondary {
          @include media('>lg') {
            margin-right: rem(18px);
          }

          @include media('>=md','<lg') {
            margin-right: rem(16px);
          }

          @include media('<md') {
            margin-bottom: rem(16px);
          }
        }
      }
    }
  }
}
