$namespace: ".ace-core-booking-engine";

#{$namespace} {
  &__options {
    margin-top: rem(32px);
    align-self: flex-end;
    width: 100%;
    left: 0;
    bottom: 0;
    top: rem(84px);

    @include media('<md') {
        margin-top: rem(24px);
    }
    @include media(">=lg") {
      position: absolute;
      align-self: auto;
      padding-top: rem(32px);
    }

    &-trigger {
      @include media(">=lg") {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(56px);
        position: absolute;
      }

      width: 100%;
      border: transparent;
      background: color(neutral, white);
      align-content: center;
      margin: 0;
      inset: rem(4px) 0 0 0;
      min-width: 100%;
      height: rem(24px);
      padding: 0;
      position: relative;

      button {
        color: #1264a3;
        display: flex;
        border: none;
        cursor: pointer;
        padding: 0;
        background: #fff;
        white-space: nowrap;

        * {
          pointer-events: none;
        }

        &[aria-expanded="true"] .icon::before {
          transform: rotate(180deg);
          transition: transform 0.5s;
          transform-origin: center;
        }

        &:hover {
          color: #002b5b;
          text-decoration: underline;
        }

        &:focus {
          border: unset !important;
          outline: unset !important;
          background-color: $bg-focus;
          color: color(neutral, white) !important;
        }

        .icon {
          position: relative;
          width: rem(18px);

          &:before {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(18px);
            width: rem(18px);
            font-size: rem(18px);
            color: inherit;
            top: rem(3px);
          }
        }
      }

      hr {
        background: #dadadd;
        width: 100%;
        display: none;

        @include media(">=lg") {
          display: block;
        }
      }
    }

    &-panel {
      display: none;

      fieldset {
        border: none;
        padding: 0;
        margin: rem(32px) 0 0 0;

        @include media(">=lg") {
          margin: 0;
        }

        label {
          margin-bottom: rem(8px);
          display: block;
        }

        .ui-heading-03 {
          margin-bottom: rem(16px);
          color: #3e3d48;

          @include media(">=md") {
            margin-bottom: rem(8px);
          }

          @include media(">=lg") {
            margin-bottom: rem(24px);
          }
        }

        span.ui-body-01 {
          color: #3e3d48;
          word-break: break-word;
        }

        span.ui-body-02 {
          display: block;
          color: #6f6e77;

          &.placeholder {
            @include media("<lg") {
              display: none;
            }
          }
        }

        &.form-control {
          flex-basis: 50%;
        }

        &.form-control:first-child,
        &.form-control:last-child {
          flex-basis: calc(25% + rem(54px));
        }
      }

      .form-control {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: nowrap;
        flex: 1;

        .content {
          display: flex;
          gap: rem(24px);
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          flex: 1;
          flex-wrap: nowrap;
          flex-direction: column;

          @include media(">=md") {
            flex-direction: row;
          }

          &>div {
            width: 100%;
          }

          & label {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .form-input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: rem(12px) rem(16px);
        gap: rem(8px);
        background: #ffffff;
        border: rem(1px) solid #8b8a93;
        border-radius: rem(6px);
        width: 100%;
        color: #3c3c3c;

        &:focus,
        &:focus-within {
          border-color: $border-focus;
          box-shadow: 0 0 0 rem(1px) $border-focus;
          outline: unset;
        }

        // &:invalid {
        //   border-color: #ba1f1a;
        //   box-shadow: 0 0 0 rem(1px) #ba1f1a;
        //   outline: unset;
        // }
      }

      &.show {
        display: block;

        .ace-core-booking-engine__options-panel--wrapper {

            @include media(">=lg") {
            display: flex;
            gap: rem(80px);
            align-items: flex-end;
            justify-content: space-between;
            padding-top: rem(32px);
            }

            fieldset {
            legend {
                color: $text-heading;
            }
            }
        }
        .ace-core-booking-engine__accessible-room {
            margin-top: rem(32px);
            input[type=checkbox] {
                width: rem(18px);
                height: rem(18px);
                padding: rem(3px) 0 rem(3px) 0;
                accent-color: $color-accent;

                &:focus {
                    outline: rem(2px) solid $border-focus;
                    outline-offset: rem(1px);
                }
            }

            label {
                @include ui-body-01;
                margin-left: rem(8px);
                color: $text-body-primary;
                vertical-align: text-bottom;
            }
        }
      }
      .ace-customdropdown {
        position: relative;
        border-radius: rem(6px);

        .show {
          display: block;
        }

        &__btn {
          border: none;
          background: color(neutral, white);
          position: relative;
          border: rem(1px) solid $border-interactive;
          color: $text-body-secondary;
          border-radius: rem(4px);
          height: auto;
          padding: rem(12px) rem(16px);
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__arrow {
          display: flex;
          .icon {
            &::before {
              font-size: rem(24px);
              display: block
            }
          }
        }

        &__selected {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: rem(250px);
        }

        &__menu {
          display: none;
          background: color(neutral, white);
          box-shadow: 0 rem(2px) rem(6px) rgba(0, 0, 0, 0.2);
          border-radius: rem(6px);
          color: $text-body-primary;
          padding: rem(16px);
          z-index: 999;
          right: 0;
          margin-top: rem(8px);
          position: absolute;
          border: rem(2px) solid $border-focus;
          white-space: nowrap;
          min-width: 100%;
          box-sizing: border-box;
        }

        &__list {
          display: flex;
          flex-direction: column;
          list-style: none;
          gap: rem(12px);
          padding: 0;
          margin: 0;
          &:focus-visible {
            outline: none;
          }
        }

        &__item {
          position: relative;
          display: flex;
          align-items: center;
          gap: rem(8px);
          white-space: unset;

          &:hover {
            cursor: pointer;
          }
          &-form {
            display: none;
          }
          &__radio {
            top: 0;
            left: 0;
            height: rem(18px);
            width: rem(18px);
            border: rem(1px) solid $border-interactive;
            border-radius: 50%;
            background: color(neutral, white);
            display: flex;
            flex: none;
            justify-content: center;
            align-items: center;
            &::after {
              content: "";
              display: none;
              width: rem(10px);
              height: rem(10px);
              border-radius: 50%;
              background: $bg-accent-primary;
              border-color: $border-interactive-selected;
            }
          }
          &--selected {
            .ace-customdropdown__item__radio {
              background-color: color(neutral, white);
              border-color: $border-interactive-selected;
              &::after {
                display: block;
              }
            }
          }
        }
        &__btn-confirm {
          width: 100%;
          padding: rem(12px) rem(20px);
          color: color(neutral, white);
          background: color(stratos-blue, 1000);
          border-radius: rem(60px);
          border: none;
          margin-top: rem(24px);
          &:disabled,
          &[disabled] {
            background-color: $bg-disabled-primary;
            color: $text-disabled;
          }
        }
      }
      // US ACDAA-16764 changes
      .ace-core-booking-engine__loyalty-product-label {
        display: none;
        &.show-label {
          display: flex;
          gap: rem(8px);
          background-color: $bg-accent-secondary;
          padding: rem(12px) rem(16px);
          @include media('>=lg') {
            padding: rem(16px) rem(24px);
          }
          margin-top: rem(32px);
          @include media('>=lg') {
            margin-top: rem(48px);
          }
          border-radius: rem(6px);
          .icon-listing-24icon {
            color: $bg-accent-primary-default;
            &::before {
              color: $bg-accent-primary-default;
            }
          }
          .loyalty__product {
            @include ui-body-01-strong;
            .loyalty__product-card {
              @include ui-body-01;
            }
          }
        }
      }
    }
  }
}
