.ace-all-safe {
  &__content {
    text-align: center;

    .aem-Grid {
      .aem-GridColumn {
        @include media('>md') {
          padding-right: .25rem;
          padding-left: .25rem;
        }
      }

      @include media('<md') {
        &.aem-Grid--sm--1 {
          .aem-GridColumn {
            &.aem-GridColumn--sm--1 {
              margin-left: auto;
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }

    &__icon {
      display: flex;
      width: rem(96px);
      margin: 0 auto;

      img {
        width: 100%;
        height: rem(96px);
        object-fit: contain;
      }
    }

    &__title {
      @include expressive-heading-05;
      color: $text-heading;
      height: rem(44px);
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-weight: 500 !important;

      @include media('>=md') {
        @include expressive-heading-02;

        margin-top: 3rem;
        margin-bottom: 1.5rem;
      }
    }

    &__desc {
      p {
        color: $text-body-primary;
        margin: 0;
        line-height: 1.5rem;
      }
    }
  }
}
