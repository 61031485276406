.long-stay-form-container {
    .ace-long-stay {
        &-form {
            @include media('>=lg') {
                margin-left: calc(61.7% - 25vw);
                margin-right: calc(61.5% - 25vw);
            }

            @include media('>=md','<lg') {
                margin-left: calc(53% - 25vw);
                margin-right: calc(53% - 25vw);
            }
        }
        &__content {
            .ace-form {
                &__title {
                    color: color(neutral-black, 1000);
                }
                &__desc {
                    color: color(neutral-black, 600);
                }
            }
            .form-control {
                border: none;
                margin: 2rem 0 0;
                padding: 0;
                position: relative;

                .form-input,select {
                    background-color: color(neutral, white);
                    padding: 1rem;
                    box-shadow: unset;
                    border: rem(1px) solid color(grey-neutral, 1000);
                    border-radius: rem(6px);
                    appearance: none;
                    display: flex;
                    color: color(neutral-black, 600);

                    &:hover {
                        border: rem(1px) solid color(grey-neutral, 1100);
                    }

                    &:focus {
                        border: rem(1px) solid color(red-functional, 1000);
                    }
                }
                .icon {
                    pointer-events: none;
                    font-size: 1.5rem;
                    position: absolute;
                    bottom: 2.5rem;
                    right: .5rem;
                    top: rem(48px);
                }

                label {
                    margin-bottom: 0.5rem;

                    span.ui-body-01 {
                        color: color(neutral-black, 1000);
                        word-break: break-word;
                    }

                    span.ui-body-02 {
                        display: block;
                        color: color(neutral-black, 600);
                    }
                }

                legend {
                    color: color(neutral-black, 1000);
                    margin-bottom: rem(8px);
                }

                .content {
                    input {
                        width: rem(18px);
                        height: rem(18px);
                        margin: 0 0 rem(10px) 0;
                    }
                    label {
                        color: color(neutral-black, 800);
                        display: inline-block;
                        vertical-align: middle;
                        margin-bottom: rem(10px);
                    }
                }

                textarea {
                    padding: rem(12px) rem(16px);
                }
            }
            .form-control {
                width: 100%;
                display: flex;
                flex: 1;
                flex-flow: column;

                .ace-dropdown {
                    &__list {
                        img {
                            width: rem(24px);
                            height: rem(24px);
                            margin-right: rem(24px);
                        }
                    }
                }
            }
            .ace-core-booking-engine__check-in {
                padding-right: rem(24px);
            }
            .ace-core-booking-engine__check-in,
            .ace-core-booking-engine__check-out {
                display: inline-block;
                padding-top: rem(32px);
                width: calc(50% - rem(19.2px));

                @include media('<lg') {
                    width: calc(49% - rem(12px));
                }

                duet-date-picker {
                    .duet-date__input {
                        padding: rem(12px) rem(16px);

                        @include media('<lg') {
                            padding: 0;
                        }

                        &:hover {
                            border: rem(1px) solid color(grey-neutral, 1100);
                        }

                        &:focus {
                            box-shadow: 0 0 0 rem(1px) color(red-functional, 1000);
                            border: rem(1px) solid color(red-functional, 1000);
                        }
                    }

                    .duet-date__toggle {
                        &:hover {
                            background-color: color(red-functional, 1000);
                        }
                        &:focus {
                            outline: rem(2px) solid color(red-functional, 1000);
                        }
                    }

                    .duet-date__dialog-content {
                        border: rem(2px) solid color(red-functional, 1000);
                    }

                    .duet-date__day[aria-pressed=true] {
                        background-color: color(red-functional, 1000);
                        border: rem(2px) solid color(red-functional, 1000);
                    }
                }
            }

            .ace-long-stay-form__terms-condition {
                margin-top: rem(40px);
                label {
                    color: color(neutral-black, 800);
                    vertical-align: middle;
                }

                input {
                    width: rem(18px);
                    height: rem(18px);
                    vertical-align: middle;
                }
            }

            .ace-button {
                justify-content: center;
                margin-top: rem(40px);

                .cmp-button.cmp-button--primary {
                    background: color(red-functional, 1000);
                    border-radius: rem(60px);
                    border: 1rem;
                    padding: rem(12px) rem(20px);
                    width: rem(198px);

                    &:hover {
                        background: color(red-functional, 1200);
                    }

                    &:focus {
                        background: color(red-functional, 1000);
                        outline-offset: rem(2px);
                        outline : rem(1px) solid color(red-functional, 1000);
                        border: 0;
                    }

                    &:active {
                        background: color(red-functional, 1100);
                    }
                }
            }

            .errorfield {
                margin: rem(8px) 0 0;
                color: color(red-functional, 500);
            }

            .ace-long-stay-form__error {
                &-block {
                    color: color(neutral-black, 1000);
                    background-color: $bg-danger-secondary;
                    border-radius: 0.375rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1rem;
                    display: flex;
                    margin-bottom: 2rem;
                    padding: 1rem 1.5rem;

                    >* {
                        margin: 0;
                    }

                    ul {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
