.button {
  margin: 0.5rem;
  padding: 0.625rem 1.25rem;
  text-align: center;

  .cmp-button {
    border: 1px solid;
    border-radius: 1.875rem;
    margin: auto;
    padding: 0.625rem 2rem;
    text-align: center;
    text-decoration: none;
    width: fit-content;

    @media screen and (max-width: $mobile-medium) {
      width: 100%;
    }

    &:focus {
      outline: 2px solid $linkColor;
      outline-offset: 1px;
    }

    &:disabled,
    &[disabled] {
      background-color: $disabledColor;
      border-color: $disabledColor;

      .cmp-button__text {
        color: $borderInputColor;
      }
    }

    .cmp-button__text {
      color: $color-light;
      font-family: $font-page;
      font-size: 1rem;
      text-align: center;
      text-transform: none;
    }
  }
}
.roundButton {
  background:  $buttonBgColor;
  border-radius: 60px;
  color: white;
  cursor: pointer;

  &:hover {
    background: $buttonBgHoverColor;
  }
}