.ace-calltoaction {
  padding: 0 rem(20px);
  @include media('>=md') {
    padding-left: 0;
  }
  &__headline {
    @include ui-heading-01;
    color: color(neutral, white);
  }
  &__body {
    @include ui-body-01;
    margin-top: rem(32px);
    margin-bottom: rem(24px);
    color: color(neutral, white);
  }
  &__display {
    display: flex;
    justify-content: center;
    border: rem(1px) solid color(neutral, white);
    @include media('>=md') {
      display: inline-block;
    }
    &.cmp-button--primary {
      background-color: $bg-brand-primary;
    }
  }
}
html[dir='rtl'] {
  @include media('<=md') {
    .ace-calltoaction {
      border-right: none;
      border-left: rem(1px) solid color(neutral, white);
    }
  }
}
