.new-container {
    @include media('>=md') {
        display: flex;
    }
    p {
        margin: 0;
        color: $text-body-primary;
        line-height: rem(24px);
    }
    &__column1,
    &__column2 {
        @include media('>=md') {
            width: 50%;
        }
    }
    &__column1 {
        @include media('>=md','<lg') {
            padding-left: rem(12px);
        }
    }
    &__column2 {
        @include media('>=lg') {
            margin-left: rem(48px);
        }

        @include media('>=md','<lg') {
            margin-left: rem(32px);
            padding-right: rem(12px);
        }

        @include media('<md') {
            margin-top: rem(24px);
        }
    }

    &.single-column {
        .new-container__column1 {
            @include media('>=md','<lg') {
                width: 80%;
            }
            p {
                @include media('>=lg') {
                    padding-right: rem(12px);
                }
            }
        }
    }
}
