.ace-navfooterlist-component {
  footer & {
    color: color(neutral, white);
    background-color: $bg-brand-primary;
  }
}
.footercontainer {
  .nav-footer-list.list.aem-GridColumn {
    width: 100%;
    @include media('>=md') {
      width: 50%;
    }
    @include media('>=lg') {
      width: 25%;
    }
  }
  .nav-footer-list.list.aem-GridColumn {
    &.last-child {
      @include media('>=md') {
        width: 100%;
      }
    }
  }
}
.footercontainer {
  .cta.aem-GridColumn {
    + .nav-footer-list {
      @include media('>=md') {
        width: 35%;
        padding-left: 0;
      }
      @include media('>=lg') {
        padding-left: 0;
      }
      + .separator {
        @include media('<md') {
          display: none;
        }
      }
    }
  }
}
.ace-navfooter-list {
  footer & {
    margin: rem(16px) 0;
    padding: 0 1.5rem;
    @include media('>md') {
      margin-top: rem(12px);
    }
    @include media('>=lg') {
      padding-left: 0;
    }
  }
  margin: 0;
  padding: 0;
}
.md-hidden {
  @include media('>=md') {
    display: none !important;
  }
}
.ld-visible {
  @include media('<md') {
    display: none !important;
  }
  @include media('>md') {
    display: block;
  }
}
.sm-hidden {
  @include media('>=sm') {
    display: none;
  }
}
.ace-navfooter-list__item {
  list-style-type: none;
}
.ace-navfooter-list__item {
  a {
    span {
      display: block;
      margin-bottom: 0;
      color: $color-link;
      list-style-type: none;
      @include ui-body-01;
      footer & {
        margin-bottom: rem(12px);
        color: color(neutral, white);
        @include ui-body-02;
      }
    }
    &:focus {
      span {
        outline: rem(2px) solid $border-focus;
      }
    }
  }
}
.ace-navfooter-list__item {
  span {
    display: block;
    margin-bottom: rem(12px);
    list-style-type: none;
    @include ui-body-02;
    footer & {
      color: color(stratos-blue, 400);
    }
  }
}
@include media('<md') {
  .ace-collapse-list {
    .ace-navfooterlist-accordion__item {
      width: 100%;
      &.active {
        + .ace-navfooterlist-accordion__panel {
          display: block !important;
          footer & {
            border-top: .0625rem solid #d8d8d8;
          }
        }
        .ace-navfooterlist-accordion__icon::before {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
    & .ace-navfooterlist-accordion__button {
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 1.5rem;
      background: none;
      border: none;
      footer & {
        display: flex;
      }
      &:focus {
        outline: rem(2px) solid $border-focus;
      }
      & .ace-navfooterlist-accordion__icon {
        top: rem(-8px);
        left: rem(-4px);
        font-size: 1.5rem;
        transition: all .2s ease-in;
        @include icomoon;
        &::before {
          float: right;
          font-size: rem(13.5px);
          line-height: rem(7.5px);
          content: '\e93d';
          footer & {
            color: color(neutral, white);
          }
        }
        &.active::before {
          transform: rotate(-180deg);
        }
      }
    }
    .ace-navfooterlist-accordion__panel[aria-hidden='true'] {
      footer & {
        display: none !important;
      }
      display: block !important;
    }
    .ace-navfooterlist-accordion__panel[aria-hidden='false'] {
      footer & {
        display: block !important;
      }
    }
    .ace-navfooterlist-accordion__panel,
    .ace-navfooterlist-accordion__panel[aria-hidden='true'] ul.ace-collapse-list__content {
      margin-bottom: 0 !important;
    }
    .ace-navfooterlist-accordion__panel[aria-hidden='true'] ul.ace-collapse-list__content {
      margin-top: 0 !important;
    }
  }
  .ace-navfooterlist-accordion__panel--hidden {
    display: block;
  }
}
@include media('>=md') {
  .ace-collapse-list {
    .ace-navfooterlist-accordion__item {
      width: 100%;
      border: 0;
    }
    .ace-navfooterlist-accordion__button {
      display: flex;
      justify-content: space-between !important;
      width: 100%;
      padding: 0 0;
      text-align: left !important;
      background: none;
      border: none;
      pointer-events: none;
      &:focus {
        outline: none;
      }
    }
    .ace-collapse-list__title {
      @include ui-body-01-strong;
      margin: 0 0 0 rem(24px);
      margin-bottom: rem(12px);
      footer & {
        color: color(neutral, white);
      }
    }
    .ace-navfooterlist-accordion__icon {
      display: none;
    }
    .ace-navfooterlist-accordion {
      &__panel {
        footer & {
          padding: 0 0 1.5625rem 1.5rem;
          @include media('<lg') {
            padding-left: 0;
          }
        }
        padding: 0;
      }
    }
  }
}
.ace-collapse-list {
  .ace-navfooterlist-accordion {
    @include media('<md') {
      &__button > span {
        color: color(neutral, white);
        pointer-events: none;
        @include ui-body-01;
        &:last-child {
          top:rem(2px);
        }
      }
      &__panel {
        footer & {
          display: none;
        }
        display: block;
        &.show {
          display: block;
        }
      }
    }
    &__header {
      display:flex;
      align-items: flex;
      margin: 0;
      padding: 0;
      @include ui-body-01-strong;
      @include media('<=md') {
        display: block !important;
      }
    }
    &__item {
      width: 100%;
      @include media('<md') {
        footer & {
          border-top: rem(1px) solid $border-decorative;
        }
        border-top: medium none;
      }
    }
  }
}
html[dir='rtl'] {
  .ace-navfooterlist-accordion {
    &__panel {
      padding-right: 0;
    }
  }
  .ace-navfooter-list {
    padding-right: 0;
  }
  .cmp-calltoaction {
    padding-right: rem(24px);
  }
}
// styles for centered list
.cmp-ace-list.cmp-ace-list__centered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-block-start: 0;
  padding-inline-start: rem(27px);
  &.space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    li {
      flex-basis: calc(100% / 6) !important;
      margin: 0;
      overflow: hidden;
      text-indent: rem(2px);
      text-overflow: ellipsis;
      &.hide {
        display: none;
      }
      a {
        span {
          pointer-events: none;
        }

        &,
        span {
          color: $color-link !important;
        }
        &:focus,
        &:focus span {
          color: $color-link-hover;
        }
        &:visited,
        &:visited span {
          color: $text-link-visited;
        }
      }
      @include media('<lg') {
        flex-basis: calc(100% / 3) !important;
      }
      @include media('<md') {
        flex-basis: calc(100% / 2) !important;
      }
    }
  }
  @include media('<md') {
    flex-direction: column;
    align-items: flex-start;
  }
  .cmp-list__item {
    margin-right: rem(14px);
    margin-bottom: rem(6px);
    & a {
      footer & {
        color: color(neutral, white) !important;
      }
      color: color(neutral, black) !important;
    }
    & a:focus .cmp-list__item-title {
      color: color(neutral, white) !important;
    }
    .cmp-list__item-title {
      @include ui-body-02;
      color: color(neutral, white);
    }
    a {
      span {
        footer & {
          @include ui-body-02;
          color: color(neutral, white);
        }
        @include ui-body-01;
        color: color(neutral, black);
      }
    }
    span {
      @include ui-body-02;
      color: color(stratos-blue, 400);
    }
  }
}
.ace-list {
  .cmp-ace-list.cmp-ace-list__centered {
    .cmp-list__item a {
      &:focus {
        .cmp-list__item-title {
          color: color(neutral, white) !important;
        }
      }
    }
  }
}
.navfooterlist-font-variation {
  .ace-collapse-list {
    .ace-collapse-list__title {
      @include ui-heading-01;
    }
    .ace-navfooterlist-accordion {
      &__header {
        @include ui-heading-01;
      }
    }
  }
}
main, #main-content{
> .cmp-container > .aem-Grid > div:is(.nav-footer-list) {
  @include media('<md') {
    width: 50% !important;
  }
  @include media('>=md', '<lg') {
    width: 33.33% !important;
  }
  @include media('>=md', '<=md') {
    width: 32.33% !important;
  }
}
}
main, #main-content{
.cmp-container .nav-footer-list .list .aem-GridColumn {
  padding: 0;
  @include media('>=md', '<=md') {
    margin-left: rem(12px);
  }
}
}
main, #main-content {
> .cmp-container > .aem-Grid .nav-footer-list.list {
  margin-top:rem(48px) !important;
  @include media('<md') {
    margin-top: rem(18px) !important;
  }
}
}
