.ace-carouselslideshow {
    $namespace: '.cmp-carouselslideshow';

    #{$namespace} {
        position: relative;

        &.containerWidth {
            margin-left: auto;
            margin-right: auto;
        }

        #{$namespace}__content {
            list-style: none;
            padding: 0;
            margin: 0;

            #{$namespace}__item {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                transition: none;
                width: 100%;
                height: 100%;

                &--active {
                    display: block;
                    position: relative;
                    opacity: 1;
                }

                .ace-image {
                    overflow: hidden;
                    margin-left: calc(50% - 50vw);
                    margin-right: calc(50% - 50vw);
                    width: 100vw;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .aem-Grid > .aem-GridColumn {
            position: relative;
            padding: 0;

            #{$namespace}__actions {
                position: relative;
                margin-top: rem(24px);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
                gap: rem(16px);
                @include media('>=lg') {
                    margin-top: rem(32px);
                }

                &#{$namespace}__actions--previous {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &#{$namespace}__actions--next {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                #{$namespace}__action {
                    outline: none;
                    background: transparent;
                    border-radius: rem(40px);
                    width: rem(40px);
                    height: rem(40px);
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    box-sizing: border-box;
                    border: rem(1px) solid color(neutral, white);
                    color: color(neutral, white);

                    html[dir='rtl'] & {
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    .icon {
                        font-size: rem(24px);
                    }

                    .icon,
                    .icon::before {
                        color: inherit !important;
                    }

                    &:hover {
                        background: $color-lightest-gray;
                        border: rem(1px) solid $color-lighter-gray;
                        color: $text-brand;
                    }

                    &:active {
                        background: $color-light-gray-1;
                        border: rem(1px) solid $color-light-gray-2;
                        color: $text-brand;
                    }

                    &:focus {
                        outline: rem(2px) solid color(neutral, white);
                        outline-offset: rem(2px);
                        background-color: transparent;
                        color: color(neutral, white);
                        /* Safari 11+ */
                        @media not all and (min-resolution: 0.001dpcm) {
                            @supports (-webkit-appearance: none) and
                    (stroke-color: transparent) {
                                & {
                                    box-shadow: 0 0 0 rem(2px) $color-blue-bg, 0 0 0 rem(4px) $color-background;
                                    outline: unset;
                                    outline-offset: unset;
                                }
                            }
                        }
                    }

                    &.disabled {
                        border: rem(1px) solid $color-gray-border;
                        background-color: $color-gray-border;
                        color: $color-text-gray;
                        pointer-events: none;

                        * {
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        #{$namespace}__indicators {
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }
    }
}

.carousel-slideshow {
    @include media('<lg') {
        margin-bottom: -3.5rem;
    }
    line-height: 0;
}
