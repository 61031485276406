$namespace: '.ace-core-booking-engine';
#{$namespace} {
    &__autocomplete {
        .ui-body-01 {
            color: $text-body-primary;
            position: relative;
        }
        .material-textfield input.withoutLabel {
            border: rem(1px) solid $border-interactive !important;
        }

        // dropdown
        #mount-autocomplete {
            .ahAutocomplete {
                &__result {
                    position: absolute;
                    width: 100%;
                    background: color(neutral, white);
                    border: rem(2px) solid $border-focus;
                    box-sizing: border-box;
                    box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 0.1);
                    border-radius: rem(6px);
                    margin-top: rem(12px);
                    z-index: 5;
                    padding: rem(24px);

                    &__items .autocompleteItem--result {
                        border-color: transparent;
                        border-radius: rem(6px);
                        align-items: center;
                        .autocompleteItem__label {
                            @include ui-body-01-strong;
                            color: $text-heading;
                            margin-left: rem(16px);
                        }
                        .buttonAutocomplete {
                            padding: rem(12px);
                            &:hover {
                                background-color: $bg-neutral-secondary;
                            }
                        }
                        .icon {
                            height: rem(44px);
                            width: rem(44px);
                        }
                    }
                }
            }
        }
    }
}
