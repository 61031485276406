.core-booking-engine-v2 .core-booking-engine {
    &.expanded{
        height: auto;
    }
    .awards {
        @include media('<md') {
            margin-top:rem(16px);
        }  
    }
    .ace-core-booking-engine{
        &__options {
            @include media('<md') {
                margin-top:rem(16px);
            }
        }
    }
    
    .ace-core-booking-engine__options-trigger {
        background-color: transparent;
        button {
            background-color: transparent;
            &:focus {
                background-color: $bg-focus;
            }
        }
    }
        .ace-core-booking-engine #mount-autocomplete .ahAutocomplete__result {
            padding: rem(24px);
            @include media('<md') {
                width: calc(100vw - rem(30px));
                padding: rem(16px);
            }
            @include media('>=md','<lg') {
                width: calc(100vw - rem(67px));
                padding: rem(16px);
            }
            .autocompleteItem__label {
                padding: 0;
            }
            .buttonAutocomplete {
                @include media('<lg') {
                    padding:rem(8px) !important;
                } 
            }
        }
        .ace-core-booking-engine__content {
            .ace-core-booking-engine__check-in,.ace-core-booking-engine__check-out {
                @include media('<md') {
                    margin-top:rem(8px) !important;
                    flex: 1;
                }
            }
        }
  }
html[lang="en"] {
    .ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__autocomplete .ace-core-booking-engine__label {
        position: relative;
        @include media('>=xl', '<=xl') {
        span.helper {
        position: absolute;
        right: rem(-9px);
        }
    }
    }
    }
}
@include media('>lg') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__label,
    .ace-core-booking-engine__guests-title,
    .ace-core-booking-engine__option-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(8px);
    color: color(neutral-black, 900) !important;
    @include ui-body-02;
    span.helper {
        color: color(neutral-black, 600);
        text-transform: lowercase;
    }
    }

    .ace-core-booking-engine__content {
    gap: 0;

    .ace-core-booking-engine__autocomplete,
    .ace-core-booking-engine__dates-box,
    .ace-core-booking-engine__guests {
        padding: 0 rem(16px);
        position: relative;

        &::after {
        content: '';
        position: absolute;
        right: 0;
        background: color(neutral-black, 200);
        bottom: 0;
        width: rem(1px);
        height: 75%;
        border-radius: rem(6px);
        }
    }

    .ace-core-booking-engine__autocomplete {
        padding-left: 0;
        width: 32%;
        .material-textfield input::placeholder {
        color: $text-body-secondary!important;
        font-family: $font-family-roboto;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    .material-textfield input::-webkit-input-placeholder {
        color: $text-body-secondary !important;
        font-family: $font-family-roboto;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    .material-textfield input:-ms-input-placeholder {
        color: $text-body-secondary !important;
        font-family: $font-family-roboto;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    #mount-autocomplete .ahAutocomplete__result__items .autocompleteItem--result .buttonAutocomplete {
        padding: rem(12px) rem(16px);
    }
    }


    .ace-core-booking-engine__dates-box {
        width: 26%;
        .ace-core-booking-engine__check-in,
        .ace-core-booking-engine__check-out {
        width: 49%;
        display: inline-block;
        position: relative;
        padding-left: rem(22px);
        box-sizing: border-box;
        padding-right: 0;
        .ace-core-booking-engine__labelDate {
            position: absolute;
            left: 0;
            color: color(neutral-black, 800);
            text-transform: capitalize;
        }
    }
    .ace-core-booking-engine__check-in{
        padding-left: 2.675rem;
        .duet-date .duet-date__dialog{
        left: -4.05rem;
        }
    }
    .ace-core-booking-engine__check-out{
        .duet-date .duet-date__dialog{
        left: -2.05rem;
        }
    }
    .ace-core-booking-engine__label{
        text-transform: capitalize;
    }
    }
    .ace-core-booking-engine__guests {
        width: 23%;
        .ace-core-booking-engine__dropdown{
        margin-left: -1rem;
        width: 17rem;
        }
        .ace-core-booking-engine__guests-title {
        display: block;
        width: 85%;
        text-align: initial;
        position: relative;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
        padding-left: rem(32px);
        text-overflow: ellipsis;
        svg {
        position: absolute;
        left: 0;
        top: 0;
        }
        }
    }
    
    .ace-core-booking-engine__option-title {
        margin: 0 0 0.5rem 0;
        white-space: nowrap;
    }

    .ahAutocomplete .ahAutocomplete__input {
        height: auto;
    }

    .ahAutocomplete__result {
        left: -1.8rem;
        min-width: 31.25rem !important;
    }

    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input,
    .ace-core-booking-engine__options-trigger .input {
        background-color: color(neutral, white);
        padding: 0;
        box-shadow: none !important;
        color: $text-body-primary !important;
        height: rem(24px) !important;
        border: rem(2px) solid transparent !important;
    }
    .ace-core-booking-engine__guests .input {
        background-color: color(neutral, white);
        padding: 0;
        box-shadow: none !important;
        color: color(neutral-black, 600) !important;
        height: rem(26px) !important;
        border: rem(2px) solid transparent !important;
    }
    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:focus,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input:focus,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input:focus,
    .ace-core-booking-engine__guests .input:focus,
    .ace-core-booking-engine__options-trigger .input:focus {
        border: rem(2px) solid transparent !important;
        box-shadow: none !important;
    }
    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:hover,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input:hover,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input:hover,
    .ace-core-booking-engine__guests .input:hover,
    .ace-core-booking-engine__options-trigger .input:hover {
        border: rem(2px) solid transparent !important;
        box-shadow: none !important;
    }
    .ace-core-booking-engine__options-trigger .input {
        width: 100%;
        text-decoration: none;
        font-weight: 400;
        font-family: $font-family-roboto;
        font-size: 1rem;
        line-height: 1.5rem;
        color: color(neutral-black, 600) !important;
        border-radius: 0.375rem;
        span.option-yes{
        display: none;
        }
        span{
        color: color(neutral-black, 600)
        }
        &:focus{
        border: rem(2px) solid $border-focus !important;
        }
    }
    
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__toggle,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__toggle {
        box-shadow: none;
        width: 100%;
        border-radius: rem(6px);

        &:focus {
        background-color: transparent;
        }

        &:hover {
        background-color: transparent;
        }

        &:active {
        background-color: transparent;
        }

        span svg {
        display: none;
        }
    }
    }
    #mount-autocomplete {
    input.invalid,
    input.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="%23BA1F1A" fill-rule="evenodd" d="M21.74 18.05 13.77 4.02c-.77-1.36-2.77-1.36-3.55 0L2.26 18.05C1.51 19.37 2.49 21 4.03 21h15.94c1.55 0 2.52-1.63 1.77-2.95ZM11.24 8c0-.41.34-.74.76-.74s.76.33.76.74v5.8c0 .41-.34.74-.76.74a.75.75 0 0 1-.76-.74V8Zm.76 9.77a.997.997 0 0 1-1.01-.99c0-.55.45-.99 1.01-.99s1.01.44 1.01.99-.45.99-1.01.99Z" clip-rule="evenodd"/></svg>');
        }
    }
    .ace-core-booking-engine__footer {
    margin-left: rem(16px);
    .ace-button .cmp-button {
        padding: rem(16px) rem(20px);
        height: auto;
        margin: 0;
    }
    }

    .core-booking-engine__date-search {
    display: none;
    }
}
}
@include media('>=lg') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__label,
    .ace-core-booking-engine__guests-title,
    .ace-core-booking-engine__option-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(8px);
    color: color(neutral-black, 900) !important;
    span.helper {
        color: color(neutral-black, 600);
        text-transform: lowercase;
    }
    }

    .ace-core-booking-engine__content {
    gap: 0;

    .ace-core-booking-engine__autocomplete,
    .ace-core-booking-engine__guests,
    .ace-core-booking-engine__check-in,
    .ace-core-booking-engine__check-out {
        padding: 0 rem(16px);
        position: relative;

        &::after {
        content: '';
        position: absolute;
        right: 0;
        background: color(neutral-black, 200);
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem(1px);
        height: 75%;
        border-radius: rem(6px);
        }
    }
    .ace-core-booking-engine__guests{
        &::after {
            background: none;
        }
    }
    .ace-core-booking-engine__options {
        padding-top: 0;
    }

    .ace-core-booking-engine {
        &__autocomplete,
        &__check-in,
        &__check-out,
        &__guests {
            padding: rem(12px) rem(16px);

            &:hover,
            &:focus-within {
                outline: rem(2px) solid $border-focus;
                border-radius: rem(6px);
            }
        }
        &__autocomplete {
            padding-left: rem(24px);

            &:hover,
            &:focus-within {
                border-top-left-radius: rem(100px);
                border-bottom-left-radius: rem(100px);
            }
        }
    }

    .ace-core-booking-engine__autocomplete {
        width: 34%;
    }

    .ace-core-booking-engine__dates-box {
        width: 28%;
        .ace-core-booking-engine__check-in,.ace-core-booking-engine__check-out {
        width: 48%;
        display: inline-block;
        position: relative;
        padding-left: rem(22px);
        box-sizing: border-box;
        padding-right: 0;
        .ace-core-booking-engine__labelDate {
            position: absolute;
            left: 0;
            color: color(neutral-black, 800);
            text-transform: capitalize;
        }
        }
        .ace-core-booking-engine__check-in{
        padding-left: rem(38px);
        .duet-date .duet-date__dialog{
            left: -4.05rem;
        }
        }
        .ace-core-booking-engine__check-out{
        .duet-date .duet-date__dialog{
            left: -2.05rem;
        }
        }

        .ace-core-booking-engine__label{
        text-transform: capitalize;
        }

    }

    .ace-core-booking-engine__guests {
        width: 23%;
        >svg {
        position: absolute;
        left: rem(14px);
        top: 0;
        }
        .ace-core-booking-engine__dropdown{
        margin-left: -1rem;
        }
        .ace-core-booking-engine__guests-title {
        display: block;
        width: 85%;
        text-align: initial;
        position: relative;
        top: 0;
        height:rem(22px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: rem(32px);
        svg {
            position: absolute;
            left: 0;
            top: 0;
            }
        }
    }

    .ace-core-booking-engine__options-trigger .input {
        width: 100%;
        text-decoration: none;
        font-weight: 400;
        font-family: $font-family-roboto;
        font-size: 1rem;
        line-height: 1.5rem;
        color: color(neutral-black, 600) !important;
        border-radius: 0.375rem;
        span.option-yes{
        display: none;
        }
        span{
        color: color(neutral-black, 600)
        }
    }

    .ahAutocomplete .ahAutocomplete__input {
        height: auto;
    }

    .ahAutocomplete__result {
        left: -1.8rem;
        min-width: 31.25rem !important;
    }

    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input,
    .ace-core-booking-engine__options-trigger .input {
        background-color: color(neutral, white);
        padding: 0;
        box-shadow: none !important;
        color: $text-body-primary !important;
        height: rem(24px) !important;
        border: rem(2px) solid transparent !important;
    }
    .ace-core-booking-engine__guests .input {
        background-color: color(neutral, white);
        padding: 0;
        box-shadow: none !important;
        color: color(neutral-black, 600) !important;
        height: rem(26px) !important;
        border: rem(2px) solid transparent !important;
    }
    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:focus,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input:focus,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input:focus,
    .ace-core-booking-engine__guests .input:focus,
    .ace-core-booking-engine__options-trigger .input:focus,
    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:hover,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input:hover,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input:hover,
    .ace-core-booking-engine__guests .input:hover,
    .ace-core-booking-engine__options-trigger .input:hover {
            border: rem(2px) solid transparent !important;
    }
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__toggle,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__toggle {
        box-shadow: none;
        width: 100%;
        border-radius: rem(6px);
        
        &:hover,
        &:active,
        &:focus {
        background-color: transparent;
        outline: none;
        }

        span svg {
        display: none;
        }
    }
    }

    form {
    height: auto;
    border-radius: rem(100px);
    border: rem(1px) solid color(neutral-black, 200);
    background: color(neutral, white);
    box-shadow: 0 rem(2px) rem(6px) 0 rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    input.invalid,
    input.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="%23BA1F1A" fill-rule="evenodd" d="M21.74 18.05 13.77 4.02c-.77-1.36-2.77-1.36-3.55 0L2.26 18.05C1.51 19.37 2.49 21 4.03 21h15.94c1.55 0 2.52-1.63 1.77-2.95ZM11.24 8c0-.41.34-.74.76-.74s.76.33.76.74v5.8c0 .41-.34.74-.76.74a.75.75 0 0 1-.76-.74V8Zm.76 9.77a.997.997 0 0 1-1.01-.99c0-.55.45-.99 1.01-.99s1.01.44 1.01.99-.45.99-1.01.99Z" clip-rule="evenodd"/></svg>');
    }

    }
    #mount-autocomplete {
    input.invalid,
    input.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="%23BA1F1A" fill-rule="evenodd" d="M21.74 18.05 13.77 4.02c-.77-1.36-2.77-1.36-3.55 0L2.26 18.05C1.51 19.37 2.49 21 4.03 21h15.94c1.55 0 2.52-1.63 1.77-2.95ZM11.24 8c0-.41.34-.74.76-.74s.76.33.76.74v5.8c0 .41-.34.74-.76.74a.75.75 0 0 1-.76-.74V8Zm.76 9.77a.997.997 0 0 1-1.01-.99c0-.55.45-.99 1.01-.99s1.01.44 1.01.99-.45.99-1.01.99Z" clip-rule="evenodd"/></svg>');
        }
    }


    .ace-core-booking-engine__footer {
        align-self: center;
        margin-left: rem(16px);
        margin-right: rem(12px);

    .ace-button .cmp-button {
        padding: rem(16px) rem(20px);
        height: auto;
        margin: 0;
    }
    }

    .core-booking-engine__date-search {
    display: none;
    }
}
}
@include media('>=lg', '<=lg') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__option-title {
    white-space: nowrap;
    }
}
}
@include media('<lg') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__label,
    .ace-core-booking-engine__guests-title,
    .ace-core-booking-engine__option-title {
    color: color(neutral-black, 900) !important;
    @include ui-body-02;
    span.helper {
        color: color(neutral-black, 600);
        text-transform: lowercase;
    }
    }
    form{
    display: block;
    input.invalid,
    input.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="%23BA1F1A" fill-rule="evenodd" d="M21.74 18.05 13.77 4.02c-.77-1.36-2.77-1.36-3.55 0L2.26 18.05C1.51 19.37 2.49 21 4.03 21h15.94c1.55 0 2.52-1.63 1.77-2.95ZM11.24 8c0-.41.34-.74.76-.74s.76.33.76.74v5.8c0 .41-.34.74-.76.74a.75.75 0 0 1-.76-.74V8Zm.76 9.77a.997.997 0 0 1-1.01-.99c0-.55.45-.99 1.01-.99s1.01.44 1.01.99-.45.99-1.01.99Z" clip-rule="evenodd"/></svg>');
        background-position: calc(100% - -0.2rem);
    }
    }
    #mount-autocomplete {
    input.invalid,
    input.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="%23BA1F1A" fill-rule="evenodd" d="M21.74 18.05 13.77 4.02c-.77-1.36-2.77-1.36-3.55 0L2.26 18.05C1.51 19.37 2.49 21 4.03 21h15.94c1.55 0 2.52-1.63 1.77-2.95ZM11.24 8c0-.41.34-.74.76-.74s.76.33.76.74v5.8c0 .41-.34.74-.76.74a.75.75 0 0 1-.76-.74V8Zm.76 9.77a.997.997 0 0 1-1.01-.99c0-.55.45-.99 1.01-.99s1.01.44 1.01.99-.45.99-1.01.99Z" clip-rule="evenodd"/></svg>');
        }
        .ahAutocomplete__result__items .autocompleteItem--result .buttonAutocomplete {
        padding: rem(12px) rem(16px);
        }
    }

    .ace-core-booking-engine__header{
    display: none;
    }
    .ace-core-booking-engine__open--button{
    display: none;
    }

    .ace-core-booking-engine__content {
        height: auto;
        overflow: inherit;
        width: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    .ace-core-booking-engine__autocomplete,
    .ace-core-booking-engine__check-in,
    .ace-core-booking-engine__check-out,
    .ace-core-booking-engine__guests {
        display: flex;
        padding: rem(8px) rem(16px) rem(8px) rem(52px);
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: rem(6px);
        border: rem(1px) solid color(neutral-black, 500);
        background: color(neutral, white);
        position: relative;

        .ace-core-booking-engine__label,
        .ace-core-booking-engine__option-title {
        margin-bottom: rem(4px);
        position: relative;

        >svg {
            position: absolute;
            left: rem(-36px);
            top: 50%;

        }
        }

        .ace-core-booking-engine__guests-title {
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        }
    }

    .ace-core-booking-engine__autocomplete {
        .ace-shared-acp-component {
        width: 100%;
        }
    }

    .ahAutocomplete .ahAutocomplete__input {
        height: auto;
        font-family: $font-family-roboto;
        font-size: rem(14px);
        font-weight: 400;
        line-height: rem(20px);
        .material-textfield input::placeholder {
        color: $text-body-secondary !important;
        font-family: $font-family-roboto;
        font-size: rem(16px);
        font-weight: 400;
        line-height: rem(20px);
        }
        .material-textfield input::-webkit-input-placeholder {
        color: $text-body-secondary !important;
        font-family: $font-family-roboto;
        font-size: rem(16px);
        font-weight: 400;
        line-height: rem(20px);
        }
        .material-textfield input:-ms-input-placeholder {
        color: $text-body-secondary !important;
        font-family: $font-family-roboto;
        font-size: rem(16px);
        font-weight: 400;
        line-height: rem(20px);
        }
    }

    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel,
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__input,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__input,
    .ace-core-booking-engine__guests .input,
    .ace-core-booking-engine__check-in .ace-core-booking-engine__labelDate,
    .ace-core-booking-engine__check-out .ace-core-booking-engine__labelDate,
    .ace-core-booking-engine__options-trigger .input
        {
        padding: 0;
        box-shadow: none !important;
        color: color(neutral-black, 600) !important;
        height: rem(24px) !important;
        font-size: rem(16px);
        line-height: rem(24px);
        border: rem(2px) solid transparent !important;
    }

    .ace-core-booking-engine__dates-box {
        width: 31%;
        display: inline-block;
        margin-top: rem(24px);
        margin-right: rem(12px);
        .ace-core-booking-engine__check-in,.ace-core-booking-engine__check-out {
        width: 42%;
        display: inline-block;
        position: relative;
        padding-left: rem(17px);
        box-sizing: border-box;
        padding-right: 0;
        .ace-core-booking-engine__labelDate {
            position: absolute;
            left: 0;
            color: color(neutral-black, 800);
            top: 0;
            text-transform: capitalize;
        }
        }
        .ace-core-booking-engine__check-in{
        padding-left: rem(38px);
        .duet-date .duet-date__dialog{
            left: -6.35rem;
        }
        }
        .ace-core-booking-engine__check-out{
        .duet-date .duet-date__dialog{
            left: -2.05rem;
        }
        }

        .ace-core-booking-engine__label{
        text-transform: capitalize;
        }
    }
    
    .ace-core-booking-engine__check-in,
    .ace-core-booking-engine__check-out{
        width: calc((100% + rem(16px))/ 4*2 - rem(16px));
        box-sizing: border-box;
    }
    .ace-core-booking-engine__guests {
        width: 100% !important;
        display: inline-block;
        margin-top: rem(24px);
        &-title{
            position: static;
            svg{
                position: absolute;
                top: 29%;
                left: rem(16px);
            }
        }
        >svg {
        position: absolute;
        left: rem(14px);
        top: 29%;
        }
        .ace-core-booking-engine__dropdown {
        left: 0;
        min-width: 20rem;
        margin-top: rem(16px);
        }
    }
    
    .ace-core-booking-engine__check-in duet-date-picker .duet-date__toggle,
    .ace-core-booking-engine__check-out duet-date-picker .duet-date__toggle {
        box-shadow: none;
        width: 100%;
        border-radius: rem(6px);
        max-width: 100%;

        &:focus {
        background-color: transparent;
        }

        &:hover {
        background-color: transparent;
        }

        &:active {
        background-color: transparent;
        }

        span svg {
        display: none;
        }
    }

    .ahAutocomplete__result {
        left: rem(-54px);
        min-width: 92vw !important;
        z-index: 9;
    }
    }

    .ace-core-booking-engine__footer {
    position: relative;
    margin-left: 0;
    z-index: 0;
    background: none;
    .ace-button .cmp-button {
        padding: rem(16px) rem(20px);
    }
    }

    .core-booking-engine__date-search {
    display: none;
    }
}

.ace-core-booking-engine.show {
    position: fixed;
    z-index: 9999;
    width: auto;
    margin: unset;
    background: color(neutral, white);
}
}
@include media('>=md', '<lg') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__content {
    duet-date-picker .duet-date__dialog.is-active {
        position: absolute;
        left: rem(-63px);
        top: rem(22px);
    }
    .ace-core-booking-engine__check-in{
        display: inline-grid;
        margin-right: rem(16px);
    }
    .ace-core-booking-engine__check-out{
        display: inline-grid;
    }
    .ace-core-booking-engine__dates-box {
        .ace-core-booking-engine__check-in,.ace-core-booking-engine__check-out {
        width: 48%;
        padding-right: 0;
        margin-top: 0;
        }
    }

    

    .ace-core-booking-engine {
        &__autocomplete,
        &__check-in,
        &__check-out,
        &__guests { 
            &:focus-within {
                outline: rem(2px) solid $border-focus;
                border-radius: rem(6px);
            }
        }
    }

    .ace-core-booking-engine {
        &__autocomplete,
        &__check-in,
        &__check-out,
        &__guests { 
            &:focus-within {
                outline: rem(2px) solid $border-focus;
                border-radius: rem(6px);
            }
        }
    }
}
}
}

@include media('<md') {
.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    form{
    display: block;
    }
    .ace-core-booking-engine__header{
    display: none;
    }
    .ace-core-booking-engine__content {
    height: auto;
    overflow: inherit;
    margin-top: rem(40px);
    width: auto;
    column-gap: 8px;
    justify-content: unset;
    .ace-core-booking-engine__autocomplete{
        width: 100%;
        display: block;
    }

    .ace-core-booking-engine {
        &__autocomplete,
        &__check-in,
        &__check-out,
        &__guests { 
            &:focus-within {
                outline: rem(2px) solid $border-focus;
                border-radius: rem(6px);
            }
        }
    }
    #mount-autocomplete .ahAutocomplete__result__items .autocompleteItem--result .buttonAutocomplete {
        padding: rem(8px) rem(16px);
    }
    
      .ace-core-booking-engine__options{
        background: none;
        border: none;
        padding: 0;
    }
    .ace-core-booking-engine__dates-box {
        width: auto;
        display: block;
        margin-right: 0;
        margin-top: rem(24px);
        .ace-core-booking-engine__check-in,.ace-core-booking-engine__check-out {
        width: 41%;
        display: inline-block;
        position: relative;
        padding-left: rem(22px);
        box-sizing: border-box;
        margin-top: 0 !important;
        .ace-core-booking-engine__labelDate {
            position: absolute;
            top: 0;
            left: 0;
            color: color(neutral-black, 800);
            text-transform: capitalize;
        }
        }
        .ace-core-booking-engine__check-in{
        padding-left: rem(38px);
        .duet-date .duet-date__dialog{
            left: 0;
        }
        }
        .ace-core-booking-engine__check-out{
        .duet-date .duet-date__dialog{
            left: 0;
        }
        }
    }

    .ace-core-booking-engine__guests {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-top: rem(8px);
    }
    
    .ace-core-booking-engine__header button{
    top: -2rem;
    }
    .ahAutocomplete__result {
    left: rem(-54px);
    min-width: 92vw !important;
    z-index: 9;
    }

}
.ace-core-booking-engine__footer {
    position: relative;
    margin-left: 0;
    z-index: 0;
    padding-top: rem(32px);
}

}
}

.ace-core-booking-engine.core-booking-engine__homepagewrapper {
    .ace-core-booking-engine__content{
            .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel{
                  @include media('>=lg') {
                    height: rem(25px);
                  }
            }
            .ace-core-booking-engine__guests {
                    .ace-room__title {
                    font-size: rem(18px);
                    display:inline-block;
                    text-transform: lowercase !important;
                        &:first-letter {
                                text-transform: uppercase;
                            }
                }
                .ace-counter__buttons button:not(.disabled):focus {
                    box-shadow: none !important;
                }
            }
    }
}

$namespace: '.ace-core-booking-engine';
.core-booking-engine-v2 {
    .core-booking-engine {
        #{$namespace}.core-booking-engine__homepagewrapper {
            #{$namespace}__content {
                fieldset#{$namespace}__date-picker--wrapper {
                    width: 100%;
                    padding: 0;
                    border: none;
                    margin: 0;
                    display: flex;

                    @include media('>=lg') {
                        width: calc((100% + 24px)/ 9*4 - 24px);
                        gap: 0;
                    }

                    #{$namespace}__check-in,
                    #{$namespace}__check-out {
                        @include media('>=lg') {
                            width: calc((100% + 24px)/ 4*2 - 24px);
                        }
                    }

                    #{$namespace}__check-in {
                        @include media('<md') {
                            margin-right: rem(16px);
                        }
                    }

                    .duet-date__input-wrapper {
                        input {
                            &::placeholder {
                                color: $text-body-primary !important;
                            }
                        }
                    }
                }

                .ace-core-booking-engine {
                        &__guests {
                            button.input {
                                color: $text-body-primary !important;
                            }
                        }

                        &__options-panel--wrapper {
                            .ace-customdropdown {
                                &__selected {
                                    color: $text-body-primary !important;
                                }
                            }
                        }
                    }
                }

                .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel {
                    color: $text-body-primary !important;
                }

            &:has(.awards fieldset:only-child) {
                .ace-core-booking-engine__options {
                    @include media('>=lg') {
                        top: 12.2rem;
                    }
                }
            }
        }

        @include media('>=lg') {
            &.booking-engine.not-merge-next.awards-toggle {
                &:has(.awards fieldset:only-child) {
                    height: calc(14rem + var(--errorHeight));
                }

                &.expanded:has(.awards fieldset:only-child) {
                    height: calc(34rem + var(--errorHeight));
                }

                &.expanded:has(.accessible-room .awards fieldset:only-child) {
                    height: calc(38rem + var(--errorHeight));
                }
            }
        }
    }
}

