.sub-nav-container {
  width: 100%;
  background: $color-background;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  box-sizing: border-box;
  @include media('<=sm'){
    top: 0;
    min-height: rem(494px);
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  @include media('>sm','<lg'){
    top: 0;
    overflow-y: auto;
    height: 100%;
    min-height: rem(494px);
    display: flex;
    flex-direction: column;
  }
  @include media('>=lg') {
    padding: rem(48px) rem(64px);
    max-height: calc(100vh - rem(92px));
    overflow: auto;
    box-shadow: rem(0px) rem(5px) rem(5px) rem(-5px) color(neutral-black,200);
  }
  &__menu__back {
    display: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__panel__scroll {
    padding: 0;
    height: max-content;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include media('>=lg') {
    gap: rem(24px);
       &:not(:has(.sub-nav-container__push-nav)) {
            flex: 0 1 auto;
        }
    }
    @include media('<lg') {
      flex-direction: column;
      flex: 0;
    }
  }

  &__main-nav,
  &__secondary-nav {
    flex: 1;
    padding: 0;
    width: 100%;
  }

  &__main-nav {
    @include media(">=md", "<lg") {
      .image-navigation{
        padding-top: 3rem;
      }
    }
    .ace-image-nav-container .ace-image-nav > li {
      display: flex;
      flex-direction: column;
      padding: 0 rem(24px) 0 0;
      box-sizing: border-box;
      width: rem(285px) !important;
      &:last-child {
        padding-right: 0;
    }
    @include media('<lg') {
      width: 100% !important;
      padding: 0;
    }
    @include media('>=lg', '<xl') {
      width: 100% !important;
    }

  }
  .ace-image-nav-container .ace-image-nav .ace-image-nav-list{
    width: rem(285px);
  }
}

  &__secondary-nav {
    .simple-nav-list-item {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;

      @include media('<lg') {
        flex-direction: column;
      }

      li {
        width: 100%;
        padding-right: rem(24px);
        max-width: rem(182px);
      }
    }
  }

  .sub-nav-container__push-nav .push-nav-list-item.two-pushnavs .ace-push-nav .ace-push-nav__img {
    width: rem(112px);
    height: rem(140px);
  }
  .sub-nav-container__push-nav .push-nav-list-item.two-pushnavs .ace-push-nav .ace-push-nav__element{
    @include media('>lg') {
      padding:rem(16px);
    }
  }
  .sub-nav-container__push-nav .push-nav-list-item.two-pushnavs .ace-push-nav .ace-push-nav__content {
    width: calc(100% - rem(112px));
    justify-content: flex-start;
    padding-left: rem(16px);
    @include media('<md') {
      padding-left: 0 !important;
      width: 100% 0 !important;
    }
    &-title {
      font-family: $font-family-roboto !important;
      font-style: normal;
      font-weight: 500 !important;
      font-size: rem(18px) !important;
      line-height: rem(24px) !important;
      color: color(neutral-black, 900);
      margin-bottom: rem(12px);
      margin-top: 0;

      @include media('<md') {
        margin-top: rem(24px)
      }
    }

    &-desc p {
      font-family: $font-family-roboto;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(20px) !important;
      color: color(neutral-black, 800);
      margin-bottom: rem(16px);
      margin-top: 0;
    }

    &-link {
      width: max-content;
    }
  }

  @include media('<lg') {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sub-nav-container__secondary-nav ul.simple-nav-list-item.two-simplenavs li .ace-simple-nav.withoutSection-nav ul li ul {
    margin-top: 0;
  }
 
}
.header-navigation .sub-nav-container__secondary-nav .ace-navigation .ace-simple-nav.withoutSection-nav .ace-simple-nav__listItems li:first-child {
  margin-top: 0;
}
.sub-nav-container__push-nav .push-nav-list-item.two-pushnavs{
  @include media('<lg') {
    :first-child{
      .ace-push-nav{
      margin-bottom: rem(48px);
      }
    }
  }
}
.sub-nav-container__secondary-nav .simple-nav-list-item.two-simplenavs{
  @include media('<lg') {
    :first-child{
      .ace-navigation{
      margin-bottom: rem(48px);
      }
    }
  }
}
.sub-nav-container__push-nav .ace-push-nav .ace-push-nav__content-desc p{
  @include media('<lg') {
    margin-bottom:rem(24px) !important;
    font-size: rem(16px) !important;
  }
}

html[dir="rtl"] {
.sub-nav-container__main-nav .ace-image-nav-container .ace-image-nav>li:last-child {
  padding-right: rem(24px);
}
}