.cmp-ace-anchor-navigation {
    &.sticky-bar {
        position: sticky;
        top: 0;
        left: 0;
        z-index: map-get-deep($z-index, header, stickybar);
        @include fluid("parent");
        background-color: $bg-neutral-primary;
        border-bottom: rem(1px) solid $border-decorative ;
        @include media("<lg") {
            border: rem(2px) solid $border-focus;
            width: auto;
            min-width: -webkit-fill-available;
        }
    }
    &.flexible-bar {
        display: flex;
    }
    &__tabs {
        background-color: $bg-neutral-primary;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include fluid("child");
        @include media("<lg") {
            margin-left: 0;
        }
        &.flexi-bar {
            display: flex;
            flex: 1;
            @include media("<lg") {
                flex-direction: column;
            }
        }
        &__anchors {
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                @include media("<lg"){
                    flex-direction: column;
                }
            }
            margin: rem(30px) 0;
            @include media(">=lg") {
                display: flex;
                flex-wrap: wrap;
            }
            @include media(">=lg", "<xl") {
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
            }
            a {
                text-decoration: none;
                display: block;
                @include media(">=lg", "<xl") {
                    max-width: 8.375rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-bottom: 0;
                    min-width: rem(60px);
                }
            }
        }
        &__anchor-button {
            @include media(">=lg") {
                margin-bottom: rem(18px);
                margin-top: rem(18px);
            }
            @include media(">=lg", "<=xl") {
                margin-right: 2.5rem;
            }
            .cmp-button--primary {
                display: none !important;
            }
        }
    }
    .cmp-ace-anchor-navigation__tab {
        @include ui-body-01;
        color: $text-heading;
        margin-right: 0;
        padding: rem(28px) padding(05);
        cursor: pointer;
        @include media("<lg") {
            color: $text-body-primary;
        }
    }
    .cmp-ace-anchor-navigation__tab--active::after {
        content: "";
        position: absolute;
        background-color: $bg-accent-primary;
        height: rem(4px);
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 1;
    }
    .cmp-ace-anchor-navigation__tab--active:focus {
        color: $text-heading;
    }
    &.stickyHide {
        display: none;
    }
}
@include media("<lg") {
    .ace-quick-access-component {
        z-index: 11;
        position: relative;
    }
    .cmp-ace-anchor-navigation {
        &__tabs {
            &__anchors {
                margin: rem(16px) 0;
                width: 100%;
            }
        }
        .cmp-filter__item:hover,
        .cmp-filter__item:focus,
        .cmp-filter__item:any-link:focus {
            background-color: $bg-brand-secondary;
            color: $text-inverse-body-primary;
            outline: unset;
        }
        &__tab--active::after,
        &__tabs__anchor-button {
            display: none;
        }
    }
    .cmp-ace-anchor-navigation__tab {
        padding-bottom: padding(04);
    }
    .ace-quick-access-component > div > .cmp-anchor-nav-button {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: 100vw;
        z-index: map-get-deep($z-index, header, stickybar);
        left: 0;
        top: 0;
    }
    .ace-quick-access-component > * .cmp-filter__menu.show {
        padding-top: 0;
    }
    .cmp-filter__menu > * .cmp-ace-anchor-navigation__tab.cmp-filter__item {
        padding: padding(02) padding(06);
        margin-bottom: 0;
        &.cmp-ace-anchor-navigation__tab--active::before {
            @include icomoon;
            content: "\e93c";
            display: block;
            position: absolute;
            left: 0.5rem;
        }
    }
}
.ace-quick-access-component {
    z-index: 124;
    position: relative;
    .ace-booknow__open & {
        display: none;
    }
    .cmp-filter.cmp-anchor-nav-button {
        @include media(">=lg") {
            border-bottom: medium none;
        }
        .cmp-ace-anchor-navigation {
            &__tabs {
                &:after {
                    display: none;
                }
                max-width: inherit;
                @include media(">=lg") {
                    max-height: rem(80px);
                }
                &__anchors {
                    @include media(">=lg") {
                         margin-left: rem(32px);
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    @include media(">xl") {
                        margin-left: rem(64px);
                    }
                    @include media(">=xxl") {
                        margin-left: 0;
                    }
                }
                &__anchor-button {
                    @include media(">=lg") {
                        margin-right: rem(64px);
                    }
                }
                @include media(">=xl") {
                    margin: 0;
                }
                @include media(">=lg") {
                    margin-left: 0;
                    margin-right: 0;
                }
                @include media(">=xxl") {
                    margin: auto;
                    max-width: rem(1310px);
                }
            }
            .cmp-ace-anchor-navigation {
                &__tab {
                    @include media(">=lg") {
                        padding-bottom: rem(28px);
                        padding-top: rem(28px);
                    }

                    @include media("<lg") {
                        margin-right: rem(15px);
                        padding-bottom: padding(02);
                        padding-top: padding(02);
                    }
                }
            }
        }
    }
}
.fixed-pos {
    position: fixed;
}
@include media("<lg") {
    .cmp-anchor-nav-button {
        button {
            color: $text-heading;
            @include ui-body-01;
            height: rem(48px);
            display: flex;
            align-items: center;
            margin-top: 0;
        }
    }
}
.cmp-ace-anchor-navigation.sticky-bar {
    .cmp-button--primary {
        display: none;
    }
}
.sticky-bar.booknow_button_show {
    .cmp-button--primary {
        display: flex;
        border: unset;
    }
}
// drodown filter styles
// The dropdown menu
.cmp-filter {
    &__toggle {
       position: relative;
        &[aria-expanded="true"]::after {
            transform: rotate(180deg);
        }
        &[aria-expanded="true"]button:focus {
            outline: unset;
        }
        &::after {
            content: "\e93d";
            position: absolute;
            width: rem(24px);
            height: rem(24px);
            @include icomoon;
            float: right;
            right: rem(16px);
            top: rem(12px);
            font-size: rem(24px);
            color: $text-brand;
        }
    }
}
@include media(">=lg") {
    .cmp-filter {
        color: color(text, body);
        font-size: 1rem;
        line-height: 1.5rem;
        border-bottom: rem(1px) solid $border-decorative;
        &__toggle,
        &__icon {
            display: none;
            position: fixed;
            left: rem(-10000px);
            opacity: 0;
        }
        &__menu {
            display: flex; // none by default, but block on "open" of the menu
            text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
            list-style: none;
            background-clip: padding-box;
        }
        &__item {
            background-color: $bg-neutral-primary;
            border: none;
            padding-bottom: padding(03);
            color: color(neutral, 900);
            position: relative;
            padding-left: 0;
            padding-right: 0;
            margin-right: 1rem;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &-all {
                display: inline-block;
                white-space: nowrap;
            }
            &::after {
                content: "";
                position: absolute;
                background-color: $bg-accent-primary;
                height: rem(4px);
                width: 0%;
                bottom: 0;
                left: 0;
                right: 0;
                transition: all 0.4s ease;
                opacity: 0;
            }
            &.selected,
            &.active,
            &:hover,
            &:focus {
                &::after {
                    width: 100%;
                    opacity: 1;
                }
            }
            &:focus {
                outline: rem(2px) solid $border-focus;
                outline-offset: rem(-2px);
            }
        }
    }
}
@include media("<lg") {
    .cmp-filter {
        position: relative;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: 100vw;
        margin-bottom: 0;
        .cmp-filter__icon {
            position: absolute;
            right: rem(26px);
            top: rem(20px);
            fill: $color-accent;
            transition: all 0.4s ease;
            pointer-events: none;
        }
    }
    .cmp-filter__toggle {
        width: 100%;
        line-height: rem(48px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 0;
        background-color: $bg-neutral-primary;
        box-shadow: none;
        border: rem(1px) solid $border-decorative;
        font-size: 1rem;
        padding-left: padding(04);
        padding-right: rem(20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .cmp-filter__menu {
        > div {
            width: 100%;
        }
        display: none;
        background-color: $bg-neutral-primary;
        border: rem(1px) solid color(stroke);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: padding(05);
        .cmp-filter__item {
            position: relative;
            cursor: pointer;
            border: none;
            width: 100%;
            align-items: center;
            display: flex;
            margin-bottom: rem(16px);
            padding-left: padding(06);
            padding-right: padding(04);
        }
        &.show {
            display: flex;
            position: absolute;
            left: 0;
            right: 0;
            z-index: map-get-deep($z-index, main, dropdownfilter);
        }
    }
}
@include media("<lg") {
    .cmp-filter {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }
}
@include media("<lg") {
    @supports (-webkit-touch-callout: none) {
        .cmp-filter {
            &__menu {
                &__item {
                    &.selected {
                        &::after {
                            top: rem(4px);
                        }
                    }
                }
            }
        }
    }
}

html[dir="rtl"] {
    .ace-quick-access-component {
        .cmp-filter.cmp-anchor-nav-button {
            .cmp-ace-anchor-navigation {
                &__tabs {
                    &__anchors {
                        @include media(">=lg") {
                            margin-right: rem(32px);
                            margin-left: 0;
                        }
                        @include media(">=lg") {
                            margin-left: 0;
                        }
                    }
                    &__anchor-button {
                        @include media(">=lg") {
                            margin-left: rem(80px);
                            margin-right: 0;
                        }

                        @include media(">=lg", "<=xl") {
                            margin-left: rem(40px);
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .cmp-filter {
        .cmp-filter {
            &__icon {
                left: rem(26px);
                right: auto;
            }
            &__menu {
                .cmp-filter__item::before {
                    margin-left: rem(8px);
                    margin-right: 0;
                }
            }
        }
    }
    .cmp-filter {
        &__item {
            margin-right: 0;
            margin-left: 1rem;
        }
    }
}

