.offer {

  .ace-headingpagehero__content {
    width: 100%;
    text-align: left;
    left: auto;

    @include media('<lg') {
      max-width: 92%;
      padding-top: rem(78px);
    }

    h2,
    h3,
    h4 {
      margin-top: 0 !important;
      margin-bottom: rem(32px);
      width: calc(100vw - 31%);
      @include media('>=md','<lg') {
          margin-left: -4%;
      }

      @include media('<lg') {
        padding: 0;
        width: 100%;
        margin-bottom: rem(24px);
        font-size: rem(24px);
        line-height: rem(36px);

      }

    }
  }

  .ace-headingpagehero-headline {
    display: flex;
    align-items: flex-start;
    margin-left: 4%;
    @include media('<md') {
      margin-left: 0;
    }

  }

  .offer__list {
    flex-wrap: wrap;
    gap: 0 rem(28px);
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    color:color(neutral, white);
    margin-bottom: rem(24px);

    @include media('<lg') {
      margin-bottom: 0;

    }

    width: 100%;

    li {
      min-width: 2rem;
      flex-direction: row-reverse;
      flex-basis: 20.5%;
      justify-content: flex-end;
      align-items: center;
      display: flex;

      @include media('<lg') {
        margin-bottom: rem(20px);
        flex-basis: 48%;

      }
      @include media('<md') {
        margin-bottom: rem(8px);
        &:last-child{
          margin-bottom: 0;
        }


      }
    }
  }

  .ace-highlight__content--link {
    @include ui-body-01;
    color:color(neutral, white);
    width: auto;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
    .icon{
      display: none;
    }

    @include media('<lg') {
      margin-top: rem(28px);

    }

  }
.ace-headingpagehero__content{
  .offer-link-right{
    color:color(neutral, white);
    width: 94%;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
    .icon{
      display: none;
    }
  }
  }
  .icon-plus-24icon {
    margin-right: rem(16px);
    background-color:color(neutral, white);
    border-radius: 50%;
  }
  .ace-modal-component {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    max-width: 90%;
}
}
.disable-scroll-modal{
  .heading-offer{
    @include media('<md') {
    margin-top: 0 !important;
    .ace-modal__dialog{
      margin: rem(24px)!important;
    }
    }
.offer {
  .ace-headingpagehero__content{
    max-width:100%;
    overflow: inherit;
    z-index: 9999;
    @include media('<lg') {
      max-width:92%;
    }
  }
.ace-headingpagehero-headline{
  margin-left: 0;

}
.ace-modal-component{
  max-width: 100%;
  position:fixed;
  .ace-highlight__content--link{
    display: none;
  }
}
}

}

  .offer{
    .ace-headingpagehero__content{
      h2,h3,h4{
      @include media('>=md','<lg') {
        margin-left:0;
    }
  }

    }
  }


}
