.ace-booknow-component {

  &:not(.show)#{&}__merge-prev,
  &:not(.show)#{&}__merge-next {
    position: relative;
    z-index: 5;

    @include media('>lg') {
      z-index: 125;
    }
  }

  &:not(.show)#{&}__merge-prev {
    margin-top: rem(-28px);

    @include media('>=lg') {
      margin-top: rem(-40px);
    }
  }

  &:not(.show)#{&}__merge-next {
    margin-bottom: rem(-92px);

    @include media('>=md') {
      margin-bottom: rem(-108px);
    }

    @include media('>=lg') {
      margin-bottom: rem(-136px);
    }
  }

  .icon {
    svg {
      display: none;
    }
  }

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @include media('>=md', '<lg') {
    padding: 0 1rem;
  }

  @include media('>=lg') {
    position: relative;
    max-height: rem(80px);
    overflow: unset;
    background: color(neutral, white);
    box-sizing: border-box;
    border: rem(1px) solid color(neutral-black, 200);
    width: 100%;
    border-radius: rem(60px);
    box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 10%);
    flex: 1;
    margin: 0;
    padding: 0;
  }

  @include media('>=lg', '<xl') {
    width: calc(100vw - rem(64px));
    margin: auto;
  }

  form {
    position: relative;
    display: none;
    height: 100%;

    @include media('>=lg') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: rem(80px);
    }
  }

  &.show {
    position: fixed;
    z-index: 200;
    width: auto;
    background-color: color(neutral, white);
    margin: unset;

    @include media('>=lg') {
      position: relative;
    }

    form {
      display: block;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .ui-body-01 {
    color: color(neutral-black, 800);
  }

  .ui-body-02 {
    color: color(neutral-black, 600);
  }

  .ace-booknow {

    // header styles
    &__header {
      position: relative;
      height: rem(64px);
      background: color(neutral, white);
      top: 0;
      margin-right: calc(50% - 50vw);
      margin-left: 100vw;

      &-scroll {
        box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 10%);
      }

      button {
        background-color: color(neutral, white);
        padding: 0;
        border: 0;
        position: relative;
        top: rem(16px);
        right: rem(24px);
        float: right;
        width: rem(24px);
        height: rem(24px);
        font-size: 1.5rem;
        bottom: rem(24px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include media('>=lg') {
        display: none;
      }
    }

    // content styles
    &__content {
      height: calc(100vh - rem(200px));
      overflow: scroll;

      .icon-search-24icon {
        display: none;
      }

      @include media('<lg') {
        padding: 0 1rem;
      }

      @include media('>=lg') {
        .icon-search-24icon {
          margin-right: rem(16px);
          font-size: rem(24px);
          display: flex;
          margin-left: rem(24px);
          align-items: center;
          justify-content: center;
        }

        legend {
          color: color(neutral-black, 600);
        }

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: visible;
      }
    }

    // footer styles
    &__footer {
      position: fixed;
      height: auto;
      bottom: 0;
      padding: 2rem 1rem;
      right: 0;
      left: 0;
      background: color(neutral, white);
      @include media('>md') {
        z-index: 2;
      }

      .ace-button .cmp-button {
        width: 100%;
        text-transform: capitalize;
        border-color: transparent;

        @include media('<lg') {
          height: rem(48px);
        }
      }

      @include media('>=lg') {
        position: relative;
        height: auto;
        padding: 0;
        background-color: transparent;

        .ace-button .cmp-button {
          height: rem(56px);
          margin: rem(12px) rem(12px) rem(12px) 0;
          padding: rem(16px) rem(24px);
          width: auto;
          border: rem(1px) solid color(neutral, white);
          &:focus {
            outline-offset: rem(1px);
          }
        }
      }
    }

    &__location,
    &__dates,
    &__guests {
      @include media('>=lg') {
        position: relative;
        flex-basis: calc(100% / 3);
      }
    }

    @include media('>=lg') {

      &__location,
      &__dates {
        border-right: rem(1px) solid color(neutral-black, 200);
      }
    }

    // location block styles
    &__location {
      position: relative;

      &>div {
        position: relative;
        z-index: 12;
      }

      [data-slug] {
        &:hover {
          background-color: color(neutral-black, 100);
          border-radius: rem(6px);
        }

        cursor: pointer;

        & * {
          pointer-events: none;
        }
      }

      svg#icon-error {
        position: absolute;
        top: rem(44px);
        right: rem(16px);
        display: none;
      }

      &--icon {
        width: rem(44px);
        height: rem(44px);
        background-color: $bg-brand-secondary;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;

        &--Hotel {
          -webkit-mask: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M19.376 6.85423V2.81942C19.3744 1.26256 18.1124 0.00103592 16.5556 0H3.44455C1.88769 0.00103592 0.625702 1.26256 0.62409 2.81942V6.85423H0V15.9304H1.04415V14.5977H18.9558V15.9304H20V6.85423H19.376ZM1.66824 2.81942C1.66985 1.83923 2.46436 1.04519 3.44455 1.04415H16.5556C17.5357 1.04519 18.3303 1.83923 18.3319 2.81942V6.85423H17.51V6.27608C17.5089 4.97792 16.4567 3.92585 15.1586 3.92476H12.7562C11.4587 3.92676 10.4078 4.97863 10.4069 6.27608V6.85423H9.59315V6.27608C9.59205 4.97794 8.53997 3.92586 7.24182 3.92476H4.83944C3.54127 3.92585 2.48916 4.97792 2.48801 6.27608V6.85423H1.66824V2.81942ZM11.451 6.85423H16.4659V6.27608C16.4654 5.55429 15.8804 4.96927 15.1586 4.96891H12.7562C12.0351 4.97018 11.4512 5.555 11.451 6.27608V6.85423ZM8.54899 6.85423H3.53216V6.27608C3.53328 5.55458 4.11793 4.96997 4.83944 4.96891H7.24182C7.96329 4.97002 8.54788 5.55461 8.54899 6.27608V6.85423ZM1.04415 13.5535H18.9558V7.89838H1.04415V13.5535Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          mask: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M19.376 6.85423V2.81942C19.3744 1.26256 18.1124 0.00103592 16.5556 0H3.44455C1.88769 0.00103592 0.625702 1.26256 0.62409 2.81942V6.85423H0V15.9304H1.04415V14.5977H18.9558V15.9304H20V6.85423H19.376ZM1.66824 2.81942C1.66985 1.83923 2.46436 1.04519 3.44455 1.04415H16.5556C17.5357 1.04519 18.3303 1.83923 18.3319 2.81942V6.85423H17.51V6.27608C17.5089 4.97792 16.4567 3.92585 15.1586 3.92476H12.7562C11.4587 3.92676 10.4078 4.97863 10.4069 6.27608V6.85423H9.59315V6.27608C9.59205 4.97794 8.53997 3.92586 7.24182 3.92476H4.83944C3.54127 3.92585 2.48916 4.97792 2.48801 6.27608V6.85423H1.66824V2.81942ZM11.451 6.85423H16.4659V6.27608C16.4654 5.55429 15.8804 4.96927 15.1586 4.96891H12.7562C12.0351 4.97018 11.4512 5.555 11.451 6.27608V6.85423ZM8.54899 6.85423H3.53216V6.27608C3.53328 5.55458 4.11793 4.96997 4.83944 4.96891H7.24182C7.96329 4.97002 8.54788 5.55461 8.54899 6.27608V6.85423ZM1.04415 13.5535H18.9558V7.89838H1.04415V13.5535Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
        }

        &--Restaurant {
          -webkit-mask: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M5.81383 0.419549C5.76143 0.138952 5.50522 -0.0441354 5.24029 0.00969255C4.97565 0.0645688 4.80278 0.336178 4.85379 0.616964C4.96469 1.72664 5.02109 2.84169 5.02275 3.95758C5.02275 6.5706 4.13141 7.18181 3.48818 7.32555V0.518826C3.48818 0.23266 3.26942 0.00067675 2.99956 0.00067675C2.7297 0.00067675 2.51094 0.23266 2.51094 0.518826V7.32555C1.86859 7.18181 0.977246 6.5706 0.977246 3.95758C0.978915 2.84169 1.03531 1.72664 1.14621 0.616964C1.19741 0.335934 1.02419 0.0640665 0.759207 0.00957851C0.494227 -0.0449094 0.237732 0.138595 0.186165 0.419549C0.0633282 1.59441 0.00118023 2.77552 0 3.95758C0 7.30731 1.40577 8.19086 2.51094 8.36796V19.4818C2.51094 19.768 2.7297 20 2.99956 20C3.26942 20 3.48818 19.768 3.48818 19.4818V8.36796C4.59325 8.19086 6 7.30731 6 3.95758C5.99882 2.77552 5.93667 1.59441 5.81383 0.419549ZM11.5504 0.0693511C11.8093 0.115052 11.9972 0.32201 12 0.564273V19.4839C12 19.7689 11.7487 20 11.4386 20C11.1286 20 10.8772 19.7689 10.8772 19.4839V11.3455H7.72163C7.43058 11.345 7.18806 11.1404 7.1625 10.8738C6.6603 5.61723 7.32362 2.20118 9.1317 0.720543C9.74134 0.179023 10.5857 -0.0783857 11.4265 0.0209427C11.4697 0.0327253 11.5113 0.0489649 11.5504 0.0693511ZM8.23922 10.3134H10.8772V1.04103C10.4878 1.09819 10.1272 1.26515 9.84544 1.51882C9.021 2.21738 7.73702 4.28574 8.23922 10.3134Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          mask: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M5.81383 0.419549C5.76143 0.138952 5.50522 -0.0441354 5.24029 0.00969255C4.97565 0.0645688 4.80278 0.336178 4.85379 0.616964C4.96469 1.72664 5.02109 2.84169 5.02275 3.95758C5.02275 6.5706 4.13141 7.18181 3.48818 7.32555V0.518826C3.48818 0.23266 3.26942 0.00067675 2.99956 0.00067675C2.7297 0.00067675 2.51094 0.23266 2.51094 0.518826V7.32555C1.86859 7.18181 0.977246 6.5706 0.977246 3.95758C0.978915 2.84169 1.03531 1.72664 1.14621 0.616964C1.19741 0.335934 1.02419 0.0640665 0.759207 0.00957851C0.494227 -0.0449094 0.237732 0.138595 0.186165 0.419549C0.0633282 1.59441 0.00118023 2.77552 0 3.95758C0 7.30731 1.40577 8.19086 2.51094 8.36796V19.4818C2.51094 19.768 2.7297 20 2.99956 20C3.26942 20 3.48818 19.768 3.48818 19.4818V8.36796C4.59325 8.19086 6 7.30731 6 3.95758C5.99882 2.77552 5.93667 1.59441 5.81383 0.419549ZM11.5504 0.0693511C11.8093 0.115052 11.9972 0.32201 12 0.564273V19.4839C12 19.7689 11.7487 20 11.4386 20C11.1286 20 10.8772 19.7689 10.8772 19.4839V11.3455H7.72163C7.43058 11.345 7.18806 11.1404 7.1625 10.8738C6.6603 5.61723 7.32362 2.20118 9.1317 0.720543C9.74134 0.179023 10.5857 -0.0783857 11.4265 0.0209427C11.4697 0.0327253 11.5113 0.0489649 11.5504 0.0693511ZM8.23922 10.3134H10.8772V1.04103C10.4878 1.09819 10.1272 1.26515 9.84544 1.51882C9.021 2.21738 7.73702 4.28574 8.23922 10.3134Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
        }

        &--City {
          -webkit-mask: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M2 17V6.5C2 6.22386 2.22386 6 2.5 6H5V0.5C5 0.223858 5.22386 0 5.5 0H12.5C12.7761 0 13 0.223858 13 0.5V3H15.5C15.7761 3 16 3.22386 16 3.5V17H17.5C17.7761 17 18 17.2239 18 17.5C18 17.7761 17.7761 18 17.5 18H0.5C0.223858 18 0 17.7761 0 17.5C0 17.2239 0.223858 17 0.5 17H2ZM5 17V16C5 15.7239 5.22386 15.5 5.5 15.5C5.77614 15.5 6 15.7239 6 16V17H8V7H3V17H5ZM9 17V13H10.5C10.7761 13 11 12.7761 11 12.5C11 12.2239 10.7761 12 10.5 12H9V10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H9V7H10.5C10.7761 7 11 6.77614 11 6.5C11 6.22386 10.7761 6 10.5 6H6V1H12V17H9ZM15 17V4H13V6H13.5C13.7761 6 14 6.22386 14 6.5C14 6.77614 13.7761 7 13.5 7H13V9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H13V12H13.5C13.7761 12 14 12.2239 14 12.5C14 12.7761 13.7761 13 13.5 13H13V17H15ZM7 3.5C7 3.77614 7.22386 4 7.5 4H10.5C10.7761 4 11 3.77614 11 3.5C11 3.22386 10.7761 3 10.5 3H7.5C7.22386 3 7 3.22386 7 3.5ZM4.5 13C4.22386 13 4 12.7761 4 12.5C4 12.2239 4.22386 12 4.5 12H6.5C6.77614 12 7 12.2239 7 12.5C7 12.7761 6.77614 13 6.5 13H4.5ZM4 9.5C4 9.77614 4.22386 10 4.5 10H6.5C6.77614 10 7 9.77614 7 9.5C7 9.22386 6.77614 9 6.5 9H4.5C4.22386 9 4 9.22386 4 9.5Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          mask: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M2 17V6.5C2 6.22386 2.22386 6 2.5 6H5V0.5C5 0.223858 5.22386 0 5.5 0H12.5C12.7761 0 13 0.223858 13 0.5V3H15.5C15.7761 3 16 3.22386 16 3.5V17H17.5C17.7761 17 18 17.2239 18 17.5C18 17.7761 17.7761 18 17.5 18H0.5C0.223858 18 0 17.7761 0 17.5C0 17.2239 0.223858 17 0.5 17H2ZM5 17V16C5 15.7239 5.22386 15.5 5.5 15.5C5.77614 15.5 6 15.7239 6 16V17H8V7H3V17H5ZM9 17V13H10.5C10.7761 13 11 12.7761 11 12.5C11 12.2239 10.7761 12 10.5 12H9V10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H9V7H10.5C10.7761 7 11 6.77614 11 6.5C11 6.22386 10.7761 6 10.5 6H6V1H12V17H9ZM15 17V4H13V6H13.5C13.7761 6 14 6.22386 14 6.5C14 6.77614 13.7761 7 13.5 7H13V9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H13V12H13.5C13.7761 12 14 12.2239 14 12.5C14 12.7761 13.7761 13 13.5 13H13V17H15ZM7 3.5C7 3.77614 7.22386 4 7.5 4H10.5C10.7761 4 11 3.77614 11 3.5C11 3.22386 10.7761 3 10.5 3H7.5C7.22386 3 7 3.22386 7 3.5ZM4.5 13C4.22386 13 4 12.7761 4 12.5C4 12.2239 4.22386 12 4.5 12H6.5C6.77614 12 7 12.2239 7 12.5C7 12.7761 6.77614 13 6.5 13H4.5ZM4 9.5C4 9.77614 4.22386 10 4.5 10H6.5C6.77614 10 7 9.77614 7 9.5C7 9.22386 6.77614 9 6.5 9H4.5C4.22386 9 4 9.22386 4 9.5Z'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
        }

        &--Country {
          -webkit-mask: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M5.55584 18.8191C5.64213 18.9833 5.81239 19.0861 5.9979 19.086C6.18336 19.086 6.35353 18.9832 6.43996 18.8191C6.64696 18.4272 11.505 9.1863 11.505 6.36077C11.4749 3.34173 9.01902 0.910156 5.99983 0.910156C2.98064 0.910156 0.524714 3.34173 0.494629 6.36077C0.494629 9.1863 5.34876 18.4272 5.55584 18.8191ZM1.49463 6.36077C1.51219 3.88952 3.52661 1.89872 5.9979 1.9103C8.47021 1.89774 10.4863 3.88849 10.505 6.36077C10.505 8.30024 7.57343 14.4246 5.9979 17.5008C4.42363 14.4246 1.49463 8.30024 1.49463 6.36077ZM5.9985 8.95977C4.4627 8.94509 3.22819 7.69077 3.23797 6.15493C3.24774 4.61908 4.49813 3.38058 6.03399 3.38546C7.56985 3.39034 8.81234 4.63676 8.81236 6.17264C8.80367 7.71876 7.54464 8.96584 5.9985 8.95977ZM4.23785 6.19039C4.2281 5.20679 5.01496 4.40019 5.9985 4.38557C6.99258 4.37889 7.80425 5.17857 7.81236 6.17264C7.81238 7.15628 7.01758 7.95506 6.03394 7.95995C5.0503 7.96484 4.2476 7.17399 4.23785 6.19039Z'/%3E%3C/svg%3E") no-repeat 50% 50%;
          mask: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='red' fill-rule='evenodd' clip-rule='evenodd' d='M5.55584 18.8191C5.64213 18.9833 5.81239 19.0861 5.9979 19.086C6.18336 19.086 6.35353 18.9832 6.43996 18.8191C6.64696 18.4272 11.505 9.1863 11.505 6.36077C11.4749 3.34173 9.01902 0.910156 5.99983 0.910156C2.98064 0.910156 0.524714 3.34173 0.494629 6.36077C0.494629 9.1863 5.34876 18.4272 5.55584 18.8191ZM1.49463 6.36077C1.51219 3.88952 3.52661 1.89872 5.9979 1.9103C8.47021 1.89774 10.4863 3.88849 10.505 6.36077C10.505 8.30024 7.57343 14.4246 5.9979 17.5008C4.42363 14.4246 1.49463 8.30024 1.49463 6.36077ZM5.9985 8.95977C4.4627 8.94509 3.22819 7.69077 3.23797 6.15493C3.24774 4.61908 4.49813 3.38058 6.03399 3.38546C7.56985 3.39034 8.81234 4.63676 8.81236 6.17264C8.80367 7.71876 7.54464 8.96584 5.9985 8.95977ZM4.23785 6.19039C4.2281 5.20679 5.01496 4.40019 5.9985 4.38557C6.99258 4.37889 7.80425 5.17857 7.81236 6.17264C7.81238 7.15628 7.01758 7.95506 6.03394 7.95995C5.0503 7.96484 4.2476 7.17399 4.23785 6.19039Z'/%3E%3C/svg%3E") no-repeat 50% 50%;
        }
      }

      .error-icon {
        display: none;
      }

      @include media('<lg') {
        &:focus-within {
          z-index: 50;
          margin-top: rem(24px);
        }

        &-error {
          svg#icon-error {
            display: flex;
          }

          .ahAutocomplete__input input {
            border: rem(2px) solid $border-danger;

            &+button {
              top: rem(32px);
              display: flex;

              .icon::before {
                color: $text-danger;
              }
            }
          }

          .error-icon {
            position: absolute;
            top: rem(40px);
            right: rem(12px);
            display: block;
          }

          .ace-booknow__error-message {
            display: block;
          }
        }
      }

      @include media('>=lg') {
        .ace-booknow__dropdown {
          top: rem(12px);
          left: -7rem;
          min-width: rem(500px);
        }

        legend {
          float: left;
          margin-top: calc(rem(80px) / 2 - rem(8px));
          margin-bottom: calc(rem(80px) / 2 - rem(8px));
          color: color(neutral-black, 600);
          text-transform: uppercase;
          @include ui-label-01;
        }

        &>div {
          display: flex;
          align-items: center;

          & .ui-body-02 {
            width: 0;
            visibility: hidden;
            height: 0;
            opacity: 0;
          }
        }

        &-error {
          svg#icon-error {
            display: none;
          }

          border: rem(2px) solid $border-danger;
          border-left: 0;

          &::before {
            width: rem(80px);
            content: ' ';
            height: rem(80px);
            position: absolute;
            display: block;
            border: rem(2px) solid $border-danger;
            border-top-left-radius: 50%;
            margin-left: -4rem;
            border-bottom-left-radius: 50%;
            top: rem(-2px);
            border-right: 0;
          }

          .ace-booknow__error-message {
            display: flex;
            color: color(neutral-black, 800);
          }
        }
      }
    }

    &__dates {
      display: flex;
      margin-top: rem(24px);
      flex-wrap: nowrap;

      &-delegate {
        display: none;
        align-items: center;
        height: rem(80px);

        .ui-label-01 {
          margin: calc(rem(80px) / 2 - rem(8px)) rem(24px);
          text-transform: uppercase;
          color: color(neutral-black, 600);

          @include media('>xl') {
            white-space: nowrap;
          }
        }

        button {
          height: rem(24px);
          border: 0;
          display: block;
          width: 100%;
          margin: calc(rem(80px) / 2 - rem(12px)) rem(24px) calc(rem(80px) / 2 - rem(12px)) 0;
          color: color(neutral-black, 600);
          text-align: left;
          background-color: color(neutral, white);
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;

          @include media('>xl') {
            white-space: nowrap;
          }

          & .icon {
            display: flex;
            margin: 0 rem(8px);

          }

          &:focus,
          &:hover {
            outline: rem(1px) solid $border-focus;
          }

          & * {
            text-align: center;
            text-transform: capitalize;
            pointer-events: none;
          }
        }
      }

      div.ace-booknow__dropdown--content>fieldset {
        display: flex;
        width: 100%;
      }

      div.ace-booknow__dropdown--content>fieldset>div {
        flex-basis: 48%;

        &>label {
          display: block;
          color: color(neutral-black, 800);

          span {
            display: block;
          }
        }

        &:first-of-type {
          margin-right: rem(12px);
        }

        &:last-of-type {
          margin-left: rem(12px);
        }
      }

      @include media("<lg") {
        & {
          position: relative;
          z-index: 2;
        }

        .ace-booknow__dropdown {
          position: relative;
          margin: 0;
          padding: 0;
          border: 0;
          display: block;
          border-radius: 0;
          box-shadow: unset;

          & .ace-booknow__dropdown--content--visible {
            display: flex;
            min-height: unset;
            max-height: unset;
            overflow: visible;
          }
        }
      }

      @include media('>=lg') {
        z-index: 99;
        margin-top: 0;

        &-delegate {
          display: flex;
          position: relative;
          width: 100%;
          overflow: hidden;

          button {
            display: flex;
          }
        }

        .ace-booknow__dropdown {
          top: rem(12px);
          width: auto;
          min-width: rem(500px);

          .duet-date__dialog {
            position: relative;
            transform: scale(1.0001) translateZ(0) translateY(0);
            opacity: 1;
            visibility: visible;

            .duet-date__dialog-content {
              border: 0;
              margin-top: 1.5rem;
              padding: 0;
              box-shadow: unset;
            }
          }
        }
      }

      @include media('>=lg', '<xl') {
        min-width: rem(300px);

        .ace-booknow__dropdown {
          left: rem(-160px);
        }
      }
    }

    &__guests {
      position: relative;
      margin-top: rem(24px);

      @include media('<lg') {
        &>p.ui-label-01 {
          margin: 0;
          text-transform: capitalize;
          @include ui-body-01;
        }

        &>button+span {
          position: absolute;
          display: block;
          top: 55%;
          right: 1rem;
        }

        &>button[aria-expanded='true']+span {
          top: rem(36px);
          transform: rotate(180deg);
        }

        & .ace-booknow__dropdown {
          position: relative;
          margin-bottom: 4rem;
        }

        & .withoutLabel {
          color: color(neutral-black, 800);
        }
      }

      @include media('>=lg') {
        &>button+span {
          display: none;
        }

        display: flex;
        margin-top: 0;
        align-items: center;

        & .ace-booknow__dropdown {
          width: 100%;
          min-width: rem(370px);
          top: rem(12px);
          margin-top: rem(64px);
        }

        &>p.ui-label-01 {
          margin-left: rem(24px);
          color: color(neutral-black, 600);
        }

        &>button {
          margin: rem(0px) rem(24px);
          white-space: nowrap;
        }
      }
    }

    &__dropdown {
      position: absolute;
      display: none;
      width: 100%;
      padding: padding(06);
      background: color(neutral, white);
      box-sizing: border-box;
      border: rem(2px) solid $border-focus;
      z-index: 2;
      margin-top: rem(12px);
      border-radius: rem(6px);
      box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 10%);

      &.show {
        display: block;
      }

      @include media('>=lg') {
        margin-top: rem(80px);
      }

      &:focus {
        outline: rem(2px) solid $border-focus;
      }

      &--content {
        max-height: rem(400px);
        width: 100%;
        overflow: auto;

        &--auto {
          min-height: auto;

          @include media('<lg') {
            max-height: unset;
          }
        }

        &--visible {
          @include media('>=lg') {
            display: flex;
            height: auto;
            min-height: rem(430px);
            overflow: visible;
          }
        }
      }

      &--footer {
        margin-top: rem(16px);

        button {
          color: $color-link;
          text-decoration: underline;
          background-color: unset;
          border: 0;
          cursor: pointer;
        }
      }

      &--list {
        width: 100%;
        padding: 0;
        list-style: none;
        margin: 0;

        li {
          display: flex;
          padding: padding(03);

          img {
            border-radius: rem(6px);
          }

          span {
            display: flex;
            margin: 0 0 0 rem(16px);

            @include ui-body-01;

            strong {
              @include ui-body-01-strong;
              display: contents;
              font-weight: bold;
            }
          }

          span:first-child,
          span:nth-child(2) {
            color: color(neutral-black, 900);
            text-transform: capitalize;
          }

          span:last-child {

            @include ui-body-02;
            color: color(neutral-black, 600);
            text-transform: capitalize;
          }
        }
      }
    }

    &__input {
      box-sizing: border-box;
      border: rem(1px) solid color(neutral, 500);
      height: rem(48px);
      border-radius: rem(6px);
      width: 100%;
      font-size: 1rem;
      padding: padding(03) padding(04);
      display: -webkit-inline-box;
      margin-top: rem(8px);
      color: color(neutral, 500);
      text-align: left;
      background-color: color(neutral, white);
      cursor: pointer;

      &:focus,
      &:hover {
        outline: rem(1px) solid $border-focus;
      }

      &:not(:focus)+.ace-booknow__input-clear {
        display: none;
      }

      &-clear {
        position: absolute;
        right: 0;
        bottom: 0;
        width: rem(48px);
        height: rem(48px);
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 2;
        font-size: rem(20px);
        background-color: transparent;
        border: 0;
        cursor: pointer;

        & * {
          pointer-events: none;
        }

        .icon {
          position: relative;
          top: rem(2px);
          color: $text-brand;
        }
      }

      @include media('>=lg') {
        height: rem(24px);
        margin: rem(28px) rem(24px);
        padding: 0 rem(5px);
        border: 0;
        color: color(neutral-black, 600);
        border-radius: 0;

        &-clear {
          right: rem(24px);
          bottom: rem(28px);
          width: rem(24px);
          height: rem(24px);
          display: none;
        }
      }
    }

    &__error-message {
      position: relative;
      display: none;
      z-index: 1;
      margin-top: rem(8px);
      color: $text-danger;

      @include ui-caption-01;
      margin-bottom: 0;

      .icon {
        display: none;
      }

      @include media('>=lg') {
        position: absolute;
        display: none;
        bottom: -5.175rem;
        color: unset;
        left: -8rem;
        padding: padding(04) padding(05);
        background: color(error, 100);
        flex-wrap: nowrap;
        width: auto;
        border-radius: rem(6px);
        box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 20%);
        overflow: visible;
        align-items: center;
        box-sizing: content-box;
        white-space: nowrap;

        .icon {
          display: block;

          &::before,
          svg {
            color: color(neutral, white);
            background-color: $bg-danger-primary;
            width: rem(18px);
            height: rem(18px);
            font-size: rem(18px);
            margin-right: rem(8px);
            border-radius: 50%;

          }
        }
      }
    }

    &__select {
      display: none;
      position: relative;
      margin-bottom: rem(32px);

      label {
        display: block;
        margin-bottom: rem(8px);
      }

      select {
        box-sizing: border-box;
        border: rem(1px) solid color(neutral-black, 500);
        width: 100%;
        border-radius: rem(6px);
        height: rem(48px);
        padding: padding(03) padding(04);
        color: color(neutral-black, 800);
        background-color: color(neutral, white);
        appearance: none;

        @include ui-body-01;

        &:focus+span svg {
          transform: rotate(180deg);
        }

        &:focus {
          outline: rem(2px) solid $border-focus;
          outline-offset: rem(-2px);
        }
      }

      span[aria-hidden='true'] {
        position: absolute;
        display: flex;
        top: auto;
        right: 0;
        bottom: 0;
        z-index: 1;
        align-items: center;
        justify-content: center;
        padding: padding(02);
        background: color(neutral, white);
        margin: rem(4px);
        border: transparent;
        pointer-events: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 rem(32px) 0;

      p {
        margin: 0;

        &:first-child {
          @include ui-body-01-strong;
          color: color(neutral-black, 900);
        }

        &:last-child {
          @include ui-body-02;
          color: color(neutral-black, 600);
        }
      }

      &--value {
        @include ui-body-01;
        width: rem(24px);
        border: 0;
        margin-left: rem(12px);
        pointer-events: none;
        color: color(neutral-black, 800);

        &:focus {
          outline: unset;
        }
      }

      div:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: rem(3px);
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(32px);
        width: rem(32px);
        font-size: rem(16px);
        box-sizing: border-box;
        border: rem(1px) solid $border-brand;
        background-color: color(neutral, white);
        border-radius: rem(54px);
        padding: 0 rem(6px);
        cursor: pointer;

        & * {
          pointer-events: none;
        }

        @include media('>=lg') {
          width: rem(48px);
          height: rem(48px);
          font-size: rem(24px);
        }

        &:focus {
          outline-offset: rem(1px);
        }

        &:hover {
          background-color: $bg-brand-secondary;

          &::before {
            color: color(neutral, white);
          }
        }

        &:active {
          background-color: $bg-brand-secondary-hover;

          &::before {
            color: color(neutral, white);
          }
        }

        &:disabled {
          border: color(neutral-black, 200);
          font-weight: bold;
          background-color: color(neutral-black, 200);
          pointer-events: none;

          &::before {
            color: color(neutral-black, 600);
          }

          &:focus {
            outline: unset;
          }
        }
      }
    }

    // modal open button styles
    &__open--button {
      background: #fff;
      box-sizing: border-box;
      border: rem(1px) solid #dadadd;
      height: rem(56px);
      padding: padding(04) padding(05);
      border-radius: rem(40px);
      box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 10%);
      width: 100%;
      color: color(neutral-black, 800);
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
      margin: auto;

      @include ui-body-01;

      .icon {
        display: flex;
        margin-right: rem(16px);
        font-size: rem(24px);
      }

      @include media('<md') {
        width: calc(100vw - rem(32px));
      }

      @include media('>=lg') {
        display: none;
      }
    }
  }
}

.booking-engine {
  padding: 0;
}

html[dir='rtl'] .ace-booknow-component {
  duet-date-picker .duet-date__input {
    padding-right: padding(09);
  }

  @include media('<lg') {
    .ace-booknow {

      &__input {
        &-clear {
          right: auto;
          left: rem(24px);
        }
      }

      &__dropdown {
        [data-slug]>div {
          margin-right: 1rem;
        }
      }

      &__counter--value {
        margin-right: 1rem;
        margin-left: 0rem;

      }

      &__select select {
        padding-right: padding(08);
      }

      &__guests>button+span {
        right: auto;
        left: 1rem;
      }

      &__open--button {
        align-items: center;

        span {
          margin-left: 1rem;
        }
      }
    }
  }

  @include media('>lg') {
    .ace-booknow {

      &__footer {
        margin: rem(12px);
      }

      &__location {
        padding-left: padding(06);
        border-right: 0;

        &-error {
          border-right: 0;
          border-right: rem(2px) solid $border-danger;
          border-left: 0.125rem solid $border-danger;

          &::before {
            border-top-right-radius: 50%;
            border-top-left-radius: unset;
            border-bottom-right-radius: 50%;
            margin-left: rem(-40px);
            border-bottom-left-radius: unset;
          }
        }
      }

      &__input {
        &-clear {
          right: auto;
          left: rem(24px);
        }
      }

      &__dropdown {
        [data-slug]>div {
          margin-right: 1rem;
        }
      }

      &__counter--value {
        margin-right: 1rem;
        margin-left: 0rem;
      }

      &__select select {
        padding-right: padding(08);
      }

      &__error-message .icon {
        margin-left: rem(8px);
      }

      &__dates {
        margin-left: rem(24px);
        border-left: rem(1px) solid color(neutral-black, 200);

        &-delegate button {
          margin-right: 0;
        }
      }
    }
  }
}

main, #main-content {
  > .cmp-container > .aem-Grid > div.booking-engine {
  @include media('>=xl') {
    padding: 0 rem(12px);
  }

  &:has(.ace-booknow-component__merge-next) {
    position: relative;
    bottom: rem(-92px);
    margin-top: rem(-56px) !important;

    @include media('>=md') {
      bottom: rem(-108px);
    }

    @include media('>=lg') {
      bottom: rem(-136px);
      margin-top: rem(-80px) !important;
    }
  }
}
}
.duet-date__header {
  h2 {
    display: none;
  }
}
.heading-override+.booking-engine.merge-next{
  top: auto;
}
.heading-override+.booking-engine {
  &:has(.ace-booknow-component__merge-next) {
    top: auto;
  }  

}
// adding css
.ace-booknow-component .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel {
  height: 1.5rem;
  color: color(neutral-black, 600);
  border: 0 !important;
  border-radius: 0;
  padding: 0 0.3125rem;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  background-color: color(neutral, white);
  margin: 1.75rem 1.5rem;
  box-sizing: border-box;
  box-shadow: none;
}
@include media('<lg') {
  .ace-booknow-component .ace-booknow__location-error .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel {
    border: 0.125rem solid $border-danger !important;
  }
}
.ace-booknow-component .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:focus,
.ace-booknow-component .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel:hover{
  outline: 0.0625rem solid $border-focus;
}
.ace-booknow-component .ace-core-booking-engine__autocomplete .material-textfield#search-destination {
  align-items: center;
  display: flex;
}  
#search-destination input::-webkit-input-placeholder {
  color: color(neutral-black, 600);
  font-style: normal;
}
#search-destination input:-ms-input-placeholder {
  color: color(neutral-black, 600);
  font-style: normal;
}
#search-destination input::placeholder {
  color: color(neutral-black, 600);
  font-style: normal;
}
.ahAutocomplete__input input:focus, .ahAutocomplete__input input:hover{
  outline: 0.0625rem solid $border-focus;
}
.ace-booknow-component .ace-core-booking-engine__autocomplete .ace-core-booking-engine__label {
  display: none !important;
}
.ace-booknow-component .ace-booknow__location {
  position: relative;
  display: flex;
  align-items: center;
  @include media('>lg') {
    height: 100%;
  }

}
.ace-booknow-component .ace-booknow__location > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .ahAutocomplete{
    @include media('>=lg','<xl') {
      min-width: 9.75rem !important;
    }
    .material-textfield {
      @include media('>=lg','<xl') {
        margin: 0 rem(24px);
      }
    }
  }
  .ahAutocomplete__result {
    @include media('>=lg') {
        min-width: 31.25rem !important;
        left: -7rem;
      }
  }
}
.ahAutocomplete{
    min-width: 12.75rem;
}
.ace-core-booking-engine__autocomplete #mount-autocomplete .ahAutocomplete__result {
  width: 100%;
}
.ace-booknow-component .ahAutocomplete__result {
  max-height: 23rem;
  overflow-y: auto;
}
.ace-booknow__location span.icon.icon-clear-24icon {
  display: none;
}

//Media css autocomplete
@include media('<lg'){
  .ace-booknow-component {
    .ace-booknow__location > div {
      flex-direction: column;
      align-items: flex-start;
      width: 100% !important;
  }
  .ace-core-booking-engine__autocomplete{
    width:100%;
  }
    .ahAutocomplete .ahAutocomplete__input {
      width: 100% !important;
      display: block;
    }
    
    .ace-core-booking-engine__autocomplete .material-textfield input.withoutLabel{
        box-sizing: border-box;
        height: 3rem;
        width: 100%;
        color: color(neutral-black,500);
        border: 0.0625rem solid color(neutral-black,500) !important;
        border-radius: 0.375rem;
        margin: 0.5rem 0 0 0;
        padding: 0.75rem 1rem;
        display: -webkit-inline-box;
    }
  }
}