.ace-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(16px);
    &__previous,
    &__next {
        display: flex;
        width: rem(32px);
        height: rem(32px);
        padding: rem(7px);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: rem(32px);
        border: rem(1px) solid $border-brand;
        background: $bg-neutral-primary;
        @include media('>=md') {
            padding: rem(8px);
            width: rem(40px);
            height: rem(40px);
            border-radius: rem(40px);
        }
        .icon {
            &::before {
                font-size: 1rem;
                @include media('>=md') {
                  font-size: 1.5rem;
                }
            }
        }
        &:hover {
            background: $border-brand;
            .icon {
                &::before {
                    color: $bg-neutral-primary;
                }
            }
        }
        &:active {
            background: $bg-brand-secondary-hover;
            .icon {
                &::before {
                    color: $bg-neutral-primary;
                }
            }
        }
        &:focus,
        &:focus-visible {
            background: $bg-neutral-primary;
            border: rem(1px) solid $border-brand;
            stroke-width: rem(2px);
            stroke: $color-focus;
            outline-offset: rem(2px);
            outline: 0.125rem solid $color-focus;
            margin-bottom: rem(4px);
            .icon {
                &::before {
                    color: $border-brand;
                }
            }
        }
        &:disabled {
            background: $bg-disabled-primary;
            .icon {
                &::before {
                    color: $color-link-disabled;
                }
            }
        }
    }
    &__locator {
        @include ui-body-02;
        margin: 0;
    }
}
