.ace-paragraph {
  &.aem-Grid {
    > .aem-GridColumn {
      @include media('>=lg') {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      @include media('>md', '<lg') {
        padding-right: 0;
        padding-left: 0;
      }

      @include media('<md') {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__title {
    margin-right: auto;
    margin-bottom: rem(32px);
    color: $text-heading;

    @include media('>=md') {
      margin-bottom: rem(48px);
    }
  }

  &__content p {
    margin: 0;
    padding: 0;
    color: $text-body-primary;
    line-height: rem(24px);
  }

  &__subtitle {
    margin-bottom: rem(16px);
    color: $text-heading;
  }

  &__text {
    @include ui-body-01;
    margin-left: auto;
    @include iconList;

    .ace-button {
      flex-direction: column;
      align-items: flex-start;

      .cmp-button {
        width: 100%;
        margin-top: rem(24px);

        @include media('>=md') {
          width: auto;
        }
      }

      .ace-paragraph__content--link {
        margin-top: rem(16px);
      }
    }
  }

  &__images {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @include media('>=md') {
      margin-top: 3rem;
    }
  }

  &__imageCol1 {
    width: 100%;

    img {
      width: 100%;
      height: rem(154px);
      object-fit: cover;

      @include media('>=md', '<lg') {
        height: rem(265px);
      }

      @include media('>=lg') {
        height: rem(270px);
      }
    }
  }

  & .two-images {
    width: 48%;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  & .video-viewer-component {
    margin-top:2rem;
    width:100%;

    @include media('>=md') {
      margin-top: rem(48px);
    }    
  }

  &__item {
    &--list {
      .ace-paragraph__content--link{
        &[target=_blank], &[target=_self]{
          text-decoration: none !important;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .ace-paragraph {
    @include media('>=md') {
      display: flex;
    }

    &__text {
      margin-right: rem(16px);
    }
  }
}