.ace-push-nav {
  width: rem(491px);
  margin-bottom: rem(20px);

  @include media('<lg') {
    width: 100%;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__push {
    background: color(grey-neutral, 1200);
    border-radius: rem(6px);
  }

  &__element {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(16px);
    padding: rem(24px);

    @include media('<md') {
      flex-direction: column;
    }
  }

  &__img {
    width: rem(170px);
    height: rem(212px);
    position: relative;
    border-radius: rem(6px);
    overflow: hidden;
     > div{
      width: 100%;
      height: 100%;
     }
     @include media('<md') {
      width: 100% !important;
      height: rem(212px) !important;
      position: relative;
     }
    img {
      border-radius: rem(6px);
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }

  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - rem(170px));
    padding-left: rem(24px);

    &-title {
      font-family: $font-family-montserrat !important;
      font-style: normal;
      font-weight: 700 !important;
      font-size: rem(20px) !important;
      line-height: rem(28px) !important;
      color: color(neutral-black, 900);
      margin-bottom: rem(12px);
      @include media('<lg') {
        margin-top: rem(24px);
      }
    }

    &-desc p {
      font-family: $font-family-roboto;
      font-style: normal;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(24px) !important;
      color: color(neutral-black, 800);
      margin-bottom: rem(24px);
      margin-top: 0;
    }

    &-link {
      font-family: $font-family-roboto;
      font-size: rem(16px);
      line-height: rem(24px);
      border-radius: rem(60px);
      text-align: center;
      padding: rem(12px) rem(24px) !important;
      }
      .ace-push-nav__content-link span {
        font-weight: 400;
      }
      .ace-push-nav__content-link:focus span{
        color: $text-brand;
    }
    .ace-push-nav__content-link:active span{
        color: color(neutral, white);
    }

    @include media('<md') {
      padding-left: 0;
      width: 100% !important;
    }
  }
}
