// Image Usage:
// .img-fluid{}
// .img-thumbnail{}

.img {
  &-fluid {
    max-width: 100%;
    height: auto;
  }

  &-thumbnail {
    // padding: 0.25rem;
    // background-color: #fff;
    // border: rem(1px) solid #dee2e6;
    // border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
  }
}

// Figure Usage:
// .figure{}
// .figure-img{}
// .figure-caption{}
.figure {
  display: inline-block;
  
  &-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  &-caption {
    color: #6c757d;
    font-size: 0.875em;
  }
}
