html {
  color: $textColor;

  body {
    font-family: $font-page;
    &.has-emergency {
      .breadcrumb-container {
        margin: 0.75rem auto;
      }
    }

    a,
    button {
      &:focus {
        outline: 2px solid $linkColor;
        outline-offset: 1px;
      }
    }
  }
}

body {
  a {
    color: $linkColor;
    text-decoration-line: underline;
  }
}

main {
  .cmp-container {
    .aem-Grid {
      div.aem-GridColumn {
        margin-top: 0;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.loading {
  opacity: .5;
  position: relative;

  &:after {
    animation: spin 2s linear infinite;
    border-radius: 50%;
    border: 4px solid #068484;
    border-top: 4px solid transparent;
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 40vh;
    z-index: 2;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
// Dark mode light
:root {
  color-scheme: light only;
}