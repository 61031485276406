
.ace-header__logo--image {
    width: 14rem !important;
    
}

@media (width <= 64rem) {
    .ace-header__logo--image {
      max-width: 9rem !important;
    }
  }