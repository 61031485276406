.ace-image-nav-container {

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    z-index: 0;
  }

  .ace-image-nav {
    flex: 1;
    display: flex;

    @include media('<lg') {
      flex: 0;
      flex-direction: column;
    }

    &__element {
      display: flex;
      flex-direction: column;
      position: relative;
      background: $color-background;
    }

    &__img {
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: rem(6px);
      height: rem(190px);
      @include media('<lg') {
        height: rem(195px);
      }

      >div {
        width: 100%;
      }

      img {
        border-radius: rem(6px);
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    &__content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      overflow: hidden;
      margin-bottom: rem(48px);

      @include media('<md') {
        overflow:unset;
      }
  

      &-title {

        margin-top: rem(16px);
        margin-bottom: rem(4px);

        a {
          font-family: $font-family-roboto;
          font-style: normal;
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(24px);
          color: color(neutral-black, 800);
          text-decoration: none;
        }
        :focus{
          outline-offset: -0.125rem;
        }
      }

      &-desc p {
        font-family: $font-family-roboto;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(20px);
        color: color(neutral-black, 600);
        margin: 0;
      }
    }

    a.ace-image-component-default__link {
      position: relative;
      z-index: 2;
      display: flex;
    }

    a:not(.ace-image-component-default__link):after {
      bottom: 0.125rem;
      content: '';
      left: 0.375rem;
      position: absolute;
      right: 0.375rem;
      top: 0;
      z-index: 1;
    }

  }

  @include media('<md') {
    .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--4 {
      width: 100%;
    }
  }
}