// Burger Menu code
.header-navigation {
    @include media('<lg') {
        height: rem(72px);
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0;
        width: 100vw;
        right: rem(0px);
        position: relative;
        box-sizing: border-box;
        border-bottom: rem(1px) solid color(neutral-black, 200);
        padding:0 rem(8px)
    }

    .ace-header-navigation {

        &__pos-container {

            .pos-container-geolocation__menu__back,
            .pos-container-currency__menu__back {
                display: none;

                @include media('<md') {
                    background: transparent;
                    display: flex;

                    .link-navigation__nav__item {
                        background: transparent;
                        color: color(neutral-black, 900);
                        padding: rem(24px) rem(0px) rem(24px) rem(16px);
                        border: none;
                        display: flex;
                        gap: (8px);

                        .icon {
                            display: flex;
                            align-self: center;
                        }
                    }

                }

            }
        }

        @include media('<lg') {
            width: 100%;
            height: 100%;

            &__content {
                height: 100%;
            }

            &__header {
                height: 100%;
                display: flex;
                justify-content: space-between;
            }

            &__logo--desktop {
                display: none
            }

            &__logo--mobile {
                display: flex !important;
            }

            &__wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: rem(72px);
            }

            &__overlay {
                &.enableOverlay {
                    @include media('>=md', '<lg') {
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100vh;
                        top: rem(72px);
                        right: 0;
                        left: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, .5);
                        z-index: 0;
                        cursor: pointer;
                    }
                }
            }

            &__main-container {
                width: 100%
            }

            &__overlay {
                display: none;
            }

            &__pos-container {
                padding: rem(16px);
                padding-top: 0;

                .ace-geolocation-selector,
                .ace-currency-selector {
                    &__button {
                        padding: rem(12px) rem(16px);
                        align-items: center;
                        border: none;
                        background: transparent;
                        color: color(neutral-black, 800);
                        cursor: pointer;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;

                        &:hover {
                            border-color: color(stratos-blue, 1000);
                            text-decoration: none;
                            color: $text-brand;

                        }

                        .icon-chevron-right-24icon {
                            display: block;
                        }
                    }
                }
            }

            &__logo--mobile {
                display: flex;
                flex-direction: row;
                column-gap: rem(1px);
                align-self: center;
            }

            &__loginconnect {
                align-self: center;
            }

            @include media('<md') {
                &__loginconnect {
                    align-self: center;

                    .login-nav {
                        margin-left: calc(50% - 50vw);
                        margin-right: calc(50% - 50vw);
                        margin-top: rem(24px);
                    }
                }
            }

            .ace-geolocation-selector {
                padding-top: rem(24px);
            }
        }

        @include media('<=sm') {
            &__pos-container {

                .pos-container-geolocation__menu__back,
                .pos-container-currency__menu__back {
                    background: transparent;
                    border-bottom: rem(1px) solid color(neutral-black, 200);
                    display: flex;

                    .link-navigation__nav__item {
                        background: transparent;
                        color: color(neutral-black, 900);
                        padding: rem(24px);
                        border: none;
                        display: flex;
                        gap: (8px);
                        .link-navigation__sr-only{
                            font-family: $font-family-roboto;
                            font-size: rem(18px);
                            font-weight: 500;
                            line-height: rem(24px);
                        }
                        .icon-chevron-left-24icon::before {
                            height:rem(24px);
                            width:rem(24px);
                            color:$color-icon-arrow;
                        }
                    }
                }
            }
        }

        .link-navigation_nav_menu-list {
            padding: rem(0px);
        }
        .ace-header-navigation__main-container.removeover{
            @include media('<md') {
            overflow: hidden;
            }
         }
        .ace-header-navigation__main-container {
            @include media('<md') {
                width: 100vw !important;
                margin-left: calc(50% - 50vw);
                margin-right: calc(50% - 50vw);
                transition: transform .6s cubic-bezier(.19, 1, .22, 1);
                transition: transform .6s cubic-bezier(0.19, 1, .22, 1);
                position: absolute;
                left: 0;
                visibility: hidden;
                display: flex;
                flex-direction: column;

                &.show {
                    visibility: visible;
                    transform: none;
                    top: rem(72px);
                    background-color: $color-background;
                    display: flex;
                    justify-content: space-between;
                    min-height: 0 !important;
                }

                .sub-nav-container__menu__back {
                    display: flex;
                    flex-direction: column;
                    border-bottom: rem(1px) solid color(neutral-black, 200);

                    .link-navigation__nav__item {
                        padding: rem(24px) 0 !important;
                        gap: rem(8px);

                        .icon {
                            display: flex;
                            align-items: center;
                            height: rem(24px);
                            width: rem(24px);
                        }

                        .link-navigation__sr-only {
                            padding: 0;
                            font-weight: 500;
                            color: color(neutral-black, 900);
                        }
                    }

                }

                .sub-nav-container {
                    .sub-nav-container__panel__scroll {
                        padding-top: rem(24px) !important;
                        gap:rem(48px);
                    }
                }
            }
          
            @include media('<lg') {
                transition: transform .6s cubic-bezier(.19, 1, .22, 1);
                transition: transform .6s cubic-bezier(0.19, 1, .22, 1);
                position: absolute;
                margin-left: calc(50% - 50vw);
                top: rem(72px);
                left: 0;
                width: 50vw;
                visibility: hidden;
                display: flex;
                flex-direction: column; 
                overflow-y: auto;
                &.show {
                    visibility: visible;
                    transform: none;
                    background-color: $color-background;
                    display: flex;
                    justify-content: space-between;
                    min-height: 0 !important;
                    @include media('<md'){
                    }
                }

                .ace-header-navigation__menu-container {
                    padding: rem(12px) rem(16px);
                    padding-bottom: rem(24px);

                    .ace-menuheader {
                        height: 100%;
                    }
                }
            }
        }

        .ace-navbar-toggler {
            color: $text-brand;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.5rem;
            text-decoration: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            width: 3rem;
            height: 2.8rem;

            @include media('>=lg') {
                display: none;
            }

            @include media('<lg') {
                position: relative;
                align-self: center;
                justify-content: center;
                height: rem(38px);
                width: rem(37px);
            }

            .ace-burger-menu__icon {
                width: rem(24px);
                display: flex;
                flex-direction: column;
                padding: 0;
                color: $text-brand;

                &::before {
                    background-color: $bg-brand-secondary;
                    left: 0;
                    top: 0;
                    border-radius: rem(1px);
                    content: " ";
                    height: rem(2px);
                    transition: transform .6s cubic-bezier(.19, 1, .22, 1);
                    margin-bottom: rem(5px);
                }

                &::after {
                    background-color: $bg-brand-secondary;
                    left: 0;
                    top: 0;
                    border-radius: rem(1px);
                    content: " ";
                    height: rem(2px);
                    transition: transform .6s cubic-bezier(.19, 1, .22, 1);
                    margin-bottom: rem(5px);
                }
            }


            &[aria-expanded="true"] {
                .ace-burger-menu__icon {
                    &::before {
                        transform: rotate(-45deg) translate(rem(-1px), rem(1px));
                    }

                    &::after {
                        transform: rotate(45deg) translate(rem(-3px), rem(-5px));
                    }
                }
            }
        }

        .icon-chevron-right-24icon::before {
            color: $color-icon-arrow !important;
            height: rem(24px);
            width: rem(24px);
            display: flex;
            align-items: center;
            font-size: rem(24px);
        }

        .icon-chevron-left-24icon::before {
            font-size: rem(24px);
        }
    }

    .sub-nav-container__main-nav {
        .ace-navigation {
            .ace-simple-nav {
                &.withoutSection-nav {
                    .ace-simple-nav__listItems {
                        @include media('>=lg') {
                            margin-top: 0;
                        }
                        @include media('<md') {
                            margin-top: 0;
                        }
                        li {
                            @include media('>=md','<lg') {
                                margin-top: rem(24px);
                                margin-bottom: rem(24px);
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .sub-nav-container__secondary-nav {
        .ace-simple-nav__listItems {
            @include media('>=md','<lg') {
                margin-top: rem(20px);
            }
            li {
                @include media('>=md','<lg') {
                    margin-top: rem(20px);
                    margin-bottom: rem(20px);
                }
            }
        }
    }
}

//burger menu ends

//rtl


html[dir="rtl"] {
    .ace-header-navigation {
        &__header {

            .icon-chevron-right-24icon,
            .icon-chevron-left-24icon {
                transform: rotate(180deg);
            }
        }

        &__pos-container {

            &-geolocation__menu__back,
            &-currency__menu__back {
                justify-content: left;
            }

            .ace-geolocation-selector .geolocdropdown__field-icon,
            .ace-currency-selector .currencydropdown__field-icon {
                left: 0;
                right: auto;
            }
        }

        &__logo--mobile {
            @include media('<lg') {
                .ace-logoheader1:only-child{
                    border-left: 0 !important;
                }
                .ace-logoheader1 {
                    padding-right: 0;
                    padding-left: rem(12px);
                    border-left: rem(1px) solid color(neutral-black, 800);
                }

                .ace-logoheader2 {
                    padding-right: rem(12px);
                    border-left: 0
                }
            }
        }
        &__main-container{
            @include media('<lg') {
                margin-right: calc(50% - 50vw);
                right:0;
                left:unset;
                transform: translate(100%) ;
            }
            .link-navigation__panel.showPanel{
                @include media('>=md','<lg') {
                right:50% !important;
                }
            }
            @include media('<md') {
                .sub-nav-container__menu__back .link-navigation__nav__item{
                    justify-content: right !important;
                }
            }

        }
        .link-navigation__morePanel{
            @include media('>=lg') {
                .link-navigation__menu__link {
                    text-align: right;
                    border-left: 0.25rem solid #0000!important;
                    border-right: none;
                    width: 75%!important;
                    margin-right: 4rem!important;
                    margin-left: 0 !important;
                    padding-left: 1.8rem!important;
                    padding-right: 0 !important;
                    white-space: unset !important;
                    &.activeMoreBtn {
                        border-left: 0.25rem solid #1e1852 !important;
                        border-right: none !important;
                    }
                    &:hover{
                        border-left: 0.25rem solid #1e1852!important;
                        border-right: none !important;
                    }
            }
            .link-navigation__sub-more-container .link-navigation__sub-more-items{
                border-left: 0.0625rem solid #dadadd;
            }
           }
        } 
        .sub-nav-container__secondary-nav .simple-nav-list-item li{
            padding-left:rem(24px);
            padding-right:0 !important
        }
        .link-navigation__panel.showMorePanel{
            @include media('>=lg') {
                border-right: 0.03125rem solid #dadadd;
                border-left: none;
            }
        }
}
}

// rtl code ends


//adding body overflow enable sticky and disable scroll for burger screen
.disableScroll {
    overflow-y: hidden !important;
    height: 100%;
    width: 100%;
    position: fixed;
}


.ace-header-nav {
    background: color(neutral, white);
    z-index: 999;
    width: 100vw !important;
    position: sticky;
    margin: 0 calc(50% - 50vw);
    top: 0;
    left: 0;
    right: 0;
    }

.ace-geolocation-selector,
.ace-currency-selector {
    &__button {
        .icon {
            display: none;
        }

        @include media('>=lg') {
            white-space: nowrap;
            padding: rem(32px) 0 !important;
        }
    }

    @include media('>=lg') {
        height: 100%;

        &__modal {
            position: absolute;
            left: 0;
            right: 0;
            width: 100vw;
            background-color: color(neutral, white);
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
            z-index: -1;
        }
    }

    @include media('<lg') {
        &__modal {
            position: absolute;
            left: 0;
            right: 0;
            width: 100vw;
            height: 100%;
            top: 0;
            overflow-y: scroll;
            background-color: color(neutral, white);
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
            z-index: 0;
        }
    }
}

.ace-geolocation-selector {
    &__label-value {
        @include ui-body-01;
    }

    &__button {
        border: none;
        background: transparent;
        position: relative;
        color: $text-brand;
        cursor: pointer;
        border-bottom: rem(4px) solid transparent;
        padding: 0;
        height: 100%;

        &:hover {
            border-color: color(stratos-blue, 1000);
            text-decoration: none;
            color: $text-brand;
        }
    }

    // ACDAA-12429 Start
    .geolocdropdown {
        @include media('<lg') {
            padding: rem(40px) rem(16px) rem(10px) rem(16px);  
        }
        padding: rem(48px) 0 rem(10px);

        * {
            box-sizing: content-box;
        }

        @include ui-body-01;

        &__title {
            display: flex;
            font-family: $font-family-montserrat;
            font-size: rem(24px);
            font-weight: 700;
            line-height: rem(36px);
            color: color(neutral-black, 900);
            flex: 1;
            justify-content: center;
            text-align: center;
        }

        &__form {
            display: flex;
            flex-direction: column;
            width: calc(rem(388px) - rem(20px));
            padding: rem(48px) 0 0;
            margin: 0 auto;

            @include media('>=md', '<lg') {
                width: calc(rem(388px) - rem(36px));
            }

            @include media('<md') {
                width: calc(100% - rem(16px));
            }
        }

        &__field {
            display: flex;
            flex-direction: column;
            padding: 0 0 rem(32px);
        }

        &__label {
            font-size: rem(16px);
            color: color(neutral-black, 800);
            margin-bottom: rem(8px);
        }

        &__field-layout {
            position: relative;
        }

        &__field-icon {
            display: inline-flex;
            height: 100%;
            padding: 0 rem(5px);
            pointer-events: none;
            position: absolute;
            right: rem(0px);
            align-items: center;
            z-index: 1;

            .icon:before {
                display: inline-block;
                font-size: rem(24px);
            }
        }

        &__field-select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: color(neutral, white);
            border: 0;
            border-radius: rem(6px);
            box-shadow: inset 0 0 0 rem(1px) color(neutral-black, 500);
            color: color(neutral-black, 800);
            display: block;
            font-size: rem(16px);
            outline: none;
            padding: rem(12px) rem(16px);
            position: relative;
            text-transform: capitalize;
            transition: all 0.15s ease;
            width: calc(100% - rem(30px));

            &:focus {
                box-shadow: inset 0 0 0 rem(2px) $border-focus;
            }
        }

        &__btn {
            background-color: color(stratos-blue, 1000);
            border: 0;
            border-radius: rem(60px);
            cursor: pointer;
            display: inline-flex;
            outline: 0;
            max-height: rem(56px);
            padding: rem(16px) rem(20px);
            transition: all 0.15s ease;
            color: color(neutral, white);
            text-decoration: none;
            flex: 1;
            font-size: rem(16px);
            justify-content: center;
            text-align: center;
            margin: rem(16px) auto 0;

            @include media('<md') {
                max-width: 100%;
                margin: unset;
            }

            &:focus {
                outline: rem(2px) solid $border-focus;
                outline-offset: rem(2px);
                color: color(neutral, white);
                background-color: $bg-brand-secondary;
            }

            &:hover {
                background-color: $bg-brand-secondary-hover;
            }

            &:active {
                background-color: $bg-brand-secondary-pressed;
            }
        }
    }

    // ACDAA-12429 End
}

.ace-currency-selector {

    &__label-value {
        @include ui-body-01;
    }

    &__button {
        border: none;
        background: transparent;
        color: $text-brand;
        cursor: pointer;
        border-bottom: rem(4px) solid transparent;
        height: 100%;
        padding: 0;

        &:hover {
            border-color: color(stratos-blue, 1000);
            text-decoration: none;
            color: $text-brand;
        }
    }

    // ACDAA-12208 currency-selector currencydropdown
    .currencydropdown {
        * {
            box-sizing: content-box;
        }

        align-items: center;
        display: flex;
        flex-direction: column;
        @include ui-body-01;
        padding: rem(48px);

        @include media('<lg') {
            padding: rem(40px) rem(16px) rem(10px) rem(16px);
        }

        &__panel {
            max-width: rem(562px);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include media('>=lg', '<xl') {
                max-width: rem(458px);
            }

            @include media('>=md', '<lg') {
                max-width: rem(496px);
            }

        }

        &__title {
            color: color(neutral-black, 900);
            font-family: $font-family-montserrat;
            font-size: rem(24px);
            font-style: normal;
            font-weight: 700;
            line-height: rem(36px);
            text-align: center;
        }

        &__form {
            display: flex;
            flex-direction: column;
            max-width: 24.35rem;
            width: 100%;

            @include media('>xl') {
                max-width: 24.05rem
            }

            @include media('>=xxl') {
                max-width: 26.15rem
            }

            @include media('<=xl') {
                max-width: 22.95rem
            }

            @include media('>=lg', '<xl') {
                max-width: 18.85rem;
            }

            @include media('>=md', '<lg') {
                max-width: 22.35rem;
            }

            @include media('<md') {
                max-width: 22.45rem;
            }

        }

        &__field {
            display: flex;
            flex-direction: column;
        }

        &__fieldBtn {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .currencydropdown__formfields {
            padding: rem(48px) 0 rem(48px);
        }

        .currencydropdown__field:first-child {
            padding-bottom: rem(32px);
        }

        &__label {
            color: color(neutral-black, 800);
            font-family: $font-family-roboto;
            font-size: rem(16px);
            font-style: normal;
            font-weight: 400;
            line-height: rem(24px);
            margin-bottom: rem(8px);
        }

        &__field-layout {
            position: relative;
        }

        &__field-icon {
            background: color(neutral, white);
            display: inline-flex;
            fill: color(neutral-black, 800);
            height: 100%;
            padding: 0 rem(5px);
            pointer-events: none;
            position: absolute;
            right: rem(0px);
            align-items: center;

            .icon:before {
                display: inline-block;
                font-size: rem(24px);
            }
        }

        &__field-select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: transparent;
            border: 0;
            gap: rem(8px);
            border-radius: rem(6px);
            box-shadow: inset 0 0 0 rem(1px) color(neutral, 500);
            color: color(neutral-black, 800);
            display: block;
            font-family: $font-family-roboto;
            font-size: rem(16px);
            font-style: normal;
            font-weight: 400;
            line-height: rem(24px);
            padding: rem(12px) rem(16px);
            outline: none;
            position: relative;
            text-transform: capitalize;
            transition: all 0.15s ease;
            width: calc(100% - rem(30px));

            &:focus {
                box-shadow: inset 0 0 0 rem(2px) $border-focus;
            }
        }

        &__btn {
            display: flex;
            padding: rem(16px) rem(20px);
            justify-content: center;
            align-items: center;
            font-family: $font-family-roboto;
            font-size: rem(16px);
            font-style: normal;
            font-weight: 400;
            line-height: rem(24px);
            text-align: center;
            color: color(neutral, white);
            background-color: $bg-brand-secondary;
            border-radius: 3.125rem;
            border: 0;
            cursor: pointer;
            max-width: rem(188px);
            width: 100%;
            box-sizing: border-box;

            &:focus {
                outline: rem(2px) solid $border-focus;
                outline-offset: rem(2px);
                color: color(neutral, white);
                background-color: $bg-brand-secondary;
            }

            &:hover {
                background-color: $bg-brand-secondary-hover;
            }

            &:active {
                background-color: $bg-brand-secondary-pressed;
            }

            @include media('<md') {
                max-width: 100%;
                margin: unset;
            }
        }
    }

    // ACDAA-12208 currency-selector currencydropdown end
}

.header-navigation {
    .ace-header-navigation {
        

        &__main-container {
            @include media('>=lg') {
            visibility: hidden;
            max-height: rem(92px);

            &.--jsfied {
                visibility: visible;
            }
        }
            .link-navigation__panel {
                &.showPanel {
                    display: block;

                    @include media('>=md', '<lg') {
                        display: block;
                        width: 50vw;
                        right: 0;
                        position: fixed;
                        bottom: 0;
                        top: rem(72px);
                        left: unset;
                    }
                }
            }

            .--hidden {
                display: none !important;
            }
        }

        .link-navigation__panel {
            display: none;

            @include media('>=lg') {
                &.showMorePanel {
                    position: relative;
                    width: 100%;
                    display: flex;
                    z-index: 2;
                    box-shadow: none;
                    border-left: rem(0.5px) solid color(neutral-black, 200);

                    .sub-nav-container {
                        position: relative;
                        box-shadow: none;
                        padding: rem(48px) rem(64px) rem(48px) rem(24px);
                        width:79.5vw;
                        .ace-push-nav {
                            width: 100%;
                            max-width: rem(491px);
                        }
                    }
                }

                &:not(.ace-geolocation-selector__modal):not(.ace-currency-selector__modal):not(.showMorePanel) {
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100vw;
                    bottom: rem(0px);
                    background-color: color(neutral, white);
                    margin-left: calc(50% - 50vw);
                    margin-right: calc(50% - 50vw);
                    box-shadow: 0 rem(2px) rem(6px) rgba(0, 0, 0, .1) !important;
                    box-sizing: border-box;
                    z-index: -1;
                }
            }
        }

        &__logo {
            display: none;
        }

        @include media('>=lg') {
            overflow: hidden;
            padding: 0 rem(40px);

            &::after {
                content: '';
                width: 100vw;
                z-index: 99;
                border-bottom: 0.0625rem solid color(neutral-black, 200);
                margin-left: calc(50% - 50vw);
                margin-right: calc(50% - 50vw);
                position: absolute;
                bottom: rem(0px);
                left: 0;
            }

            &__logo--desktop,
            &__pos-container {
                display: flex;
                align-items: center;
            }

            &__logo--desktop {
                gap: rem(24px);

                .ace-logoheader2 {
                    &::before {
                        content: '';
                        width: 0;
                        height: 100%;
                        position: absolute;
                        border: rem(1px) solid color(neutral-black, 200);
                        top: 0;
                        transform: scale(0.8) translateX(rem(-16px));
                    }
                }
            }

            &__pos-container {
                align-self: stretch;
            }

            &__main-container {
                flex-wrap: wrap;

                .activeBtn {
                    border-bottom: rem(4px) solid $border-brand-hover !important;
                }
            }

            &__pos-container,
            &__main-container {
                gap: 1.5rem;
            }

            &__header,
            &__wrapper,
            &__main-container,
            &__menu-container {
                display: flex;
                align-items: center;
                flex: 1;
            }

            &__overlay,
            .ace-navbar-toggler {
                display: none;
            }

            &__header {
                gap: 2rem;
            }

            .link-navigation__moreitem .link-navigation__more-btn {
                span {
                    // font-weight: inherit;
                    &::after {
                        width: 0.15rem;
                        content: "";
                        height: 0.15rem;
                        border-radius: 50%;
                        background-color: $bg-brand-secondary;
                        box-shadow: 0rem 0.3125rem 0rem $border-brand, 0rem 0.625rem 0rem $border-brand;
                        margin-left: rem(5px);
                        vertical-align: middle;
                        display: inline-block;
                        position: relative;
                        bottom: rem(6px);
                    }
                }
            }

            .link-navigation__morePanel {
                .link-navigation__menu__link {
                    width:75% !important;
                    border-bottom: none !important;
                    border-right: 0.25rem solid transparent !important;
                    min-height: 2rem;
                    text-align: left;
                    padding-right: 1.8rem !important;
                    margin-left: 4rem !important;

                    &:hover,
                    &.activeMoreBtn {
                        border-right: 0.25rem solid $border-brand !important;
                    }
                }

                .link-navigation__sub-more-container {
                    position: absolute;
                    background: color(neutral, white);
                    right: 0;
                    left: 0;
                    display: flex;
                    flex: 1;
                    box-shadow: rem(0px) rem(5px) rem(5px) rem(-5px) color(neutral-black, 200);
                    max-height: calc(100vh - rem(92px));
                    overflow-y: auto;
                    overflow-x: hidden;

                    .link-navigation__sub-more-items {
                        .link-navigation__menu__link {
                            .link-navigation__sr-only {
                                font-weight: 500;
                            }
                            font-size: rem(18px);
                        }
                        position: relative;
                        bottom: rem(0px);
                        padding: rem(48px) 0;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        align-self: flex-start;
                        flex: 0 0 auto;
                        width: 19.8%;
                        gap: 0.5rem;
                        border-right: rem(1px) solid color(neutral-black, 200);
                    }
                }
            }
        }
        .ace-header-navigation__loginconnect {
            @include media('>=lg') {
            width: rem(250px);
            }
        }
        @include media('>=lg') {
        .sub-nav-container__panel__scroll{
            flex-direction: row;
            flex: 1;
            justify-content:initial;
            height: max-content;
            padding: 0;
            display: flex;
            width: 100%;
            }
            .sub-nav-container__main-nav {
                width: 33%;
                padding: 0;
                max-width:rem(234px);
                .simple-nav-list-item.two-simplenavs{
                    width:137% ;
                }
            }
            .sub-nav-container__secondary-nav {
                width: 33%;
                padding: 0;
                .simple-nav-list-item.two-simplenavs{
                    width:rem(412px) ;
                }
            }
            .sub-nav-container__panel__scroll.hasPushNav{
                .sub-nav-container__main-nav,.sub-nav-container__secondary-nav{
                    flex: 0 1 auto;
                    width: 33%;
                    padding: 0;
                }
            }
            .sub-nav-container__main-nav.hasImageNav{
                width:100%;
                max-width: none;
            }
            .link-navigation__morePanel {
                .sub-nav-container__main-nav{
                flex: 0 1 auto;
                width: 100%;
                padding: 0; 
                max-width:rem(284px);
            }
            .sub-nav-container__main-nav.hasImageNav{
                max-width:none
            }
            .sub-nav-container__secondary-nav{
                flex: 0 1 auto;
                width: 100%;
                padding: 0; 
            }
        }
            .link-navigation__morePanel .sub-nav-container__panel__scroll.hasPushNav{
                .sub-nav-container__secondary-nav{
                    flex:none;
                    padding: 0; 
                    ul {
                        min-width: rem(182px);
                    }
                }
                .sub-nav-container__main-nav{
                    flex:none;
                    width: 100%;
                    padding: 0; 
                }
            }

            .sub-nav-container__push-nav {
                justify-content: flex-end;
                display: flex;
                flex-basis: 100%;
            }
            .link-navigation__panel .xf-content-height{
                min-height: auto;
            }
            .link-navigation__panel.showMorePanel .xf-content-height{
                min-height: auto;
                margin:0;
                .root.responsivegrid{
                    margin:0;
                }
            }
            .ace-geolocation-selector__modal, .ace-currency-selector__modal{
                box-shadow: none;
                overflow: auto;
                max-height: calc(100vh - rem(92px));
            }
        }
    }
}
.ace-toggleNavbar{
    @include media('>=md','<lg') {
        padding: 0  !important;
      }
}
.ace-currency-selector__label .ace-currency-selector__symbol-value{
    @include media('<lg'){
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
    }
}
.ace-geolocation-selector__modal {
    .pos-container-geolocation__menu__back{

.icon-chevron-left-24icon::before{
    height:rem(24px);
    width:rem(24px);
    color:$color-icon-arrow;
}
        .link-navigation__sr-only{
            
            font-family:$font-family-roboto;
            font-size: rem(18px);
            font-weight: 500;
            line-height: rem(24px);

        }
    }
}

.ace-currency-selector__modal {
    .pos-container-currency__menu__back{

.icon-chevron-left-24icon::before{
    height:rem(24px);
    width:rem(24px);
    color:$color-icon-arrow;
}
        .link-navigation__sr-only{
            
            font-family:$font-family-roboto;
            font-size: rem(18px);
            font-weight: 500;
            line-height: rem(24px);

        }
    }
}
.ace-header-navigation .ace-header-navigation__logo--desktop.frPage{
    .ace-logoheader1{
        display: none;
    }
    .ace-logoheader2::before{
        display:none;
    }
}
.ace-header-navigation .ace-header-navigation__logo--mobile.frPage{
    .ace-logoheader1{
        display: none;
    }  
    .ace-logoheader2{
        border-left: none;
       .ace-logo {
            padding-left:0;
        }
    }  
}

.ace-header-navigation {
.link-navigation__morePanel .link-navigation__sub-more-items .link-navigation__menu__link{
    @include media('>=lg') {
        white-space: revert !important;
    }
}
}
html[dir=ltr] .ace-header-navigation__main-container{
    @include media('<lg') {
    transform: translate(-100%);
    }
    .sub-nav-container__menu__back .link-navigation__nav__item{
        @include media('<md') {
        justify-content: left !important;
        }
    }
}
.enableSticky {
    overflow: visible;
}
