.callout-container {
  ul.ace-callout-section {
    margin: 0;
    padding: 0;
  }
}
.cmp-teaser {
  &__content {
    a {
      display: flex;
    }
  }
}
.ace-callout-container-filters .ace-filter-dropdownbutton__menu ul li{
	input[type="checkbox"]{
	 accent-color: $color-accent;
	 &:focus{
		outline: $border-focus;
     }
	}
 }
.ace-filter-form__checkbox:focus + label::before{
	outline: rem(2px) solid $border-focus;
    outline-offset: rem(2px);
	border-radius: rem(2px);
}


// ACDAA-12488 Callout-container-api Showmap Button CSS
.callout-container-api {
    .cmp-container {
        @include media('>=md') {
            order: 2;
        }
    }
    .ace-modal-component {
        @include media('<md') {
            display: flex;
            flex-direction: column;

            &.sticky {
                display: flex;
                justify-content: center;
                z-index: 101;
            }
        }
        .ace-showmap {
            &__icon {
                height: rem(18px);
                padding-left: rem(4px);
            }

            &__button {
                background-color: $bg-brand-secondary;
                color: color(neutral, white);

                &:hover {
                    background-color: $bg-brand-secondary-hover;
                }

                &:focus {
                    background-color: $bg-brand-secondary;
                    color: color(neutral, white) !important;
                }

                &:active {
                    background-color: $bg-brand-secondary-pressed;
                }
            }
        }

        @include media('>=md') {
            position: relative;
            order: 1;
        }

        @include media('>=lg') {
            margin: rem(48px) 0;
            left: 43%;
        }

        @include media('>=md','<lg') {
            left: 17.5rem;
            margin: rem(20px) 0;
        }

        &.carousel-v2 {
            @include media('<md') {
                position: relative;
                bottom: rem(85px);
            }
        }

        .ace-modal__dialog {
            margin: rem(0px) !important;
            max-height: 100vh;

            @include media('>=md') {
                margin: rem(24px) !important;
                max-height: calc(100vh - rem(48px));
                border-radius: rem(6px);
            }

            .ace-modal__body {
                overflow-y: hidden;
                padding: 0 rem(8px);

                @include media('>=md') {
                    padding: 0 rem(24px);
                }

                .map-container {
                    @include media('>=lg') {
                        height: calc(100vh - 11vw);
                    }

                    @include media('>=md','<lg') {
                        height: calc(100vh - 18vw);
                    }

                    @include media('<md') {
                        height: rem(380px);
                    }
                }
            }
        }
    }
    .ace-loyalty__inner.ace-button {
        @include media('>=md') {
            order: 3;
        }

    }
}
