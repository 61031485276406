.ace-linkgallery {
  .aem-Grid {
    > ul {
      > li {
        @include media('>md') {
          padding-right: rem(16px);
          padding-left: 0;
          &:nth-child(3n-1) {
            padding-right: rem(8px);
            padding-left: rem(8px);
          }
          &:nth-child(3n) {
            padding-right: 0;
            padding-left: rem(16px);
          }
        }
        @include media('<md') {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  display: block;
  @include media('>md') {
    display: flex;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      padding: 0 rem(12px);
      list-style-type: none;
      @include media ('>=md', '<lg') {
        width: 30%;
      }
      @include media ('>=lg') {
        width: 32%;
      }
    }
  }
  &__item {
    margin-bottom: rem(24px);
  }
  img {
    display: -webkit-box;
    width: 100%;
    height: rem(132px);
    margin-bottom: rem(8px);
    object-fit: cover;
    @include media('>=md') {
      height: rem(149px);
      margin-bottom: rem(12px);
    }
  }
  &__item--title {
    margin: 0;
    padding: 0;
    color: color(neutral-black, 900);
    @include ui-heading-03;
  }
}