@use 'sass:math';

/* maximum amount of grid cells to be provided */
$max-col: 12 !default;
$max-col-mobile: 4 !default;
$grid-gutter-width: rem(24px);
$grid-gutter-width-mobile: rem(16px);

/* default breakpoint */

.aem-Grid {
  @include generate-grid(default, $max-col);

  width: auto;
}

@include media('>=xl') {
  .aem-Grid {
    @include generate-grid-hide(default);
  }
}

/* sm breakpoint */

@include media('<md') {
  .aem-Grid {
    @include generate-grid(sm, $max-col-mobile);
    @include generate-grid-hide(sm);
  }
}

/* md breakpoint */

@include media('>=md', '<lg') {
  .aem-Grid {
    @include generate-grid(md, $max-col);
    @include generate-grid-hide(md);
  }
}

/* lg breakpoint */

@include media('>=lg', '<xl') {
  .aem-Grid {
    @include generate-grid(lg, $max-col);
    @include generate-grid-hide(lg);
  }
}

/* xl breakpoint */

@include media('>=xl', '<xxl') {
  .aem-Grid {
    @include generate-grid(xl, $max-col);
    @include generate-grid-hide(xl);
  }
}

/* xxl breakpoint */

@include media('>=xxl') {
  .aem-Grid {
    @include generate-grid(xl, $max-col);
    @include generate-grid-hide(xl);
  }
}

.aem-GridColumn {
  @include media('<md') {
    padding-right: math.div($grid-gutter-width-mobile, 2);
    padding-left: math.div($grid-gutter-width-mobile, 2);
  }

  padding-right: math.div($grid-gutter-width, 2);
  padding-left: math.div($grid-gutter-width, 2);
}

.responsivegrid.aem-GridColumn.aem-GridColumn--default--16 {
  padding-right: 0;
  padding-left: 0;
}

.responsivegrid.aem-GridColumn.aem-GridColumn--default--16 > .aem-GridColumn.aem-GridColumn--default--16 {
  padding-right: 0;
  padding-left: 0;
}

/* grid component */

.aem-Grid {
  display: block;
  width: 100%;
}

.aem-Grid::before,
.aem-Grid::after {
  display: table;
  content: ' ';
}

.aem-Grid::after {
  clear: both;
}

.root.responsivegrid {
  @include media('>sm') {
    margin-right: rem(8px);
    margin-left: rem(8px);
  }

  @include media('>md') {
    margin-right: rem(20px);
    margin-left: rem(20px);
  }

  @include media('>xl') {
    margin-right: rem(52px);
    margin-left: rem(52px);
  }

  @include media('>xxl') {
    max-width: rem(1310px);
    margin-right: auto;
    margin-left: auto;
  }
}

/* placeholder for new components */

.aem-Grid-newComponent {
  clear: both;
  margin: 0;
}

/* column of a grid */

.aem-GridColumn {
  clear: both;
  box-sizing: border-box;
}

/* force showing hidden */

.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
  display: block !important;
}

.page.basicpage {
  background-color: $color-background;
}
