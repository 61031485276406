footer {
  & > .cmp-container {
    @include fluid('parent');
    width: 100vw;
    background-color: $bg-brand-primary;

    & > div {
      @include fluid('child');
      margin: auto !important;
    }
  }
  .ace-pagecontainer {
    @include media('<md') {
      padding: 0 !important;
    }
  }
  .image {
    @include media('>lg') {
      padding: 0 rem(12px);
    }
    @include media('<md') {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  // override for mobile list
  .ace-list,.ace-cta {
    @include media('<md') {
      width: 100% !important;
    }
  }
  .cta {
    @include media('<md') {
      width: 100% !important;
    }
  }
  .ace-footercontainer {
    .ace-container {
      @include media('>xl') {
        margin: 0 rem(-24px);
      }
      @include media('<md') {
        .ace-cta + .ace-list {
          margin-left: 0% !important;
        }
        .mov-logo.ace-logo {
          width: 100%;
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }
  .ace-nav-footer-list {
    @include media('>=md') {
      margin-left: rem(-25px);
    }
    @include media('>lg') {
      margin-left: rem(-8px);
    }
  }

  .ace-navfooterlist-accordion__header {
    color: color(neutral, 900);
  }

  .ace-collapse-list .ace-navfooterlist-accordion__button {
    @include media('<md') {
      justify-content: space-between !important;
      padding: rem(16px) rem(24px);
      @include ui-body-01;
      & span:last-child {
        top: rem(2px);
      }
    }
  }

  .ace-collapse-list__content {
    @include media('<md') {
      padding: 0 rem(24px);
    }
  }
  .ace-footer-icon {
    margin-left: rem(9px);
    @include media('>=lg') {
      margin-left: rem(26px);
    }
  }
  .ace-calltoaction {
    margin-left: rem(9px);
    @include media('>=lg') {
      margin-left: rem(26px);
    }
  }
  .ace-seperator-component {
    hr {
      @include media('>=md', '<lg') {
        margin-right: rem(8px);
        margin-left: rem(8px);
      }
      @include media('>=lg') {
        margin-right: rem(25px);
        margin-left: rem(25px);
      }
    }
  }

  .brand-logo.cmp-header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cmp-header__logo--image {
    max-width: 100%;
  }
}

html[dir='rtl'] {
  .ace-footer-icon > div {
    display: flex;
    @include media('<md') {
      flex-direction: column;
    }
  }
}

.ace-footercontainer {
  .ace-footer-icon {
    align-items: flex-start;
    justify-content: flex-start;

    &__list--item--link {
      display: flex;
    }

    &__list {
      &.listCentered {
        width: 100%;
      }
    }
    @include media('<md') {
      align-items: flex-start;
    }
    @include media('<md') {
      .button {
        width: 100%;
      }
    }
  }

  @include media('<md') {
    .ace-list + .ace-separator {
      display: none;
    }

    .ace-list + .ace-list + .ace-separator {
      display: block;
    }
  }
  .cmp-ace-list.cmp-ace-list__centered {
    padding-inline-start: 0;
  }
}

html[dir='rtl'] {
  footer {
    @include media('<md') {
      .ace-calltoaction {
        border-right: none;
        border-left: none;
      }
      .ace-footer-icon__list.listCentered {
        margin-right: 0;
      }
    }
  }
  .ace-footer-icon {
    margin-right: rem(9px);
    margin-left: 0;
    @include media('>=lg') {
      margin-right: rem(26px);
    }
  }

  @include media('<md') {
    .shared-footer {
      .footerComponent {
        .footerComponent__wrapper {
          .footerLinks .linksList__btn {
            display: flex;

            &::before {
              left: rem(5px);
              right: auto;
              transform: rotate(-45deg);
            }

            &::after {
              left: 0;
              right: auto;
              transform: rotate(45deg);
            }

            &.open {
              &::before {
                transform: rotate(45deg);
              }

              &::after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }

  footer .footerComponent__title {
    text-align: right;
  }

}

body {
  .shared-footer {
    .footerComponent {
      margin-top: 0;
    }
  }
}

html[dir='rtl'] {
  body {
    .shared-footer {
      .footerComponent__title {
        text-align: right;
      }
    }
  }
}
footer{
  li{
    list-style: none;
  }
  .ace-image img{
    width: 100%;
    height: 100%;
  }
}
.ace-footer-icon__list{
  li{
    list-style: none;
  }
}
footer{
  margin-top: 0;
  &.aem-GridColumn{
    margin-top: 8rem;
    @include media('<md') {
        margin-top: 5rem;
    }
  }
}
