.ace-chip {
    display: flex;
    position: relative;
    align-items: center;
    padding: rem(32px) rem(8px);

    @include media('>=md', '<lg') {
        padding: rem(32px) rem(13.5px);
    }

    @include media('>=lg') {
        padding: rem(48px) rem(14px);
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        gap: rem(16px);
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &--item {
            list-style-type: none;
            flex: 1 0 auto;

            button {
                display: inline-flex;
                align-items: center;
                padding: rem(8px) rem(16px);
                border: rem(1px) solid $border-interactive;
                border-radius: rem(25px);
                color: $text-brand;
                background-color: transparent;
                gap: rem(2px);

                &:focus,
                &:focus-visible {
                    background-color: $bg-neutral-secondary;
                    border: rem(1px) solid $border-interactive-pressed;
                    color: $text-brand-pressed;
                }

                &:hover {
                    background-color: $bg-neutral-secondary;
                    color: $text-brand-hover;
                    border: rem(1px) solid $border-interactive-hover;
                    cursor: pointer;
                }

                &:active,
                &.active {
                    background-color: $bg-accent-secondary;
                    border: rem(2px) solid $border-interactive-selected;
                    color: $text-accent;
                }

                &:disabled {
                    background-color: transparent;
                    border: rem(1px) solid $border-interactive-disabled;
                    color: $text-disabled;
                }
            }
        }
    }

    .icon {
        height: rem(32px);
        width: rem(32px);
        color: $text-brand;
        background-color: $bg-neutral-primary;
        border: rem(1px) solid $border-brand;
        border-radius: rem(40px);
        justify-content: center;
        position: relative;
        height: rem(32px);
        width: rem(32px);
        padding: rem(7px);
        box-shadow: 0 rem(2px) rem(6px) 0 $color-box-shadow;
        border: rem(1px) solid $border-brand;
        border-radius: rem(40px);
        background-color: $bg-neutral-primary;
        color: $text-brand;
        right: 0;
        z-index: 1;

        &:first-child {
            left: 0;
            right: unset;
        }

        span {
            color: $text-brand;

            &::before {
                font-size: rem(18px);
                line-height: rem(18px);
                color: inherit;
            }
        }
    }
}
