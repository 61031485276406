//== Font loading
@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
      font-weight: $weight;
      font-family: '#{$name}';
      font-style: $style;
      src:
        url('resources/fonts/#{$file}.woff2') format('woff2'),
        url('resources/fonts/#{$file}.woff') format('woff'),
        url('resources/fonts/#{$file}.ttf') format('truetype'),;
      font-display: swap;
    }
  }
  
  @include fontface('Montserrat', 'montserrat-bold', 700);
  @include fontface('Roboto', 'roboto-bold', 700);
  @include fontface('Roboto', 'roboto-medium', 500);
  @include fontface('Roboto', 'roboto-regular', 400);
  @include fontface('Unna', 'unna-italic', 400);
  
  @font-face {
    font-weight: normal;
    font-family: 'icomoon';
    font-style: normal;
    src: url('resources/fonts/icomoon.eot');
    src:
      url('resources/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
      url('resources/fonts/icomoon.woff') format('woff'),
      url('resources/fonts/icomoon.ttf') format('truetype'),
      url('resources/fonts/icomoon.svg#icomoon') format('svg');
  }
  
  @import '../resources/fonts/icomoon/style.scss';
  