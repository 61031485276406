$namespace: ".ace-heading-list";

#{$namespace} {
  &#{$namespace}__container {
    flex: 1;
  }
  #{$namespace}__title {
    padding: 0;
    margin: 0;
    color: $text-heading;
  }
  #{$namespace}__description {
    color: $text-body-primary;
    p {
      line-height: inherit;
      margin-top: rem(24px);
      margin-bottom: 0;
    }
    @include iconList;
    ul li,
    ol li {
      margin-bottom: 1rem;
    }
    &.ace-heading-list__arrow {
      li:before {
        content: "\e91f";
        color: $text-brand;
        [dir="rtl"] & {
          transform: rotate(180deg);
        }
      }
    }
    [dir="rtl"] & {
      ul,
      ol {
        padding-right: 2.5rem;
        padding-left: 0;
        li:before {
          margin-right: -2.5rem;
        }
      }
    }
  }
}
