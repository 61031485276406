.ace-dropdown {
  &.hidden {
    display: none;
  }
  &__container {
    height: rem(48px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(24px);

    & > * {
      flex: 1;
    }
  }
  &__listbox {
    position: relative;
    .ace-dropdown__button {
      background: #ffffff;
      padding: rem(12px) rem(16px);
      gap: rem(8px);
      border: rem(1px) solid #8b8a93;
      border-radius: rem(6px);
      color: #3e3d48;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &[aria-expanded="true"] .icon {
        transform: rotate(180deg);
      }
      & > .icon {
        font-size: rem(24px);

        &::after {
          color: #1e1852;
        }
      }
    }
    .ace-dropdown__list {
      display: none;
      list-style: none;
      position: absolute;
      top: rem(34px);
      z-index: 1;
      background: #ffffff;
      border: rem(2px) solid #3470df;
      box-shadow: rem(0px) rem(8px) rem(16px) rgba(0, 0, 0, 0.1);
      padding: rem(16px) 0;
      min-width: 100%;
      border-radius: rem(6px);
      &.show {
        display: block;
      }
      li {
        color: #3e3d48;
        padding: rem(8px) rem(32px);
        width: auto;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &.selected {
          line-height: 1.5;
          &::before {
            @include icomoon;
            color: #1e1852;
            content: "\e93c";
            position: absolute;
            left: rem(8px);
            font-size: rem(18px);
            margin-top: rem(3px);
          }
        }
        &:hover,
        &:focus,
        &[aria-selected="true"],
        .focus {
          background: #1e1853;
          color: #ffffff;
          &.selected::before {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.ace-dropdown__selectbox {
    height: rem(48px);
    justify-content: space-between;
    align-items: center;
    gap: rem(24px);
    display: flex;

  & > * {
    flex: 1 rem(30px);
  }
}

.ace-dropdown {
    &__selectbox {
        position: relative;
        select {
            box-sizing: border-box;
            border: rem(1px) solid color(neutral-black, 500);
            width: 100%;
            border-radius: rem(6px);
            height: rem(48px);
            padding: rem(12px) rem(16px);
            color: color(neutral-black, 800);
            background-color: color(neutral, white);
            appearance: none;
            @include ui-body-01;
            &:focus {
                outline: 0.125rem solid color(royal-blue,500);
            }
            option {
                @include ui-body-01;
            }
        }
        span[aria-hidden='true'] {
            position: absolute;
            display: flex;
            top: auto;
            right: 0;
            bottom: 0;
            z-index: 1;
            align-items: center;
            justify-content: center;
            padding: padding(02);
            background: color(neutral, white);
            margin: rem(4px);
            border: transparent;
            pointer-events: none;
        }
    }
}
