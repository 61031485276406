.section-background-container {
   
  .ace-bgColor {
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    @include media('<md') {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  > div {
    max-width: 81.875rem;
    margin: 0 auto;
     
    @include media('<md') {
      margin-right: rem(20px);
      margin-left: rem(20px);
      padding: 0 !important;
    }
    @include media('<lg') {
      padding: 0 0.8rem;
    }
    @include media('>=lg') {
      padding: 0 0.8rem;
    }
    @include media('<xl') {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }
  
    @include media('>xl') {
      margin-left: 3.25rem;
      margin-right: 3.25rem;
      padding: 0 0.8rem;
    }
    @media (min-width: rem(1280px)) and (max-width: rem(1280px)){
      padding: 0 2.8rem;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }
    @include media('>xxl') {
      max-width: rem(1310px);
      margin-right: auto;
      margin-left: auto;
    }
  }
}
  .ace-bg- {
    &no-color {
      background: none;
    }

    &accent-primary-default {
      background-color: $bg-accent-primary-default;
    }

    &accent-secondary {
      background-color: $bg-accent-secondary;
    }

    &brand-primary {
      background-color: $bg-brand-primary;
    }

    &brand-secondary-default {
      background-color: $bg-brand-secondary-default;
    }

    &brand-tertiary {
      background-color: $bg-brand-tertiary;
    }

    &danger-primary-default {
      background-color: $bg-danger-primary-default;
    }

    &danger-secondary {
      background-color: $bg-danger-secondary;
    }

    &disabled-primary {
      background-color: $bg-disabled-primary;
    }

    &disabled-secondary {
      background-color: $bg-disabled-secondary;
    }

    &loyalty-primary {
      background-color: $bg-loyalty-primary;
    }

    &loyalty-secondary {
      background-color: $bg-loyalty-secondary;
    }

    &neutral-primary {
      background-color: $bg-neutral-primary;
    }

    &neutral-secondary {
      background-color: $bg-neutral-secondary;
    }

    &neutral-tertiary {
      background-color: $bg-neutral-tertiary;
    }

    &offer-primary {
      background-color: $bg-offer-primary;
    }

    &offer-secondary {
      background-color: $bg-offer-secondary;
    }

    &overlay-primary {
      background-color: $bg-overlay-primary;
    }

    &overlay-secondary {
      background-color: $bg-overlay-secondary;
    }

    &focus {
      background-color: $bg-focus;
    }

    &success-primary {
      background-color: $bg-success-primary;
    }

    &success-secondary {
      background-color: $bg-success-secondary;
    }

    &warning-primary {
      background-color: $bg-warning-primary;
    }

    &warning-secondary {
      background-color: $bg-warning-secondary;
    }

    &brand-default-inverse {
      background-color: $bg-brand-default-inverse;
    }

    &overlay-inverse {
      background-color: $bg-overlay-inverse;
    }

    &focus-inverse {
      background-color: $bg-focus-inverse;
    }

    &danger-primary-inverse {
      background-color: $bg-danger-primary-inverse;
    }

    &success-primary-inverse {
      background-color: $bg-success-primary-inverse;
    }
  }
}
.section-background-container.container.responsivegrid.aem-GridColumn--lg--12.aem-GridColumn.aem-GridColumn--sm--4.aem-GridColumn--xl--12.aem-GridColumn--default--12.aem-GridColumn--xxl--12.aem-GridColumn--md--12 {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 0;
}