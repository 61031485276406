.ace-loyalty {
  background-color: $bg-loyalty-secondary;

  @include media('>=md', '<lg') {
    width: calc(100vw - rem(64px));
    margin: auto;
  }

  @include media('<md') {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  &__kicker {
    @include ui-label-01;
    color: $text-body-primary;
    width: 100%;
    font-style: normal;
    letter-spacing: rem(1.12px);
  }
  &__inner {
    @include media('<md') {
      padding: rem(16px) rem(16px) 0 rem(16px);
    }

    @include media('>=md') {
      padding: rem(64px) rem(2px);
    }

    @include media('>=lg') {
      padding: rem(64px) rem(3px);
    }

    @include fluid('child');
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__textindent {
    align-items: center;
    width: 100%;
    text-align: center;
    display: inline-block;
    max-width: 98%;
    margin: 0;
  }
  &__titleheading {
    @include expressive-heading-03;
    padding: rem(8px) 0 rem(16px) 0;
    width: 100%;
    color: $text-heading;

    @include media('<md') {
      font-size: rem(36px);
      line-height:rem(48px);
    }

    @include media('>=md') {
      @include expressive-heading-02;
      padding: rem(8px) 0 rem(32px) 0;
    }

    @include media('>=lg') {
      @include expressive-heading-03;
      padding: rem(8px) rem(0px) rem(32px) rem(0px);
    }

    h2 {
      @include expressive-heading-03;

      @include media('>=md','<lg') {
        font-size: rem(36px);
        line-height: rem(48px);
      }
    }
  }

  &__descriptiontext {
    padding: 0 0 rem(16px) 0;
    width: 100%;
    color: $text-loyalty;
    p {
      margin: 0;
    }

    @include media('>=md') {
      padding: 0 0 rem(32px) 0;
    }

    @include media('>=lg') {
      padding: 0 rem(0px) rem(32px) rem(0px);
    }
  }

  &__bullets {
    span {
      color: $text-loyalty;
    }
  }

  &__button {
    width: 100%;

    @include media('>=md') {
      width: auto;
    }

    @include media('>=lg') {
      width: auto;
    }

    .cmp-button--secondary {
      background-color: $bg-loyalty-secondary;
    }

    .ace-button {
      display: block;
      @include media('>=md') {
        display: flex;
        justify-content: flex-end;
      }
    }

    .aem-GridColumn {
      padding: 0 rem(8px) 0 rem(8px);

      @include media('>=md') {
        padding: 0;
      }

      @include media('>=lg') {
        padding: 0 0 0 rem(29px);
      }
    }
  }

  &__btn {
    margin: 0 0 rem(16px) 0;

    @include media('>=md') {
      width: auto;
      margin: 0 rem(8px) 0 rem(8px);

    }

    .cmp-button--text {
      white-space: pre-line;
    }
  }
}
.loyalty-billboard{
li{
  list-style: none;
}
}

//ACDAA-6609
.loyalty-login-block {
  position: relative;
  padding: rem(0px);
.ace-loyalty-container {
  .ace-loyalty {
    &__content {
      @include media('<md') {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
      }
      border-radius: rem(6px);
    }
    &__block {
      margin: rem(24px) 0;
      padding-left: rem(24px);

      @include media('<md') {
        padding-left: rem(16px);
        padding-right: rem(16px);
        margin: rem(16px) 0 0 0;
      }
      .expressive-heading-05 {
        @include ui-body-01-strong;
        color: $text-loyalty;
        margin: rem(16px) 0 rem(4px) 0;
      }
      &__des p {
        @include ui-body-02;
        color: $text-loyalty;
        margin: 0;
      }

      img.img-fluid {
        width: auto;
        height: auto;
      }
    }
    &__button {
      margin: rem(56px) 0 rem(32px) 0;
      @include media('<md') {
        margin: rem(16px) 0;
        padding-right: rem(16px);
      }

      .ace-button {
        margin-right: rem(4px);
        margin-top: rem(4px);
        margin-bottom: rem(4px);

        @include media('<md') {
          margin: 0;

        }
        .cmp-button {
          &--primary{
            padding: rem(8px) rem(20px);
          }
        }

        .ace-loyalty__btn {
          background-color: $status-classic-default;

          @include media('<md') {
            margin: 0;
          }

          &:hover {
            background-color: $status-classic-hover;
          }

          &:focus {
            outline: rem(2px) solid $border-focus;
          }

          &:active {
            background-color: $status-classic-pressed;
          }
        }
      }
    }
  }
}
}
// CSS added : ACDAA-11837 - for [Homepage] Create a new component to display user loyalty infos
.loyalty-info  {
        display: none;
    .ace-loyalty {
        &__inner {
            padding: rem(32px) rem(64px) !important;

            @include media(">=md",'<lg') {
                padding: rem(32px) !important;
            }

            @include media('<md') {
                padding: rem(24px) rem(16px) !important;
            }
        }
        &.loyalty-billboard {
            @include media('<md') {
                width: auto;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &__titleheading {
            padding: 0;
        }
        &__textindent {
            text-align: start;
            color: $text-brand;
            @include media('<md') {
                @include expressive-heading-05;
            }
        }
        &__bullets {
            padding-top: rem(32px);
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            ul {
                padding-left: 0;
                margin: 0;
                display: flex !important;
                @include media('<md') {
                    flex-direction: column;
                }

                li {
                    display: flex !important;
                    text-align: left;
                    align-items: flex-start !important;
                    .icon.icon.icon-listing-24icon {
                        padding-right: rem(16px);
                        color: $text-brand;
                        height: rem(24px);

                        &:before {
                            color: $text-brand !important;
                        }
                    }
                    span,h3 {
                        @include ui-heading-03;
                        font-weight: 400 !important;
                        color: color(neutral-black, 900);
                    }

                    .ace-loyalty__description {
                        @include media('>=md') {
                            padding-right: rem(24px);
                        }

                        @include media('<md') {
                            padding-bottom: rem(16px);
                        }

                        p {
                            margin: 0;
                            padding-top: rem(8px);
                            @include ui-body-01;
                            color: color(neutral-black, 800);
                        }
                    }

                    &:last-child {
                        .ace-loyalty__description {
                            padding-right: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
            .ace-loyalty {
                &__description {
                    &-dynamic-data {
                        display: flex;
                        box-sizing: border-box;
                        @include media('<md') {
                            flex-direction: column;
                        }
                        margin-top: rem(32px) !important;
                        .ace-loyalty__description-sub-container {
                            display: flex;
                            @include media('>=lg') {
                                justify-content: center;
                            }
                            @include media('>=md','<lg') {
                                display: block !important;
                            }
                            @include media('<md') {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                            }
                        }
                        border-radius: rem(6px);
                        border: rem(1px) solid $border-loyalty;
                        padding: rem(16px) rem(40px) !important;

                        @include media('>=md','<lg') {
                            padding: rem(16px) 0 !important;
                            justify-content: center;
                            align-items: center;
                        }

                        @include media('<md') {
                            padding: rem(16px) !important;
                        }

                        hr {
                            height: rem(24px);
                            border: rem(1px) solid $border-loyalty;
                            margin-top: 0;
                            margin-bottom: 0;
                            margin-left: rem(32px);
                            margin-right: rem(32px);
                            align-self: center;
                            @include media('>=md','<lg') {
                                margin: 0 rem(32px);
                                height: rem(52px);
                                align-self: center;
                            }

                            @include media('<md') {
                                width: 100%;
                                height: 0;
                                margin: rem(16px) 0;
                            }
                        }

                        &--label {
                            color: color(neutral-black, 800);
                            @include media('>=lg') {
                                margin-right: rem(16px);
                                align-self: center;
                            }

                            @include media('>=md','<lg') {
                                text-align: center;
                                padding-bottom: rem(8px);
                            }
                        }

                        &--value {
                            color: color(neutral-black, 900);
                            align-self: center;

                            .noOfNightsValue,
                            .noOfPointsValue {
                                @include ui-body-01-strong;
                                color: color(neutral-black, 900) !important;
                                padding-left: 0 !important;
                            }

                            @include media('>=md','<lg') {
                                text-align: center;
                            }

                            &.productTier.ui-caption-01 {
                                .productTierValue {
                                    @include ui-caption-01;
                                    font-style: normal;
                                    border-radius: rem(2px);
                                    padding: rem(2px) rem(8px);
                                    color: color(neutral, white);
                                    align-items: center;
                                    display: flex;
                                    justify-content: center;

                                    &.classic {
                                        background-color: $bg-brand-primary ;
                                    }
                                    &.silver {
                                        background-color: $status-silver-default;
                                    }
                                    &.gold {
                                        background-color: $status-gold-default;
                                        color: color(neutral-black, 900);
                                    }
                                    &.platinum {
                                        background-color: $status-platinum-default;
                                    }
                                    &.diamond {
                                        background-color: $status-diamond-default;
                                        color: color(neutral-black, 900);
                                    }
                                    &.limitless {
                                        background-color: $status-limitless-default;
                                    }

                                    @include media('>=md','<lg') {
                                        width: 40%;
                                        margin-left: calc(50% - 6.5vw);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ace-loyalty.loyalty-billboard {
        @include media('>=md','<lg') {
            margin: 0 rem(12px);
        }
        .ace-loyalty__inner {
            margin: 0;
            .ace-loyalty__bullets {
                ul {
                    @include media('<md') {
                        padding: 0;
                    }
                    li {
                        margin-left: 0;
                        @include media('>=lg') {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
