.expressive-heading-01 {
  @include expressive-heading-01;
}

.expressive-heading-01-alt {
  @include expressive-heading-01-alt;
}

.expressive-heading-02 {
  @include expressive-heading-02;
}

.expressive-heading-03 {
  @include expressive-heading-03;
}

.expressive-heading-04 {
  @include expressive-heading-04;
}

.expressive-heading-05 {
  @include expressive-heading-05;
}

// SubHeadding
.expressive-subheading-01 {
  @include expressive-subheading-01;
}

// This is for component and layout headings.
.ui-heading-01 {
  @include ui-heading-01;
}

.ui-heading-02 {
  @include ui-heading-02;
}

.ui-heading-03 {
  @include ui-heading-03;
}

.ui-body-01-strong {
  @include ui-body-01-strong;
}

.ui-body-01 {
  @include ui-body-01;
}

.ui-body-02-strong {
  @include ui-body-02-strong;
}

.ui-body-02 {
  @include ui-body-02;
}

.ui-caption-01 {
  @include ui-caption-01;
}

.ui-label-01 {
  @include ui-label-01;
}
