//With AVATAR
.ace-quote {
    &__text, p {
        text-align: center;
        color: $text-body-primary;
        margin: 0;
        font-style: italic;
        @include expressive-subheading-01;
        @include media('>=md') {
            line-height: rem(32px);
            font-size: rem(24px);
        }
    }

    &__content {
        display: flex;
        justify-content: center;

        .ace-quote__image {
            padding-right: rem(16px);
            padding-top: rem(32px);
            @include media('<md') {
                padding-top: rem(24px);
            }
            &--display {
                width: rem(48px);
                height: rem(48px);
                border-radius: 50%;
                object-fit: fill;
            }
        }
        .ace-quote__description {
            display: flex;
            flex-direction: column;
            padding-top: rem(32px);
            @include media('<md') {
                padding-top: rem(24px);
            }
            .ace-quote__name {
                padding-bottom: rem(4px);
                color: $text-heading;
                font-style: normal;

                &:last-child {
                    padding-bottom: 0;
                }
            }
            .ace-quote__disc {
                color: $text-body-secondary;
                font-style: normal;

                p {
                    @include ui-body-02;
                    margin: 0;
                    color: $text-body-secondary;
                    font-style: normal;
                }
            }
        }
    }

    &__icon {
        text-align: center;

        @include media('>=md') {
            margin-bottom: rem(32px);
        }

        @include media('<md') {
            margin-bottom: rem(24px);
        }

        .icon-left-double-quote {
            color: $bg-brand-secondary-default;
            svg {
            fill: $bg-brand-secondary-default;
            }
        }
    }
}
// No-AVATAR
.ace-quote.no-avatar {
    .ace-quote {
        &__content {
            text-align: center;
            .ace-quote__description {
                padding-left: 0;
                align-items: center;
            }
        }
    }
}
