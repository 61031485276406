.ace-date-component {
    .duet-date-picker .duet-date__day[tabindex='0'][aria-pressed=true]{
        border-radius: rem(48px);
        box-shadow: 0;
    }
    .duet-date-picker .duet-date__input{
        margin-top: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 2.5rem;
    }
    // .duet-date__toggle{
    //     // display:none;
    // }
    .duet-date__dialog-content {
        margin-top: rem(12px);
        .duet-date__day {
            color: $text-body-primary;
        }
        .duet-date__day[aria-pressed='true']{
            color: #fff;
            box-shadow: 0 0 0 var(--duet-color-primary);
        }
        .duet-date__day.is-outside:disabled {
            color: $text-disabled;
        }
        .duet-date__select-label {
            color: $text-heading;
        }
        .duet-date__header {
            margin-bottom: rem(24px);
        }
        .duet-date__table-header {
            color: $text-body-secondary;
        }
        .duet-date__mobile-heading {
            &::first-letter {
                text-transform: uppercase;
            }
        }
        .duet-date__mobile-heading {
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
    .date__checkin {
        label {
            color: $text-body-primary;
        }
        display: inline-grid;
        padding-right: rem(24px);
        @include media('<md') {
            width: 45%;
            padding-right: rem(16px);
          }
        .duet-date__input {
            pointer-events: none;
        }
        .duet-date__dialog.is-active {
            @include media('>=md','<lg') {
                position: absolute;
            }
        }
        .duet-date__input {
            &.active {
                border: rem(2px) solid #3470DF;
            }
        }
    }
    .date__checkout {
        label {
            color: $text-body-primary;
        }
        display: inline-grid;
        @include media('<md') {
            width: 45%
        }
        .duet-date__input {
            pointer-events: none;
        }
        .duet-date__dialog.is-active {
            @include media('>=md','<lg') {
                position: absolute;
            }
        }
        .duet-date__input {
            &.active {
                border: rem(2px) solid #3470DF;
            }
        }
    }
}
