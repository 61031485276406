.ace-section-title{
    &__content {
        &--kicker {
            @include ui-label-01;
            text-align: center;
            letter-spacing: 0.07rem !important;
            text-transform: uppercase;
            color: $text-body-secondary;
            @include media("<md") {
                padding: 0;
            }
            @include media(">=lg") {
                padding: 0 rem(6px);
            }
            p{
                margin: 0;
            }
        }
        &--title {
            text-align: center;
            color: $text-heading;
            margin: rem(8px) 0 rem(16px) 0;
            @include media(">=md") {
                margin: rem(8px) 0 rem(24px) 0;
            }
            @include media("<md") {
                padding: 0;
            }
            @include media(">=lg") {
                padding: 0 rem(2px);
                letter-spacing: rem(-0.5px);
            }
            h1,h2,h3,h4,h5 {
                margin: 0;
            }
        }
        &--description {
            @include ui-body-01;
            display: flex;
            align-items: flex-end;
            text-align: center;
            justify-content: center;
            color: $text-body-primary;
            @include media("<md") {
                padding: 0;
            }
            @include media(">=lg") {
                padding: 0 rem(6px);
            }
            p{
                margin: 0;
            }
        }
    }
}
