.ace-list-v2-component {
    .list-default {
        padding: 0;
        .ace-list-default {
            &__item {
                padding-left: 0 !important;
                padding-right: 0 !important;

                @include media('>=md','<lg') {
                    margin-left: rem(12px) !important;
                    padding-right: rem(24px) !important;
                }

                &--list {
                    padding-left: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                    list-style: none;
                    column-gap: rem(24px);
                    &.column-2,
                    &.column-3,
                    &.column-4 {
                    @include media('>=md','<lg') {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        column-count: 2;
                    }
                }

                    &.column-1,
                    &.column-2 {
                        margin-top: rem(32px);
                        @include media('>=lg') {
                            padding-left: rem(12px);
                            margin-top: 0;
                        }
                        @include media('>=md', '<lg') {
                            margin-top: rem(48px);
                        }
                    }

                    &.column-1 {
                        column-count: 1;
                        
                        .ace-list-default__item--display:nth-child(n+4) {
                            display: none;
                        }
                    }

                    &.column-2 {
                        @include media('>=lg') {
                            column-count: 2;
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }

                        .ace-list-default__item--display:nth-child(n+7) {
                            display: none;
                        }
                    }

                    &.column-3 {
                        @include media('>=lg') {
                            column-count: 3;
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                        }

                        .ace-list-default__item--display:nth-child(n+10) {
                            display: none;
                        }
                    }

                    &.column-4 {
                        @include media('>=lg') {
                            column-count: 4;
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                        }

                        .ace-list-default__item--display:nth-child(n+13) {
                            display: none;
                        }
                    }
                }
                &--display {
                    display: flex;
                    margin-bottom: rem(24px);

                    @include media('<md') {
                        margin-bottom: rem(16px);
                        &:nth-child(n+4) {
                            display: none;  
                        }
                    }

                    @include media('>=md','<lg') {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }
                &--description {
                    padding-top: rem(8px);
                    color: $text-body-primary;

                    p {
                        margin: 0;
                        @include ui-body-01;
                    }
                }
            }
            &__title {
                padding-left: 0 !important;
                color: $text-heading;

                @include media('>=md','<lg') {
                    padding-left: rem(12px) !important;
                }

                @include media('<md') {
                    padding-right: 0;
                }

                h2 {
                    @include expressive-heading-03;
                }
            }
            &__description {
                @include media('>=lg') {
                    padding-left: 0;
                }
                @include media('<md') {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            &__disc {
                color: $text-body-primary;
                margin-top: rem(32px);
                margin-bottom: rem(32px);
                
                @include media('>=md') {
                    margin-top: rem(48px);
                    margin-bottom: rem(48px);
                }

                p {
                    margin: 0;
                    @include ui-body-01;
                }
            }
            &__align {
                color: $text-heading;
                font-weight: 400;

                &.bold-title {
                    font-weight: 700;
                }
            }
            &__subtitle {
                padding-top: rem(8px);
                color: $text-body-secondary;
            }
            &__icon {
                font-size: rem(24px);
                height: rem(24px);
                color: $color-secondary;
                padding-right: rem(16px);

                &.large-picto {
                    font-size: rem(36px);
                    height: rem(36px);

                    img {
                        width: rem(36px);
                        height: rem(36px);
                    }
                }

                .cq-placeholder {
                    min-width: rem(36px);
                }
            }
            &__content {
                word-break: break-word;
            }
            &--item__icon {
                justify-content: start !important;
                padding-top: rem(8px) !important;
                align-items: center !important;

                &.icon-arrow-right-24icon {
                    padding-top: 0 !important;
                    padding-left: rem(4px);
                    position: relative;
                    @include media('<md') {
                        bottom: rem(3px);
                    }
                    &:before {
                        color: $color-link;
                    }
                }
            }

            .aem-GridColumn.aem-GridColumn--default--12.aem-GridColumn--sm--4.aem-GridColumn--offset--sm--0 {
                padding-left: 0;
                @include media('>=md','<lg') {
                    padding-left: rem(12px);
                }
            }

            .aem-GridColumn.aem-GridColumn--sm--1.aem-GridColumn--md--12.aem-GridColumn--default--6.aem-GridColumn--offset--sm--0.aem-GridColumn--offset--default--6 {
                @include media('<lg') {
                    margin-left: 0;
                }
                @include media('<md') {
                    padding-left: 0;
                }
            }
        }

        .ace-list__button {
            display: none;
            &.see-more {
                display: block;
            }
            &--icon {
                position: relative;
                top: rem(3px);
                width: rem(18px);
                height: rem(18px);

                &::before {
                    width: rem(18px);
                    height: rem(18px);
                    font-size: rem(18px);
                    line-height: rem(18px);
                }
            }
        }
    }
}

.list {
    &.cq-Editable-dom {
        .ace-list-v2-component  {
            .list-default .ace-list-default__item--list .ace-list-default__item--display {
                display: flex !important;
            }
            .ace-list__button {
                display: none !important;
            }
        }
    }
}