.navigation-panel--container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  @include fluid(child);
  .navigation-panel {
    background: color(neutral, white);
    .brand-logo {
      @include media("<=md") {
        position: relative;
        z-index: map-get-deep($z-index, header, headingPageHero, content-modal);
        display: flex;
        justify-content: center;
        order: 2;
        padding-left: 0;
      }
      @include media("<=lg") {
        position: relative;
        z-index: 100;
      }
    }

    .loginbutton {
      position: relative;
      @include media("<md") {
        .button {
          display: none;
        }
      }
    }

    li:last-child span.more-icon::before {
      margin-left: rem(6px);
      font-family: icomoon;
      content: "⠇";
    }

    @include media("<=lg") {
      li span.more-icon::before {
        display: none;
      }
    }
  }

  .navigation-panel > .cmp-container > .aem-Grid {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include media("<=lg") {
      position: relative;
      justify-content: center;
    }
  }

  @include media("<=lg") {
    .disable-scroll-menu,
    .disable-scroll-panel {
      position: relative;
      height: 100%;
      max-height: 100vh;
      overflow-y: hidden;
    }
  }

  .navbar {
    .navbarContent {
      .nav-item__submenu {
        & > ul {
          flex-direction: row;
          flex-wrap: nowrap;
          @include media("<md") {
            flex-direction: column;
          }
        }

        .cmp-ace-gallery__item {
          @include media("<=lg") {
            display: flex;
            flex-direction: column;
          }
          @include media("<md") {
            width: 100%;
            padding: 0;
          }
        }
        &ul {
          flex-wrap: nowrap;
          @include media("<md") {
            margin-left: 0;
          }
        }
      }
    }
  }
}
@include media("<=lg") {
  p {
    display: block;
  }
}
.nav-item {
  .nav-item__submenu {
    #menu-heading {
      @include media(">md") {
        display: none;
      }
    }
  }
}
@include media("<md") {
  body {
    &.disable-scroll-myaccount {
      position: relative;
      height: 100vh;
      overflow-y: hidden !important;
    }
  }
}
.navbar {
  .navbarContent {
    > ul {
      .nav-item {
        &__dropdown-menu {
          @include media(">=sm", "<=md") {
            margin-right: 0;
            margin-left: 0;
          }
          .nav-item__submenu {
            ul {
              @include media(">=md") {
                flex-direction: column;
              }
            }
            @include media(">md", "<lg") {
              margin-right: rem(16px);
              margin-left: rem(16px);
            }
            @include media(">=md", "<=xl") {
              margin-right: rem(32px);
              margin-left: rem(32px);
            }
          }
        }
      }
    }
  }
}
@include media("<=lg") {
  html[dir="rtl"] {
    .navbar {
      .navbarContent > ul {
        li {
          display: flex;
          flex-direction: column;
          margin-right: rem(32px);
        }
      }

      .nav-item {
        .nav-link {
          left: auto;
          margin-right: 0;

          &::before {
            right: rem(-19px);
            left: auto;
          }
        }
      }
    }
  }
}
html[dir="rtl"] {
  .navbar {
    .navbarContent > ul {
      li {
        display: flex;
        flex-direction: column;
        margin-right: rem(32px);
        @include media("<=lg") {
          margin-right: 0;
        }
      }
      .nav-item {
        .nav-link {
          left: auto;
          margin-right: 0;
          &::before {
            right: rem(-19px);
            left: auto;
          }
        }
      }
    }
  }
}
@include media(">=lg") {
  .navigation-panel--container {
    #navigationpanel {
      .navigation-panel > .cmp-container > .aem-Grid {
        justify-content: left;
        position: relative;
      }
    }
    .navigation-panel {
      .aem-Grid.aem-Grid--12.aem-Grid--default--12 .logo:first-child {
        width: auto;
        padding-left: 0.375rem;
        padding-right: 1.875rem;
        .ace-header__logo--image {
          max-width: 8.19188rem;
          height: auto;
        }
      }
      .aem-Grid.aem-Grid--12.aem-Grid--default--12 .logo:not(:first-child){
        .ace-header__logo--image {
          width: rem(90px);
          height: rem(15px);
        }
      }
    }
  }
  .navigation {
    .navbar {
      float: right;
      .navbar-toggler {
        display: none;
      }
      .navbarContent {
        display: block;
      }
      .navbarContent > ul {
        flex-direction: row;
        max-width: 100%;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding: 0;
        .nav-item__dropdown-toggle:before,
        .nav-item .nav-link:before {
          display: none;
        }
        .nav-item .nav-link {
          margin-right: 1rem;
          padding: 0;
          padding-left: 1rem;
          padding-right: 1rem;
          font-weight: 400;
          font-size: 1rem;
          height: 4.5rem;
        }
      }
    }
  }
  .ace-navbar-component {
    .ace-header {
      height: 4.5rem;
      width: 100%;
    }
  }
  header {
    .cmp-container {
      .headercontainer {
        .aem-Grid .aem-GridColumn {
          .language-selector {
            display: block;
            position: relative;
            height: auto;
            padding: 0;
            width: auto !important;
            &:before {
              display: none;
            }
            .ace-language-selector__button button:after {
              display: none;
            }
            .ace-language-selector {
              padding-left: 0;
            }
          }
          .myaccount {
            top: 1rem;
          }
        }
      }
    }
  }
}
@include media('<md'){
  .navigation-panel {
    .aem-Grid.aem-Grid--12.aem-Grid--default--12 .logo:first-child .ace-header__logo--image {
      width: rem(94px);
      height: auto;
    }
  }
}
