.cmp-page__skiptomaincontent {
    .cmp-page__skiptomaincontent-link {
        &:focus {
            @include ui-body-02;
            border: 1px $border-focus;
            color: $text-inverse-body-primary;
            background-color: $bg-focus;
            text-decoration: underline;
        }
    }
}
