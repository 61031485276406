%column-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
%zero-padding-margin {
  margin: 0;
  padding: 0;
}
$namespace: ".ace-nested-list";
#{$namespace} {
  ul,
  ol {
    list-style: none;
    @extend %zero-padding-margin;
  }
  &#{$namespace}__container {
    &.turquoise-100 {
      background-color: $bg-accent-secondary;
    }
    &.neutral-black-100 {
      background-color: color(neutral-black, 100);
    }
    padding: padding(09) padding(04) padding(09);
    @extend %column-flex-start;
    gap: rem(48px);
    margin: 0 rem(-16px);
    @include media(">=md") {
      border-radius: rem(6px);
      padding: padding(09);
      margin: 0 rem(12px);
    }
    @include media(">=md", "<=md") {
      margin: 0 rem(12px);
    }
    @include media(">md") {
      margin: 0;
    }
    #{$namespace}__title {
      @extend %zero-padding-margin;
      color: color(neutral-black, 900);
    }
    #{$namespace}__content {
      @extend %column-flex-start;
      gap: rem(32px);
      width: 100%;
      @include media(">=md") {
        flex-direction: row;
        gap: rem(64px);
      }
    }
  }
}
