.ace-myaccount {
    position: absolute;
    top: rem(66px);
    right: 0;
    z-index: map-get-deep($z-index, header, myaccount, normal);
    display: none;
    flex-direction: column;
    align-items: flex-start;
    float: right;
    clear: both;
    margin-top: 0;
    padding: rem(24px);
    background: color(neutral, white);
    box-shadow: 0 rem(8px) rem(16px) rgba(37, 35, 57, 0.1);

    @include media(">=md") {
      width: rem(288px);
    }

    @include media(">=md", "<=lg") {
      top: rem(48px);
    }

    @include media(">md", "<lg") {
      margin-top: rem(-7px);
    }

    @include media("<md") {
      position: fixed;
      top: rem(60px);
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      overflow-y: scroll;
    }

    @include media(">lg") {
      top: rem(70px) !important;
    }

    &__header {
      button.cmp-button {
        padding: rem(12px) rem(24px);
        border: 0;
      }
    }

    &_innerbox__images {
      max-width: rem(178px);
      height: rem(16px);
    }

    .button {
      width: 100%;

      span {
        pointer-events: none;
      }
    }

    &__button-text.cmp-button__text {
      @include ui-body-01;

      display: inline-block;
      max-width: rem(190px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media("<=lg") {
        display: none;
      }
    }

    &__button-icon.cmp-button__icon {
      position: relative;
      left: rem(-5px);
      width: rem(24px);
      font-size: rem(24px);

      &::before {
        font-size: 1.5rem !important;
      }

      .inverse & > [class*="path"]::before {
        position: relative;
        top: rem(1px);
        left: rem(-3px);
        color: color(brand, 900);
        font-size: rem(30px);
      }

      @include media("<=lg") {
        left: 0;
        margin-right: 0;
      }
    }

    &__list {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: rem(24px) 0 0 0;
      list-style: none;

      .seperator {
        position: relative;
        margin-bottom: rem(17px);

        hr {
          position: absolute;
          right: rem(-24px);
          bottom: 0;
          left: rem(-24px);
          display: block;
          width: auto;
          margin: 0;
          border-color: color(grey-neutral, 200);
          border-right: 0;
          border-bottom: 0;
          border-left: 0;
        }
      }
    }

    &__item {
      @include ui-body-01;

      margin-bottom: rem(16px);
      color: color(neutral-black, 800);

      &:last-child {
        @include ui-body-01;

        margin-bottom: 0;
        padding-bottom: 1.5rem;
      }

      a {
        display: block;
        width: 100%;
        color: color(neutral-black, 800);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      float: right;

      @include media("<=lg") {
        position: relative;
        z-index: map-get-deep($z-index, header, myaccount, header, "<=lg");

        & button.cmp-button {
          width: rem(36px);
          height: rem(36px);
          padding: 0;
          border-radius: 50%;
        }

        & .cmp-button.primary.active,
        & .cmp-button.primary:active {
          background-color: color(brand, 500);
        }
      }

      & .cmp-button.inverse {
        &:focus {
          outline: rem(2px) solid $border-focus;
        }

        @include media(">lg") {
          &:hover,
          &:active {
            background-color: color(neutral, 100);
          }
        }
      }
    }

    &__rewards.ace-myaccount-btn-clasic {
      background-color: $bg-brand-secondary;
    }

    &.show {
      .ace-myaccount-btn {
        &:focus {
          border: 0;
          outline: rem(1px) solid $border-focus;
        }
      }
    }
  }

  .ace-myaccount.show {
    display: flex !important;
  }

  .ace-myaccount__icon {
    display: flex;
    width: 100%;
    cursor: pointer;

    button {
      margin-left: auto;
      background: transparent;
      border: none;
    }

    a {
      margin-left: auto;
      background: transparent;
      border: none;
    }
  }

  .ace-myaccount__icon a {
    text-decoration: none;

    &:focus {
      position: relative;
      display: block;
      text-decoration: none;
      background: transparent;
      outline: rem(2px) solid $border-focus;
    }
  }

  .ace-myaccount__title {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: rem(24px);
    padding-bottom: rem(24px);
    color: color(neutral-black, 900);
    word-break: break-all;

    @include ui-heading-02;
  }

  .ace-myaccount_innerbox {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: rem(8px);
    padding: rem(16px) rem(15px);
    background-color: $bg-loyalty-secondary;
    border-radius: rem(6px);

    &__decs {
      margin-bottom: 0;
    }
    img{
      width: rem(178px);
      height: rem(16px);
    }
  }

  .ace-myaccount-btn {
    max-height: rem(48px) !important;
    margin-top: rem(16px);
  }

  .ace-myaccount-btn-clasic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: rem(12px);
    color: color(neutral, white);
    background-color: $bg-brand-primary;
    border: 0;
    border-radius: rem(6px);

    & .cmp-button__icon {
      width: rem(24px);
      height: rem(24px);
      font-size: rem(24px);

      &::before {
        font-size: rem(24px) !important;
      }
    }
  }

  .ace-myaccount_innerbox__decs {
    color: $text-loyalty;

    @include ui-body-01;
  }

  .btn-bot-margin {
    margin-bottom: rem(16px);
  }

  .btn-clasic {
    width: 100%;
  }

  .btn-desc {
    text-align: left;
  }

  .btn-desc-first {
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: rem(4px);
    color: color(neutral, white);
    text-align: left;

    @include ui-label-01;
  }

  .btn-desc-second {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: color(neutral, white);

    @include ui-heading-03;
  }

  .ace-myaccountrewards {
    position: relative;
  }

  #close_all_div_connected,
  #close_all_div_nonmember {
    ul.ace-myaccount__list:first-of-type {
      li:first-child {
        a {
          color: color(neutral-black, 900);
        }
      }
    }

    ul.ace-myaccount__list:last-of-type {
      li:last-child {
        padding-bottom: 0;
      }
    }
  }

  #close_all_div {
    ul.ace-myaccount__list:last-of-type {
      li:last-child {
        padding-bottom: 0;
      }
    }
  }

  html[dir="rtl"] {
    .ace-myaccount {
      right: auto;
      left: 0;
      @include media("<md") {
        right: 0;
      }
    }
    .ace-myaccount__icon button, .ace-myaccount__icon a{
      margin-right: auto;
      margin-left: 0;
  }
  }

  .acc-hide {
    display: none;
  }

  html[dir="rtl"] {
    .ace-myaccount-disconnected {
      .ace-myaccount__button-icon.cmp-button__icon {
        top: -0.325rem;
        left: 0.6875rem;

        @include media("<lg") {
          display: contents;
        }
      }
    }

    .ace-myaccount-connected {
      .cmp-button__icon {
        display: block;
      }

      .ace-myaccount__button-icon.cmp-button__icon {
        top: rem(-9px);
        left: 1.6875rem;

        @include media("<=md") {
          top: -0.5625rem;
          left: 0.35rem;
        }
      }
    }
  }

  html[dir='rtl'] {
    header .cmp-container .aem-Grid .aem-GridColumn .myaccount {
      left: 0;
      right: auto;
    }
  }
