.cmp-title {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;

  .cmp-title__text {
    color: $titleColor;
    font-family: $font-heading;
    font-weight: 700;
    margin: 0;
  }

  h1.cmp-title__text {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  h2.cmp-title__text {
    font-size: 1.875rem;
    line-height: 2.75rem;
  }

  h3.cmp-title__text {
    font-family: $font-page;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
.cmp-title__link {
  color: $color-accent;
  border: 0.125rem solid $color-transparent;
  border-radius: 0.25rem;
  &:hover {
    color: $color-accent-darker;
    text-decoration: none;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }
  &:focus {
    color: $color-accent;
    text-decoration: none;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}
