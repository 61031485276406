.ace-table-container {
  @include media('>=md', '<lg') {
    padding: 0 rem(12px);
  }
  &__select {
    display: block;
    @include media('>=lg') {
      display: none;
    }
    button {
      padding-left: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &__select-dropdown {
    @include ui-body-01;
    color: $color-link;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    border: none;
    align-items: center;
    background: color(neutral, white);
    .icon-chevron-down-24icon {
      &::before {
        color: $color-link;
      }
    }
    .icon-chevron-up-24icon {
      &::before {
        color: $color-link;
      }
    }
    .ace-heading-dropdown__icon {
      height: rem(18px);
      width: rem(18px);
      padding-left: rem(8px);
      line-height: rem(18px);
      display: block;
    }
  }
  &__dropdown-value{
    white-space: break-spaces;
  }
  &__select-content {
    display: none;
    z-index: 1;
    &.active {
      display: block;
      width: rem(197px);
      position: absolute;
    }
  }
  &__list-content {
    background: color(neutral, white);
    border-radius: rem(6px);
    overflow: hidden;
    list-style: none;
    position: relative;
    padding: rem(16px) 0;
    margin: 0;
    &::before {
      border: rem(2px) solid color(royal-blue, 500);
      box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 0.1);
      border-radius: rem(6px);
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
  &__list-item {
    @include ui-body-01;
    padding: rem(8px) rem(32px) rem(8px) rem(0px);
    text-align: left;
    a {
      color: color(neutral-black, 800);
    }
    span.icon.icon--24.icon-check-24icon {
      width: rem(13.13px);
      height: rem(10.13px);
      padding: 0 rem(8.44px) 0 rem(10.44px);
      color: $text-brand;
      visibility: hidden;
      &.active {
        visibility: visible;
      }
    }
    &:hover {
      padding: rem(8px) rem(30px) rem(8px) rem(0px);
      background-color: $bg-brand-secondary;
      color: color(neutral, white);
      a {
        color: color(neutral, white);
      }
      span.icon.icon-check-24icon {
        color: color(neutral, white);
        padding: 0 rem(8.44px);
        &::before {
          color: color(neutral, white);
        }
      }
    }
    &:focus {
      padding: rem(8px) rem(30px) rem(8px) rem(0px);
      background-color: $bg-brand-secondary;
      color: color(neutral, white);
      a {
        color: color(neutral, white);
      }
      span.icon.icon--24.icon-check-24icon {
        color: color(neutral, white);
        padding: 0 rem(8.44px);
        &::before {
          color: color(neutral, white);
        }
      }
    }
  }
  ul {
    margin: 0;
  }
  &__switcher {
    display: flex;
    place-content: center;
    list-style: none;
    padding-bottom: rem(32px);
    padding-left: rem(0px);
    @include media('>=lg') {
      padding-bottom: 0;
    }
    li:first-child {
      border-radius: rem(8px) 0 0 rem(8px);
    }
    li:last-child {
      border-radius: rem(0px) rem(8px) rem(8px) rem(0px);
    }
    &:focus {
      outline: rem(2px) solid color(royal-blue, 500);
      box-sizing: border-box;
    }
  }
  &__switcher-text {
    @include ui-body-01;
    border: rem(1px) solid $border-brand;
    padding: rem(8px) rem(12px);
    color: $text-brand;
    cursor: pointer;
    &.active {
      background-color: $bg-brand-secondary;
      color: color(neutral, white);
    }
    &:focus {
      outline: rem(2px) solid color(royal-blue, 500);
      box-sizing: border-box;
    }
  }
  &__head {
    margin-top: rem(24px);
    @include media('>=md') {
      margin-top: rem(32px);
    }

  }
  &__table {
    display: table;
    width: 100%;
    border-spacing: 0;
    position: relative;

  }
  &__heading {
    display: table-cell;
  }
  &__actions {display: none;}
  @include media('<lg') {
    &__actions {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: color(neutral-black, 800);
      width: 100%;
      top: 38%;
      left: 0;
    }
    &__action {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      width: rem(40px);
      height: rem(40px);
      background-color: color(neutral, white);
      border: rem(1px) solid $border-brand;
      border-radius: rem(40px);
      outline: none;
      cursor: pointer;
      .icon {
        color: $text-brand;
        pointer-events: none;
        &::before {
          color: $text-brand !important;
        }
      }
      &:focus {
        color: color(neutral-black, 200);
        background-color: color(neutral, white);
        border-color: color(neutral-black, 200);
        outline: rem(2px) solid $border-focus;
        outline-offset: rem(2px);
        .icon::before {
          color: color(neutral-black, 200);
        }
      }
      &:active {
        background-color: $bg-brand-secondary-hover;
        .icon::before {
          color: color(neutral, white) !important;
        }
      }
    }
    &__action--previous {
      &:disabled {
        background-color: color(neutral-black, 200);
        border: color(neutral-black, 200);
        .icon::before {
          color: color(neutral-black, 600) !important;
        }
      }
      margin-right: rem(5px);
    }
    &__action--next {
      &:disabled {
        background-color: color(neutral-black, 200);
        border: color(neutral-black, 200);
        .icon::before {
          color: color(neutral-black, 600) !important;
        }
      }
      margin-left: rem(5px);
    }
  }
  &__plan {
    @include ui-label-01;
    color: color(neutral-black, 900);
    padding: rem(24px) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    text-align: center;
    text-transform: none !important;
    @include media('<lg') {
      padding: rem(16px) 0;
      width: 75%;
      margin: 0 auto;
    }
    &-image{
      width: rem(182.5px);
      height: rem(115px);
      position: relative;
      overflow: hidden;
      @include media('<lg') {
        width: rem(111.087px);
        height: rem(70px);
      }
      >div{
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: rem(8px);
      }
    }
    &-title{
      @include ui-heading-02;
      color: color(neutral-black, 900);
      margin-top: rem(16px);
      @include media('<lg') {
        font-size: rem(16px);
        line-height: rem(24px);
        margin-top: rem(8px);
      }

    }
    &-desp p{
      @include ui-caption-01;
      color: color(neutral-black,900);
      margin-top: rem(8px);
      margin-bottom: 0;
      span{
        font-size: rem(16px);
        font-style: normal;
        font-weight: 700;
        line-height: rem(24px);
        display: flex
      }
    }
    &-link a{
      @include ui-body-02;
      color: $color-link;
      display: flex;
      gap: rem(4px);
      margin-top: rem(24px);
      @include media('<lg') {
        margin-top: rem(8px);
      }
    }

  }
  .cmp-accordion__item {
    border-bottom: none !important;
    .cmp-accordion__item:first-child {
      border-top: 0.0625rem solid color(neutral-black, 200);
    }
    width: 100% !important;
    .cmp-accordion__button {
      padding: rem(16px) 0;
      span {
        @include media('<lg') {
          &:first-child {
            margin-left: rem(16px);
          }
        }
      }
      .cmp-accordion__title {
        @include ui-heading-03;
      }
      .cmp-accordion__icon {
        padding-right: rem(21px);
        @include media('>=lg') {
          padding-right: rem(29px);
        }
      }
    }
    .cmp-accordion__panel {
      padding: 0;
      width: auto !important;
      margin: 0;
    }
  }
  &__accordian-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(24px);
    align-self: stretch;
  }
  &__accordian-cell {
    cursor: pointer;
    @include ui-body-01;
    color: $color-link !important;
    padding: rem(16px) rem(16px);
    display: flex;
    width: 100% !important;
    border: none;
    background: none;
    text-align: left;
    align-items: center;
    flex: 1 0 0;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    @include media('>=md') {
      padding: rem(16px) rem(16px);
    }
    @include media('>=lg') {
      padding: rem(16px) 0 rem(16px) rem(24px);
    }
    span:last-child {
      line-height: rem(5.62px);
      padding-left: rem(4px);
      place-self: center;
    }
    &:focus {
      outline: rem(2px) solid color(royal-blue, 500);
      outline-offset: rem(-2px);
      box-sizing: border-box;
    }
    .icon-chevron-down-24icon {
      &::before {
        color: $color-link;
      }
    }
    .icon-chevron-up-24icon {
      &::before {
        color: $color-link;
      }
    }
  }
  &__content-cell {
    @include ui-body-02;
    font-size: rem(16px) !important;
    text-align: center;
    color: color(neutral-black, 800);
    padding-top: rem(18px);
    padding-bottom: rem(18px);
    display: flex;
    align-items: center;
    flex: 1 0 0;
    justify-content: center;
    &:first-child{
      justify-content:flex-start;
      padding-left: rem(24px);
      text-align: left;
      @include media('<lg') {
        padding-left: 1rem;
      }
    }
    span{
      height: auto;
    }
    @include media('<lg') {
      padding: 1rem 1rem;
    }
    @include media('>=lg') {
      &:last-child {
        padding-right: rem(24px);
      }
    }
  }

  &__accordian-description {
    @include ui-body-02;
    width: 100%;
    color: color(neutral-black, 800);
    padding: rem(8px) rem(16px) rem(16px) rem(16px);
    display: table-cell;
    @include media('>=md') {
      padding: rem(8px) rem(24px) rem(16px) rem(24px);
      display: table-cell;
    }
    @include media('>=lg') {
      width: 50%;
      padding: rem(8px) rem(0px) rem(16px) rem(24px);
      display: table-cell;
    }
    @include media('>=lg') {
      width: 50%;
      display: block;
    }
    &.active {
      display: block;
    }
  }
  &__footer {
    margin-top: rem(16px);
    width: 100%;
    margin-bottom: rem(16px);
    @include media('>=lg') {
      margin-top: rem(20px);
      margin-bottom:rem(20px)
    }
  }
  &__foot {
    display: table-cell;
    .ace-button {
      place-content: center;
      .cmp-button {
        padding: rem(12px) rem(24px);
        @include media('<md') {
          width: 100%;
        }
      }
      a:focus {
        color: color(neutral, white);
        outline: rem(2px) solid color(royal-blue, 500);
        outline-offset: rem(2px);
      }
    }
    a.cmp-button[aria-disabled] {
      outline: none;
      cursor: default;
      pointer-events: none;
      background-color: color(neutral-black, 200) !important;
      color: color(neutral-black, 600) !important;
      border-radius: 3.75rem;
      padding: 0.75rem 1.5rem;
      border-color:  color(neutral-black, 200) !important;
    }
  }
  &__foot-btn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: rem(12px) rem(24px);
    background-color: $bg-accent-primary;
    border-radius: rem(60px) !important;
    color: color(neutral, white);
  }
}
.tablecontainerbenefits:nth-child(odd),
.tablecontainerbenefits:nth-child(odd) table tbody > tr + tr {
  background-color: color(neutral-black, 100);
}
.tablerow:nth-child(odd),
.tablerow:nth-child(odd) table tbody > tr + tr {
  background-color: color(neutral-black, 100);
}
.cmp-container {
  .tablecontainerbenefits:last-child {
    padding-bottom: rem(24px);
  }
}
.table-desc {
  display: table;
  width: 100%;
  td:nth-child(n + 3) {
    @include media('<lg') {
      display: none;
    }
  }
  &.close {
    display: none;
  }
}
@include media('<lg') {
  .hide{
    display: none;
  }
}
.tablecontainer{
  .ace-faq-component{
    @include media('<md'){
    border-top: rem(1px) solid rgba($color-gray-dark,.5);
    border-bottom: rem(1px) solid rgba($color-gray-dark,.5);
    }
    @include media('>=md','<lg'){
      border-top: rem(1px) solid rgba($color-gray-dark,.5);
      border-bottom: rem(1px) solid rgba($color-gray-dark,.5);
      }
  }
}
