$namespace: '.ace-core-booking-engine';
#{$namespace} {
  &__destination-dropdown {
    width: calc(40% - 0.9rem);
    display: inline-grid;
    padding-right: rem(24px);
    position: relative;
    &-hidden {
      display: none !important;
    }
    @include media('<lg') {
        width: 100%;
    }
    .ace-destination-dropdown__lable {
        color: color(neutral-black, 800);
        padding-bottom: rem(8px);
        display: block;
    }
    .ace-destination-dropdown__select {
        white-space: nowrap;
        padding: 0.75rem 2rem 0.75rem 1rem;
        border-radius: rem(6px);
        border: 1px solid $bg-destination-dropdown-select;
        overflow: hidden !important;
        text-overflow: ellipsis;
        background: color(neutral, white);
        appearance: none;
        box-sizing: border-box;
        width: 100%;
        color: $text-body-primary;
        height: 3.5rem!important;

        option {
            color: $text-heading;
        }

        optgroup {
            color: $text-body-primary;
        }

        &:focus {
            border: 0.0625rem solid $bg-focus!important;
            outline: 0!important;
            box-shadow: 0 0 0 .0625rem $bg-focus!important;
        }
    }
    span[aria-hidden=true] {
        width: rem(24px);
        height: rem(24px);
        position: absolute;
        display: flex;
        top: 53%;
        bottom: 0;
        right: 1.9375rem;
        pointer-events: none;
    }
  }
  &#{$namespace}__two-destination-dropdown {
    #{$namespace}__options {
        top: rem(212px);
    }
    #{$namespace}__destination-dropdown {
        width: 100%;
        padding-right: 0;
        #{$namespace}__destination-container {
            display: flex;
            gap: rem(25px);
            .ace-destination-dropdown__country, .ace-destination-dropdown__city {
                width: calc(40% - rem(15px));
                position: relative;
                span[aria-hidden=true] {
                    right: rem(10px);    
                }
            }
            .ace-destination-dropdown__city {
                .ace-destination-dropdown__select {
                    &:disabled {
                        background-color: $bg-disabled-secondary;
                        border-color: $border-interactive-disabled;
                    }
                }
            }
        }
    }
    @include media('>=lg') {
        #{$namespace}__destination-dropdown {
            margin-bottom: rem(32px);
        }
        form, #{$namespace}__content {
            flex-wrap: wrap;
        }
        #{$namespace}__content {
            #{$namespace}__check-in, #{$namespace}__check-out {
                flex: 1;
            }
        }
    }
    @include media('>=md', '<lg') {
        #{$namespace}__content {
            display: flex;
            flex-wrap: wrap;
        }
        #{$namespace}__destination-dropdown {
            #{$namespace}__destination-container {
                .ace-destination-dropdown__country, .ace-destination-dropdown__city {
                    width: 50%;
                }
            }
        }
    }
    @include media('<md') {
        #{$namespace}__destination-dropdown {
            #{$namespace}__destination-container {
                display: block;
                .ace-destination-dropdown__country, .ace-destination-dropdown__city {
                    width: 100%;
                }
                .ace-destination-dropdown__country {
                    margin-bottom: rem(24px);
                }
            }
        }
    }
  }
}

.core-booking-engine {
    &.two-destination-dropdown {
        @include media('>=lg') {
            height: rem(273px);
            &.expanded {
                min-height: rem(478px);
                &:has(.ace-core-booking-engine__accessible-room) {
                    min-height: rem(543px);
                }
            }
        }
    }
}
