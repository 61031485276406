.ace-headingpagehero {
  background-color: rgb(0 0 0 / 50%);
  background-repeat: no-repeat;
  width: 100vw;
  background-position: right center;
  display: flex;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  border: 0 !important;
  justify-content: center;
  position: relative;
  @include media('>=sm','<lg'){
    background-color: color(neutral, white) !important;
  }
  @include media('>xl') {
    max-height: calc(100vw / 2.6);
  }
  @include media('>xxl') {
    max-height: calc(100vw / 2.6);
  }
  &.headingno-image {
    height: auto !important;
    background-color: color(neutral, white);
  }

  .ace-image {

    @include media('<lg') {
      img {
        height: 100%;
      }
    }
  }
  h1 {
    margin: rem(8px) 0 rem(24px) 0;
  }
  h2 {
    margin: rem(8px) 0 rem(24px) 0;
    color: color(neutral, white);
  }
  span.ui-label-01 {
    margin: rem(8px) 0;
  }
  span.ui-body-01 {
    margin: rem(8px) 0;
    color: $text-body-secondary;
  }
  .expressive-subheading-01 {
    align-items: center;
    position: relative;
    justify-content: center;
    margin: 0;
  }


  &--fullWidth {
    width: 100%;
    line-height: 0;
  }
  &__image {
    z-index: 0;
    width: 100%;
    height: 100%;

    @include media('<=md') {
      object-fit: cover;
      min-height: rem(400px);
      object-position: center;
    }

    @include media('>md') {
      max-height: 100vh;
    }
  }

  &__content {
    top: 0;
    z-index: map-get-deep($z-index, header, headingpagehero, content-normal);
    width: calc(100vw - 26%);
    max-width: rem(1310px);

    @include media('>=md', '<lg') {
      max-width: rem(566px);
    }

    @include media('<md') {
      width: 100%;
      max-width: rem(343px);
    }
    .headline-text {
      top: 25%;
    }
    .modal-opened &,
    .lightbox-opened & {
      z-index: map-get-deep($z-index, header, headingpagehero, content-modal);
    }
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    position: absolute;

    justify-content: flex-end;
    overflow: hidden;

    h2 {
      margin-top: 0.5rem !important;
    }

    &--link {
      @include ui-body-01;

      display: inline-block;
      margin-top: 1rem;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        margin-bottom: rem(2px);
      }

      .ace-highlight__icon {
        display: inline-block;
        margin-left: rem(4px);

        &::before {
          width: 1.125rem;
          height: 1.125rem;
          font-size: 1.125rem;
        }
      }
    }
  }

  &-headline {
    display: flex;
    top: 50%;
    padding: 0;
    height: auto !important;
    position: absolute;
    -webkit-transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    flex-direction: column;
    width: 100%;

    p {
      margin: 0;
    }
    .ui-label-01 {
      color: color(neutral, white);
    }
    .expressive-heading-01,
    .expressive-heading-01-alt,
    .expressive-heading-02,
    .expressive-heading-03 {
      color: color(neutral, white);
    }
    .expressive-subheading-01 {
      color: color(neutral, white);
    }

    span[data-cmp-text='text_01'] {
      color: $text-body-secondary;
    }

    h1[data-cmp-text='text_02'] {
      color: $text-heading;
    }

    span[data-cmp-text='text_03'] {
      color: $text-body-primary;
    }
    .cmp-button {
      margin: 3rem auto 0;
    }

    @include media('<md') {
      margin-top: 0;
      display: flex;
      height: 100%;
      padding: 0;
      align-items: center;
      width: 100%;
    }

    @include media('>=md', '<=lg') {
      & {
        padding: 0;
      }
    }

    @include media('>=md') {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &-btn-group {
    z-index: 1;
    display: flex;
    align-items: flex-end;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    button {
      height: rem(48px);
    }

    @include media('<md') {
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      position: absolute;
      height: auto;
      bottom: 0;
      z-index: unset;
    }

    @include media('>=md', '<lg') {
      width: calc(100% - rem(64px));
      margin: 0 rem(32px) rem(32px) rem(32px);

    }

    @include media('>=lg') {
      min-width: calc(100% - rem(128px));
      margin: 0 rem(64px) rem(64px) rem(64px);

    }
    &__item {
      display: flex;

      @include media('<md') {
        &:first-child {
          justify-content: center;
          width: 100%;
          align-items: baseline;
          height: 100%;
          margin-bottom: 0;
          margin-top: rem(42px);
        }
        &:last-child {
          width: 100%;
          .cmp-button {
            width: 100%;
          }
        }
      }

      &--left {
        .button {
          &:first-child {
            margin-right: rem(16px);
          }
        }
        .cmp-button--inverse {
          @include media('<md') {
            width: rem(48px);
            height: rem(48px);
            padding: 0;
          }
        }
      }
      &--right {
        .button {
          &:first-child {
            @include media('>=md') {
              margin-right: rem(16px);
            }
          }
        }
      }
    }
    &--fullHeight {
      height: 100%;
    }
  }

  &__overlay {
    top: 0;
    pointer-events: none;
    right: 0;
    left: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100vw;
    display: block;
    height: 100%;
    opacity: .5;
    background-color: color(neutral, black);

  }
  .headingno-image {
    position: relative;
    min-height: auto;
    display: flex;
    .ace-headingpagehero-headline {
      position: inherit;
      transform: inherit;
      h1,h2,h3,h4 {
        margin: 0;
      }
    }
    h1,h2,h3,h4 {
      color: $text-heading;
    }
    .expressive-subheading-01 {
      margin-top: rem(24px);
      color: $text-body-primary;
    }
    .expressive-heading-01 {
      color: $text-heading;
    }
    .expressive-heading-01-alt {
      color: $text-heading;
    }

    span[data-cmp-text='text_01'] {
      color: $text-body-secondary;
    }

    h1[data-cmp-text='text_02'] {
      color: $text-heading;
    }

    span[data-cmp-text='text_03'] {
      color: $text-body-primary;
    }
    .ui-label-01 {
      color: $text-body-secondary;
    }

    .ace-heading-dropdown {
      &__button {
        color:$text-heading;
        span.icon.icon-chevron-down-24icon {
          &::before {
            color: $text-brand;
            font-size: rem(13.5px);
            padding: rem(8.25px) rem(5.25px);
            line-height: rem(7.5px);
            vertical-align: middle;
          }
        }
        span.icon.icon-chevron-up-24icon {
          &::before {
            color: $text-brand;
            font-size: rem(13.5px);
            padding: rem(8.25px) rem(5.25px);
            line-height: rem(7.5px);
            vertical-align: middle;
          }
        }
      }

      &__drop-content {
        &.active {
          min-width: rem(160px);
          position: relative;
          display: block;
          max-width: rem(360px);
          top: 0;
          bottom: auto;
          align-content: center;
          justify-content: center;
        }
      }
  }
  }
}

@include media('<md') {
  .gallery-button {
    .ace-headingpagehero {
      &-headline {
        align-items: flex-end;
        height: 50%;
      }
      &-btn-group {
        position: static;
        height: 50%;
        bottom: inherit;
      }
    }
  }
}

.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  @include media('<md') {
    margin: 0;
    padding: 0;
  }
}

.cmp-headinghero-btn-full-width {
  display: flex;
  width: 100%;

  @include media('<md') {
    &.sticky {
      position: fixed;
      top: 0;
    }
  }
  .cmp-button {
    width: 100%;
    padding: rem(13px) rem(20px) rem(13px) rem(21px);
  }

  div[data-cmp-is='booknow'] {
    width: 100%;
  }
}

html[dir='rtl'] {
  .ace-headingpagehero-btn-group--item--left {
    .button {
      &:first-child {

        @include media('>=md') {
          margin-right: 0;
          margin-left: rem(16px);
        }
      }
    }
  }
  .ace-headingpagehero-btn-group__item--right {
    .button {
      &:first-child {

        @include media('>=md') {
          margin-right: 0;
          margin-left: rem(16px);
        }
      }
    }
  }
}

.cmp-imageLightbox--container .cmp-button {
  &--secondary {
    margin: 0 0 0 rem(16px);
    padding: rem(16px) rem(24px);
    border: 0 !important;

    span[data-placement='right'] {
      &::before {
        color: $text-brand !important;
      }
    }
    &:hover {
      span[data-placement='right'] {
        &::before {
          color: color(neutral, white) !important;
        }
      }
    }

    @include media('<=md') {
      span[data-placement='right'] {
        &::before {
          margin-left: 0;
        }
      }
    }
    &:focus {
      span[data-placement='right'] {
        &::before {
          color: $text-brand !important;
        }
      }
    }

    @include media('<=md') {
      .cmp-button--text {
        display: none;
      }

      width: rem(40px);
      height: rem(40px);
      position: relative;
      padding: rem(12px) rem(12px);
      border-radius: (40px);
      bottom: 8rem;
      border: 0 !important;
      span[data-placement='right'] {
        margin-left: 0;
      }
    }
  }
  &--accent {
    margin: 0 0 0 rem(16px);
    padding: rem(16px) rem(24px);

    @include media('<=md') {
      .cmp-button--text {
        display: none;
      }

      width: rem(40px);
      height: rem(40px);
      position: relative;
      padding: rem(12px) rem(12px);
      border-radius: (40px);
      bottom: rem(135px);
      border: 0;
      span[data-placement='right'] {
        margin-left: 0;
      }
    }
  }
}
.page-hotel .ace-headingpagehero__overlay {
  display: block;
}
.modal-opened.page-hotel {
  .ace-headingpagehero--overlay {
    display: none;
  }
}
.mov-headingpagehero {
  .ace-headingpagehero-btn-group--item--right {
    .button {
      &:first-child {
        margin-right: 0;
      }
    }
  }
}
.ace-headingpagehero-btn-group__item--right {
  .cmp-button {
    &--accent {
      padding: rem(16px) rem(24px);

      @include media('<=md') {
        border: 0;
        border-radius: 0;
      }
    }
    &--primary {
      padding: rem(16px) rem(24px);

      @include media('<=md') {
        border: 0;
        border-radius: 0;
      }
    }
    &--secondary {
      margin-right: rem(16px);
      padding: rem(16px) rem(24px);
      border: 0 !important;

      @include media('<=md') {
        margin-right: 0;
        border: 0;
        border-radius: 0;
      }
    }
  }
}

@include media('<=md') {
  .modal-open {
    .mov-headingpagehero {
      &.ace-headingpagehero {
        position: fixed;
        z-index: map-get-deep($z-index, header, headingPageHero, content-modal);
        height: 100vh;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
}
.ace-headingpagehero {
  .book-now--selector {
    .selector-item {
      .selector-item-radio-button {
        height: 0;
      }
    }
  }
}

/* footer scss */
.cmp-list {
  margin-top: rem(12px);
  padding-left: 0;
}
.sm-hidden {

  @include media('>=sm') {
    display: none;
  }
}

.cmp-list--item {
  list-style-type: none;
}

@include media('<md') {
  .cmp-collapse-list {
    .cmp-accordion--item {
      width: 100%;
    }
    & .cmp-accordion__button {
      display: flex;
      width: 100%;
      align-items: center;
      &[aria-expanded="true"] .cmp-accordion__icon {
        transform: rotate(180deg);
      }
      & .cmp-accordion__icon {
        position: relative;
        top: rem(-8px);
        left: rem(-4px);
        font-size: 1.5rem;
      }
    }
    .cmp-accordion__panel[aria-hidden="true"] {
      display: none !important;
    }
    .cmp-accordion__panel[aria-hidden="false"] {
      display: block !important;
    }
    .cmp-accordion__panel,
    .cmp-accordion__panel[aria-hidden="true"] ul.cmp-collapse-list__content {
      margin-bottom: 0 !important;
    }
    .cmp-accordion__panel[aria-hidden="true"] ul.cmp-collapse-list__content {
      margin-top: 0 !important;
    }
  }
  .cmp-accordion__panel--hidden {
    display: block;
  }
}

@include media('>=md') {
  .cmp-collapse-list {
    .cmp-accordion--item {
      width: 100%;
      border: 0;
    }
    .cmp-accordion__button {
      padding: 0 0;
      pointer-events: none;
    }
    .cmp-collapse-list__title {
      @include ui-heading-03;
      margin-bottom: rem(12px);
      color: color(neutral, 900);
    }
    .cmp-accordion__icon {
      display: none;
    }
  }
}

.cmp-collapse-list {
  .cmp-accordion {

    @include media('<md') {
      &__button > span {
        pointer-events: none;
      }
      &__panel {
        display: none;
        &.show {
          display: block;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .cmp-accordion {
    &__panel {
      padding-right: 0;
    }
  }
  .cmp-list {
    padding-right: 0;
  }
  .cmp-calltoaction {
    padding-right: rem(24px);
  }
}

.cmp-ace-list.cmp-ace-list--centered {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-block-start: 0;
  padding-inline-start: rem(32px);

  @include media('<md') {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
  }
  .cmp-list__item {
    margin-right: rem(14px);
    margin-bottom: rem(6px);
    & a:focus .cmp-list__item-title {
      color: color(neutral, white) !important;
    }
    .cmp-list__item-title {
      @include ui-body-02;
      color: color(neutral, 900);
    }
  }
}
.ace-list {
  .cmp-ace-list.cmp-ace-list--centered {
    .cmp-list--item a {
      &:focus {
        .cmp-list--item-title {
          color: color(neutral, 900) !important;
        }
      }
    }
  }
}

  /* footer scss */

.heading {
  > h2,
  h3,
  h4,
  h5 {
    margin: 0;
    color: $text-heading;

    @include media('<md') {
      font-weight: 700;
      font-size: rem(30px);
      line-height: rem(44px);
      letter-spacing: 0;
    }
  }

  h2,
  h3 {
    @include media('>=md', '<=md') {
      padding: 0 0.75rem;
    }
  }
  .expressive-subheading-01 {
    @include ui-body-01;
    p {
      @include ui-body-01;
      font-size: rem(20px) !important;
      font-weight: 500 !important;
      line-height: rem(28px) !important;

      @include media('<md') {
        @include ui-body-01;
        font-size: rem(18px) !important;
        font-weight: 500 !important;
        line-height: rem(24px) !important;
      }
    }
  }
  h3,h4 {
    width: calc(100vw - 26%);
    max-width: 81.875rem;

    @include media('<md') {
      width: 100%;
      max-width: 21.4375rem;
    }
  }
}
.container {
  .container {
    &.responsivegrid {

      @include media('<md') {
        padding: 0;
      }
    }
  }
}

  /* below image variation */

.ace-headingpage--below--image {
  @include media('<=lg') {
    flex-wrap: wrap;
    .ace-headingpagehero--overlay {
      display: none;
      background: none;
    }
    .ace-headingpagehero--content {
      position: inherit;
      @include media('>=md', '<lg') {
        width: calc(100vw - 22%);
        max-width: rem(582px);
      }
    }
    .ace-headingpagehero-headline {
      position: inherit;
      display: block;
      transform: inherit;
      .ui-label-01 {
        margin-top: rem(32px);
        color: $text-body-secondary;
      }
      .expressive-heading-02,
      .expressive-heading-01-alt {
        margin: rem(8px) 0 rem(24px) 0;
        color: $text-heading;

        @include expressive-heading-01;
        padding: 0;
      }
      .expressive-subheading-01 {
        color: $text-body-primary;
        @include expressive-subheading-01;
      }
    }
  }
}

.root {
    .container {
            .heading:first-child {
                margin: 0;
        .headingno-image {
          .ace-headingpagehero-headline {
            p.ui-label-01 {
              margin-top: rem(32px);
              margin-bottom: rem(8px);
              @include media('>=md') {
                margin-top: rem(56px);
                margin-bottom: rem(8px);
              }
              + .expressive-heading-02,
              + .expressive-heading-01-alt {
                margin-top: rem(8px);
              }
            }
            .expressive-heading-02,
            .expressive-heading-01-alt {
              margin-top: rem(32px);

              @include media('>=md') {
                margin-top: rem(80px);
              }
            }
          }
        }
      }
    }

main, #main-content{
  > .cmp-container > .aem-Grid>.heading:first-of-type
{
	margin-top:0 !important;
}
}
.ace-headingpage--below__image {
  .ace-headingpagehero__content {
    .ace-headingpagehero-headline {
      @include media('<lg') {
        .cmp-button {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Heading with image variation */
.heading.offer{
  /* Added this code for bug 10017 */
  .ace-heading-component {
    background: $bg-neutral-tertiary;
  }
}

.ace-heading-component {
  .ace-heading-image--fullwidth {
    @include media('>lg') {
      display: block;
      width: 100vw;
      margin-right: calc(50% - 50vw);
      margin-left: calc(50% - 50vw);
    }
  }
  .ace-heading-image {

    @include media('>=lg', '<xl') {
      margin: 0 2rem;
    }

    @include media('>=xl') {
      margin-right: rem(64px);
      margin-left: rem(64px);
    }
    .ace-heading-image {
        @include media('>=lg', '<xl') {
            margin: 0 2rem;
        }

        @include media('>=xl') {
            margin-right: rem(64px);
            margin-left: rem(64px);
        }
        .aem-Grid {
            @include media('>lg') {
            display: flex;
            align-items: center;
        }
            .aem-GridColumn {

            @include media('<md') {
               padding-right: 0;
                padding-left: 0;
            }

            @include media('>md') {
                padding-right: 0;
                padding-left: 0;
            }

            @include media('>=lg') {
                &:first-child {
                  padding-right: 0;
                  padding-left: rem(14px);
                }
                &:last-child {
                  padding-right: rem(12px);
                  padding-left: 0;
                }
            }

            .expressive-heading-02,
            .expressive-heading-01-alt {
              margin-top: rem(32px);

              @include media('>=md') {
                margin-top: rem(80px);
              }
            }
          }
        }

        &__bg {
            @include media('>lg') {
                order: 2;
            }

            @include media('<lg') {
            display: flex;
            width: 100vw !important;
            margin-right: calc(50% - 50vw) !important;
            margin-left: calc(50% - 50vw) !important;
            padding: 0;
        }

            &__cont {
                width: 100%;
                .ace-image {
                    display: flex;

                    @include media('>lg') {
                        margin: 4rem 0;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: rem(6px);

                        @include media('>lg') {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
        &__content {
            border-radius: rem(6px);

            @include media('>=md') {
              height: auto !important;
            }

            @include media('>lg') {
                margin-top: 0;
                padding: 0;
            }
            &__icon {
                width: rem(40px);

                @include media('>=md') {
                    width: rem(60px);
                }
                img {
                    width: 100%;
                    height: rem(40px);
                    object-fit: contain;

                    @include media('>=md') {
                        height: rem(60px);
                    }
                }
            }
            &__title {
                h1,
                h2,
                h3,
                h4 {
                    margin-top: rem(25px);
                    padding: 0;

                    @include expressive-heading-05;
                    margin-bottom: rem(8px);
                    height: 2rem;
                    font-weight: 500 !important;

                    @include media('>=md') {
                        @include expressive-heading-02;
                        margin-top: rem(20px);
                        height: 3rem;
                        margin-bottom: rem(32px);
                    }
                }
            }
            &__desc {
                p {
                    @include ui-body-01;
                    margin: 0;
                    line-height: rem(24px);
                }
            }
          }
        }
      }
    }
    &__content {
      margin-top: rem(-58px);
      border-radius: rem(6px);
      padding: 2rem 2rem 1.5rem;

      @include media('>=md') {
        margin-top: rem(-66px);
        padding: 3rem rem(38px);
      }

      @include media('>lg') {
        margin-top: 0;
        padding: 0;

      }
      &__icon {
        width: rem(40px);

        @include media('>=md') {
          width: rem(60px);
        }
        img {
          width: 100%;
          height: rem(40px);
          object-fit: contain;

          @include media('>=md') {
            height: rem(60px);
          }
        }
      }
      &__title {
        h1,
        h2,
        h3,
        h4 {
          margin-top: rem(25px);
          padding: 0;

          @include expressive-heading-05;
          margin-bottom: rem(8px);
          height: 2rem;
          font-weight: 500 !important;

          @include media('>=md') {
            @include expressive-heading-02;
            margin-top: rem(20px);
            height: 3rem;
            margin-bottom: rem(32px);
          }
        }
      }
      &__desc {
        p {

          @include ui-body-01;
          margin: 0;
          line-height: rem(24px);
        }
      }
    }
  }

// heading dropdown css
.ace-heading-dropdown {
  &__button {
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    border: none;
    color:white;
    background: none;
    span.icon.icon-chevron-down-24icon {
      &::before {
        color: color(neutral, white);
        font-size: rem(13.5px);
        padding: rem(8.25px) rem(5.25px);
        line-height: rem(7.5px);
        vertical-align: middle;
      }
    }
    span.icon.icon-chevron-up-24icon {
      &::before {
        color: color(neutral, white);
        font-size: rem(13.5px);
        padding: rem(8.25px) rem(5.25px);
        line-height: rem(7.5px);
        vertical-align: middle;
      }
    }
  }
  &__icon {
    display: block;
    padding-left: rem(16px);
    line-height: rem(18px);

    @include media('>=md') {
      max-width: rem(12px);
    }

    @include media('>=lg') {
      width: rem(24px);
      height: rem(24px);
      line-height: rem(3px);
    }
  }

  &__drop-content {
    // transform: translateX(90%);
    z-index: 1;
    display: none;
    min-width: rem(160px);
    max-width: rem(360px);

    &.active {
      position: relative;
      display: block;
      align-content: center;
      top: 0;
      justify-content: center;
      bottom: auto;
    }
  }

  &__list-content {
    background: #fff;
    border-radius: rem(6px);
    overflow: hidden;
    list-style: none;
    position: relative;
    width: max-content;

    // width: 100%;
    margin: 0;
    padding: rem(16px) 0;

    @include media('<md') {
        max-width: rem(344px);
      }

    &::before {
      border: rem(2px) solid $border-focus;
      bottom: 0;
      border-radius: rem(6px);
      box-shadow: 0 rem(8px) rem(16px) rgba(37, 35, 57, 10%);
      left: 0;
      content: ' ';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__list-item {

    @include ui-body-01;
    padding: rem(8px) rem(32px) rem(8px) 0;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    a {
      color: $text-body-primary;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      text-overflow: ellipsis;
      ::after {
        position: absolute;
        color: $text-body-primary;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: ' ';
      }
    }

    span.icon.icon--24.icon-check-24icon {
      width: rem(18px);
      height: rem(18px);
      padding: 0 rem(6px) 0 rem(8px);
      color: $text-brand;
      visibility: hidden;
      &::before{
        padding: rem(3.94px) rem(2.44px);
        font-size: rem(13.13px);
        line-height: rem(10.13px);
      }
      &.active {
        visibility: visible;
      }
    }
    &:hover {
      padding: rem(8px) rem(30px) rem(8px) 0;
      background-color: $text-brand;
      a {
        color: color(neutral, white);
      }
      span.icon.icon-check-24icon {
        color: color(neutral, white);
        padding: 0 rem(6px);
        &::before {
          color: color(neutral, white);
          line-height: rem(10.13px);
          font-size: rem(13.13px);
          padding: rem(3.94px) rem(2.44px);
        }
      }
    }
    &:focus {
      padding: rem(8px) rem(30px) rem(8px) 0;
      background-color: $text-brand;
      a {
        color: color(neutral, white);
      }
      span.icon.icon--24.icon-check-24icon {
        padding: 0 rem(6px);
        color: color(neutral, white);
        &::before {
          color: color(neutral, white);
          font-size: rem(13.13px);
          line-height: rem(10.13px);
          padding: rem(3.94px) rem(2.44px);
        }
      }
    }
  }
}
main, #main-content {
> .cmp-container > .aem-Grid > .heading:first-of-type {
  margin-top: 0 !important;
}
}
.ace-headingpage--below__image {
  .ace-headingpagehero__content {
    .ace-headingpagehero-headline {
      @include media('<lg') {
        .cmp-button {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Heading with image variation */
.ace-heading-component {
  .ace-heading-image--fullwidth {
    @include media('>lg') {
      display: block;
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
  .ace-heading-image {
    @include media('>=lg', '<xl') {
      margin: 0 2rem;
    }

    @include media('>=xl') {
      margin-right: rem(64px);
      margin-left: rem(64px);
    }

    .aem-Grid {
      @include media('>lg') {
        display: flex;
      }

      .aem-GridColumn {
        @include media('<md') {
          padding-right: 0;
          padding-left: 0;

        }

        @include media('>md') {
          padding-right: 0;
          padding-left: 0;
        }

        @include media('>=lg') {
          &:first-child {
            padding-right: 0;
            padding-left: rem(14px);

          }

          &:last-child {
            padding-right: rem(12px);
            padding-left: 0;

          }
        }
      }
    }

    &__bg {
      @include media('>lg') {
        order: 2;
      }

      @include media('<lg') {
        display: flex;
        width: 100vw !important;
        margin-right: calc(50% - 50vw) !important;
        margin-left: calc(50% - 50vw) !important;
        padding: 0;
      }
      &__cont {
        width: 100%;
        .ace-image {
          display: flex;
          @include media('>lg') {
            margin: 4rem 0;
          }
          img {
            width: 100%;
            height: auto;
            border-radius: rem(6px);

            @include media('>lg') {

              border-radius: 0;
            }
          }
        }
      }
    }
    &__content {
      margin-top: rem(-58px);
      border-radius: rem(6px);
      padding: 2rem 2rem 1.5rem;
      @include media('>=md') {
        margin-top: rem(-66px);
        padding: 3rem rem(38px);
      }

      @include media('>lg') {
        margin-top: rem(64px);
        margin-bottom: rem(64px);
        padding: 0;

      }
      &__icon {
        width: rem(40px);
        @include media('>=md') {
          width: rem(60px);
        }

        img {
          width: 100%;
          height: rem(40px);
          object-fit: contain;

          @include media('>=md') {
            height: rem(60px);
          }
        }
      }

      &__title {
        h1,
        h2,
        h3,
        h4 {
          margin-top: rem(25px);
          padding: 0;

          @include expressive-heading-05;
          margin-bottom: rem(8px);
          height: 2rem;
          font-weight: 500 !important;

          @include media('>=md') {
            @include expressive-heading-02;
            margin-top: rem(20px);
            height: 3rem;
            margin-bottom: rem(32px);
          }
        }
      }
      &__desc {
        p {

          @include ui-body-01;
          margin: 0;
          line-height: rem(24px);
        }
      }
    }
  }
}

.heading-drop-active {
  z-index: 4;
  overflow: visible;
}

.heading-drop-show {
  margin-bottom: 0 !important;
}

html[dir='rtl'] {
  .ace-heading-dropdown {
    &__list-item {
      padding: rem(8px) 0 rem(8px) rem(32px);
      span.icon.icon--24.icon-check-24icon {
        padding: 0 rem(8px) 0 rem(6px);
      }

      &:hover {
        padding: rem(8px) 0 rem(8px) rem(30px);
      }

      &:focus {
        padding: rem(8px) 0 rem(8px) rem(30px);
      }
    }
  }
}
main, #main-content
{
> .cmp-container > .aem-Grid > .heading:not(.heading-override):first-of-type {
  margin-top: 0 !important;
}

>.cmp-container>.aem-Grid>.heading:first-of-type {
  margin-top: 0 !important;
}
}
.ace-headingpage--below__image .ace-headingpagehero__overlay{
  @include media('<lg') {
display: none;
  }

}
.ace-heading-component{
  .ace-headingpagehero{
    .ace-headingpagehero__content{
      .cmp-button{
        display: inline-block;
      }
    }
  }
}

.callout-container{
  .ace-callout-container{
    .callout-section ul li{
       .ace-callout-list-vcard{
        .ace-image {
          justify-content: center;
          align-items: center;
          display: flex;
        }
       }
    }
  }
}
  .sharedNavigation{
      #link-navigation{
          &.link-navigation {
            .link-navigation__primaryHeader{
              position: relative !important;
            }
          }
      }
}

.ace-heading-component {
    .ace-headingpagehero__content.headingno-image {
        .ace-headingpagehero-headline {
            .expressive-subheading-01 {
                p {
                    @include ui-body-01;
                    font-style: normal;
                }
            }
        }
    }
}

.ace-headingpage--below__image {
    @include media('>=lg') {
        height: auto;
    }
    @include media('<=lg') {
      flex-wrap: wrap;
      .ace-headingpagehero__overlay {
        display: none;
        background: none;
      }
      .ace-headingpagehero__content {
        position: inherit;
        @include media('>=md', '<lg') {
          max-width: rem(582px);
          width: calc(100vw - 22%);
        }
      }
      .ace-headingpagehero-headline {
        display: block;
        position: inherit;
        transform: inherit;
        .ui-label-01 {
          color: $text-body-secondary;
          margin-top: rem(32px);
        }
        .expressive-heading-02,
        .expressive-heading-01-alt {
          color: $text-heading;
          @include expressive-heading-01;
          margin: rem(32px) 0 rem(16px) 0;
          padding: 0;
        }
        .expressive-subheading-01 {
          color: $text-body-primary;
          @include ui-body-01;
        }
        .cmp-button {
            margin-top: rem(32px);
        }
      }
    }
}
/* Added this code for bug 10017 */
.heading.offer{
  .ace-headingpagehero {
  background-color: rgb(0 0 0 / 50%);
  }
}

// Define rule to set margin for first heading on page after header
main, #main-content {
    > .cmp-container > .aem-Grid>.heading {
        &:first-child {
            &:has(.headingno-image) {
                margin-top: margin(03) !important;
                @include media('>=md') {
                    margin-top: margin(06) !important;
                }
            }
        }
    }
}
