.ace-card-editorial {
    $namespace: '.cmp-slideshow';

    #{$namespace} {
        position: relative;
        box-sizing: border-box;
        padding: 0;

        @include media('>=lg') {
            &[data-cmp-direction='left'],
            &[data-cmp-direction='right'] {
                display: flex;
                gap: rem(24px);
            }

            &[data-cmp-direction='left'] {
                flex-direction: row;
            }

            &[data-cmp-direction='right'] {
                flex-direction: row-reverse;
            }
        }

        @include media('>=md', '<=md') {
            padding: 0 rem(12px);
        }
        @include media('>=lg') {
            border: rem(1px) solid $border-decorative;
            border-radius: rem(6px);
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;
        }

        > div {
            flex-grow: 1;
            @include media('>=lg') {
                max-width: 50%;
            }
        }

        #{$namespace}__content {
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;

            #{$namespace}__item {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                transition: all 0.7s ease-in;
                width: 100%;
                height: 100%;
                line-height: 0;
                visibility: hidden;

                &--active {
                    display: block;
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }

                .ace-image {
                    height: 100%;
                    width: 100%;

                    img {
                        width: 100%;
                        height: 100%;

                        @include media('>=lg') {
                            &:not(.no-right-corners) {
                                border-radius: rem(6px);
                            }
                            &.no-right-corners {
                                border-radius: rem(6px) 0 0 rem(6px);
                            }
                        }
                        @include media('<=lg') {
                            border-top-left-radius: rem(6px);
                            border-top-right-radius: rem(6px);
                        }
                    }
                }
            }
        }

        #{$namespace}__callout-section {
            padding: rem(40px) rem(40px) rem(24px);
            display: flex;
            flex-direction: column;
            border-radius: 0 0 rem(6px) rem(6px);
            justify-content: space-between;
            position: relative;
            flex-basis: 0;

            &.single-callout {
                padding: rem(40px);
                min-height: calc(rem(400px) - rem(40px) - rem(40px));
                @include media('>=md') {
                    padding: rem(64px);
                }
                @include media('>=lg') {
                    padding: rem(32px) rem(64px);
                }
            }

            @include media('>=md') {
                min-height: calc(rem(468.5px) - rem(64px) - rem(32px));
                padding: rem(64px) rem(64px) rem(32px);
            }
            @include media('>=lg') {
                min-height: calc(rem(605px) - rem(32px) - rem(32px));
                padding: rem(32px) rem(96px) rem(32px);
            }
            @include media('<lg') {
                border-width: 0 rem(1px) rem(1px);
                border-style: solid;
                border-color: $border-decorative;
            }

            #{$namespace}__callouts {
                #{$namespace}__callout-item {
                    transition: all 0.7s ease-in;
                    display: none;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    &:only-child {
                        display: flex !important;
                    }

                    [data-cmp-hook-slideshow='link'] {
                        margin-top: rem(24px);
                        &.ui-body-01 {
                        text-decoration: underline;
                        }
                        @include media('>=md') {
                            margin-top: rem(32px);
                        }

                        &.cmp-button {
                            padding: rem(12px) rem(24px);
                        }

                        &.cmp-button:focus {
                            box-shadow: unset;
                            outline: rem(2px) solid $border-focus;
                            outline-offset: rem(2px);
                            /* Safari 11+ */
                            @media not all and (min-resolution: 0.001dpcm) {
                                @supports (-webkit-appearance: none) and
                  (stroke-color: transparent) {
                                    & {
                                        box-shadow: 0 0 0 rem(2px) rgba(255, 255, 255, 1),
                                        0 0 0 rem(4px) $border-focus;
                                        outline: unset;
                                        outline-offset: unset;
                                    }
                                }
                            }
                        }
                    }

                    a:not(.cmp-button) {
                        color: color(neutral, white);
                    }

                    #{$namespace}__callout-heading {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        gap: rem(24px);
                        @include media('>=md') {
                            gap: rem(32px);  
                        }
                        #{$namespace}__callout-title {
                            margin: 0;
                            color: $card-editorial-title !important;
                        }

                        #{$namespace}__callout-description {
                            margin: 0;
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;

                            p {
                                margin-top: 0;
                                margin-bottom: rem(8px);
                                line-height: inherit;
                                color: $card-editorial-description !important;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }

                            @include iconList;

                            & > ul:not(:first-child) {
                                margin-top: rem(32px);
                            }

                            ul {
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            #{$namespace}__actions {
                margin-top: rem(32px);
                display: flex;
                flex-direction: row;
                justify-content: right;
                align-items: center;
                padding: 0;
                gap: rem(16px);
                @include media('>=lg') {
                    margin-top: rem(32px);
                }

                #{$namespace}__action {
                    outline: none;
                    background: transparent;
                    border-radius: rem(40px);
                    width: rem(40px);
                    height: rem(40px);
                    flex: 0 0 auto;
                    display: flex;
                    place-items: center;
                    padding: 0;
                    cursor: pointer;
                    box-sizing: border-box;
                    border: rem(1px) solid;

                    html[dir='rtl'] & {
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    .icon {
                        font-size: rem(24px);
                        margin: auto;
                    }

                    .icon,
                    .icon::before {
                        color: inherit !important;
                    }

                    &:focus {
                        /* Safari 11+ */
                        @media not all and (min-resolution: 0.001dpcm) {
                            @supports (-webkit-appearance: none) and
                (stroke-color: transparent) {
                                & {
                                    box-shadow: 0 0 0 rem(2px) rgba(255, 255, 255, 1), 0 0 0 rem(4px) $border-focus !important;
                                }
                            }
                        }
                    }

                    &.disabled {
                        pointer-events: none;

                        * {
                            pointer-events: none;
                        }
                    }
                }

                #{$namespace}__action-content {
                    white-space: nowrap;
                    text-align: center;

                    html[dir='rtl'] & {
                        direction: ltr;
                    }

                    @include media('>=md') {
                        text-align: left;
                        html[dir='rtl'] & {
                            text-align: right;
                        }
                    }
                }

                hr {
                    width: 100%;
                    border: medium none;
                    display:none;
                }
            }
        }

        #{$namespace}__indicators {
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }
    }
}


@include media('>=lg') {
    .ace-card-editorial {
        .cmp-slideshow.fullWidth {
            .cmp-slideshow__content.video_content {
                width: 50%;
                display: flex;
                align-items: center;
            }

            .cmp-slideshow__content.video_content .cmp-slideshow__item {
                height: 0;
                padding-top: 56.25%;

                .video-viewer-component {
                    position: absolute;
                    top: 0;
                }
            }
        }

        .cmp-slideshow__callout-section {
            min-height: rem(200px);
        }
    }
}

@include media('<lg') {
    .ace-card-editorial {
        .cmp-slideshow.fullWidth {
            .cmp-slideshow__content.video_content {
                .video-viewer-component {
                    height: auto !important;
                }
            }
        }
    }
}


@include media('>=lg') {
    .ace-card-editorial {
        .cmp-slideshow.fullWidth {
            .cmp-slideshow__content.video_content {
                width: 100%;
                max-width: rem(606px);
            }
            &[data-cmp-direction="right"] {
                .cmp-slideshow{
                    &__content {
                        .cmp-slideshow__item {
                            .ace-image {
                                img{
                                    border-radius: 0 0.375rem 0.375rem 0; 
                                }
                            }
                        }
                    }
                }
            }
        }

        .cmp-slideshow__callout-section {
            min-height: rem(200px);
        }
    }
}
.ace-card-editorial {
    .cmp-slideshow.fullWidth {
        .cmp-slideshow__content.video_content {
            display: flex;
            align-items: center;
        }

        .cmp-slideshow__content.video_content .cmp-slideshow__item {
            height: 0;
            padding-top: 100%;

            .video-viewer-component {
                position: absolute;
                top: 0;
            }
        }
       
    }
}


