.ace-carousel-component-v2 {
  .ace-carousel {
    @include media('>=lg') {
        overflow: hidden;
        margin-right: rem(12px);
      }
    &:has(.onLastPage) {
      @include media('>=lg') {
          margin-left: rem(12px);
          margin-right: 0;
      }
    }
      &__content {
        margin-right: rem(8px) !important;
        overflow-x: auto;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        @include media('>=md') {
          margin-right: rem(12px) !important;
        }
        @include media('>=lg') {
          overflow: visible;
          scroll-snap-type: none;
        }
        .ace-carousel__item{
          padding: 0 rem(12px);
          &.hide {
              display: inline-block;
              visibility: visible;
              opacity: 1;
              position: relative;
              @include media('>=lg') {
                  display: none;
                }
            }
          &.hide.carousel-display {
              .cmp-teaser__image .ace-image {
                  @include media('<lg') {
                      aspect-ratio: 2/1;
                    }
              }
          }

        @include media ('<=md') {
          padding: 0 0.5rem;
        }
      }

      .ace-carousel__item {
        .ace-callout-component.ace-callout-component-default {
          margin-bottom: 0 !important;

          .cmp-teaser__image .ace-image {
            img {
              height: 100%;
            }
          }
        }
      }
    }

    &__actions {
      &.show {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;          // flex-start for Left Alignment - sofitel  // space-between - novotel center align
        margin-top: rem(16px) !important;

        @include media('>=md') {
          margin-top: rem(24px) !important;
        }
        
        &:after {
          display: none;
        }

      }
    }

    .carousel-display {
      flex: 0 0 90%;
      scroll-snap-align: start;

      @include media('>=md') {
        flex: 0 0 45%;

        &:nth-of-type(2n + 1) {
          scroll-snap-align: start;
        }

        scroll-snap-align: none;
      }

      @include media('>=lg') {
        flex: 0 0 23%;
        scroll-snap-align: none;
      }
    }

    .carousel-display-2 {
      flex: 0 0 45%;
    }

    .carousel-display-3 {
      flex: 0 0 30%;
    }
  }
}

.ace-carousel-component-v2 .ace-carousel .ace-carousel__item.odd-filter-callout {
  scroll-snap-align: start;
}

.ace-carousel-component-v2 .ace-carousel .ace-carousel__item.even-filter-callout {
  scroll-snap-align: none;
}

.ace-carousel .ace-carousel__actions+.ace-carousel__content {
  @include media ('<=md') {
    margin-left: 0;
  }

  &.onLastPage {
    margin-left: rem(12px);
    margin-right: 0 !important;

    @include media ('<lg', '>=md') {
      margin-left:  rem(12px);
      margin-right:  rem(5px) !important;
    }

    @include media ('<md') {
      margin-left:  rem(8px);
      margin-right: 0 !important;
    }
  }
}

.filter-hide {
  display: none !important;
}
