.cmp-back-link {
    text-decoration: none !important;
    color: $color-link;
    padding: rem(8px) 0;
    display: inline-block;
    margin-left: 0;
    &--text {
      display: inline-block;
      text-decoration: none;
    }
    &:hover &--text {
      text-decoration: underline;
      color: $color-link-hover;
    }
    &:hover &--icon{
      &::before{
        color: $color-link-hover;
      }
    }
    &:focus &--icon{
      &::before{
        color: color(neutral, white) ;
      }
    }
    &--icon {
     &::before {
        content: "\e91e";
        @include icomoon;
        color: $color-link;
        font-size: 1.125rem;
        position: relative;
        top: rem(2px);
        width: rem(13px);
       height: rem(10px);
       margin-right: rem(4px);
      }
    }
  &:focus &--text{
    color: color(neutral, white);
    text-decoration: none;
  }
  &:focus {
  background-color: color(royal-blue, 500);
  outline: none;
}
  }
.ace-default {
  background-color: $bg-brand-primary;
  width: 100%;
  padding: rem(10px);
.cmp-back-link--icon {
   &--inverse {
        &::before {
           content: "\e91e";
           @include icomoon;
           color: color(neutral, white) ;
           font-size: 1.125rem;
           position: relative;
           top: rem(2px);
           width: rem(13px);
          height: rem(10px);
          margin-right: rem(4px);
         }
       }
      }
}
.cmp-back-link--text--inverse{
  color: color(neutral, white) ;
  &:hover {
   text-decoration: underline;
  }
}
.cmp-back-link--inverse{
  &:focus {
    background-color:color(neutral, white) ;
    .cmp-back-link--text--inverse{
      color: $color-link;
      text-decoration: none;
    }
    .cmp-back-link--icon--inverse{
      &::before{
        color: $color-link;
      }
    }
  }
}
  .cmp-imageLightbox--backbutton{
      button:hover{
          text-decoration: none;
          .cmp-button__text{
          text-decoration: underline;
          }
      }
  }
  html[dir="rtl"] {
    .ace-backbutton {
      .cmp-back-link--icon {
        &::before {
          content: "\e91f";
        }
      }
    }
  }