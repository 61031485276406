$namespace: ".ace-language-selector";

#{$namespace} {
  position: relative;
  z-index: 115;
  #{$namespace}__button {
    button {
      background-color: transparent;
      border: unset;
      cursor: pointer;
      color: color(neutral-black, 900);
      &:focus,
      &:focus-visible {
        outline: rem(2px) solid $border-focus;
      }
    }
  }

  #{$namespace}__modal {
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
    display: none;
    z-index: 10;
    &.show {
      display: flex;
    }
    #{$namespace}__container {
      position: fixed;
      inset: 0;
      padding: padding(04);
      background: color(neutral, white);
      @include media(">=md") {
        inset: auto;
        padding: padding(05);
        width: rem(640px);
        box-sizing: border-box;
        border-radius: rem(6px);
      }
      @include media(">=md", "<lg") {
        width: calc(100vw - rem(64px));
      }
      // header block styles
      #{$namespace}__header {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        #{$namespace}__close--button {
          margin-bottom: 1rem;
          background-color: transparent;
          border: unset;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:focus,
          &:focus-visible {
            outline: rem(2px) solid $border-focus;
          }
          .icon {
            font-size: rem(24px);
          }
        }
      }
      // // content block styles
      #{$namespace}__content {
        & > p.ui-heading-01 {
          margin: 0 0 1rem 0;
          color: color(neutral-black, 900);
        }

        #{$namespace}__dropdown {
          @include ui-body-01;
          height: rem(48px);
          background: color(neutral, white);
          border: rem(1px) solid color(neutral-black, 500);
          border-radius: rem(6px);
          display: flex;
          width: 100%;
          margin: rem(8px) 0 rem(16px) 0;
          padding: padding(03) padding(04);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          color: color(neutral, 500);
          text-transform: capitalize;
          &--hidden {
            display: none;
            & + .icon-chevron-down-24icon {
              display: none;
            }
          }
          &:focus + .icon-chevron-down-24icon {
            transform: rotate(180deg);
          }
          option {
            @include ui-body-01;
            color: color(neutral-black, 800);
            &[disabled],
            &:disabled {
              color: color(neutral-black, 500);
            }
          }
        }
        .ui-body-01 {
          color: color(neutral-black, 800);
          margin: rem(0px);
        }
        fieldset {
          padding: 0;
          margin: 0;
          position: relative;
          border: 0;
          legend {
            margin-bottom: rem(16px);
          }
          & > div {
            position: relative;
          }
        }
        .icon-chevron-down-24icon {
          font-size: rem(24px);
          position: absolute;
          right: rem(8px);
          bottom: rem(12px);
          pointer-events: none;
          html[dir="rtl"] & {
            left: rem(8px);
            right: auto;
          }
        }
      }
      // footer block styles
      #{$namespace}__footer {
        position: fixed;
        top: auto;
        margin: 1rem;
        left: 0;
        right: 0;
        bottom: 0;
        @include media(">=md") {
          position: relative;
          justify-content: end;
          margin: 0;
        }
        .ace-button {
          @include media(">=md") {
            justify-content: flex-end;
          }
          button {
            width: 100%;
            border: unset;
            @include media(">=md") {
              margin-top: rem(32px);
              width: auto;
            }
          }
        }
      }
    }
  }
}