$flaglinkvisited:#706B9D;
.country-region {
  @include media('<md') {
    width: 100%;
  }
  @include media('>=md','<lg') {
    width: 80%;
  }
  @include media('>md','<lg') {
    padding-left: rem(2px);
    width: 83%;
  }
  margin: 0 auto;
  &__list {
    display: flex;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    gap: 0 rem(24px);
    @include media('<lg') {
      gap: 0 rem(22px);
    }
    margin: 0;
    &-item {
      display: flex;
      flex-basis: 23.5%;
      min-width: 2rem;
      color: color(neutral-black, 900);
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      margin-top: rem(24px);
      @include media('<lg') {
        flex-basis: 48%;
      }

      @include media('<md') {
        flex-basis: 100%;
        margin-top: rem(16px);
      }

      a {
        color: color(neutral-black, 900);
      }
    }
  }

  svg {
    width: rem(24px);
    height: rem(24px);
    display: flex;
    border-radius: rem(4px);
    margin-right: rem(16px);
  }

  &__name {
    display: flex;
  }

  &__title {
    width: 49%;
    margin-top: rem(64px);
    margin-bottom: rem(24px);
    color: color(neutral-black, 800);

    @include media('<lg') {
      width: 48%;
    }

    @include media('<md') {
      width: 100%;
      margin-top: rem(48px);
      margin-bottom: rem(16px);
    }
  }
}

.flag-accor-logo {
  margin: 0 auto;
  width: 100%;
  text-align: center;

  @include media('<lg') {
    width: calc(100vw - 26%);
  }

  @include media('<md') {
    width: 100%;
  }

  h1,h2,h3 {
    margin-top: rem(32px);
    color: color(neutral-black, 900);

    @include media('<md') {
      margin-top: rem(24px);
    }
  }

  img {
    width: rem(262px);

    @include media('<lg') {
      width: rem(214px);
    }

    @include media('<md') {
      width: rem(174px);
    }
  }
}

.country-region {
  &__list {
    &-item {
      a {
        &:hover{
          color: $color-link-hover;
          text-decoration: underline;
        }
      &:visited {
          color: $text-link-visited;
        }
      }
    }
  }
}