@use 'sass:math';

// Typography mixin
@mixin typography($font, $weight: null, $size: null, $line-height: null, $letter-spacing: null, $uppercase: null) {
  font-weight: $weight;
  font-family: $font;
  font-size: rem($size);
  line-height: rem($line-height);
  letter-spacing: $letter-spacing;
  text-transform: $uppercase;
}

// Text alignment mixins
@mixin text-alignment($break-value) {
  .text#{$break-value} {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }

    &-justify {
      text-align: justify;
    }
  }
}

@mixin icomoon {
  font-family: 'icomoon', serif !important;
  speak: never;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Typography heading
// Usage  $font,$weight , $size, $line-height, $letter-spacing, $uppercase
// weight regular:400, medium:500, semibold:600
@mixin expressive-heading-01 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 30px, 44px, null, null);
  }

  @include media('>=md') {
    @include typography($font-family-montserrat, 700, 48px, 56px, rem(-0.5px), null);
  }

  @include media('>=lg') {
    @include typography($font-family-montserrat, 700, 72px, 80px, rem(-1px), null);
  }
}

@mixin expressive-heading-01-alt {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 30px, 44px, null, null);
  }

  @include media('>=md') {
    @include typography($font-family-montserrat, 700, 48px, 56px, rem(-0.5px), null);
  }

  @include media('>=lg') {
    @include typography($font-family-montserrat, 700, 64px, 76px, rem(-0.5px), null);
  }
}

@mixin expressive-heading-02 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 30px, 44px, null, null);
  }

  @include media('>=md') {
    @include typography($font-family-montserrat, 700, 36px, 48px, null, null);
  }

  @include media('>=lg') {
    @include typography($font-family-montserrat, 700, 48px, 56px, rem(-0.5px), null);
  }
}

@mixin expressive-heading-03 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 24px, 36px, null, null);
  }

  @include media('>=md') {
    @include typography($font-family-montserrat, 700, 30px, 44px, null, null);
  }

  @include media('>=lg') {
    @include typography($font-family-montserrat, 700, 36px, 48px, null, null);
  }
}

@mixin expressive-heading-04 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 24px, 36px, null, null);
  }

  @include media('>=lg') {
    @include typography($font-family-montserrat, 700, 30px, 44px, null, null);
  }
}

@mixin expressive-heading-05 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 24px, 36px, null, null);
  }
}

@mixin expressive-heading-06 {
  @include media('>=sm') {
    @include typography($font-family-montserrat, 700, 20px, 28px, null, null);
  }
}

// Typography Subheading
@mixin expressive-subheading-01 {
  @include media('>=sm') {
    @include typography($font-family-unna, 400, 18px, 24px, null, null);
  }

  @include media('>=lg') {
    @include typography($font-family-unna, 400, 24px, 32px, null, null);
  }
}

@mixin expressive-subheading-02 {
  @include media('>=sm') {
    @include typography($font-family-unna, 400, 18px, 24px, null, null);
  }
}

@mixin ui-heading-01 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 700, 24px, 32px, null, null);
  }
}

@mixin ui-heading-02 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 500, 20px, 28px, null, null);
  }
}

@mixin ui-heading-03 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 500, 18px, 24px, null, null);
  }
}

// Typography body text
@mixin ui-body-01-strong {
  @include media('>=sm') {
    @include typography($font-family-roboto, 500, 16px, 24px, null, null);
  }
}

@mixin ui-body-01 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 400, 16px, 24px, null, null);
  }
}

@mixin ui-body-02-strong {
  @include media('>=sm') {
    @include typography($font-family-roboto, 500, 14px, 20px, null, null);
  }
}

@mixin ui-body-02 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 400, 14px, 20px, null, null);
  }
}

// Typography Captions
@mixin ui-caption-01 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 400, 12px, 16px, null, null);
  }
}

// Typography Label
@mixin ui-label-01 {
  @include media('>=sm') {
    @include typography($font-family-roboto, 500, 12px, 16px, rem(1px), uppercase);
  }
}

// Grid Mixins

/* Generates all the rules for the grid columns up to the given amount of column */

@mixin generate-columns($columnTotal, $breakPoint, $column: 1) {
  @if $column <=$columnTotal {
    > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--#{$column} {
      float: left;
      clear: none;
      width: math.div($column * 100%, $columnTotal);
    }

    @include generate-columns($columnTotal, $breakPoint, ($column + 1));
  }
}

/* Generates all the rules for the grid column offset up to the given amount of column */

@mixin generate-offsets($columnTotal, $breakPoint, $column: 0) {
  @if $column <=$columnTotal {
    > .aem-GridColumn.aem-GridColumn--offset--#{$breakPoint}--#{$column} {
      margin-left: math.div($column * 100%, $columnTotal);
    }

    @include generate-offsets($columnTotal, $breakPoint, ($column + 1));
  }
}

/* Generates all the rules for the grid and columns for the given break point and total of columns */

@mixin generate-grid-columns($breakPoint, $columnTotal) {
  @if $columnTotal>0 {
    &.aem-Grid--#{$columnTotal} {
      @include generate-columns($columnTotal, $breakPoint);
      @include generate-offsets($columnTotal, $breakPoint);
    }

    &.aem-Grid--#{$breakPoint}--#{$columnTotal} {
      @include generate-columns($columnTotal, $breakPoint);
      @include generate-offsets($columnTotal, $breakPoint);
    }
  }
}

/* Generates all the rules for the grids and columns */

@mixin grid-loop($breakPoint, $columnTotal, $column: 1) {
  @if $column <=$columnTotal {
    @include generate-grid-columns($breakPoint, $column);
    @include grid-loop($breakPoint, $columnTotal, ($column + 1));
  }
}

/* API function to be called to generate a grid config */

@mixin generate-grid($breakPoint, $columnTotal) {
  @include grid-loop($breakPoint, $columnTotal);

  > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--newline {
    /* newline behavior */
    display: block;
    clear: both !important;
  }

  > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--none {
    /* none behavior */
    display: block;
    float: left; // Enforce the float positioning to maintain the column height and position relative to previous columns
    clear: none !important; // Prevent the clear:both effect of another breakpoint new line
  }
}

@mixin generate-grid-hide($breakPoint) {
  > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--hide {
    /* hide behavior */
    display: none;
  }
}

// Mixin for Links
@mixin link-default {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }

  &:focus-visible {
    color: $color-link-hover;
    outline: rem(1px) solid $border-brand;
  }

  &:disabled {
    color: $color-link-disabled;
    background-color: color(neutral, white);
  }
}

// Use this mixin for the full width fluid container

@mixin fluid($fluid-type) {
  @if ($fluid-type==parent) {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }

  @else if($fluid-type==child) {
    @include media('>sm') {
      margin-right: rem(8px);
      margin-left: rem(8px);
    }

    @include media('>md') {
      margin-right: rem(20px);
      margin-left: rem(20px);
    }

    @include media('>xl') {
      max-width: rem(1310px);
      margin-right: auto;
      margin-left: auto;
    }
  }

   @else {
    @error 'Unknown fluid type --> #{$fluid-type}.';
  }
}

// padding and margin spacing mixins
@mixin padding-00 {
  @include media('>sm') {
    padding-top: padding(00);
  }
}

@mixin padding-01 {
  @include media('>=sm') {
    padding-top: padding(01);
  }
}

@mixin padding-02 {
  @include media('>=sm') {
    padding-top: padding(02);
  }
}

@mixin padding-03 {
  @include media('>=sm') {
    padding-top: padding(03);
  }
}

@mixin padding-04 {
  @include media('>=sm') {
    padding-top: padding(04);
  }
}

@mixin padding-05 {
  @include media('>=sm') {
    padding-top: padding(04);
  }
  @include media('>=md') {
    padding-top: padding(05);
  }
}

@mixin padding-06 {
  @include media('>=sm') {
    padding-top: padding(05);
  }
  @include media('>=md') {
    padding-top: padding(06);
  }
}

@mixin padding-07 {
  @include media('>=sm') {
    padding-top: padding(06);
  }
  @include media('>=md') {
    padding-top: padding(07);
  }
}

@mixin padding-08 {
  @include media('>=sm') {
    padding-top: padding(06);
  }
  @include media('>=md') {
    padding-top: padding(08);
  }
}

@mixin padding-09 {
  @include media('>=sm') {
    padding-top: padding(07);
  }
  @include media('>=md') {
    padding-top: padding(08);
  }
  @include media('>=lg') {
    padding-top: padding(09);
  }
}

// margin mixins starts
@mixin margin-00 {
  @include media('>=sm') {
    margin-top: margin(00);
  }
}

@mixin margin-01 {
  @include media('>=sm') {
    margin-top: margin(01);
  }
}

@mixin margin-02 {
  @include media('>=sm') {
    margin-top: margin(02);
  }
}

@mixin margin-03 {
  @include media('>=sm') {
    margin-top: margin(02);
  }
  @include media('>=md') {
    margin-top: margin(03);
  }
}

@mixin margin-04 {
  @include media('>=sm') {
    margin-top: margin(03);
  }
  @include media('>=md') {
    margin-top: margin(04);
  }
}

@mixin margin-05 {
  @include media('>=sm') {
    margin-top: margin(04);
  }

  @include media('>=md') {
    margin-top: margin(05);
  }
}

@mixin margin-06 {
  @include media('>=sm') {
    margin-top: margin(05);
  }

  @include media('>=md') {
    margin-top: margin(06);
  }
}

@mixin margin-07 {
  @include media('>=sm') {
    margin-top: margin(05);
  }

  @include media('>=md') {
    margin-top: margin(06);
  }

  @include media('>=lg') {
    margin-top: margin(07);
  }
}

@mixin margin-08 {
  @include media('>=sm') {
    margin-top: margin(06);
  }

  @include media('>=md') {
    margin-top: margin(07);
  }

  @include media('>=lg') {
    margin-top: margin(08);
  }
}

// Mixin for the Icon list
// use only for call-out side by side, paragraph & editorial paragraph component.
@mixin iconList {
  ul,
   ol {
    padding-left: rem(40px);
    margin-top: rem(24px);
    list-style: none;

    li {
      position: relative;
      margin-bottom: rem(24px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    li::before {
      color: $text-accent;
      content: '\e97a';

      @include icomoon;
      display: inline-block;
      font-weight: 700;
      width: rem(24px);
      height: rem(24px);
      margin-left: rem(-40px);
      position: absolute;
      font-size: rem(24px);
    }
  }
}

:any-link,
button {
  &:focus {
    box-sizing: border-box;
    outline: rem(2px) solid #3470df;
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin input-overide {
  padding: 1rem 2rem 1rem 1rem;
  background-color: color(neutral, white);
  border: rem(1px) solid color(neutral, 500) !important;
  border-radius: rem(6px) !important;
  height: rem(56px) !important;
  box-shadow: unset !important;

  @include ui-body-01;
  color: color(neutral-black, 600);

  @include media('<md') {
    padding: rem(12px) rem(16px);
  }

  &:focus {
    border: rem(1px) solid $border-focus !important;
    outline: 0 !important;
    box-shadow: 0 0 0 rem(1px) #3470df !important;
  }
}
