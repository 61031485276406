//Menu
.ace-menuheader {
    display: flex;
    flex-direction: row;
    height: rem(90px);

    @include media('<lg') {
        height: 100vh;

    }

    .mainLogo {
        height: 100%;
        width: 7rem;
    }


    .link-navigation_nav_menu {
        align-self: center;
        height: rem(90px);
        align-items: center;
        display: flex;

        @include media('<lg') {
            height: 100%;
            width: 100vw;
        }

        .link-navigation_nav_menu-content {
            height: 100%;

            @include media('<lg') {
                width: 100%;
            }
        }

        .link-navigation_nav_menu-list {
            list-style-type: none;
            height: 100%;
            margin-top: rem(0px);
            margin-bottom: rem(0px);

            .link-navigation__menu {
                height: 100%;
                align-items: center;
                display: flex;

                @include media('<lg') {
                    height: auto;
                    width: 100%
                }

                .link-navigation__menu__link {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    :hover {
                        @include media('<lg') {
                            background: $color-background;
                            box-shadow: none;
                            color: $color-link !important;
                            outline: 0;
                            border-bottom: none;
                        }
                    }

                    :focus {
                        @include media('<lg') {
                            outline: colors(royal-blue, 500) solid rem(2px);
                            outline-offset: rem(-2px);
                        }
                    }
                }
            }
        }

        .link-navigation_nav_menu-list {
            display: flex;
            flex-direction: row;
            color: $text-brand;
            font-family: $font-family-roboto;
            font-size: rem(16px);
            font-style: normal;
            font-weight: 500;
            line-height: rem(24px);
            gap: rem(24px);
            padding: 0;

            @include media('<lg') {
                flex-direction: column;
                gap: 0;
            }

            .link-navigation__menu {
                button.link-navigation__menu__link, a.link-navigation__menu__link {
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    border-bottom: rem(4px) solid transparent;
                    padding: 0;
                    height: 100%;

                    &:hover {
                        border-color: color(stratos-blue, 1000);
                        text-decoration: none;
                        color: $text-brand;
                    }

                    @include media('<lg') {
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding: rem(12px) rem(16px);
                    }
                }

                @include media('>=lg') {
                    .link-navigation__menu__link:hover {
                        border-bottom: rem(4px) solid $border-brand-hover;
                        text-decoration: none;
                    }

                    .link-navigation__menu__link:active {
                        border-bottom: rem(4px) solid color(stratos-blue, 1000);
                    }

                    .link-navigation__menu__link:focus {
                        border-bottom: rem(4px) solid color(stratos-blue, 1000);
                    }

                }

                .link-navigation__menu__link {
                    @include media('>=lg') {
                        height: 100%;
                        align-content: center;
                        display: flex;
                        flex-wrap: wrap;
                        border-bottom: rem(4px) solid transparent;
                        box-sizing: border-box;
                        white-space: nowrap;
                    }

                    color: color(neutral-black, 900);
                }
            }

        }
    }

    .icon-chevron-right-24icon::before {
        color: $text-brand !important;
    }

    .link-navigation__sr-only {
        font-weight: 400;
        @include media('<lg') {
            font-weight: 500;
            font-family: $font-family-roboto;
            font-size: rem(18px);
            font-style: normal;
            line-height: rem(24px);
        }
    }

    .icon-chevron-right-24icon {
        display: none;

        @include media('<lg') {
            display: block;
        }
    }
}

//Logo
.ace-logoheader1 {
    @include media('<lg') {
        align-self: center;

        .ace-logo {
            padding-right: rem(12px);
        }
    }

    img {
        display:flex;
        max-width: none;
        object-fit: contain;
    }
}

.ace-logoheader2 {
    @include media('<lg') {
        border-left: rem(1px) solid #dadadd;

        .ace-logo {
            padding-left: rem(12px);
        }
    }

    img {
        display:flex;
        height: rem(58px);
        width: auto;
        max-width: none;
        object-fit: contain;

        @include media('>=lg') {
            height: rem(68px);
        }
    }
}

// Header Navigation

@include media('>=lg') {
    .ace-header-navigation__nav {
        display: contents;
    }
}


