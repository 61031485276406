.ace-callout-container-filters {
  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(48px);
    position: relative;
  }

  .ace-filter-dropdownbutton {
    width: 100%;
    position: unset;

    .ace-filter-dropdownbutton__toggle {
      @include ui-body-01;
      width: 100%;
      height: rem(56px);
      padding: rem(16px) rem(16px) rem(16px) rem(0px);

      @include media(">=md") {
        height: rem(72px);
        padding: rem(24px) rem(24px) rem(24px) rem(0px);
      }

      @include media(">=lg") {
        height: rem(72px);
        padding: rem(24px) rem(24px) rem(24px) rem(0px);
      }

      align-items: center;
      color: $color-link;
      background: color(neutral, white);
      border: none;
      text-transform: capitalize;
      border-bottom: rem(1px) solid $border-decorative;

      .ace-filter-dropdownbutton__counter {
        margin-left: rem(4px);

        html[dir="rtl"] & {
          margin-left: 0;
          margin-right: rem(4px);
        }
      }

      &.active {
        .icon-chevron-down-24icon {
          transform: rotate(-180deg);
        }
      }

      .icon {
        color: $color-link;

        &::before {
          color: $color-link !important;
        }
      }

      .arrow {
        display: none;

        @include media(">=md") {
          display: block;
        }
      }

      .mob {
        display: block;

        @include media(">=md") {
          display: none;
        }
      }
    }

    .btn {
      cursor: pointer;
      display: flex;
      margin-top: 0;
      white-space: nowrap;
    }

    .ace-filter-dropdownbutton__menu {
      &.active {
        display: block;
      }

      padding: rem(16px) rem(0px) rem(16px) rem(16px);
      top: 0;

      @include media("<md") {
        padding: rem(16px) rem(16px) rem(16px) rem(16px);
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 999;
        height: 100%;
        position: fixed;
      }

      @include media(">=md") {
        padding: rem(0px) rem(0px) rem(24px) rem(0px);
        top: 100%;
        position: relative;
      }

      @include media(">=lg") {
        padding: rem(0px) rem(0px) rem(24px) rem(0px);
        top: 100%;
        position: relative;
      }

      @include ui-body-01;
      align-items: flex-start;
      background: color(neutral, white);
      display: none;
      justify-content: space-between;
      left: 0;
      right: 0;
      text-transform: capitalize;

      ul {
        flex-wrap: wrap;
        margin: 0;
        width: 100%;
        padding-left: 0;
      }

      .ace-seperatorLine {
        display: none;
        border-top: rem(1px) solid $border-decorative;

        @include media(">=md") {
          display: block;
        }
      }

      .clear-filters-button.hidden {
        display: none;
      }

      .ace-filterText {
        color: $text-heading;
        display: block;
        padding-bottom: rem(32px);

        @include media(">=md") {
          display: none;
        }
      }
    }

    .ace-filter-dropdownbutton__icon {
      display: flex;
      width: 100%;
      cursor: pointer;

      a {
        background: transparent;
        border: none;
        margin-left: auto;

        span {
          &:before {
            width: rem(15.5px);
            height: rem(15.5px);
            margin-right: rem(20.5px);
          }
        }
      }

      @include media(">=md") {
        display: none;
      }
    }
  }

  .cmp-modal__openButton {
    width: auto !important;
    background-color: color(neutral, white);
    border-color: color(brand, 900) !important;
    box-sizing: border-box;
    color: color(brand, 900);
    text-decoration: none;
    margin-top: rem(24px);

    &:hover {
      .cmp-button__text {
        text-decoration: underline;
      }
    }

    &:disabled {
      background-color: color(neutral, 100);
    }
  }

  .cmp-button.inverse {
    @include ui-body-01;
    border: rem(1px) solid transparent;
    color: $color-link;
    text-decoration-line: underline;
    padding: 0;
  }

  .ace-filter-dropdownbutton__menu ul li {
    height: rem(18px);
    align-items: center;
    flex: 0 0 100%;
    list-style: none;
    margin: 0 0 rem(24px);
    position: relative;

    @include media(">=md") {
      flex: 0 0 33%;
    }

    @include media(">=lg") {
      flex: 0 0 25%;
    }
  }

  &__link {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__filter-counter {
    @include ui-body-01;
    white-space: nowrap;
    color: $text-body-secondary;
    padding: rem(16px) rem(0px) rem(16px) rem(16px);
    position: absolute;
    top: 0;
    right: 0;

    @include media(">=md") {
      padding: rem(24px) rem(0px) rem(24px) rem(24px);
    }

    @include media(">=lg") {
      padding: rem(24px) rem(0px) rem(24px) rem(24px);
    }
  }

  &__item {
    width: calc(50% - rem(12px));
    margin-bottom: 4rem;
    padding-top: 0 !important;
    position: relative;
    display: flex;
    flex-direction: column;

    h3 {
      text-transform: capitalize;
    }

    &:focus {
      background-color: color (neutral, white);

      span,
      h3 {
        color: color(brand, 900);
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      transition: transform 0.7s;
    }

    &:hover {
      text-decoration: none;
      background-color: white;

      img {
        transform: scale(1.1);
      }
    }

    @include media("<md") {
      margin-bottom: rem(48px);
      width: 100%;
    }

    &:nth-child(odd) {
      margin-right: rem(24px);

      @include media("<=md") {
        margin-right: 0;
      }
    }
  }

  @include media("<md") {
    margin-top: rem(48px);

    .ace-filter-dropdownbutton__menu ul {
      flex-direction: column;
    }

    .ace-filter-dropdownbutton__menu.show {
      flex-direction: column;
    }
  }

  .cmp-button--primary {
    @include ui-body-01;
    width: 100%;
    margin: rem(96px) rem(16px) rem(48px) 0;

    @include media(">=md") {
      display: none;
    }
  }
}

.shadow__bottom {
  box-shadow: rem(0px) rem(0px) rem(0px) rem(0px) rgba(0, 0, 0, 0.1);
}

.ace-filter-form {
  display: flex;
  padding: 0;

  @include media(">=md") {
    padding-top: rem(24px);
  }

  &_outercheck {
    color: $text-body-primary;
  }

  &__checkbox {
    height: rem(18px);
    width: rem(18px);
    position: absolute;
    left: 0;
    top: 0;
    margin: rem(3px) 0 0 0;
    padding: 0;

    @include media(">=md") {
      margin: rem(2px) 0 0 0;
    }
  }

  &__checkbox+label {
    display: flex;
    align-items: center;

    &::before {
      line-height: rem(18px);
      text-align: center;
      content: "";
      margin-right: rem(8px);
      border-radius: rem(0px);
      height: rem(18px);
      width: rem(18px);
      display: block;
      box-sizing: border-box;
    }
  }
}

.cmp-ace-offer__filter {
  @include media(">=md") {
    padding: 0 rem(12px) 0 rem(12px);
  }

  @include media(">=lg") {
    padding: 0 rem(12px);
  }
}

.filter-li-hide {
  display: none;
}

@include media("<md") {
  body {
    &.disable-scroll-callout-container {
      overflow-y: hidden !important;
      height: 100vh;
      position: relative;
    }
  }

  .fixedbyModal,
  .page.basicpage.disable-scroll-callout-container {
    position: fixed;
    left: 0;
    right: 0;
  }
}

$namespace: ".ace-callout-container-filters";

#{$namespace}__tab-nav {
  display: flex;
  place-items: center;
  width: 100%;

  #{$namespace}__tab-list {
    list-style: none;
    padding: 0;
    display: none;
    margin: rem(24px) auto rem(40px);

    @include media(">=md") {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include media(">=lg") {
      margin: rem(24px) auto rem(62px);
    }

    #{$namespace}__tab-item {
      padding: 0;
      margin: 0;
      height: rem(48px);

      #{$namespace}__tab-button {
        display: flex;
        place-items: center;
        padding: rem(12px) rem(24px);
        background: transparent;
        border: 0;
        position: relative;
        color: $text-heading;
        cursor: pointer;

        & * {
          pointer-events: none;
        }

        #{$namespace}__tab-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }

        &:disabled {
          color: color(neutral, 500);
        }

        &::after {
          content: "";
          height: rem(1px);
          background-color: $bg-disabled-primary;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &[aria-current="true"]::after {
          background-color: $bg-accent-primary;
          height: rem(4px);
        }

        &:hover::after {
          background-color: $bg-accent-primary-hover;
          height: rem(4px);
        }

        &:focus {
          outline: rem(2px) solid $border-focus;
          position: relative;
          z-index: 6;
        }

        #{$namespace}__tab-counter {
          margin-left: rem(4px);
          color: $text-body-secondary;
        }
      }
    }
  }

  #{$namespace}__tab-dropdown {
    @include media(">=md") {
      display: none;
    }

    position: relative;
    width: 100%;
    margin: 0 rem(8px);

    select {
      width: 100%;
      margin: rem(32px) auto rem(24px);
      background: color(neutral, white);
      border: rem(1px) solid $border-interactive;
      color: $text-body-primary;
      border-radius: rem(4px);
      height: rem(48px);
      padding: rem(12px) rem(16px);
      appearance: none;

      option {
        color: $text-body-primary;
        line-height: inherit;
      }

      &:focus+span {
        transform: rotate(180deg);
      }

      &:focus,
      &:focus-within,
      &:focus-visible {
        border-radius: rem(6px);
        border: rem(1px) solid $border-focus;
        outline: rem(1px) solid $border-focus;
      }
    }

    .icon {
      pointer-events: none;
      font-size: 1.5rem;
      position: absolute;
      bottom: rem(36px);
      right: rem(16px);

      html[dir="rtl"] & {
        left: 1rem;
        right: auto;
      }
    }
  }
}

.ace-callout-container-filters__tab-nav {
  position: relative;

  button.icon {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    border-radius: 50%;
    height: rem(40px);
    width: rem(40px);
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border-width: rem(1px);

    &:first-child {
      left: 0;
      right: unset;
    }

    span{
      &.left {
        left: 0;
      }

      &.right {
        right: 0;
        justify-content: flex-end;

      }
      &::before {
        color: black !important;
      }
    }


  }

  .callout-container-api .ace-callout-container-filters__tab-list {
    justify-content: left;
    margin: rem(20px) 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include media ('<md') {
        display: none !important;
    }

    &::-webkit-scrollbar {

      display: none;

    }
  }
}
