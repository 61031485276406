body,
html {
  margin: 0;
}
.btn {
  display: inline-block;
  margin-top: 1rem;
  padding: rem(12px) rem(18px);
  cursor: pointer;
}
header {
  .modal-opened &,
  .lightbox-opened & {
    display: none;
  }
}

@include media('<=lg') {
  body.sub-menu .language-selector {
    display: none !important;
  }
}

.ace-header {
  display: flex;
  height: $header-height-size;
  align-items: center;
  position: relative;
  background: color(neutral, white);
  z-index: 126;
  width: 100%;

  @include media('<=lg') {
    width:auto;
    height: $header-height-size-mobile;
    margin:0;
  }

  &::before{
    @include media('<md') {
        @include fluid(parent);
        background: $color-background;
        position: absolute;
        right: 0;
        left: 0;
        content: ' ';
        bottom: 0;
        z-index: 0;
    }
  }
  &::after {
    position: absolute;
    content: ' ';
    bottom: 0;
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    border-bottom: rem(1px) solid color(stroke);
    z-index: 99;
  }
  &__logo {
    &--link {
      &:focus {
        background-color: unset;
        outline: rem(2px) solid $border-focus;
      }
    }
    &--image {
      max-width: rem(131.07px);
      @include media('<=lg') {
        max-width: rem(94.37px);
      }
    }
  }

  @include media('<md') {
    .ace-list {

      @include media('<md') {
        width: 100% !important;
      }
    }
  }

  @include media('<=lg') {
    .navigation {
      position: absolute;
      order: 1;
      left: 0;
      width: 100% !important;
      z-index: map-get-deep($z-index, header, navigationbar, '<=lg');
      margin-left: 0 !important;
    }
    .ace-logo {
      width: auto;
      margin-right: auto;
      margin-left: auto !important;
      display: flex;
      text-align: center;
    }
  }

  @include media('<=xl') {
    .navigation {
      width: 100% !important;
      padding: 0;
    }
  }

  @include media('>=xl') {
    .ace-logo {
      padding: 0 !important;
    }
    .navigation {
      width:80.3333% !important;
      padding-right: rem(0px);
    }
  }
  .navbar {
    &--items li {
      a {
        &:focus {
          outline: rem(2px) solid $border-focus;
        }
      }
    }
    .openList {
      display: block;
    }
  }
}
.ace-panel__dropdown--list--item {
  display: none;
}
.navigation-panel--container {

  @include media('<md') {
    .ace-nav-image {
      right: 0;
      left: 0;
      width: 100%;
      max-width: inherit;
      margin-right: 0;
      margin-left: 0;
    }
  }
  .aem-Grid {
    &.aem-Grid--12 {
      &.aem-Grid--default--12 {
        .logo {
          width: auto;
          padding: 0;

          &:first-child{
            @include media('>=lg') {
              padding-right: rem(30px);
              width: auto;
              padding-left: rem(6px);
            }

            @include media('<=lg') {
              padding-left: rem(0px);
            }

            @include media('<md') {
              padding-right: rem(0px);
            }

            padding-right: rem(30px);
            width: auto;
            padding-left: rem(6px);
          }
        }
      }
    }
  }
}

 :root {
  color-scheme: light only;
}
@media (prefers-color-scheme: dark) {
  html {
    background-color: #fff;
  }
}
main, #main-content {
  & > .cmp-container {
    & > .aem-Grid {
      .ghost{
        display: none;
        margin-top: 0;
      }
    }
  }
}

.ghost.aem-GridColumn.aem-GridColumn--default--12 + div {
  margin-top: 0;
}
