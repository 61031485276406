.ace-footer-icon {
  display: flex;
  @include media('<md') {
    flex-direction: column;
  }
  &__title {
    @include expressive-subheading-01;
    flex: 0 0 auto;
    margin: rem(12px) 0 rem(8px) 0;
    color: color(neutral, white);
    @include media('>=md') {
      width: 14.6666666667%;
    }
    &--count {
      @include media('>=md') {
        display: none;
      }
      @include media('<md') {
        margin-left: rem(5px);
        @include ui-body-02;
      }
    }
  }
  &__button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: rem(32px) rem(20px);
    border: solid rem(1px) color(neutral, white);
    @include media('>=md') {
      display: none !important;
    }
    &.cmp-button--primary {
      background-color: $bg-brand-primary;
    }
  }
  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    &--item--link {
      display: inline-block;
      &:focus {
        background-color: unset;
        outline: rem(2px) solid $color-focus;
      }
    }
    &.listVertical {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: rem(10px);
      .ace-footer-icon__list--item {
        &--image {
          max-width: rem(96px);
          @include media('>md') {
            max-width: rem(100px);
          }
          @include media('<375px') {
            max-width: rem(92px);
          }
        }
      }
    }
    &.listCentered {
      img {
        @include media('<md') {
          height: rem(18px);
          margin-right: rem(33px);
        }
      }
      align-items: center;
      justify-content: space-between;
      width:100%;
      @include media('<md') {
        margin-right: rem(18px);
        margin-left: rem(18px);
      }
      @include media('>md') {
        justify-content: center;
      }
      li {
        &:last-child {
          margin-right:0;
        }
        @include media('>=md') {
          margin-right: rem(36px);
        }
        &--image {
          max-width: rem(24px);
          @include media('>md') {
           max-width: rem(36px);
          }
        }
      }
    }
  }
}
html[dir='rtl'] {
  .ace-footer-icon {
    &__list--item {
      margin-left: 0 !important;
      margin-left: rem(36px);
    }
    .listCentered {
      li {
        &:first-child {
          margin-right:0;
        }
        @include media('>=md') {
          margin-right: rem(36px);
        }
      }
    }
  }
}
