:root {
  --errorHeight: 0rem;
}
.core-booking-engine {

  @include media('>=lg') {
    height: calc(rem(145px) + var(--errorHeight));
    transition: none;

    &.expanded {
      height: calc(rem(350px) + var(--errorHeight));
      transition: none;
    }
  }
  &.private-hide {
      display: none;
    }
}

.ace-core-booking-engine {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &__label {
    display: block;
    margin-bottom: rem(8px);
  }

  @include media('>=md', '<lg') {
    padding: 0 1rem;
  }

  @include media('>=lg') {
    position: relative;
    width: 100%;
    overflow: unset;

    flex: 1;
    margin: 0;
    padding: 0;
  }

  @include media('>=lg', '<xl') {
    width: calc(100vw - rem(64px));
    margin: auto;
  }

  form {
    position: relative;
    display: none;
    height: 100%;

    @include media('>=lg') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    input.invalid,
    input.invalid#search\.destination\.userlang,
    select.invalid#search\.destination\.userlang,
    button.invalid,
    .ace-core-booking-engine__content
      duet-date-picker
      .duet-date__input.invalid {
      padding-right: rem(56px);
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14V7ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z' fill='%23BA1F1A'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: calc(100% - rem(16px));
      border: rem(1px) solid $border-danger !important;
      outline: unset;
      box-shadow: 0 0 0 rem(1px) $border-danger !important;
      transition: unset;
    }

    .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
      padding-right: rem(56px) !important;
      background-position: calc(100% - rem(12px)) !important;
      @include media('>=md','<lg') {
          padding-right: rem(40px) !important;
          background-position: calc(100% - rem(8px)) !important;
      }
      &:focus {
        border: rem(1px) solid $border-danger !important;
        box-shadow: 0 0 0 rem(1px) $border-danger !important;
      }
      & + input + .duet-date__toggle {
        background-color: unset !important;
        color: unset !important;
        .duet-date__toggle-icon {
          display: none;
        }
      }
    }


    .ace-core-booking-engine__content
      duet-date-picker
      .duet-date__input.invalid:disabled {
      background-image: unset !important;
      box-shadow: unset !important;
    }

    [aria-haspopup='listbox'].invalid {
      padding-right: rem(16px);
      background-image: unset !important;
    }
  }

  &.show {
    position: fixed;
    z-index: 200;
    width: auto;
    margin: unset;

    @include media('>=lg') {
      position: relative;
      width: 100%;
      overflow: unset;

      flex: 1;
      margin: 0;
      padding: 0;
    }

    @include media('>=lg', '<xl') {
      width: calc(100vw - rem(64px));
      margin: auto;
    }

    form {
      display: block;
    }
  }

  $namespace: '.ace-core-booking-engine';

  & {
    #mount-autocomplete {
      .material-textfield#search-destination,
      .ahAutocomplete {
        width: 100%;
        min-width: auto;
      }

      input.invalid,
      input.invalid#search\.destination\.userlang,
      button.invalid,
      .ace-core-booking-engine__content
        duet-date-picker
        .duet-date__input.invalid {
        padding-right: rem(56px);
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14V7ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z' fill='%23BA1F1A'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - rem(16px));
        border: rem(1px) solid $border-danger !important;
        outline: unset;
        box-shadow: 0 0 0 rem(1px) $color-danger !important;
        transition: unset;
      }

      .ace-core-booking-engine__content
          duet-date-picker
          .duet-date__input.invalid {
        padding-right: rem(56px) !important;
        background-position: calc(100% - rem(12px)) !important;
        @include media('>=md','<lg') {
            padding-right: rem(40px) !important;
            background-position: calc(100% - rem(8px)) !important;
        }
        &:focus {
          border: rem(1px) solid $border-danger !important;
          box-shadow: 0 0 0 rem(1px) $color-danger !important;
        }
        & + input + .duet-date__toggle {
          background-color: unset !important;
          color: unset !important;
          .duet-date__toggle-icon {
            display: none;
          }
        }
      .ahAutocomplete {
        &__result {
          position: absolute;
          width: 100%;
          background: color(neutral, white);
          box-sizing: border-box;
          border: rem(2px) solid $border-focus;
          z-index: 2;
          margin-top: rem(12px);
          border-radius: rem(6px);
          box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 10%);
          padding: rem(24px);
          &__items .autocompleteItem--result {
            border-color: transparent;
          }
        }
      }
    }
}

    // header styles
    #{$namespace}__header {
      position: relative;
      top: 0;
      margin-right: calc(50% - 50vw);
      margin-left: 100vw;

      button {
        background-color: color(neutral, white);
        padding: 0;
        border: 0;
        position: relative;
        top: rem(16px);
        right: rem(24px);
        float: right;
        width: rem(24px);
        height: rem(24px);
        font-size: 1.5rem;
        bottom: rem(24px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include media('>=lg') {
        display: none;
      }
    }

    // content styles
    #{$namespace}__content {
      width: calc(100vw - rem(32px));
      height: calc(100vh - rem(200px));
      overflow: scroll;

      @include media('<lg') {
        padding: 0 1rem;
      }

      @include media('>=md', '<lg') {
        width: calc(100vw - rem(64px));
      }

      @include media('>=lg') {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: auto;
        flex-wrap: nowrap;
        gap: rem(24px);
        overflow: visible;
      }

      #{$namespace}__autocomplete {
        @include media('>=lg') {
          width: 30%;
        }
        .material-textfield input.withoutLabel {
          @include input-overide;
          color: $text-body-primary !important;
        }
      }

      #{$namespace}__check-in,
      #{$namespace}__check-out {
          @include media('<md') {
              margin-top: rem(24px) !important;
              margin-bottom: 0 !important;
          }
        duet-date-picker .duet-date__input {
          @include input-overide;
          margin-top: 0 !important;
          color: $text-body-primary !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        duet-date-picker .duet-date__toggle {
          top: rem(1px);
          max-height: 3.5rem;
        }
      }
      #{$namespace}__check-in,
      #{$namespace}__check-out,
      #{$namespace}__guests {
        @include media('>=lg') {
          width: 20%;
        }
      }
    }

    // error block styles
    &__error-desktop,

    &__error-mobile {
      display: none;
    }

    &__error-mobile {
      @include media('<=md') {
        display: block;
      }
    }
    &__error-desktop {
      @include media('>md') {
        display: block;
      }
    }

    &__error-block {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: rem(16px);
      padding: rem(16px) rem(24px);
      margin-bottom: rem(32px);
      color: $text-body-primary;
      background: #fbe6e7;
      border-radius: rem(6px);
      svg path {
        fill: $text-danger;
      }

      @include media('<md') {
        padding: rem(12px) rem(16px);
      }

      & > * {
        display: flex;
        gap: rem(8px);
        margin: 0;
        align-items: flex-start;
      }

      & > p {
        flex-direction: row;
        padding: rem(0px);
      }

      & > ul {
        flex-direction: column;
        margin-left: rem(24px);
        padding: rem(0px) rem(0px) rem(0px) rem(24px);

        li > a {
          text-decoration-line: underline;
          color: #252339;

          &:link {
            color: #252339;
          }

          &:visited {
            color: #706b9d;
          }

          &:hover {
            color: #002b5b;
          }

          &:focus {
            color: #fff;
            background: #3470df;
            outline: unset;
          }
        }
      }
    }

    // footer styles
    &__footer {
      position: fixed;
      height: auto;
      bottom: 0;
      padding: 1.5rem 1rem 0;
      right: 0;
      left: 0;
      z-index: 2;
      background: color(neutral, white);

      .ace-button .cmp-button {
        width: 100%;
        border-color: transparent;

        @include media('<md') {
          height: rem(48px);
          padding: rem(12px) rem(24px);
        }
      }

      @include media('>=lg') {
        position: relative;
        height: auto;
        padding: 0;
        background-color: transparent;
        width: 16.666%;
        align-self: flex-end;
        margin: 0 0 0 rem(24px);

        .ace-button .cmp-button {
          height: rem(56px);
          margin: 0;
          padding: rem(16px) rem(24px);
          width: 100%;
          border: rem(1px) solid color(neutral, white);
          background: $color-accent;

          &:hover {
            background: $color-accent-hover;
          }

          &:focus {
            outline: 0 !important;
            background: $color-accent;
            box-shadow: 0 0 0 rem(1px) #fff, 0 0 0 rem(3px) $color-focus !important;
          }

          &:active {
            background: $color-accent-pressed;
          }
          * {
            pointer-events: none;
          }
        }
      }
    }

    // modal open button styles
    &__open--button {
      background: color(neutral, white);
      box-sizing: border-box;
      border: rem(1px) solid $border-decorative;
      height: rem(56px);
      padding: padding(04) padding(05);
      border-radius: rem(6px);
      box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 10%);
      width: 100%;
      color: $text-body-secondary;
      display: flex;
      text-align: left;
      justify-content: flex-start;
      align-items: center;
      margin: auto;

      @include ui-body-02;

      .icon {
        display: flex;
        margin-right: rem(8px);
        font-size: rem(24px);
      }

      @include media('<md') {
        width: calc(100vw - rem(32px));
      }

      @include media('>=lg') {
        display: none;
      }
    }
  }
}
html {
  @include media('>=md') {
      scroll-snap-type: y proximity;
      scroll-padding-top: rem(86px);
    }
}
.booking-engine-container {
  &.private-hide {
    display: none;
  }
}


  main>.cmp-container>.aem-Grid>div.booking-engine.core-booking-engine.not-merge-next{
    position:relative !important;
  }
