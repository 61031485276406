.mapcontainer{
   height: auto;
   width:100%
  }
  .location{
    display:block;
  }
.location.hidecomponent{
    display:none
}
.ace-map-container{
display: flex;
flex-direction: column;
align-items: center;
padding: rem(0px);
@include media('>=md', '<lg') {
    padding:0 rem(32px);
    }
@include media('>=lg')  {
width: 100%;
}
}
.ace-map__wrapper{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: rem(0px);
width: 100%;
height: auto;
flex: none;
order: 0;
flex-grow: 0;
margin-bottom:rem(64px);
box-sizing: border-box;
@include media('>=md', '<lg') {
    flex-direction: column;
    margin-bottom:rem(48px);
}
@include media('<md') {
    flex-direction: column;
    margin-bottom:rem(32px);
    margin-left: 2rem;
    padding-right: 2rem;
}

}
.ace-mapheadingtype{
    width: rem(375px);
height: rem(36px);
flex: none;
order: 0;
flex-grow: 0;
@include media('>=md', '<lg') {
    flex-direction: row;
}
}
.ace-map__content{
display: flex;
flex-direction: column;
align-items: flex-start;
width: 50%;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@include media('>=md', '<lg')  {
    flex-direction: row;
}
@include media('>=lg','<=xl')  {
    margin-left:rem(0px);
    flex-direction: row;
    width:49%;
    padding: rem(0px) rem(0px) rem(0px) rem(0px);
}

@include media('>xl')  {
    flex-direction: row;
    padding: rem(0px) rem(0px) rem(0px) rem(40px);
}
}
.ace-map__title {
@include expressive-heading-03;
font-style: normal;
width:100%;
color:$text-heading;
@include media('>lg')  {
    margin-left: rem(64px);
}
}
.ace-map__heading{
    margin:0;
    @include expressive-heading-03;
    font-style: normal;
}
.ace-map__content-location{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0;
gap: rem(16px);
width: 100%;
flex: none;
order: 0;
flex-grow: 0;
@include media('<md')  {
    padding-bottom:rem(16px);
}
@include media('>=md', '<lg')  {
    width:97%;
    padding-right:rem(24px);
}
@include media('>=lg','<=xl') {

}
@include media('>xl') {
    padding: 0rem 0 0 rem(13px);
}
}
.ace-map__content-reservation{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: rem(0px);
width: 100%;
flex: none;
order: 1;
flex-grow: 0;
@include media('>=md', '<lg')  {
    width:97%;
}
@include media('>xl') {
        padding: 0rem 0 0 rem(24px);
    }
}

.ace-mapreservation.mapreservation-margin{
    @include media('>=md', '<lg')  {
        margin-left:69%;
    }
    @include media('>=lg')  {
    margin-left:87%;
    }
}
.ace-mapopening{
display: none;
flex-direction: column;
align-items: flex-start;
padding: rem(0px);
gap: rem(10px);
width: 70%;
flex: none;
order: 2;
flex-grow: 0;
@include media('>=md', '<lg')  {
    width:40%;
    padding-left: rem(141px);
}
@include media('>=lg','<=xl')  {
    width:35%;
    padding-left: 22%;
}
@include media('>xl')  {
    width:35%;
    padding-left: 22%;
}

}
.ace-mapopening.openinghour-hide{
    display:none;
}
p.ace-map__content-location-head{
width: rem(159.5px);
height: rem(28px);
@include ui-heading-02;
font-style: normal;
display: flex;
align-items: center;
color:$text-heading;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0 0 rem(16px) 0;
@include media('<md'){
    margin:rem(24px) 0 rem(16px) 0;
}
@include media('>=md', '<lg')  {
    width:100%;
    margin:rem(24px) 0 rem(16px) 0;
}
@include media('>=lg')  {
    width:100%;
}

}
.ace-map__content-location-detail{
width: 100%;
@include ui-body-01;
font-style: normal;
margin-top: rem(-16px);
text-decoration-line: underline;
color : $text-body-primary;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

p{
    line-height: rem(24px);
    margin:0;
    a {
        color: $text-body-primary;
    }
}
}
p.ace-map__content-reservation-head{
width: rem(159.5px);
height: rem(28px);
@include ui-heading-02;
font-style: normal;
display: flex;
align-items: center;
color:$text-heading;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0 0 rem(16px) 0;
@include media('<md'){
    margin:0 0 rem(16px) 0;
}
@include media('>=md', '<lg')  {
    margin:rem(24px) 0 rem(16px) 0;
    width:100%;
}
@include media('>=lg')  {
    width:100%;
}

}

.ace-map__content-reservation-detail{
width: 100%;
@include ui-body-01;
font-style: normal;
text-decoration-line: underline;
color : $text-body-primary;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
p{
    margin:0;
    line-height: rem(24px);
    a {
        color: $text-body-primary;
    }
}
}
.ace-mapopen{
    width: 100%;
    height: rem(28px);
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: rem(20px);
    line-height: rem(28px);
    display: flex;
    align-items: center;
    color : $text-heading;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    p{
        margin-bottom: rem(10px);
        margin-top: rem(16px);
    }
}
.ace-maphours{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: rem(0px);
gap: rem(24px);
width: 100%;
height: 100%;
flex: none;
order: 1;
flex-grow: 0;
margin-bottom: rem(32px);
}
.ace-openingday{
font-family: $font-family-roboto;
font-style: normal;
font-weight: 400;
font-size: rem(16px);
line-height: rem(24px);
color : color(neutral,800);
}
.ace-openingtime{
font-family: $font-family-roboto;
font-style: normal;
font-weight: 400;
font-size: rem(16px);
line-height: rem(24px);
color : color(neutral,800);
flex: none;
order: 1;
}
.ace-openingtag{
    margin-block-start: unset;
    margin-block-end: unset;
}
.ace-map__content-details{
    display: flex;
    flex-direction: row;

    @include media('>=md', '<lg')  {
        width: 100%;
    }
     @include media('>=lg','<=xl')  {
        width: 46.5%;
    }
    @include media('>xl')  {
        width: 42.5%;
    }
    @include media('<md')  {
        width: 100%;
        flex-direction: column;
        }


}
.ace-linktext{
font-family: $font-family-roboto;
font-style: normal;
font-weight: 400;
font-size: rem(16px);
    @include media('<md')  {
        display:none;
    }
    @include media('>=md', '<lg')  {
        line-height: rem(40px);

    }
     @include media('>=lg')  {
        padding-left: rem(35px);
        padding-top: rem(7px);
    }
    a{
        color: $color-link;
    }
}
.ace-locationheadline{
top: rem(0px);
font-family: $font-family-montserrat;
font-style: normal;
font-weight: 700;
font-size: rem(24px);
color : color(neutral-black,900);
margin-bottom: rem(24px);
@include media('>=md', '<lg')  {
    font-size: rem(30px);

}
@include media('>=lg')  {
    font-size: rem(36px);
}

}
.ace-locationreservation.locationreservation-row{
    @include media(">=md")  {
    display:flex;
    flex-direction: row;
}
}
.ace-openinghour{
font-family: $font-family-roboto;
font-style: normal;
font-weight: 400;
font-size: rem(16px);
line-height: rem(24px);
color : color(neutral,800);
@include media('>=md', '<lg')  {
    padding-left: rem(0px);

}
@include media('<md')  {
    padding-left: rem(22px);

}
}
.ace-openinghr{
    margin-block-start: unset;
    margin-block-end: unset;
}

//after integration changes
.location .ace-map{
width:100vw;
margin-left: calc(50% - 50vw);
margin-right: calc(50% - 50vw);
overflow:hidden;
 .ace-map-container{
    position: relative;
}
}
.map-container{
    top: 0 !important;
    @include media('>=sm', '<md')  {
        height: 100% !important;
        aspect-ratio: 8/10;
    }
    @include media('>=md', '<lg')  {
        height: 100% !important;
        aspect-ratio: 11/5;
    }
}
