$bg-color:#544D84;

.ace-subscription-container {
    padding: 0;
  .ace-subscription {
    background-color: $bg-loyalty-secondary;
    display: flex;
    border-radius: rem(6px);
    &.success {
        background-color: $bg-success-secondary;
    }
    &.error {
        background-color: $bg-danger-secondary;
        .ace-subscription__button {
            @include media('>=md') {
                margin: auto rem(24px) rem(32px) 0;
            }
        }
    }
  @include media('>=md', '<lg') {
    width: calc(100vw - rem(64px));
    margin: auto;
  }

  @include media('<md') {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
    &__content {
      @include media('<md') {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
      }
    }
    &__block {
      padding-left: rem(16px);
      padding-right: rem(16px);
      margin: rem(16px) 0 0 0;
      width: 100% !important;
      @include media('>=md') {
        margin: rem(24px) 0;
        padding-left: rem(24px);
        padding-right: 0 !important;
      }
      .expressive-heading-05 {
        @include ui-body-01-strong;
        color: $text-loyalty;
        margin: 0;
        p {
            line-height: rem(24px);
        }
      }
      &__des {
        @include ui-body-02;
        color: color(neutral-black, 800);
        margin: 0;
        &.show {
            padding-left: rem(32px);
        }
        &.hide {
            display: none;
        }
      }

      img.img-fluid {
        width: auto;
        height: auto;
      }
    }
    &__block-heading {
        display: flex;
        align-items: center;
        margin: rem(16px) 0 0 0;
        &.error__success {
            margin: rem(16px) 0 rem(4px) 0;
            .expressive-heading-05 {
                color: color(neutral-black, 800);
            }
        }
        p {
            margin-block: 0 !important;
        }
    }
    &__block-error {
        display: none;
        &.show {
            display: flex;
            padding-right: rem(8px);
        }
    }
    &__block-success {
        display: none;
        &.show {
            display: flex;
            padding-right: rem(8px);
        }
    }
    &__button {
        margin: rem(16px) 0;
        padding-right: rem(16px);
        width: rem(180px) !important;
      @include media('>=md') {
        margin: auto rem(24px) rem(24px) 0;
        padding: 0 0 0 rem(24px);
        height: fit-content;
        width: rem(214px) !important;
      }
      @include media('>=lg') {
        width: rem(189px) !important;
      }

      .ace-button {

        @include media('<md') {
          margin: 0;
        }

        .ace-subscription__btn {
          background-color: $bg-brand-primary;
          width: 100%;
          padding: rem(8px) rem(24px);

          @include media('<md') {
            margin: 0;
          }

          &:hover {
            background-color: $bg-brand-secondary-hover;
          }

          &:focus {
            outline: rem(2px) solid color(royal-blue, 500);
          }

          &:active {
            background-color: $bg-color;
          }
          .icon-loader {
            margin: rem(3px);
            position: relative;
            display: inline-block;
            width: rem(18px);
            height: rem(18px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.7286 2.54194C11.3069 1.72115 9.65418 1.3924 8.02665 1.60667C6.39913 1.82093 4.88778 2.56625 3.72701 3.72701C2.56625 4.88778 1.82093 6.39913 1.60667 8.02666C1.3924 9.65418 1.72115 11.3069 2.54194 12.7286C3.36272 14.1502 4.62967 15.2613 6.14628 15.8895C7.6629 16.5177 9.34442 16.6279 10.9301 16.203C12.5157 15.7782 13.9168 14.842 14.9162 13.5396C15.9155 12.2373 16.4571 10.6416 16.4571 9.00001C16.4571 8.57396 16.8025 8.22858 17.2286 8.22858C17.6546 8.22858 18 8.57396 18 9.00001C18 10.9812 17.3463 12.9071 16.1402 14.4789C14.9341 16.0507 13.2431 17.1806 11.3294 17.6933C9.41568 18.2061 7.38625 18.0731 5.55586 17.3149C3.72546 16.5567 2.19639 15.2158 1.20578 13.5C0.21518 11.7842 -0.18159 9.78953 0.0770088 7.82527C0.335608 5.86101 1.23512 4.03697 2.63605 2.63605C4.03697 1.23513 5.86101 0.335609 7.82527 0.077009C9.78953 -0.18159 11.7842 0.21518 13.5 1.20578C13.869 1.41881 13.9954 1.8906 13.7824 2.25957C13.5693 2.62854 13.0975 2.75496 12.7286 2.54194Z' fill='white'/%3E%3C/svg%3E");
          }
        }
        .loader {
            pointer-events: none;
        }
      }
    }
  }
}
