.cmp-breadcrumb {
  &__list {
    padding: rem(12px) 0 rem(12px) 0;

    @include media('>=md', '<lg') {
      margin: 0;
      padding: rem(12px) 0 rem(12px) rem(12px);
    }

    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-content: center; 
  }

  &__list--inverse {
    padding: rem(12px) 0 rem(12px) 0;

    @include media('>=md', '<lg') {
      margin: rem(16px) 0 0 0;
      padding: rem(12px) 0 rem(12px) rem(12px);
    }

    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-content: center;
    background-color: $bg-brand-primary;
  }
  li,
  &__item {
    
    @include ui-body-02;
    display: flex;
    align-content: center;
    align-items: center;
    color: color(neutral, 900);
    &--default {
      & + li:before {
        color: color(neutral-black, 800);
        padding: 0 rem(4px);
        @include icomoon;
        content: '\e93f';
        font-size: rem(18px);
        text-align: center;
      }
    }

    &--inverse {
      & + li:before {
        color: color(neutral, white);
        padding: 0 rem(4px);
        @include icomoon;
        content: '\e93f';
        font-size: rem(18px);
        text-align: center;
      }
    }
    .cmp-breadcrumb__item-link {
      &--default {
        color: $color-link;
        text-decoration: underline;
        font-style: normal;
        &:hover {
          text-decoration: underline;
          color: $color-link-hover;
        }
        &:focus {
          color: color(neutral, white);
          background-color: $bg-focus;
        }
      }
      &--inverse {
        color: color(neutral, white);
        text-decoration: underline;
        font-style: normal;
        &:hover {
          text-decoration: underline;
          color: rgba(255, 255, 255, 80%);
        }
        &:focus {
          color: $color-link-hover;
          background-color: $bg-focus-inverse;
          outline: rem(2px) solid color(neutral, white) !important;
        }
      }
    }
    &--active-default span {
      color: color(neutral-black, 800);
      height: rem(20px);
      font-style: normal;
    }
    &--active-inverse span {
      color: color(neutral, white);
      height: rem(20px);
      font-style: normal;
    }
  }
}
.breadcrumb {
  &.aem-GridColumn {
    padding: 0;
  }
  
  @include media('<md') {
    display: flex;
  }
  & + .ace-separator {
    &.aem-GridColumn {
      &.aem-GridColumn--default--12 {
        width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
      }
    }
  }
}
html[dir='rtl'] {
  .cmp-breadcrumb li--default + li:before,
  .cmp-breadcrumb__item--default + li::before {
    content: '\e93e';
  }
}