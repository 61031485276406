.ace-welcome-component {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $bg-neutral-primary;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    padding: rem(10px) 0 0 0;
    text-align: center;

    svg {
        display: none;
        margin: 0 auto rem(16px);
        padding: rem(10px);

        @include media('>=md') {
            margin: 0 auto rem(24px);
        }
    }

    &__headline {
        color: $text-heading;
        @include expressive-heading-02;
        max-width: 43.5%;
        margin: 0 auto;

        @include media('>=md', '<lg') {
            max-width: 75%;
        }

        @include media('<md') {
            padding: 0 1rem;
            max-width: 100%;
        }
    }

    &__description {
        @include ui-body-01;
        word-wrap: break-word;
        max-width: 100%;
        color: $text-body-primary;
        margin: 0 rem(16px) 0;
        padding: 0 1rem;

        @include media('>=md') {
            width: calc(85% - 10vw);
            margin: 0;
            padding: 0;
        }

        @include media('>=md', '<lg') {
            max-width: 75%;
        }

        @include media('>=lg') {
            max-width: 43.5%;
        }

        p {
            color: $text-body-primary;
            margin: rem(16px) 0 0 0;
            display: none;
            @include expressive-subheading-01;
            font-style: italic;

            &:first-child {
                display: block;
            }

            @include media('>=md') {
                margin: rem(24px) 0 0 0;
            }
        }

        .cmp-link {
            margin: auto;
        }

        .ace-seeless {
            display: none;
        }

        .ace-seemore,
        .ace-seeless {
            text-decoration: underline;
            color: $text-link;

            &:hover {
                color: $text-link-hover;
            }

            &:focus {
                color: $text-link-hover;
                background-color: $bg-focus;
                outline: none;
            }

            &:visited {
                color: $text-link-visited;
            }
        }

        &.show {
            display: flex;
            flex-direction: column;

            p {
                display: block;
            }

            .ace-seemore {
                display: none;
            }

            .ace-seeless {
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__author {
        text-transform: capitalize;
        @include ui-label-01;
        color: $text-body-secondary;
        margin-bottom: 0 !important;

        @include media('<lg') {
            margin: 2rem 0 0 0 !important;
        }

        @include media('>=lg') {
            margin: rem(32px) 0 0 0;
        }

    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: rem(32px) 0 0;
        padding: 0 rem(16px);
        gap: rem(24px);
        list-style-type: none;
        box-sizing: border-box;

        @include media('>=md') {
            width: 50%;
        }

        @include media('>=lg') {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        &Item {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;
            color: $text-body-primary;

            svg {
                display: block;
                fill: $text-heading;
                padding: 0;
                margin: 0 rem(16px) 0 0;
                min-width: rem(24px);
                min-height: rem(24px);
            }

            a {
                text-transform: capitalize;
            }
        }
    }

    &__cta {
        width: auto;
        margin-top: rem(32px);
        justify-content: center;

        @include media('>=md', '<lg') {
            max-width: 92% !important;
            width: 92% !important;
        }

        @include media('<md') {
            width: 91% !important;
            max-width: 91% !important;
        }

        .cmp-button {
            width: 100%;

            @include media('>=md') {
                width: auto !important;
            }

            svg {
                display: block;
                margin: 0 0 0 rem(4px);
                padding: 0;
            }
        }
    }

    &.ace-welcome-component--simplified {

        .ace-welcome-component__headline,
        .ace-welcome-component__author,
        .ace-welcome-component__cta,
        button {
            display: none;
        }

        .ace-welcome-component__description {
            margin-top: rem(8px);

            @include media('>=lg') {
                max-width: calc(100% / 12 * 8 - rem(54px));
            }

            h1,
            p {
                margin: 0;
                color: $text-heading;
                font-family: $font-family-montserrat;
                font-size: rem(24px);
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @include media('>=md') {
                    font-size: rem(32px);
                    line-height: rem(48px);
                }
            }
        }
    }
}
