// .shadow {
//   &__default {
//     box-shadow: 0 rem(18px) rem(18px) 0 #0000001a;
//   }
//   &__strong {
//     box-shadow: 0 rem(18px) rem(18px) 0 #00000033;
//   }
//   &__top {
//     box-shadow: 0 rem(-2px) rem(6px) 0 #0000001A;
//   }
//   &__bottom {
//     box-shadow: 0 rem(2px) rem(6px) 0 #0000001A;
//     &--strong{
//         box-shadow: 0 rem(2px) rem(6px) 0 #00000033;
//     }
//   }
// }

.shadow {
  &__default {
    box-shadow: rem(0px) rem(18px) rem(18px) rgba(0, 0, 0, 10%);
  }

  &__strong {
    box-shadow: rem(0px) rem(18px) rem(18px) rgba(0, 0, 0, 20%);
  }

  &__top {
    box-shadow: rem(0px) rem(-2px) rem(6px) rgba(0, 0, 0, 10%);
  }

  &__bottom {
    box-shadow: rem(0px) rem(8px) rem(16px) rem(0px) rgba(37 35 57 / 10%);
   
    &__strong {
        box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 20%);
    }
  }

  &__top-bottom-strong {
    box-shadow: rem(0px) rem(8px) rem(16px) rgba(37, 35, 57, 10%);
  }
}