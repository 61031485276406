.style {
  &_left {
    .cmp-title__text {
      text-align: left;
    }
  }

  &_center {
    .cmp-title__text {
      text-align: center;
    }
  }
}