.ace-billboard {
    .aem-Grid {
      .aem-GridColumn {
        @include media(">=lg") {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
        @include media('>=md', '<lg') {
          padding-right: rem(2px);
          padding-left: rem(2px);
        }
        @include media('<md') {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    &__fullwidth {
      display: flex;
      width: 100vw;
      margin-right: calc(50% - 50vw);
      margin-left: calc(50% - 50vw);
      padding: 2rem 0;
      color: color(neutral, white);
      text-align: center;
      background-color: $bg-loyalty-primary;
      @include media('>=md') {
        padding: 3rem 0;
      }
      @include media('>=lg') {
        padding: 4rem 0;
        text-align: left;
      }
      &__content {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
        @include media('<md') {
          margin: 0 2rem;
        }
        @include media('>=md') {
          max-width: 81.875rem;
        }
        @include media('>=md', '<xl') {
          margin: 0 2rem;
        }
        @include media('>=xl', '<xxl') {
          margin-right: rem(64px);
          margin-left: rem(64px);
        }
        @include media('>=lg') {
        .aem-Grid{
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      }
      &__title {
        h2 {
          margin: 0 auto 0.25rem;
        }
        div {
          p {
            margin: 0;
            line-height: 1.5rem;
          }
        }
      }
      &__button {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1rem;
        @include media('>=md') {
          display: block;
          margin-top: 1.5rem;
        }
        @include media('>=lg') {
          margin: rem(14px) 0;
          text-align: right;
        }
        .ace-button {
          margin-bottom: 1rem;
          @include media('>=md') {
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
          }
          .cmp-button {
            width: 100%;
            @include media('>=md') {
              width: auto;
            }
            &--primary,
            &--primary:focus {
              background: transparent;
              border: rem(1px) solid color(neutral, white);
            }
            &--secondary {
              padding: 0.75rem 1.5rem;
            }
            &__text {
              white-space: normal;
            }
          }
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  html[dir='rtl'] {
    @include media('>=lg') {
  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--1{
    margin-right: 8.33333%;
    margin-left:0;
  }
  .ace-billboard__fullwidth.ace-billboard__withimage{
    .aem-GridColumn--offset--md--1{
        float: right;
        margin-left:0;
    }
  }
  .ace-billboard.ace-billboard-component{
    .ace-billboard__withimage{
  .aem-GridColumn--default--5{
    margin-left:8.333%;
  }
  }
  }
  .ace-billboard__fullwidth{
  text-align: right;
  }
  .billboard{
  .aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--7{
    float: right;
  }
  }
  .billboard{
    .ace-billboard{
    .ace-billboard__withimage{
        .ace-billboard__fullwidth__button{
        text-align: right;
        }
    }
    }
  }
  .ace-billboard__fullwidth__button{
  text-align: left;
  }
  .ace-billboard__fullwidth__content{
    >.aem-Grid{
     >.aem-GridColumn--default--7
      {
        float: right;
        text-align: right;
      }

      .billboard {
        .aem-Grid.aem-Grid--default--12
          > .aem-GridColumn.aem-GridColumn--default--7 {
          float: right;
        }
      }
      .billboard {
        .ace-billboard {
          .ace-billboard__withimage {
            .ace-billboard__fullwidth__button {
              text-align: right;
            }
          }
        }
      }

      .ace-billboard__fullwidth__button {
        text-align: left;
      }

      .ace-billboard__fullwidth__content {
        > .aem-Grid {
          > .aem-GridColumn--default--7 {
            float: right;
            text-align: right;
            padding-right: 0;
          }
          .ace-billboard__fullwidth__button {
            text-align: left;
            padding-left: 0;
          }
        }
      }
    }
  }
    }
  }
  .ace-billboard {
    &.member{
      display: none;
    }
    &.not-member{
      display: block;
    }
    display: none;
  }

  .ace-billboard__fullwidth__button {
    a.cmp-button.cmp-button--secondary.btn-inverse:focus {
      outline: rem(2px) solid color(neutral, white);
      border: rem(1px) solid color(neutral, white) !important;
      outline-offset: rem(2px);
    }
  }
  .ace-billboard{
    &.member{
      display: none;
    }
    &.not-member{
      display: block;
    }
    display: none;
  }

  .ace-billboard__withimage {
    .ace-billboard__fullwidth__image {
      @include media('>=md','<lg') {
          padding-right: rem(10px);
        }

        @include media('>=lg') {
          padding: 0 rem(3px);
        }

        @include media('<md') {
            padding-bottom: rem(16px);
        }
      .ace-image {
          line-height: 0;
        img {
          width: 100%;
          .aem-AuthorLayer-Edit & {
            width: 100%;
          }
        }
      }
    }
  }
  .ace-billboard__withimage {
      @include media('<md') {
          padding: rem(40px) 0;
      }
    .ace-billboard__fullwidth__content {
      align-items: flex-start;
      @include media('<md') {
          margin: 0 rem(40px) 0 rem(40px);
        }
      .aem-Grid {
        align-items: flex-start;
      }
    }
  }
  .ace-billboard__withimage {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      @include media('>=md','<=md') {
          margin: 0 rem(12px);
          width: 96.7%;
        }
    &.ace-billboard__fullwidth {
      background-color: $bg-neutral-secondary;
      .ace-billboard__fullwidth__title {
        text-align: left;
        margin-bottom: 2rem;
        text-align: left;
        @include media('>=md','<lg') {
          padding-left : rem(10px);
        }
        @include media('<md') {
          margin-bottom: rem(24px);
        }

        span.ui-label-01 {
          color: $text-body-secondary;
          margin-bottom: rem(8px);
          display: flex;
        }
        h3 {
          color: $text-heading;
          margin: 0 0 rem(12px) 0;
          font-size: rem(36px);
          line-height: rem(48px);

          @include media(">=md","<lg") {
              font-size: rem(30px);
              line-height: rem(44px);
          }
          @include media(">=sm","<md") {
              font-size: rem(24px);
              line-height: rem(36px);
          }
        }

        p {
          color: $text-body-primary;
        }
      }
      .ace-billboard__fullwidth__button {
        text-align: left;
        margin: 0;

        .ace-button {
          @include media('>=md',"<lg") {
              padding-left: rem(10px);
          }
          .btn-inverse {
            background-color: color(stratos-blue, 1000);
            color: color(neutral, white);

            &:hover {
              background-color: $bg-brand-secondary-hover;
            }

            &:active {
              background-color: $billboard-button-clr-active;
            }

            &:focus {
              outline: rem(2px) solid color(royal-blue, 500);
            }
          }
        }
      }
    }
  }

  .ace-billboard__withimage .ace-billboard {
      &__fullwidth {
          &__content {
              margin: 0;
          }
      }
  }

  .ace-billboard__withimage {
    &.ace-billboard__image-on-right {
      @include media('<md') {
        padding: 2rem 0;
      }
      .aem-Grid {
        flex-direction: row-reverse;
        display: flex;

        &.aem-Grid--12 {
          &.aem-Grid--default--6 {
              display: flex;
              align-content: space-between;
              flex-direction: row-reverse;
              justify-content: center;

            @include media('<md') {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .aem-GridColumn {
          &:first-child {
            margin-left: 0;

            @include media('>=lg') {
              padding-left: rem(11px);
              padding-right: 0;
            }

            @include media('<lg') {
              padding-right: rem(4px);
            }

            @include media('>=md','<lg') {
              padding-left: 0;
            }
          }

          &:last-child {
            margin-left: 0;
            margin-right: 8.33333%;
            padding-left: rem(2px);
            padding-right: rem(10px);

            @include media('<lg') {
              margin-right: 3.33333%;
            }

            @include media('>=md','<lg') {
              padding-left: rem(5px);
              padding-right: 0;
            }
          }
          @include media("<md") {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      .ace-billboard__fullwidth__title {
        .ui-body-01 {
          p {
            @include media('>=lg') {
              padding-right: rem(5px);
            }
          }
        }
      }
    }
  }

  .ace-billboard__withimage .ace-billboard {
      &__fullwidth {
          &__content {
              margin: 0;
          }
      }
  }

  .ace-billboard__withimage {
    &.ace-billboard__image-on-right {
      @include media('<md') {
        padding: 2rem 0;
      }
      .aem-Grid {
        flex-direction: row-reverse;
        display: flex;

        &.aem-Grid--12 {
          &.aem-Grid--default--6 {
              display: flex;
              align-content: space-between;
              flex-direction: row-reverse;
              justify-content: center;

            @include media('<md') {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .aem-GridColumn {
          &:first-child {
            margin-left: 0;

            @include media('>=lg') {
              padding-left: rem(11px);
              padding-right: 0;
            }

            @include media('<lg') {
              padding-right: rem(4px);
            }

            @include media('>=md','<lg') {
              padding-left: 0;
            }
          }

          &:last-child {
            margin-left: 0;
            margin-right: 8.33333%;
            padding-left: rem(2px);
            padding-right: rem(10px);

            @include media('<lg') {
              margin-right: 3.33333%;
            }

            @include media('>=md','<lg') {
              padding-left: rem(4px);
              padding-right: 0;
            }
          }
          @include media("<md") {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      .ace-billboard__fullwidth__title {
        .ui-body-01 {
          p {
            @include media('>=lg') {
              padding-right: rem(5px);
            }
          }
        }
      }
    }
  }

  // Billboard Full-Grid variation
  .billboard {
    .ace-billboard__fullgrid {
      .aem-Grid.aem-Grid--md--12>.aem-GridColumn.aem-GridColumn--offset--md--1 {
        @include media('>=md','<lg') {
          margin-left: 0;
          margin-right: 0;
          padding: 0;
          width: 100%;
        }
      }
      display: flex;
      padding: 1rem 0;
      background-color: color(neutral-black, 100);
      text-align: center;

      @include media('>=md') {
        padding: 3rem 0;
      }
      @include media('>=lg') {
        padding: 4rem 0;
        text-align: left;
      }
      @include media('<md') {
        width: 100%;
      }
      @include media('>=md', '<lg') {
        width: 92vw;
        margin-left: calc(54% - 50vw);
      }

      &__content {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 3rem;

        @include media('>=lg') {
          margin-right: 4rem;
          margin-left: 4rem;
        }
        @include media('<md') {
          margin: 0 1rem;
        }
        .aem-Grid {
          @include media('>=lg') {
            padding-left: rem(12px);
          }
        }
      }

      &__title {
        .ui-label-01 {
          margin: 0;
          color: $text-body-secondary;
        }

        h3 {
          color: $text-heading;
          margin-top: rem(8px);
          margin-bottom: rem(12px);

          @include media('<md') {
            @include expressive-heading-05;
          }
          @include media ('>md') {
            @include expressive-heading-03;
          }
          @include media('>=md','<lg') {
            font-size: rem(30px);
            line-height: rem(44px);
          }
        }

        .ui-body-01 {
          color: $text-body-primary;
          p {
            @include ui-body-01;
            color: $text-body-primary;
            margin: 0;
          }
          &.without-cta {
            P {
              color: $text-body-primary;
              @include media('>=lg') {
                margin-left: 0;
                margin-right: calc(50% - 50vw);
                width: 70vw;
              }
            }
          }
          &.single-button {
            @include media('>=lg') {
              width: 59.5vw;
            }
            P {
              color: $text-body-primary;
            }
          }
        }
      }

      &__button {
        margin-top: rem(24px);
        height: auto;
        @include media ('>=md','<lg') {
          margin-top: rem(32px);
          display: flex;
          justify-content: center;
          margin-left: 9.7rem !important;
          width: 50% !important;
        }
        @include media('>=lg') {
          margin: rem(14px) 0;
          text-align: right;
          height: auto;
          display: flex;
          padding-left: rem(64px);
          align-items: center;
          position: relative;
          top: 3rem;
        }
        @include media('<md') {
          display: flex;
          flex-direction: column-reverse;
        }

        .ace-button {
          width: auto;
          height: auto;
          margin-bottom: 1rem;
          @include media('>=md') {
            display: inline-block;
            margin-bottom: 0;
          }
          @include media('<md') {
            width: 100%;
          }

          &:first-child {
            @include media('>=md') {
              height: auto;
              margin-right: rem(16px);
            }
            @include media('>=md','<lg') {
              margin-bottom: rem(16px);
            }
          }
          &:last-child {
            @include media('>=md') {
              height: auto;
            }
          }
          .cmp-button {
            height: auto;
            padding: rem(12px) rem(24px);
            &.cmp-button--secondary {
              width: 100%;
              border: 0;
              padding: rem(12px) rem(24px);
              background-color: color(stratos-blue, 1000);
              color: color(neutral, white);
              @include media('>=md') {
                width: auto;
              }

              &:hover {
                background-color: $bg-brand-secondary-hover;
              }
              &:active {
                color: color(neutral, white);
                background-color: $billboard-button-clr-active;
              }
              &:focus {
                background-color: $bg-brand-secondary;
                border: rem(2px) solid color(neutral, white);
                outline: rem(2px) solid color(royal-blue, 500);
              }
              .cmp-button__text {
                width: auto;
              }
            }

            &.cmp-button--teritary {
              width: auto;
              border-radius: 3.75rem;
              color: $text-brand !important;
              background-color: color(neutral-black, 100);
              border: rem(1px) solid $border-brand;
              @include media('>=md') {
                width: auto;
              }
              @include media('<md') {
                width: 100%
              }

              &:hover {
                color: color(neutral, white);
                background-color: color(stratos-blue, 1000);
                .cmp-button__text {
                  color: color(neutral, white);
                }
              }
              &:active {
                color: color(neutral, white);
                background-color: $bg-brand-secondary-hover;
                .cmp-button__text {
                  color: color(neutral, white);
                }
              }
              &:focus {
                color: $text-brand;
                background-color: color(neutral, white);
                outline: rem(2px) solid color(royal-blue, 500);
                border: rem(1px) solid $border-brand;
                outline-offset: rem(2px);
              }
              .cmp-button__text {
                width: auto;
              }
            }
          }
        }
        &.single-button  {
          @include media('>=lg') {
            padding-left: rem(64px);
            padding-right: 0;
            margin-left: rem(-8px);
            display: flex;
            justify-content: center;
            position: relative;
            top: 3rem;
          }
          @include media('>=md','<lg') {
            display: flex;
            justify-content: center;
            width: 26% !important;
            margin-left: 14.7rem !important;
          }
        }
      }
    }
  }

//List Variation
.ace-billboard__fullgrid[data-variation="billboard-list"]{
  @include media('>=md','<lg'){
    padding: rem(32px) 0;
  }
  .ace-billboard__fullgrid__content{
    @include media('>=md','<lg'){
      margin: 0 rem(32px);
    }
    @include media('>=lg'){
      text-align: center;
    }
    .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--7  {
      width:100%;
    }
    .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--5 {
      width:100%;
    }
    .ace-billboard__fullgrid__button{
      margin-top:0 !important;
      padding:rem(24px) 0 0 0;
      .ace-button .cmp-button {
        padding: rem(12px) rem(24px);
        }
      .ace-button:only-child{
        margin-right: 0;
        }
      @include media('>=md','<lg'){
        margin-left:25% !important;
        padding-top:rem(32px) !important;
      }
      @include media('>=lg'){
        display: flex;
        justify-content: center;
        padding-left: 0 !important;
        margin: 0 !important;
        top:rem(0px) !important;
        padding-top:rem(32px) !important;
      }
    }
    .ace-billboard__fullgrid__button>:first-child{
      @include media('<md'){
        margin-bottom: 0;
      }
      @include media('>=md','<lg'){
        margin-bottom: 0 ;
      }
    }
    .ace-billboard__fullgrid__title .ui-body-01.single-button {
      @include media('>=lg'){
        width: 100% !important;
      }
  }
    .ace-billboard__fullgrid__title{
      .ui-label-01{
        @include media('>=lg'){
          text-align: center;
        }
      }
      .ui-label-01,.expressive-heading-05{
        @include media('>lg'){
          display: flex;
          justify-content: center;
        }
      }
      .billboard-list{
        padding-top:rem(12px);
        padding-bottom:rem(24px);
        @include media('>=md','<lg'){
          padding-top:rem(20px);
          padding-bottom:rem(32px);
        }
        @include media('>=lg'){
          padding-top:rem(20px);
          padding-bottom:rem(32px);
        }
        ul{
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          gap:rem(16px);
          margin:0;
          @include media('>=lg'){
            flex-direction: row;
            padding: 0;
            flex-wrap: nowrap;
            row-gap: rem(16px);
            column-gap: rem(24px);
            align-items: start;
            text-align: left;
          }
          @include media('>=md','<lg'){
            gap:rem(24px);
          }
          li {
            list-style-type: none;
            @include media('>=lg'){
              width: 23%;
            }
            div{
              @include media('<lg'){
              display: flex;
              flex-direction: column;
              gap: rem(16px);
              }
              @include media('>=lg'){
                align-content: center;
                display: flex;
                flex-wrap: nowrap;
                gap:rem(16px);
                }
              .icon{
                height:rem(24px);
                width:rem(24px);
                @include media('<lg'){
                  align-self: center;
                  text-align: center;
                }
                @include media('>=lg'){
                align-content: center;
                display: flex;
                flex-wrap: wrap;
                }
              }
              .billboard-list__listItems-content{
                gap: rem(16px);
                display: flex;
              }
              .billboard-list__listItems-value{
                font-weight: 400;
                font-size: rem(16px);
                line-height: rem(24px);
                font-family: $font-family-roboto;
                color: $text-heading;
              }
            }
          }
        }
      }
    }
  }
}
