.ace-highlight {
  .aem-Grid--sm--4 {
    position: relative;
  }
  .aem-Grid {
    > .aem-GridColumn {
      @include media('>=lg') {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      @include media('>md', '<lg') {
        padding: 0;
      }

      @include media('<md') {
        padding: 0;
      }
    }
  }
  &__content {
    width: 100%;
    &--title {
      margin-top: rem(16px);
      margin-bottom: 0;
      color: $text-heading;

      @include media('>=lg') {
        margin-top: rem(64px);
      }
    }
  }
  &__content--disc {
    margin: 0;
    padding-top: rem(8px);
    padding-bottom: rem(16px);
    color: $text-body-secondary;
    p {
      margin: 0;
    }
    @include iconList;
    &.bullet-arrow {
      li::before {
        content: '\e91f';
      }
    }
    ul li {
      @include media('<md') {
        margin-bottom: 1rem;
      }
    }
  }
  .ace-button {
    .cmp-button {
      margin-right: 1rem;
    }
  }
  &__image {
    &--display {
      width: 100%;
      height: auto;
      border-radius: rem(6px);
    }
  }
  &__icon {
    vertical-align: middle;
    &::before {
      color: $color-link;
    }
  }
  .ace-button {
    @include media('<md') {
      justify-content: center;
    }
  }
  .ace-highlight__image {
    padding-left: 0;
    padding-right: 0;
  }
}
.ace-highlight.ace-highlight--large {
  .aem-Grid {
    @include media('>=lg') {
      display: flex;
      align-items: flex-end;
    }

    @include media('>=md', '<lg') {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ace-highlight__image {
      &.aem-GridColumn--default--5 {
        @include media('>=lg') {
          padding-right: 0 !important;
        }
      }
    }
  }

    .ace-highlight__image {
      &.aem-GridColumn--default--5 {
        @include media('>=lg') {
          padding-right: 0 !important;
        }
      }
    }

  .ace-highlight {
    &__image {
      width: 100vw;
      margin-left: calc(50% - 50vw);

      @include media('>=lg') {
        width: calc(66.6667% - rem(8px)) !important;
        padding-right: 0;
        padding-left: 0 !important;
      }

      @include media('>=md', '<lg') {
        width: 100%;
      }

      @include media('>=md') {
        margin-left: 0;
      }
    }
    &__content {
      &.aem-GridColumn {
        margin-top: rem(-40px);
        padding: rem(32px);
        background: rgba(236, 236, 236, 0.8);
        border-radius: rem(8px);
        backdrop-filter: blur(rem(34px));

        @include media('>=md') {
          position: relative;
          width: 82.5%;
          margin-top: rem(-60px);
          margin-left: inherit;
          padding: rem(48px);
        }

        @include media('>=md', '<=md') {
          width: 80%;
        }

        @include media('>=lg') {
          width: calc(50% - rem(12px)) !important;
          margin: 0 0 0 -15% !important;
          padding: rem(48px) !important;
        }
      }
      &--title {
        margin-top: rem(8px);
        color: #3c3c3c;
        @include expressive-heading-04;
      }
      &--disc {
        margin-bottom: rem(16px);
        padding: 0;

        @include media('>=md') {
          margin-bottom: rem(24px);
        }
        p {
          color: #3c3c3c;
        }
      }
      .ace-button {
        min-height: rem(50px);
        @include media('<md') {
          display: block;
        }
        .cmp-button {
          @include media('<md') {
            margin-right: 0;
            &:first-child {
              margin-bottom: rem(16px);
            }
          }
          &__text {
            white-space: normal;
          }
        }
      }
    }
    &__kicker {
      @include ui-label-01;
      margin: 0;
      color: color(neutral, grey);
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }
  }
}
@include media('>=md', '<lg') {
  .ace-highlight {
    .cmp-teaser__logo-slot {
      margin-left: 8.3333%;
      html[dir='rtl'] & {
        margin-right: 8.3333%;
        margin-left: 0;
      }
    }
  }
}
.cmp-teaser__logo-slot {
  &.highlight-logo {
    @include media('>=md') {
      width: rem(96px);
      height: rem(64px);
    }
  }
}
html[dir='rtl'] {
  .ace-highlight.ace-highlight--large {
    .ace-highlight__content.aem-GridColumn {
      @include media('>=lg') {
        margin: 0 -15% 0 0;
      }
    }

    @include media('>=md') {
      .ace-button {
        .cmp-button {
          margin-right: 0;
          margin-left: rem(16px);
        }
      }
    }
  }
}

.cmp-teaser__logo-slot {
  &.highlight-logo {
    @include media('>=md') {
      width: rem(96px);
      height: rem(64px);
    }
    img{
      width: 100%;
      height: rem(40px);
      @include media('>=md') {
        width: 100%;
        height: rem(64px);
      }
    }
  }
}
html[dir='rtl'] {
  .ace-highlight__content--disc ul li:before {
    margin-right: -2.5rem;
    margin-left: 0;
    right: 0;
    left: auto;
  }
  .ace-highlight .ace-button .cmp-button {
    margin-right: 0;
  }
  @include media('>=lg') {
    .ace-highlight__content {
      margin-right: 8.33333% !important;
      margin-left: 0 !important;
    }
    .ace-highlight .aem-Grid > .aem-GridColumn:first-child {
      padding-right: 0;
      padding-left: rem(12px);
    }

    .ace-highlight .aem-Grid > .aem-GridColumn:last-child {
      padding-left: 0;
      padding-right: rem(12px);
    }
  }
  @include media(">=lg") {
    .ace-highlight.right-version {
      .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--6 {
        float: right;
      }
    }
    .nov-highlight.left-version {
      .aem-GridColumn.aem-GridColumn--offset--default--1 {
        margin-left: 9.99999% !important;
        margin-right: 0;
      }
    }
    .highlight
      .aem-Grid.aem-Grid--12
      > .aem-GridColumn.aem-GridColumn--default--5 {
      float: right;
    }
    .ace-highlight_mediumlink
      .ace-highlight__content--link
      span.ace-highlight__icon {
      transform: rotate(180deg);
    }
  }
}
@include media('>=lg') {
  .ace-highlight__image-on-right {
    .aem-Grid {
      display: flex;
      flex-direction: row-reverse;
      .ace-highlight__content.aem-GridColumn--offset--default--1 {
        margin-left: 0;
        margin-right: 8.3333%;
      }
      .cmp-teaser__logo-slot {
        left: auto;
        right: 31%;
      }
    }
  }
}

@include media('>=lg') {
  .ace-highlight__image-on-right {
    .aem-Grid {
      display: flex;
      flex-direction: row-reverse;
      .ace-highlight__content.aem-GridColumn--offset--default--1 {
        margin-left: 0;
        margin-right: 8.3333%;
      }
      .cmp-teaser__logo-slot {
        left: auto;
        right: 31%;
      }
    }
  }
}

.cmp-teaser__logo-slot {
  @include media('<md') {
    left: rem(32px);
  }
}

.ace-highlight__image-on-right {
  .ace-highlight {
    .aem-Grid > .aem-GridColumn {
      &:first-child {
        padding-left: rem(12px);
        padding-right: rem(12px);
      }
      &:last-child {
        padding-left: 0;
      }
    }
  }

  .aem-Grid {
    .aem-GridColumn.ace-highlight__content {
      @include media('>=lg') {
        padding-left: 0;
      }
    }
    .aem-GridColumn.ace-highlight__image {
      @include media('>=lg') {
        padding-left: 1rem;
        padding-right: 0;
      }
    }
  }
}

.ace-highlight {
  .aem-Grid {
    .ace-highlight__image {
      @include media('>md', '<lg') {
        padding-left: 0;
        padding-right: 0;
      }
      @include media('>=md', '<=md') {
        padding-left: rem(12px);
        padding-right: rem(12px);
      }
      @include media('>=lg') {
        padding-right: rem(12px);
      }
    }
  }
  @include media('<lg') {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__content{
    @include media('>=lg') {
    p, .ace-button {
      padding-left: rem(2px);
    }
  }
  }
}
.ace-highlight_mediumlink{
.ace-highlight__content{
    &.aem-GridColumn{
    background: transparent;
    padding: 0 0 0 rem(12px);
    backdrop-filter: none;
    @include media('<lg') {
      margin-top: rem(24px);
      padding: 0;
    }
    }
    &--title{
      margin-bottom: rem(8px);
    }
}
.aem-Grid {
  .ace-highlight__image{
    @include media('>=md', '<lg') {
    padding: 0;
    }
    @include media('<md') {
      margin-left: 0;
    }
  }
  }
}
