html,
body {
    margin: 0;
    font-size: $font-size;

    // overflow-x: hidden;
    font-family: $font-family-montserrat;
    color: $text-body-primary;
    line-height: $font-height;
}

main,#main-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

a {
    @include link-default;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
}
