.social-media {
  @include media('>=lg') {
      padding: 0 !important;
  }

  @include media('>=md', '<lg') {
      padding-right: rem(6px) !important;
  }

  @include media('<md') {
      padding: 0 !important;
  }
}

.cmp-socialMedia {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(12px);

    @include media('<md') {
      display: block;
    }
  }

  &_title {
    display: flex;
    align-items: center;

    @include media('>=md') {
      width: calc(100% / 12 * 8);
      margin: 0;
    }

    .icon {
      font-size: rem(30px);
      margin-right: rem(4px);
    }

    &Text {
      margin: 0;
    }
  }

  &_brandName {
    display: flex;
    margin-top: rem(24px);
    font-weight: 500;
    font-size: rem(18px);
    line-height: 1.5rem;
    color: $color-accent;

    @include media('>=md') {
      margin-top: 0;
    }
  }

  &_list {
    margin: rem(48px) 0 0;
    padding: 0;
    list-style-type: none;
    font-size: 0;
  }

  &_listItem {
    margin-bottom: rem(16px);

    @include media('>=md') {
      margin-bottom: rem(24px);
    }

    @include media('>=md', '<lg') {
      &:nth-child(7),
      &:nth-child(8) {
          display: none;
      }
    }
  }

  &_listItemContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: rem(6px);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &_listItemUrl {
    position: relative;
    display: block;
    height: 100%;

    &:focus,
    &:hover {
      & + .cmp-socialMedia_overlay {
        opacity: 1;
      }
    }
  }

  &_listItemPicture {
    display: block;
    padding-top: 100%;

    img {
      position: absolute;
      top: 0;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &_cta {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    
    @include media('>=md') {
      margin-top: rem(24px);
    }

    a {
      color: $text-brand;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: rem(12px) rem(20px);
      border: rem(1px) solid $border-brand;
      border-radius: 3.75rem;

      @include media('<md') {
        display: block;
      }
    }
  }

  &_overlay {
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 1.5rem;
    opacity: 0;
    pointer-events: none;
    background: rgba($bg-accent-primary, 0.7);
    transition: opacity .5s ease;

    &Text {
      margin: 0;
      @include ui-body-01;
      color: color(neutral, white);
      font-size: rem(16px);
      line-height: rem(24px);
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include media('>=md') {
        -webkit-line-clamp: 7;
      }

      @include media('>=lg') {
        -webkit-line-clamp: 10;
      }
    }
  }
}