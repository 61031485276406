$icon-font-path: './fonts/icomoon/fonts';

// This is the file to override the icomoon font family icons style propertires
// usages we will add
// .cmp-componet-name{
//     @include icomoon;                //following mixin need to call
// }
// if you want to changes excting icon you need to add
// see  all the icon in following path
// movenpick\ui.frontend\src\main\webpack\resources\fonts\icomoon\demo.html

.icon-account-24icon::before {
  color: $color-background !important;
}

.icon-360::before,
.icon-enlarge-24icon::before {
  color: color(brand, 900) !important;
}

.icon-360::before {
  font-weight: bold;
}

.icon-download-24icon::before {
  color: color(brand, 900) !important;
}

.icon-chevron-right-24icon::before {
  color: color(neutral, white) !important;
}
