.ace-hub {
  .aem-Grid {
    > .aem-GridColumn {
      @include media('>=lg') {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      @include media('>md', '<lg') {
        padding-right: 0;
        padding-left: 0;
      }
      @include media('<md') {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &.righthub-image {
    .aem-Grid {
      display: flex;
      flex-direction: column-reverse;
      @include media('>lg') {
        flex-direction: row;
      }
    }
    .ace-hub {
      @include media('>md') {
        &__title {
          &__kicker {
            margin: 0;
            padding-left: 0;
          }
        }
        &__content {
          padding-left: 0;
        }
      }
    }
  }
  &__title {
    &__kicker {
      color: color(neutral-black, 600);
      letter-spacing: .07em;
      @include media('>=md') {
        padding-left: rem(1px);
      }
      @include media('<=md') {
        padding-left: 0;
      }
    }
    &__main {
      margin: .5rem 0 1.5rem;
      color: color(neutral-black, 900);
      line-height: 2.75rem;
      letter-spacing: rem(-.5px);
      @include media('>=md') {
        margin: .5rem 0 2rem;
        line-height: 3rem;
      }
      @include media('>lg') {
        line-height: 3.5rem;
      }
    }
  }
  &__content {
    margin-bottom: 1.5rem;
    @include media('>=md') {
      margin-bottom: 2rem;
      padding-left: rem(1px);
    }
    @include media('<=md') {
      padding-left: 0;
    }
    &__subtitle {
      margin: 0 0 .5rem;
      color: color(neutral-black, 900);
    }
    &__text,
    p {
      margin: 0;
      color: color(neutral-black, 600);
      line-height: 1.5rem;
    }
    &__link {
      display: inline-block;
      margin-top: 1rem;
      color: $color-link;
      cursor: pointer;
      &__icon {
        position: relative;
        top: rem(3px);
        display: inline-block;
        padding-left: .25rem;
        font-size: 1.125rem;
        &::before {
          color: $color-link;
        }
      }
      &:focus {
        color: $color-link;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    object-fit: cover;
    @include media('>=md') {
      height: rem(484px);
      margin-bottom: 2rem;
    }
    @include media('>lg') {
      height: rem(494px);
      margin-bottom: 0;
    }
  }
}

