.ace-seperator-component {
  hr {
    height: rem(1px);
    margin:0;
    background-color: color(neutral-black, 200);
    border: medium none;
  }
}
// seperator with image
.ace-separator {
  &__line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    img {
      position: absolute;
      z-index: map-get-deep($z-index, main, separator, image);
      width: rem(182px);
      height: rem(63px);
      background-color: $bg-brand-primary;
      @include media('<lg') {
       // max-width: rem(182px);
      }
      @include media('<md') {
        width: rem(136px);
        height: rem(47px);
      }
    }
  }
  &__line {
    &::after,
    &::before {
      top: 50%;
      display: block;
      width: 50%;
      border-top: rem(1px) solid color(neutral-black, 200);
      content: ' ';
    }
    &.ace-separator__image::after {
      right: 0;
      margin-left: rem(40px);
      @include media('>lg') {
        margin-right: rem(25px);
      }
      @include media('>=md', '<lg') {
        margin-right: rem(8px);
      }
    }
    &.ace-separator__image::before {
      left: 0;
      margin-right: rem(40px);
      @include media('>=lg') {
        margin-left: rem(25px);
      }
      @include media('>=md', '<lg') {
        margin-left: rem(8px);
      }
    }
  }
  &__space {
    &--top {
      &--small {
        &--16 {
          @include media('<md') {
            padding-top: rem(16px);
          }
        }
        &--24 {
          @include media('<md') {
            padding-top: rem(24px);
          }
        }
        &--32 {
          @include media('<md') {
            padding-top: rem(32px);
          }
        }
        &--48 {
          @include media('<md') {
            padding-top: rem(48px);
          }
        }
        &--64 {
          @include media('<md') {
            padding-top: rem(64px);
          }
        }
        &--80 {
          @include media('<md') {
            padding-top: rem(80px);
          }
        }
        &--96 {
          @include media('<md') {
            padding-top: rem(96px);
          }
        }
        &--128 {
          @include media('<md') {
            padding-top: rem(128px);
          }
        }
      }
      &--medium {
        &--16 {
          @include media('>=md', '<lg') {
            padding-top: rem(16px);
          }
        }
        &--24 {
          @include media('>=md', '<lg') {
            padding-top: rem(24px);
          }
        }
        &--32 {
          @include media('>=md', '<lg') {
            padding-top: rem(32px);
          }
        }
        &--48 {
          @include media('>=md', '<lg') {
            padding-top: rem(48px);
          }
        }
        &--64 {
          @include media('>=md', '<lg') {
            padding-top: rem(64px);
          }
        }
        &--80 {
          @include media('>=md', '<lg') {
            padding-top: rem(80px);
          }
        }
        &--96 {
          @include media('>=md', '<lg') {
            padding-top: rem(96px);
          }
        }
        &--128 {
          @include media('>=md', '<lg') {
            padding-top: rem(128px);
          }
        }
      }
      &--large {
        &--16 {
          @include media('>=lg') {
            padding-top: rem(16px);
          }
        }
        &--24 {
          @include media('>=lg') {
            padding-top: rem(24px);
          }
        }
        &--32 {
          @include media('>=lg') {
            padding-top: rem(32px);
          }
        }
        &--48 {
          @include media('>=lg') {
            padding-top: rem(48px);
          }
        }
        &--64 {
          @include media('>=lg') {
            padding-top: rem(64px);
          }
        }
        &--80 {
          @include media('>=lg') {
            padding-top: rem(80px);
          }
        }
        &--96 {
          @include media('>=lg') {
            padding-top: rem(96px);
          }
        }
        &--128 {
          @include media('>=lg') {
            padding-top: rem(128px);
          }
        }
      }
    }
    &--bottom {
      &--small {
        &--16 {
          @include media('<md') {
            padding-bottom: rem(16px);
          }
        }
        &--24 {
          @include media('<md') {
            padding-bottom: rem(24px);
          }
        }
        &--32 {
          @include media('<md') {
            padding-bottom: rem(32px);
          }
        }
        &--48 {
          @include media('<md') {
            padding-bottom: rem(48px);
          }
        }
        &--64 {
          @include media('<md') {
            padding-bottom: rem(64px);
          }
        }
        &--80 {
          @include media('<md') {
            padding-bottom: rem(80px);
          }
        }
        &--96 {
          @include media('<md') {
            padding-bottom: rem(96px);
          }
        }
        &--128 {
          @include media('<md') {
            padding-bottom: rem(128px);
          }
        }
      }
      &--medium {
        &--16 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(16px);
          }
        }
        &--24 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(24px);
          }
        }
        &--32 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(32px);
          }
        }
        &--48 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(48px);
          }
        }
        &--64 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(64px);
          }
        }
        &--80 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(80px);
          }
        }
        &--96 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(96px);
          }
        }
        &--128 {
          @include media('>=md', '<lg') {
            padding-bottom: rem(128px);
          }
        }
      }
      &--large {
        &--16 {
          @include media('>=lg') {
            padding-top: rem(16px);
          }
        }
        &--24 {
          @include media('>=lg') {
            padding-top: rem(24px);
          }
        }
        &--32 {
          @include media('>=lg') {
            padding-top: rem(32px);
          }
        }
        &--48 {
          @include media('>=lg') {
            padding-top: rem(48px);
          }
        }
        &--64 {
          @include media('>=lg') {
            padding-top: rem(64px);
          }
        }
        &--80 {
          @include media('>=lg') {
            padding-top: rem(80px);
          }
        }
        &--96 {
          @include media('>=lg') {
            padding-top: rem(96px);
          }
        }
        &--128 {
          @include media('>=lg') {
            padding-top: rem(128px);
          }
        }
      }
    }
  }
}