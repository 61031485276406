.ace-slideshow {
  $namespace: ".cmp-slideshow";
  #{$namespace} {
    position: relative;
    &.containerWidth {
      margin-left: auto;
      margin-right: auto;
    }
    #{$namespace}__content {
      list-style: none;
      padding: 0;
      margin: 0;
      #{$namespace}__item {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: none;
        width: 100%;
        height: 100%;
        &--active {
          display: block;
          position: relative;
          opacity: 1;
        }
          &.hidden {
              display: none;
          }
        .ace-image {
          overflow: hidden;
          margin-left: calc(50% - 50vw);
          margin-right: calc(50% - 50vw);
          width: 100vw;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .aem-Grid > .aem-GridColumn {
      position: relative;
      padding: 0;
      #{$namespace}__callout-section {
        padding: rem(24px) rem(16px);
        z-index: 20;
        position: relative;
        background: rgba(30, 34, 170, 0.7);
        backdrop-filter: blur(34px);
        color: white;
        border-radius: rem(8px);
        inset: auto 0 rem(56px) 0;
        margin: auto rem(0px);
        @include media(">md") {
          padding: rem(32px);
        }
        @include media(">=md", "<=md") {
          margin: auto rem(12px);
        }
        @include media(">=md", "<lg") {
          top: rem(-48px);
          padding: rem(48px) calc(100% / 8.3675);
          margin-bottom: rem(8px);
        }
        @include media(">=lg") {
          margin: auto rem(2.5px);
          position: absolute;
          inset: auto 0 rem(64px) 0;
        }
        #{$namespace}__callouts {
          #{$namespace}__callout-item {
            transition: all 0.7s ease-in;
            display: none;
            flex-direction: column;
            text-align: center;
            &:only-child {
              display: flex !important;
            }
            @include media(">=lg") {
              justify-content: space-between;
              flex-direction: row;
              align-items: flex-end;
              text-align: left;
              html[dir="rtl"] & {
                text-align: right;
              }
            }
            a:not(.cmp-button) {
              @include media("<=md") {
                width: auto;
                margin-right: auto;
                margin-left: auto;
              }
              &:link {
                color: color(neutral, white);
                text-decoration: underline;
              }
              &:visited {
                color: rgba(255, 255, 255, 0.8);
              }
              &:hover {
                opacity: 0.8;
              }
              &:focus {
                color: #1e22aa;
                background-color: color(neutral, white);
                outline: unset;
              }
            }
            #{$namespace}__callout-heading {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0;
              gap: rem(8px);
              @include media(">=lg") {
                align-items: flex-start;
                max-width: 70.65%;
              }
              #{$namespace}__callout-title {
                margin: 0;
              }
              #{$namespace}__callout-description {
                margin: 0;
                display: none;
                @include media(">=md") {
                  display: block;
                }
                p {
                  margin-top: 0;
                  margin-bottom: rem(8px);
                  line-height: inherit;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
                ul {
                  text-align: left;
                  margin: 1rem 0;
                  padding-left: 1rem;
                  @include media(">=lg") {
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            [data-cmp-hook-slideshow="link"] {
              margin-top: rem(24px);
              width: 100%;
              &.cmp-button {
                padding: rem(12px) rem(24px);
                @include media(">=md") {
                  padding: rem(16px) rem(24px);
                }
              }
              &.cmp-button:focus {
                box-shadow: unset;

                outline-offset: rem(2px);
                /* Safari 11+ */
                @media not all and (min-resolution: 0.001dpcm) {
                  @supports (-webkit-appearance: none) and
                    (stroke-color: transparent) {
                    & {
                      box-shadow: 0 0 0 rem(2px) rgba(30, 34, 170, 0.7),
                      0 0 0 rem(4px) color(neutral, white);
                      outline: unset;
                      outline-offset: unset;
                    }
                  }
                }
              }
              @include media(">=md") {
                width: auto;
                margin-left: auto;
                margin-right: auto;
              }
              @include media(">=lg") {
                margin-right: 0;
              }
              a:not(.cmp-button) {
                color: color(neutral, white);
              }
            }
          }
        }
        #{$namespace}__actions {
          margin-top: rem(24px);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0;
          gap: rem(16px);
          @include media(">=lg") {
            margin-top: rem(32px);
          }
          #{$namespace}__action {
            outline: none;
            background: transparent;
            border-radius: rem(40px);
            width: rem(40px);
            height: rem(40px);
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;
            border: rem(1px) solid color(neutral, white);
            color: color(neutral, white);
            html[dir="rtl"] & {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
            .icon {
              font-size: rem(24px);
            }
            .icon,
            .icon::before {
              color: inherit !important;
            }
            &:hover {
              background: rgba(255, 255, 255, 0.9);
              border: rem(1px) solid rgba(218, 218, 221, 0.9);
              color: #1e22aa;
            }
            &:active {
              background: rgba(255, 255, 255, 0.8);
              border: rem(1px) solid rgba(218, 218, 221, 0.8);
              color: #1e22aa;
            }
            &:focus {
              outline: rem(2px) solid color(neutral, white);
              outline-offset: rem(2px);
              background-color: transparent;
              color: color(neutral, white);
              /* Safari 11+ */
              @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) and
                  (stroke-color: transparent) {
                  & {
                    box-shadow: 0 0 0 rem(2px) rgba(30, 34, 170, 0.7), 0 0 0 rem(4px) $color-background;
                    outline: unset;
                    outline-offset: unset;
                  }
                }
              }
            }
            &.disabled {
              border: rem(1px) solid #ececec;
              background-color: #ececec;
              color: #595959;
              pointer-events: none;
              * {
                pointer-events: none;
              }
            }
          }
          #{$namespace}__action-content {
            color: color(neutral, white);
            white-space: nowrap;
            text-align: center;
            html[dir="rtl"] & {
              direction: ltr;
            }
            @include media(">=md") {
              text-align: left;
              html[dir="rtl"] & {
                text-align: right;
              }
            }
          }
          hr {
            width: 100%;
            border: medium none;
            border-top: 0.0625rem solid #d8d8d8;
          }
        }
      }
    }
    #{$namespace}__control-player {
      display: none !important;
      &__control-player-container {
        position: relative;
        #{$namespace}__action {
          position: absolute;
          left: 0;
          top: 0;
          height: rem(32px);
          width: rem(32px);
          z-index: 1;
          &#{$namespace}__action--disabled {
            z-index: 0;
            opacity: 0;
          }
        }
      }
    }
    #{$namespace}__indicators {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }
  }
}
.slideshow{
  @include media("<lg"){
    margin-bottom: -3.5rem;
  }
  line-height: 0;
}
