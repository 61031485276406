.ace-navigation {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ace-simple-nav {

    &__header__title {
      color: color(neutral-black, 600);
      font-family: $font-family-roboto;
      font-size: rem(12px);
      line-height: rem(16px);
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.053rem;
      text-transform: uppercase;
      margin-bottom: rem(24px);
    }

    &__listItems {
      margin-top: rem(24px);
      @include media('<lg') {
        margin-top: rem(20px);
      }
      li {
        margin: rem(8px) 0;
        cursor: auto;
        @include media('<lg') {
          margin: rem(20px) 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        a {
          font-family: $font-family-roboto;
          font-size: rem(16px);
          font-style: normal;
          font-weight: 400;
          line-height: rem(24px);
          color: color(neutral-black, 800);
          text-decoration: none;
          border-bottom: rem(1px) solid transparent;
          cursor: pointer;

          :hover {
            border-bottom: rem(1px) solid color(neutral-black, 800);
            outline: 0;
          }
        }
      }
      .ace-simple-nav__last-link a {
        text-decoration-line: underline;
        color: $color-link;
        cursor: pointer;
        &:hover span {
          border-bottom: rem(0px) solid transparent;
        }
      }
    }
  }
  .ace-simple-nav.withoutSection-nav .ace-simple-nav__listItems li{
    margin: rem(8px) 0;
    @include media('<lg') {
    margin: rem(24px) 0;
    }
  }
  .ace-simple-nav.withoutSection-nav .ace-simple-nav__listItems li a {
    font-weight: 500;
    font-size: rem(18px);
    color: $text-brand;
    @include media('<lg') {
      color: color(neutral-black, 900);
      }
}
  .ace-simple-nav.withoutSection-nav .ace-simple-nav__listItems .ace-simple-nav__last-link a {
    color: $color-link;
    font-size: rem(16px);
    font-weight: 400;
  }

}


.link-navigation__sub-more-container .ace-navigation  .ace-simple-nav__listItems{
  margin-top:rem(8px);
}
