$flaglinkhover:#1264A3;
$flaglinkvisited:#706B9D;
.flag-accor-logo img {
  margin-left: auto;
  margin-right: auto;
}
.ace-country-flag{
  margin-top: 2rem;
}
.country-region {
  &__list {
    &-item {
      a {
        &:hover{
          color: $flaglinkhover;
          text-decoration: underline;
        }
      &:visited {
          color: $flaglinkvisited;
        }
      }
    }
  }
}
