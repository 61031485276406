.cmp-headingpagehero {
  background-color: color(neutral, 900);
  background-repeat: no-repeat;
  background-position: right center;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;

  h1 {
    margin: 0;

    @include media('>=xl') {
      max-width: rem(1310px);
    }
  }

  position: relative;
  display: flex;

  &--fullWidth {
    width: 100%;
  }

  &__image {
    z-index: 0;
    width: 100%;
    // height: 100%;

    @include media('<=md') {
      object-fit: cover;
      object-position: center;
      min-height: rem(400px);
    }

    @include media('>md') {
      max-height: 100vh;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    z-index: map-get-deep($z-index, header, headingPageHero, content-normal);

    .modal-opened &,
    .lightbox-opened & {
      z-index: map-get-deep($z-index, header, headingPageHero, content-modal);
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    overflow: hidden;

    @include media('<md') {
      justify-content: flex-end;
    }
  }

  &-headline {
    padding: 0 rem(179px);
    color: color(neutral, white);

    @include media('<md') {
      margin-top: 0;
      padding: 0 rem(16px);
      height: 100%;
      display: flex;
      align-items: center;
    }

    @include media('>=md', '<=lg') {
      & {
        padding: 0 rem(154px);
      }
    }

    @include media('>=md') {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  &-btn-group {
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;


    @include media('<md') {
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: unset;
    }

    @include media('>=md', '<lg') {
      margin: 0 rem(32px) rem(32px) rem(32px);
      // substract sides margins from total viewport width
      width: calc(100% - rem(64px));
    }

    @include media('>=lg') {
      margin: 0 rem(64px) rem(64px) rem(64px);
      // substract sides margins from total viewport width
      min-width: calc(100% - rem(30px));
    }

    @include media('>xl') {
      margin: auto;
      margin-bottom: rem(64px);
      min-width: rem(1310px);
    }

    &__item {
      display: flex;

      @include media('<md') {

        // Group 1 container: Gallery + virtual tour buttons
        &:first-child {
          justify-content: center;
          width: 100%;
          margin-bottom: 0;
          height: 100%;
          align-items: baseline;
          height: 100%;
          margin-top: rem(42px);
        }

        // Group 2 container: Booking
        &:last-child {
          width: 100%;

          .cmp-button {
            width: 100%;
          }

          button {
            height: rem(48px);
          }
        }
      }

      &--left {
        .button {
          &:first-child {
            margin-right: rem(16px);
          }
        }

        // Inversed buttons on left pannel
        .cmp-button--inverse {
          @include media('<md') {
            width: rem(48px);
            height: rem(48px);
            padding: 0;
          }
        }
      }
    }

    &--fullHeight {
      height: 100%;
    }
  }

  &__overlay {
    pointer-events: none;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;

    width: 100vw;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(221, 221, 221, 0.2), rgba(221, 221, 221, 0.2));
    display: none;
  }
}

@include media('<md') {
  .gallery-button {
    .cmp-headingpagehero {
      &-headline {
        height: 50%;
        align-items: flex-end;
      }

      &-btn-group {
        height: 50%;
        position: static;
        bottom: inherit;
      }
    }
  }

  .cmp-headingpagehero {
    &-headline {
      height: 50%;
      align-items: flex-end;
    }

    &-btn-group {
      height: 50%;
      position: static;
      bottom: inherit;
    }

    &-btn-group:first-child {
      height: 100%;
      position: static;
      bottom: inherit;
    }
  }
}

.cmp-headinghero-btn-full-width {
  width: 100%;
  display: flex;

  @include media('<md') {
    &.sticky {
      position: fixed;
      top: unset;
      bottom: 0;
    }
  }

  .cmp-button {
    width: 100%;
    padding: rem(13px) rem(20px) rem(13px) rem(21px);
  }

  div[data-cmp-is="booknow"] {
    width: 100%;
  }
}

html[dir="rtl"] {
  .cmp-headingpagehero-btn-group__item--left {
    .button {
      &:first-child {
        @include media('>=md') {
          margin-right: 0;
          margin-left: rem(16px);
        }
      }
    }
  }

  .cmp-headingpagehero-btn-group__item--right {
    .button {
      &:first-child {
        @include media('>=md') {
          margin-right: 0;
          margin-left: rem(16px);
        }
      }
    }
  }
}

.page-hotel .cmp-headingpagehero__overlay {
  display: block;
}

.modal-opened.page-hotel {
  .cmp-headingpagehero__overlay {
    display: none;
  }
}

.mov-headingpagehero {
  .cmp-headingpagehero-btn-group__item--right {
    .button {
      &:first-child {

        @include media('<md') {
          margin-right: 0;
        }
      }
    }
  }
}

@include media('<=md') {
  .modal-open {
    .mov-headingpagehero {

      .cmp-headingpagehero,
      &.ace-headingpagehero {
        height: 100vh;
        z-index: map-get-deep($z-index, header, headingPageHero, content-modal);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.book-now__selector {
  .selector-item {
    .selector-item-radio-button {
      height: 0;
    }
  }
}

.ace-hotellisting {
  .cmp-ace-hotel__callout {
    .cmp-headinghero-btn-full-width .cmp-button {
      @include media('<md') {
        margin-top: rem(24px);
        width: unset;
      }
    }
  }
}


body.modal-opened {
  .ace-headingpagehero {
    .cmp-headingpagehero {
      .cmp-headingpagehero__content {
        z-index: map-get-deep($z-index, header, headingPageHero, heading-page-content);
      }
    }
  }
}

.ace-headingpagehero {
  .cmp-headingpagehero {
    .cmp-headingpagehero__overlay {
      display: block;
    }
  }
}

.page-hotel .cmp-headingpagehero__overlay,
.heading-overlay .cmp-headingpagehero__overlay {

  display: block;

}

.ace-headingpagehero {
  .cmp-ace-image {
    @include media('<md') {
      height: 100%;
    }
  }

  .cmp-headingpagehero__image {
    @include media('>lg') {
      max-height: 90vh;
      object-fit: cover;
      object-position: 0 100%;
    }

    @include media('<md') {
      height: 100%;
    }
  }
}

@include media('<md') {
  .cmp-headinghero-btn-full-width.sticky>div {
    background-color: color(neutral, white);
    box-shadow: 0 rem(-2px) rem(6px) rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: rem(4px) rem(16px) rem(30px) rem(16px);
  }

}

@include media('<md') {
  .cmp-headingpagehero-btn-group__item .cmp-headinghero-btn-full-width.sticky button.cmp-button--primary {
    height: rem(56px);
  }
}

@include media('<md') {
  .cmp-headinghero-btn-full-width.sticky {
    box-shadow: 0 rem(-2px) rem(6px) rgba(0, 0, 0, 0.1);
    background-color: color(neutral, white);

    >a.cmp-button--primary {
      margin: rem(4px) rem(16px) rem(30px) rem(16px);
      padding: 0;
      height: rem(56px);
    }
  }
}


//my css

.ace-headingpagehero {
  .cmp-headingpagehero {
    .cmp-headingpagehero--fullWidth {
      line-height: 0;

      @include media('<md') {
        height: 100%;
      }

      @include media('>=md', '<xl') {
        height: 100%;
      }
    }

    .cmp-headingpagehero__overlay {
      z-index: 0;
    }

    .cmp-ace-image {
      @include media('<md') {
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        position: relative;
        right: 50%;
        width: 100vw;
      }
    }

    &__content {
      .cmp-headingpagehero-btn-group {
        @include media('>=xl') {
          margin: 0rem 4rem 2rem 4rem;
        }
      }
      .cmp-headingpagehero-btn-group__item--left{
        .cmp-button--inverse{
          color: $text-brand;
        }

        .cmp-ace-hotel__gallery--button {
          @include media('<md') {
            border-radius: 50%;
          }

          @include media('>=md') {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: color(neutral, white);
            border-radius: rem(60px);
            flex: none;
            order: 0;
            flex-grow: 0;
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
          }

          .cmp-button__text {
            @include media('<md') {
              display: none;
            }
          }
        }

        .virtual-btn {
          @include media('<md') {
            border-radius: 50%;
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
          }

          @include media('>=md') {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: color(neutral, white);
            border-radius: rem(60px);
            flex: none;
            order: 0;
            flex-grow: 0;
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
          }

          .cmp-button__text {
            @include media('<md') {
              display: none;
            }
          }
        }
      }

      .cmp-headingpagehero-btn-group__item {
        .cmp-button--accent {
          border-top-width: 0;
          border-right-width: 0;
          border-bottom-width: 0;
          border-left-width: 0;

          @include media('<md') {
            border-radius: 0%;
          }
        }
      }

    }
  }

  .cmp-headingpagehero-maincontent {
    @include media('>=md', '<xl') {
      z-index: 2;
      width: calc(100vw - 26%);
      max-width: 81.875rem;
      height: 100%;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }

    @include media('>=xl') {
      width: calc(100vw - 26%);
      max-width: 81.875rem;
      height: 100%;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      overflow: hidden;
      justify-content: center;
      display: flex;
    }
  }

  .cmp-headingpagehero-kicker-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .cmp-headingpagehero-kicker {
    @include ui-label-01;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .cmp-headingpagehero-title {
    @include expressive-heading-01-alt;
    font-style: normal;
    text-align: center;
    color: color(neutral, white);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: rem(8px) 0 rem(24px);

    @include media('<lg') {
      color: $text-heading;
    }

    h1 {
      @include expressive-heading-01-alt;
      font-style: normal;
    }
  }

  .cmp-headingpagehero-decription {
    padding: 0;
    @include expressive-subheading-01;
    font-style: normal;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: color(neutral, white);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    justify-content: center;

    p {
      margin: 0;
    }
  }
}

.cmp-headingpagehero-btn-group__item--left .cmp-button--inverse {
  @include media('<md') {
    width: rem(40px);
    height: rem(40px);
  }
}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item--left .cmp-ace-hotel__gallery--button {

  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;

}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item--left .virtual-btn {

  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;

}


.icon-enlarge-24icon::before {
  color: $text-brand !important;
}

.icon-360::before {
  color: $text-brand !important;
}


.ace-headingpagehero .ace-image {
  @include media('>=md', '<xl') {
    height: 100%;
  }

  @include media('>=xl') {
    height: 100%;
  }
}

//single button with image
.ace-headingpagehero .cmp-button__text.displayLabel {
  @include media('<md') {
    display: block !important;
  }
}

.ace-headingpagehero .icon-enlarge-24icon.hideIcon {
  display: none;
}

.ace-headingpagehero .cmp-ace-hotel__gallery--button.singleButton {
  @include media('<md') {
    background-color: $bg-brand-secondary;
    color: color(neutral, white) !important;
    border-radius: rem(50px) !important;
    // padding: 0 rem(24px);
    padding: rem(4px) rem(11px);
  }

  @include media('>=md') {
    background-color: $bg-brand-secondary !important;
    color: color(neutral, white) !important;
    border-radius: rem(50px) !important;
  }
}

.ace-headingpagehero .cmp-headingpagehero-btn-group.alignCenter {
  justify-content: center;
  position: relative;

  @include media('>=md', '<lg') {
    bottom: rem(60px);
  }

  @include media('>=lg', '<xl') {
    bottom: rem(22px);
  }

  @include media('>=xl') {
    bottom: 1rem;
  }
}


//single button without image
.ace-headingpagehero .cmp-headingpagehero.wrapperLayout {
  @include media('<md') {
    min-height: rem(265px);
    background-color: color(neutral, white);
  }

  @include media('>=md', '<xl') {
    background-color: color(neutral, white);
    min-height: rem(265px);
  }

  @include media('>=xl') {
    min-height: rem(284px);
    background-color: color(neutral, white);
  }
}

.ace-headingpagehero .cmp-headingpagehero-maincontent.wrapperMainContent {
  @include media('<md') {
    bottom: rem(-29px);
    position: relative;
  }
}

.ace-headingpagehero .cmp-headingpagehero-decription.wrapperDescription {
  @include media('<md') {
    color: $text-body-primary;
  }

  @include media('>=md', '<xl') {
    color: $text-body-primary;
  }

  @include media('>=xl') {
    color: $text-body-primary;
  }
}

.ace-headingpagehero .cmp-headingpagehero-kicker.wrapperKicker {
  @include media('<md') {
    color: $text-body-secondary;
  }

  @include media('>=md', '<xl') {
    color: $text-body-secondary;
  }

  @include media('>=xl') {
    color: $text-body-secondary;
  }
}

.ace-headingpagehero .cmp-headingpagehero-title.wrapperTitle {
  @include media('<md') {
    color: $text-heading;
  }

  @include media('>=md', '<xl') {
    color: $text-heading;
  }

  @include media('>=xl') {
    color: $text-heading;
  }
}

.ace-headingpagehero .cmp-headingpagehero-btn-group.buttonPos {
  @include media('<md') {
    bottom: rem(-18px);
  }
}

.ace-headingpagehero .cmp-headingpagehero .cmp-headingpagehero__overlay.hideOverlay {
  display: none;
}

.ace-headingpagehero .cmp-headingpagehero__content.wrapperContent {
  @include media('>=md', '<xl') {
    height: auto;
  }

  @include media('>=xl') {
    height: rem(483px);
  }
}

.ace-headingpagehero {
  .cmp-headingpagehero-btn-group__item--left {
    @include media('>=xl') {
      margin-left: rem(50px);
    }
  }

  .cmp-headingpagehero-btn-group__item--right {
    @include media('>=xl') {
      margin-right: rem(50px);
    }
  }
}

//fullgrid
.ace-headingpagehero .cmp-headingpagehero-btn-group__item--left.buttonleft-fullgrid {
  @include media('>=xl') {
    margin-left: rem(64px);
  }

  @include media('>=md', '<xl') {
    margin-left: rem(32px);
  }
}

.ace-headingpagehero .cmp-headingpagehero-btn-group__item--right.buttonright-fullgrid {
  @include media('>=xl') {
    margin-right: rem(64px);
  }

  @include media('>=md', '<xl') {
    margin-right: rem(32px);
  }
}

//onebutton OneImage
.ace-headingpagehero .cmp-headingpagehero-btn-group.alignCenter .cmp-headingpagehero-btn-group__item.cmp-headingpagehero-btn-group__item--left {
  @include media('>=xl') {
    margin-left: 0;
  }

  @include media('>=md', '<xl') {
    margin-left: 0;
  }
}

.ace-headingpagehero .cmp-headingpagehero-btn-group__item--left.singleButtonCenter {
  margin-left: 0;

  .button:first-child {
    margin-right: 0;

    @include media('<md') {
      margin-right: 1rem;
    }
  }
}

//one button
.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group.singleButtonBottom {

  @include media('>=xl') {
    bottom: 0rem;
    margin: 0rem 4rem 0rem 4rem !important;
  }

  @include media('>=md', '<xl') {
    bottom: 0;
    margin: 0rem 2rem 0rem 2rem !important;
  }

  @include media('<md') {
    bottom: 0;
    height: auto;
  }
}

//two buttons
.ace-headingpagehero .cmp-ace-hotel__gallery--button.buttonSize1 {
  @include media('<md') {
    height: rem(40px);
    width: rem(40px);
  }
}

.ace-headingpagehero .virtual-btn.buttonSize2 {
  @include media('<md') {
    height: rem(40px);
    width: rem(40px);
  }
}

.ace-headingpagehero .cmp-headingpagehero {
  background-color: color(neutral, white);

  a.cmp-button.cmp-button--inverse.cmp-ace-hotel__gallery--button.singleButton span.cmp-button__icon {
    display: none
  }
}

.ace-headingpagehero .cmp-headingpagehero a {
  @include media('>=md') {
    padding: rem(16px) rem(20px);
  }
}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item--left .cmp-ace-hotel__gallery--button:hover {
  background-color: $headinghero-cta-hover;
}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item--left .virtual-btn:hover {
  background-color: $headinghero-cta-hover;
}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item--left .virtual-btn:visited {
  background-color: $headinghero-cta-visited;
}

//for full grid
.ace-headingpagehero .cmp-headingpagehero.fullGrid {
  margin: 0;
  width: 100%
}

.ace-headingpagehero.fullGrid {
  width: 100%;
  margin: 0;
}

.ace-headingpagehero .cmp-headingpagehero .cmp-headingpagehero__overlay.fullGrid {
  background: none;
  width: 100%;
}

.ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group.fullGrid {
  @include media('>=md') {
    margin: 0 0 rem(32px) 0;
    min-width: 100%;
  }
}

.ace-headingpagehero .cmp-headingpagehero .cmp-ace-image.fullGrid1 {
  @include media('<md') {
    margin: 0;
    width: 100%;
    left: 0;
    max-width: none;
  }

  @media(width:rem(768px)) {
    width: 97%;
    padding-left: rem(11px);
  }

  @media(width:rem(1280px)) {
    width: 100%;
    padding-left: 0;
  }
}

//for one image one button full grid in tablet and mobile
@include media('<lg') {
  .ace-headingpagehero .cmp-headingpagehero.fullGrid.oneImageFullGrid {
    flex-direction: column;

    .cmp-headingpagehero--fullWidth {
      height: inherit;
    }

    .cmp-headingpagehero__content {
      position: relative;
      justify-content: flex-start;
      top: 0;
      height: inherit;
    }

    .cmp-headingpagehero-maincontent {
      z-index: 1;
      position: relative;
      margin-top: 2rem;
      margin-bottom: 3rem;

      .cmp-headingpagehero-decription {
        color: $text-body-primary;
      }
    }

    .cmp-headingpagehero-btn-group.alignCenter {
      bottom: 0;
      margin-bottom: 0 !important;
    }

    .cmp-headingpagehero-kicker {
      color: $text-body-secondary;
    }

    .cmp-headingpagehero-title {
      color: color(neutral, 700);
    }


  }
}

@include media('<md') {
  .ace-headingpagehero .cmp-headingpagehero.fullGrid.oneImageFullGrid {
    .cmp-headingpagehero-maincontent {
      margin-bottom: 2rem;
    }
  }
}

.ace-headingpagehero .cmp-headingpagehero.wrapperLayout .cmp-headingpagehero-maincontent {
  position: relative;
  justify-content: flex-start;
  margin-bottom: rem(48px);

  @include media('<md') {
    margin-bottom: rem(32px);
    bottom: auto;
  }

  @include media('>=md', '<xl') {
    margin-bottom: rem(48px);
  }

  @include media('>=xl') {
    margin-bottom: rem(48px);
  }
}

.ace-headingpagehero .cmp-headingpagehero-btn-group__item--left.singleButtonCenter {
  @include media('<md') {
    margin-top: 0 !important;
  }
}

//for one image one button full width in tablet and mobile
@include media('<lg') {
  .ace-headingpagehero .cmp-headingpagehero.oneImageFullWidth {
    flex-direction: column;

    .cmp-headingpagehero__overlay {
      display: none;
    }

    .cmp-headingpagehero--fullWidth {
      height: inherit;
    }

    .cmp-headingpagehero__content {
      position: relative;
      justify-content: flex-start;
      top: 0;
      height: inherit;
    }

    .cmp-headingpagehero-maincontent {
      z-index: 1;
      position: relative;
      margin-top: 2rem;
      margin-bottom: 3rem;

      .cmp-headingpagehero-decription {
        color: $text-body-primary;
      }
    }

    .cmp-headingpagehero-btn-group.alignCenter {
      bottom: 0;
      margin-bottom: 0 !important;
    }

    .cmp-headingpagehero-kicker {
      color: $text-body-secondary;
    }

    .cmp-headingpagehero-title {
      color: color(neutral, 700);
    }


  }
}

@include media('<md') {
  .ace-headingpagehero .cmp-headingpagehero.oneImageFullWidth {
    .cmp-headingpagehero-maincontent {
      margin-bottom: 2rem;
    }
  }
}

.ace-headingpagehero .cmp-headingpagehero.oneImageFullWidth {
  overflow: hidden;
}

// with two buttons case-- full width
@include media('<lg') {
  .ace-headingpagehero .cmp-headingpagehero.twoButtons {
    .cmp-headingpagehero__overlay {
      display: block;
      z-index: 1;
    }

    .cmp-headingpagehero__content {
      height: 100%;
      justify-content: flex-end;
      position: absolute;
      z-index: 2;
    }

    .cmp-headingpagehero-kicker {
      color: color(neutral, white);
    }

    .cmp-headingpagehero-title {
      color: color(neutral, white);
    }

    .cmp-headingpagehero-maincontent .cmp-headingpagehero-decription {
      color: color(neutral, white);
    }
  }
}

// with two buttons case-- full grid
@include media('<lg') {
  .ace-headingpagehero .cmp-headingpagehero.fullGrid.oneImageFullGrid.oneImageFullWidth.twoButtons {
    .cmp-headingpagehero__content {
      height: 100%;
      justify-content: flex-end;
      position: absolute;
      z-index: 2;
    }

    .cmp-headingpagehero-kicker {
      color: color(neutral, white);
    }

    .cmp-headingpagehero-title {
      color: color(neutral, white);
    }

    .cmp-headingpagehero-maincontent .cmp-headingpagehero-decription {
      color: color(neutral, white);
    }
  }
}

.ace-headingpagehero .cmp-headingpagehero.wrapperLayout {
  .cmp-headingpagehero--fullWidth {
    display: none;
  }

  .cmp-headingpagehero__content {
    position: relative;
  }
}

.cmp-headingpagehero-title {
  &.hidden {
    display: none;
  }
}

// Define rule to set margin for first heading-hero on page after header
main, #main-content {
    > .cmp-container > .aem-Grid>.heading-hero {
        &:first-child {
            margin-top: 0 !important;
            &:has(.wrapperLayout) {
                margin-top: margin(03) !important;
                @include media('>=md') {
                    margin-top: margin(06) !important;
                }
            }
        }
    }
}
