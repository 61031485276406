.cmp-ace-faq {
  &.aem-Grid {
    > .aem-GridColumn {
      @include media('>md', '<lg') {
        padding-right: 0;
        padding-left: 0;
      }

      @include media('>xxl') {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .cmp-accordion {
    &__button {
      padding: rem(16px) 0;
      border: medium none;
      & * {
        pointer-events: none;
      }
    }
  }

  &__title {
    @include expressive-heading-03;
    width: 100%;
    margin: 0 0 rem(24px) 0;
    color: $text-heading;
    font-style: normal;

    @include media('>=md') {
      width: rem(582px);
      margin: 0 0 rem(32px) 0;
    }

    @include media('>=lg') {
      width: rem(491px);
      margin: 0;
    }
  }
}

.cmp-accordion {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &__item {
    width: 100%;
    border: medium none;
    border-bottom: rem(1px) solid $border-decorative;
    &:first-child {
      border-top: rem(1px) solid $border-decorative;
    }

    @include media('>=md', '<lg') {
      width: rem(582px);
    }

    @include media('>=lg', '<=xl') {
      width: rem(594px);
      border-top: medium none;
    }
    &.active {
      .cmp-accordion__panel {
        display: block;
        p {
          line-height: 1.5rem;
        }
      }
      .cmp-accordion__icon::before {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
    .cmp-accordion__panel--hidden {
      display: none;
    }
  }

  &__header {
    display: flex;
    align-items: flex;
    margin: 0;
    padding: 0;

    @include media('<=md') {
      display: block !important;
    }
  }

  &__button {
    display: flex !important;
    align-items: start !important;
    justify-content: space-between !important;
    width: 100%;
    color: $text-heading;
    font-style: normal;
    text-align: left !important;
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &__icon {
    @include icomoon;
    padding: rem(8px) rem(24px) 0 0;
    transition: all 0.2s ease-in;
    &::before {
      float: right;
      color: $text-brand;
      font-size: rem(13.5px);
      line-height: rem(7.5px);
      content: '\e93d';
    }
    &.active::before {
      transform: rotate(-180deg);
    }
  }

  &__button--expanded {
    border: medium none;
    & .cmp-accordion__icon::before {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  &__title {
    @include ui-body-01-strong;
    left: calc(50% - rem(247px) / 2 - rem(24px));
    width: rem(247px);
    margin: 0 0 0 rem(24px);

    @include media('>=md') {
      left: calc(50% - rem(486px) / 2 - rem(24px));
      width: rem(486px);
    }

    @include media('>=lg') {
      left: calc(50% - rem(498px) / 2 - rem(24px));
      width: rem(504px);
    }
  }

  &__panel {
    @include ui-body-01;
    width: rem(295px) !important;
    margin-left: rem(24px);
    padding: 0 0 rem(25px) 0;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    color: $text-body-primary;
    font-style: normal;
    transition: 0.5s;

    @include media('>=md') {
      width: rem(534px) !important;
    }

    @include media('>=lg') {
      width: rem(552px) !important;
    }
    p {
      margin: 0;
    }
  }
  &__panel--expanded {
    display: block;
  }
  &__panel--hidden {
    display: none;
  }

  .aem-GridColumn--lg--6 {
    @include media('<=md') {
      width: 100%;
    }
    width: 50%;
  }
  &__button:focus {
    outline: rem(1px) solid $color-focus;
  }
  &__more-button {
    width: 100%;
    margin-top: 2rem;

    @include media('>=md') {
      width: auto;
      margin-top: 3rem;
    }
    .ace-button {
      .cmp-button {
        width: 100%;
        &__text {
          white-space: inherit;
        }

        @include media('>=md') {
          width: auto;
        }
      }
    }
  }
}

.mov-faq {
  .cmp-accordion .cmp-title {
    margin-left: 0 !important;
  }
}

.faq {
  @include media('<md') {
    padding: 0;
  }

  @include media('>=lg') {
    padding: 0;
  }
}

html[dir='rtl'] {
  .faq {
    @include media('>=lg') {
      .ace-faq-component {
        display: flex;
      }
    }

    .cmp-accordion__button {
      text-align: right !important;
    }

    .cmp-accordion__title {
      margin: 0 rem(24px) 0 0;
    }

    .cmp-accordion__panel {
      float: right !important;
      margin-right: rem(24px);
    }
  }
}
