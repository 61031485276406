.cmp-ace-message {
  position: -webkit-sticky;

  top: 0;
  z-index: 126;
  display: none;
  padding-bottom: rem(16px);
  background-color: $bg-warning-primary;
  box-shadow: none;

  @include fluid('parent');

  &__holder {
    @include fluid('child');

    display: flex;
    flex-direction: row;
    padding-top: rem(16px);
    padding-bottom: 0;

    @include media('>=xl', '<xxl') {
      margin-right: rem(64px);
      margin-left: rem(64px);
    }

    @include media('>=md', '<lg') {
      margin-right: rem(32px);
      margin-left: rem(32px);
    }

    @include media('<md') {
      margin-right: rem(16px);
      margin-left: rem(16px);
    }
  }
  &__warning {
    @include media('>=lg') {
      margin-top: rem(-2px);
    }
  }
  &__icon {
    margin-right: rem(8px);
  }
  &__text {
    margin-right: rem(16px);
    margin-left: rem(11px);
    color: color(neutral-black, 800);
    p {
      @include ui-body-01;
      margin-top: 0;
      margin-bottom: 0;
      color: color(neutral-black, 900);
      font-weight: 700;
      font-size: rem(16px);
      line-height: rem(20px);

      @include media('<md') {
        font-size: rem(14px);
      }
      a {
        color: color(neutral-black, 900);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .ui-body-02-strong {
      font-size: 1rem;
    }
  }
  &__close {
    margin-left: auto;
    &__button {
      display: flex;
      align-items: center;
      padding: rem(5px);
      background-color: transparent;
      border: none;
      box-shadow: none;
      span {
        display: flex;
        align-items: center;
        height: rem(15px);
      }
      &:focus {
        outline: rem(2px) solid $border-focus;
      }
    }
  }
}
.cmp-ace-message__close__button {
  cursor: pointer;
}
html[dir='rtl'] {
  .cmp-ace-message--close, .cmp-ace-message__close {
    margin-right: auto;
    margin-left: 0;
  }
}
// Alert banner start
.ace-message-warning,
.ace-message-error,
.ace-message-default {
    padding-bottom: 0 !important;
    border-radius: rem(6px);
    .cmp-ace-message__holder {
        display: flex;
        flex-direction: row;
        margin: 0 rem(16px);
        padding: rem(12px) 0;
        max-width: 100%;
        @include media('>=md') {
            margin: 0 rem(24px);
            padding: rem(16px) 0;
        }
    }
    .cmp-ace-message__warning {
        height: rem(24px);
    }
    .cmp-ace-message__text {
        padding: 0 rem(8px);
        margin: 0;
        p {
            color: color(neutral-black, 800) !important;
            z-index: 1 !important;
            b {
                font-weight: 500 !important;
            }
            u {
                color: color(neutral-black, 900) !important;
            }
        }
    }
    .cmp-ace-message__close__button {
        padding: rem(4.25px);
    }
    .icon-close-24icon::before {
        width: rem(15.5px);
        height: rem(15.5px);
    }
}
.ace-message-warning {
    background-color: $bg-accent-secondary;
}
.ace-message-error {
    background-color: $bg-danger-secondary;
}
.ace-message-default {
    background-color: color(neutral, white);
}
.alert-banner {
    margin-top: 0;
}
