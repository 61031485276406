.navbar {
  display: flex;
  @include media('>lg') {
    float: right;
  }
  a {
    &:focus {
      color: color(neutral, 900);
    }
  }
  ul,li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  @include media('<md') {
    &-zoom {
      height: auto;
      min-height: 130vh;
      scroll-padding-top: calc(100vh - 30vh);
      scroll-padding-bottom: calc(100vh - 30vh);
      scroll-snap-type: y mandatory;
    }
  }
  .navbar-toggler {
    position: relative;
    display: none;
    width: rem(38px);
    height: rem(28px);
    padding: 0;
    padding-top: rem(10px);
    color: $text-brand;
    text-transform: uppercase;
    background-color: color(neutral, white);
    border: none;
    @include ui-label-01;
    &:focus {
      outline: rem(2px) solid $border-focus;
    }
    &::before {
      top: 0;
    }
    &::after {
      top: rem(6px);
    }
    &::before,
    &::after {
      position: absolute;
      display: block;
      width: rem(38px);
      height: rem(2px);
      background: $bg-brand-secondary;
      transition: all .2s ease;
      content: '';
    }
    &[aria-expanded='true'] {
      @include media('<=lg') {
        line-height: rem(26px);
      }
      &::before {
        top: rem(8px);
        right: 0;
        left: rem(5px);
        width: rem(24px);
        transform: rotate(-45deg);
      }
      &::after {
        top: rem(8px);
        right: 0;
        left: rem(5px);
        width: rem(24px);
        transform: rotate(45deg);
      }
    }
    @include media('<=lg') {
      display: block;
    }
  }
  .navbarContent {
    height: $header-height-size;
    background-color: color(neutral, white);
    .cmp-ace-gallery {
      &__item {
        position: relative;
        box-sizing: border-box;
        margin: 0;
        margin-bottom: rem(32px);
        @include media('<=lg') {
          display: flex;
          margin-bottom: rem(24px);
          overflow: hidden;
          a {
            @include ui-heading-03;
          }
        }
        @include media('>=lg') {
          width: calc((100% - rem(48px)) / 3);
          margin-right: 0;
          &:nth-child(3n+1) {
            margin-right: 0;
          }
        }
        @include ui-heading-01;
        img {
          margin: 0;
        }
        a {
          margin-top: rem(8px);
          color: color(neutral, 900);
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
          }
        }
      }
      &__readmore {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: color(brand, 900);
        text-decoration: underline;
        @include media('<=lg') {
          text-align: center;
        }
      }
    }
    @include media('<=lg') {
      display: none;
    }
    >ul {
      display: flex;
      height: 100%;
      @include fluid(child);
      .nav-item {
        &.mobile-resort {
          @include media('<=lg') {
            padding-left: 0;
          }
        }
        @include media('<lg') {
          a {
            &.nav-link::before {
              display:none;
            }
          }
        }
        &__dropdown-toggle,
        .nav-link {
          @include ui-body-01;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          padding: 0;
          color: color(neutral-black, 900);
          background: none;
          border: none;
          @include media('>lg') {
            margin-right: rem(16px);
            padding-right: rem(16px);
            padding-left: rem(16px);
          }
          @include media('<=lg') {
            justify-content: flex-start;
            width: 100%;
            height: auto;
            margin-right: rem(24px);
            padding-top: rem(12px);
            padding-bottom: rem(12px);
            color: $text-brand;
            text-align: left;
            background: none;
            border: none;
            @include ui-heading-03;
          }
          &__dropdown-toggle {
            height: 100%;
            background: none;
            border: none;
            @include media('<=lg') {
              height: auto;
              margin: 0;
              padding: 0;
              line-height: normal;
            }
          }
          &.cmp-panel__link--active {
            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: rem(4px);
              background-color: color(brand, 900);
              content: '';
            }
          }
          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: rem(4px);
            background-color: $bg-brand-secondary;
            content: '';
            @include media('<=lg') {
              display: none;
            }
          }
          &[aria-expanded='true'] {
            &::after {
              width: 100%;
            }
          }
          &:hover {
            color: color(neutral, 900);
            text-decoration: none;
            border: none;
            cursor: pointer;
            &::after {
              width: 100%;
            }
          }
          &:focus {
            outline: rem(2px) solid $border-focus;
          }
          .icon {
            display: none;
          }
          @include media('<=lg') {
            &::before {
              position: absolute;
              right: rem(36px);
              display: block;
              color: $text-brand;
              font-family: icomoon;
              transform: rotate(-90deg);
              content: '\e93d';
            }
          }
          @include media('<md') {
            &::before {
              right: 0;
            }
          }
        }
        &__submenu {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 75.875rem;
          margin-right: 1rem;
          margin-left: 1rem;
          @include fluid(child);
          @include media('<=lg') {
            flex-direction: column;
            flex-wrap: nowrap;
            li {
              width: 100%;
            }
            ul {
              width: 100%;
              margin-bottom: rem(24px);
            }
            a {
              display: block;
              width: 100%;
            }
          }
        }
        &__dropdown-menu {
          @include fluid(parent);
          position: absolute;
          top: calc($header-height-size);
          right: 0;
          left: 0;
          z-index: map-get-deep($z-index, header, myaccount, content);
          display: none;
          box-sizing: border-box;
          padding-top: rem(24px);
          padding-bottom: rem(20px);
          background: color(neutral, white);
          @include media('>=lg') {
            box-shadow: 0 rem(2px) rem(6px) 0 #0000001a;
          }
          @include media('<=lg') {
            position: fixed;
            top: calc($header-height-size + rem(1px));
            right: 0;
            left: 0;
            z-index: map-get-deep($z-index, header, myaccount, content);
            height: calc((100vh - $header-height-size) + rem(1px));
            padding-top: rem(28px);
            overflow-y: auto;
            background-color: color(neutral, white);
          }
          @include media('<=md') {
            position: fixed;
            top: calc($header-height-size-mobile + rem(1px));
            height: calc((100vh - $header-height-size-mobile) + rem(1px));
            padding-top: rem(28px);
            @include fluid(child);
          }
          &[aria-hidden='true'] {
            @include media('<=lg') {
              left: 100%;
              display: flex;
              visibility: hidden;
            }
          }
          &[aria-hidden='false'] {
            display: flex;
            visibility: visible;
          }
        }
        &__submenu-item {
          @include ui-label-01;
          text-transform: uppercase;
          @include media('>=lg') {
            width: calc((100% - rem(48px)) / 4);
          }
          p {
            @include ui-label-01;
            margin: 0;
            margin-bottom: rem(24px);
            text-transform: capitalize;
          }
          li {
            @include media('<=md') {
              margin-bottom: 0;
            }
          }
        }
        .nav-item__simple {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          a {
            display: block;
            margin-bottom: rem(8px);
            color: color(neutral, 900);
            font-size: rem(14px);
            letter-spacing: 0;
            text-transform: capitalize;
            @include ui-body-01;
          }
          p {
            @include ui-label-01;
            margin: 0 0 rem(24px) 0;
          }
          &:only-child {
            a {
              @include ui-heading-01;
              margin-bottom: rem(16px);
              @include media('<=md') {
                margin-bottom: rem(16px);
                font-size: rem(24px);
                line-height: rem(32px);
                letter-spacing: 0;
                @include ui-heading-03;
              }
              @include media('<md') {
                font-size: rem(18px);
                line-height: rem(24px);

                @include ui-heading-03;
              }
            }
          }
        }

        &__back {
          display: none;
          margin-bottom: rem(28px);
          @include media('<=lg') {
            display: block;
          }
        }
        &__backButton {
          @include ui-body-02;
          display: flex;
          align-items: center;
          padding: 0;
          color: $color-link;
          background: color(neutral, white);
          border: medium none;
          span {
            color: $color-link;
            @include ui-body-02;
            &.ace-back-link {
              &--icon {
                &::before {
                  position: relative;
                  top: rem(2px);
                  width: rem(13px);
                  height: rem(10px);
                  margin-right: rem(4px);
                  color: $color-link;
                  font-size: 1.125rem;
                  content: '\e91e';
                  @include icomoon;
                }
              }
            }
          }
          &:focus {
            outline: rem(2px) solid color(blue-functional, 500);
          }
        }
        @include media('<md') {
          padding: 0 rem(16px);
        }
        @include media('>lg') {
          &:last-child {
            .nav-link {
              padding-right: rem(8px);
            }
          }
        }
      }
      @include media('<=lg') {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: rem(8px);
      }
      @include media('>=md', '<=lg') {
        max-width: rem(340px);
        margin: 0 rem(32px);
        padding: rem(16px);
      }
      @include media('<md') {
        max-width: inherit;
        margin: 0;
        padding: rem(16px);
      }
    }
    &[aria-hidden='false'] {
      @include media('<=lg') {
        position: fixed;
        top: calc($header-height-size + rem(1px));
        right: 0;
        left: 0;
        z-index: 101;
        display: flex;
        height: calc((100vh - $header-height-size) + rem(1px));
        padding-bottom: rem(32px);
      }
      @include media('<=md') {
        top: calc($header-height-size-mobile + rem(1px));
        height: calc((100vh - $header-height-size-mobile) + rem(1px));
      }
    }

    #Destinations.nav-item__dropdown-menu {
      .nav-item__submenu {
        @include media('<=lg') {
          display: block;
        }
        .nav-item__submenu-item {
          @include media('<=lg') {
            p {
              margin-bottom: rem(12px);
              color: color(neutral, 900);
              font-size: rem(18px);
              line-height: rem(24px);
              letter-spacing: 0;
            }
            display: inline-block;
            width: 25%;
            letter-spacing: 0;
            vertical-align: top;
          }
          @include media('<md') {
            width: 80%;
          }
          &:last-child {
            @include media('<md') {
              display: none;
            }
          }
        }
      }
    }

    .nav-item__dropdown-menu {
      @include media('<=lg') {
        width: auto !important;
      }
      .nav-item__submenu {
        ul {
          width: 100%;
        }
        .cmp-ace-gallery__item {
          float: left;
          clear: none;
          width: 33.3333333333%;
          padding-right: 9pt;
          padding-left: 9pt;
          @include ui-heading-03;
          img {
            height: 100%;
          }
          @include media('>=md') {
            &:nth-child(3n+1) {
              padding-left: 0;
            }
            &:nth-child(3n+3) {
              padding-right: 0;
            }
          }
        }
      }
      .ace-image {
        height: 9.5rem;
        margin-bottom: .625rem;
        overflow: hidden;
      }
    }
  }
}
/* RESORT LOGO CSS STARTS */
.mobile-resort {
  .resort-logo {
    padding: 0;
    .ace-logo {
      padding: 0;
      .brand-logo {
        z-index: inherit;
        justify-content: start;
        width: 100%;
        margin-bottom: rem(16px);
        padding: rem(28.52px) rem(28.52px) rem(28.52px) 0;
        text-align: left;
        border-bottom: rem(1px) solid color(neutral-black, 200);
      }
    }
  }
  @include media('>=lg') {
    display: none;
  }
}
/* RESORT LOGO CSS ENDS */
html[dir='rtl'] {
  nav {
    .navbarContent {
      >ul {
        .nav-item {
          &__dropdown-toggle {
            margin-right: 0 !important;
            &::before {
              @include media('<=lg') {
                right: auto;
                left: 0;
                margin-right: 0;
                transform: rotate(90deg);
              }
            }
          }
          &__backButton {
            @include media('<=lg') {
              display: flex;
              flex-direction: row;
            }
          }
          &__dropdown-menu {
            &[aria-hidden='true'] {
              @include media('<=lg') {
                right: 100%;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
}
/* NEW LANGUAGE SELECTOR & MY ACCOUNT & REWARDS RELATED STYLE STARTS */
header {
  .header {
    padding: 0;
    @include media('<=md') {
      padding: 0 rem(12px);
    }
    @include media('<md') {
      padding: 0;
    }
    .navigation-panel--container {
      @include media('>sm') {
        margin: 0;
      }
    }
  }

  .cmp-container {
    .aem-Grid {
      .aem-GridColumn {
        .language-selector {
          width: auto;
          min-width: inherit;
          padding: 0 0;
          @include media('<=lg') {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            display: none;
            align-items: center;
            width: 100% !important;
            height: rem(92px);
            margin:0;
            padding: 0;
            padding-bottom: rem(4px);
            background: color(neutral, white);
            &[hidden='true'],
            &[hidden] {
              display: none;
            }
            &::before {
              position: absolute;
              top: 0;
              right: rem(32px);
              left: rem(32px);
              height: rem(1px);
              background-color: color(neutral-black, 200);
              content: '';
            }
            .ace-language-selector {
              &__button {
                display: flex;
                align-items: center;
                width: 100%;
                height: 3rem;
                margin: 0;
                margin-left: 0 !important;
                padding: 0;
                html[dir='rtl'] &{

                  padding-left: 1rem;


            }
                button {
                  position: relative;
                  width: 100%;
                  padding: 0 rem(16px);
                  text-align: left;
                  html[dir='rtl'] &{
                      text-align: right;

                  }


                  &::after {
                    position: absolute;
                    top: 0;
                    right: rem(52px);
                    display: block;
                    color: $text-brand;
                    font-size: rem(16px);
                    font-family: icomoon;
                    transform: rotate(-90deg);
                    content: '\e93d';
                    html[dir='rtl'] &{
                      transform: rotate(90deg);
                      right: auto;
                      left: 0;
                    }
                  }
                }
              }
              @include media('<=lg') {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: rem(370px);
                padding-left: rem(32px);
                html[dir='rtl'] &{
                  padding-right: rem(32px);
                  padding-left: 0;
                }
              }
              @include media('<md') {
                max-width: inherit;
                padding-right: rem(16px);
                padding-left: rem(16px);
              }
            }
          }
          .ace-language-selector__button {
            display: flex;
            @include media('<md') {
                padding-right: 0;
            }
            button {
              @include media('>=xl') {
                position: relative;
                padding: 0 rem(27px) 0 rem(24px);
                &::before {
                  position: absolute;
                  top: rem(-5px);
                  right: 0;
                  left: 0;
                  width: rem(1px);
                  height: rem(24px);
                  padding-left: 0;
                  background: color(neutral-black, 200);
                  content: '';
                }
              }
              @include media('<md') {
                html[dir='rtl'] &{
                  padding-right: 0;
                  margin-left: 1rem;
              }
            }
            }
          }
          @include media('<=lg') {
            display: none;
          }
          @include media('<md') {
            height: auto;
            &::before {
              right: rem(16px);
              left: rem(16px);
            }
          }
        }
        .myaccount {
          width: auto;
          @include media('>lg') {
            width: rem(16px);
          }
          padding: 0;
          @include media('<=lg') {
            position: absolute;
            top:rem(2px);
            right: 0;
            left: auto;
            z-index: 99;
            order: 3;
          }
          @include media('<md') {
            top:rem(2px);
          }
          @include media('>=lg') {
            #ace-myaccount_login,
            #ace-myaccount_login_connected,
            #ace-myaccount_login_nonmember {
              width: auto;
              margin: 0;
              padding: 0;
              background: none;
              border: none;
              outline: none;
              .icon-account-24icon {
                &::before {
                  color: $text-brand !important;
                  font-size: rem(18px);
                }
              }
              &:focus {
                outline: rem(2px) solid $border-focus;
              }
            }
          }
        }
      }
    }
  }
}
header {
  .cmp-container {
    .aem-Grid {
      .aem-GridColumn {
        .language-selector {
          .ace-language-selector__button {
            button {
              @include media('<md') {
                &::after {
                  right: rem(18px);
                }
              }
            }
          }
        }
      }
    }
  }
}
html.fixedbyModal {
  overflow: hidden;
  header {
    .cmp-container {
      .aem-Grid {
        .aem-GridColumn {
          .language-selector {
            display: flex;
          }
        }
      }
    }
  }
}
@include media('<=md') {
  header {
    .ace-header {
      .navigation-panel--container {
        .navigation-panel {
          .logo + .logo {
            display: block;
          }
          .mobile-resort {
            .logo {
              display: none;
            }
          }
        }
      }
    }
  }
}
// RESORT LOGO START HERE
@include media('<md') {
  header {
    .ace-header {
      .navigation-panel--container {
        .navigation-panel {
          .logo + .logo {
            display: none;
          }
          .mobile-resort {
            .logo {
              display: none;
              &:first-child {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@include media('>md') {
  .mobile-resort {
    display: none;
  }
}
@include media('<md') {
  .navbar .navbarContent > ul .nav-item__submenu {
    margin-right:rem(16px);
    margin-left:rem(16px);
  }
}
.nav-item__dropdown-menu {
  #nav_menu {
    @include media('<=md') {
      margin-top: rem(8px);
      margin-bottom: rem(24px);
      color: color(neutral-black, 900);
      @include ui-heading-01;
    }
  }
}
@include media('<md') {
  .fixedbyModal,
  .disable-scroll-myaccount {
    .usabilla_live_button_container {
      display: none;
    }
  }
}
/* RESORT LOGO ENDS HERE */
/* NEW LANGUAGE SELECTOR & MY ACCOUNT & REWARDS RELATED STYLE ENDS */
/* MY ACCOUNT OUSTSIDE CoreJS.DomEventConstants.CLICK SCROLL ISSUE FIXED */
@include media('<lg') {
  .navbar {
    .navbarContent[aria-hidden=false] {
      overflow-y: auto;
    }
  }
  .navbar {
    .navbarContent {
      & > ul {
        padding-bottom: rem(96px);
      }
    }
  }
  .nav-item {
    &.nav-dropdown {
      &:nth-child(3) {
        .nav-item_submenu2 {
          padding-bottom: rem(60px);
        }
      }
    }
  }
}
.navbar .navbarContent .nav-item__dropdown-menu .nav-item__submenu .cmp-ace-gallery__item img {
  height:unset !important;
}
@include media('<md') {
  .fixedbyModal,
  .page.basicpage.disable-scroll-myaccount {
    position: fixed;
    right: 0;
    left: 0;
  }
  .fixedbyModal {
    .navbar {
      .navbarContent > ul {
        .nav-item__dropdown-menu[aria-hidden=false] {
          position: absolute;
          top: 0 !important;
        }
      }
    }
  }
}
.cmp-page__skiptomaincontent {
  display: flex;
}
@include media('<lg') {
  html[dir='rtl'] {
    .navbar .navbarContent > ul li.nav-item .nav-link:before {
      left: 0 !important;
      right: auto !important;
      transform: rotate(90deg) !important;
    }
    .navbar .navbarContent>ul .nav-item__backButton span.cmp-back-link--icon {
      transform: rotate(180deg);
    }
  }
}

html[dir=rtl] {
header {
  .cmp-container {
    .aem-Grid {
      .aem-GridColumn {
        .language-selector {
        .ace-language-selector__button{
          @include media('<md') {
            padding-right: 0;
          }
        }
        }
      }
    }
  }
}
}
header .cmp-container .aem-Grid .myaccount {
	@include media('>=lg') {
		width: 1rem !important;
	}
}