.ace-quick-access-component-v2 {
    margin-bottom: rem(48px);
    @include media('>=lg') {
        margin-bottom: rem(80px);
    }
    position: relative;
    .cmp-filter {
        z-index: 9;
        &__toggle {
            @include media('<lg') {
                padding: rem(12px) rem(16px);
                position: absolute;
            }
        }
        &__menu {
            &.show {
                @include media('<lg') {
                    padding-top: 0;
                }
            }
            > * .cmp-ace-anchor-navigation__tab.cmp-filter__item {
                @include media('>=lg') {
                    padding-left: 0;
                }
                @include media('<lg') {
                    padding: rem(12px) 1rem;
                }

                &.cmp-ace-anchor-navigation__tab--active {
                    @include media('<lg') {
                        &::before {
                            left: unset;
                            right: 3rem;
                        }
                    }
                }
            }
        }
        .cmp-ace-anchor-navigation {
            &.sticky-bar {
                position: absolute;
            }
            &__tabs {
                &.cmp-ace-anchor-navigation__tab--active {
                    &::after {
                        @include media('>=lg') {
                            display: none;
                        }
                    }

                }
                &__anchors {
                    @include media('>=lg') {
                        margin: 0 rem(52px);
                    }
                    @include media('<lg') {
                        margin: 0;
                    }
                }
            }
            .cmp-ace-anchor-navigation__tab {
                @include media('>=lg') {
                    margin: 1.75rem 1.5rem 0 0;
                    padding-bottom: rem(28px) !important;
                    padding: 0;
                }
            }
        }
    }
}

