.ace-carousel {
  .cmp-teaser__cell {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0;
  &__content {
    position: relative;
    display: flex;
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    @include media('>=md') {
      display: flex;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    &.hide {
      position: absolute;
      display: block;
      visibility: hidden;
      opacity: 0;
    }
    .cmp-teaser__logo-slot {
      padding: rem(5px) rem(4px);
    }
    .callout {
      @include media('<md') {
        padding: 0 rem(8px);
      }
      padding: 0 rem(12px);
    }
    .callout-section {
      ul.ace-callout-section {
        padding: 0;
      }
    }
  }
  &__actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    order: 2;
    width: 95.5%;
    margin-top: rem(32px);
    padding-left: rem(8px);
    color: $text-body-primary;
    @include media('>=md') {
      width: 97%;
      padding-left: rem(12px);
    }
    @include media('>=lg') {
      width: 98%;
      padding-left: rem(12px);
    }
    &::after {
      position: absolute;
      left: rem(58px);
      z-index: 0;
      display: block;
      width: calc(100% - rem(116px));
      width: calc(100% - rem(114px));
      height: rem(1px);
      border: 0;
      border-top: rem(1px) solid $border-decorative;
      content: '';
    }
  }
  &__action {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: rem(40px);
    height: rem(40px);
    background-color: color(neutral, white);
    border: rem(1px) solid $border-brand;
    border-radius: rem(40px);
    outline: none;
    cursor: pointer;
    .icon {
      color: $text-brand;
      pointer-events: none;
      &::before {
        color: $text-brand !important;
      }
    }
    &:focus {
      color: $color-neutral-disabled-primary;
      background-color: color(neutral, white);
      border-color: $border-decorative;
      outline: rem(2px) solid $border-focus;
      outline-offset: rem(2px);
      .icon::before {
        color: $color-neutral-disabled-primary;
      }
    }
    &:hover {
      background-color: $bg-brand-secondary;
      .icon::before {
        color: color(neutral, white) !important;
      }
    }
    &:active {
      background-color: $bg-brand-secondary-hover;
      .icon::before {
        color: color(neutral, white) !important;
      }
    }
  }
  &__action-content {
    @include ui-body-02;
    z-index: 1;
    padding-right: rem(16px);
    padding-left: rem(16px);
    background-color: color(neutral, white);
  }
  &__action--previous {
    &:disabled {
      background-color: $bg-disabled-primary;
      border: $border-interactive-disabled;
      .icon::before {
        color: $text-body-secondary !important;
      }
    }
    margin-right: rem(5px);
  }
  &__action--next {
    &:disabled {
      background-color: $bg-disabled-primary;
      border: $border-interactive-disabled;
      .icon::before {
        color: $text-body-secondary !important;
      }
    }
    margin-left: rem(5px);
  }
  &__action--disabled {
    display: none;
  }
}
.ace-carousel {
  .ace-carousel__actions {
    display: none;

    &+.ace-carousel__content {
      display: flex;
    }
  }
}
.carousel {
  padding: 0;

  @include media('>=md', '<lg') {
    padding: rem(12px);
  }
}

html[dir='rtl'] {

  .ace-carousel__action--previous,
  .ace-carousel__action--next {
    transform: rotate(180deg);
  }
}

.carousel {

  &.panelcontainer {
    .ace-carousel__content {
      overflow-y: hidden;

    }

  }
}
.cmp-teaser{
  &.ace-callout-clickable{
    .cmp-teaser__image{
      border-radius: rem(6px);
    }
  }
}
