.cmp-breadcrumb {
    margin-top: 2rem;
    ol{
        margin: 0;
    }
}
main,#main-content{
    >.cmp-container>.aem-Grid>.container>.cmp-container>.aem-Grid>.breadcrumb:first-of-type{
        margin-top: rem(8px) !important;
    }
    }
.sharedNavigation + .breadcrumb{
    margin: 0px rem(8px);
    @include media('>=lg') {
      margin: 0px rem(52px);
    }
  }
