.secondary {
  .cmp-button {
    background-color: $greenB2BColor;
    border-color: $greenB2BColor;

    &:hover {
      background-color: #007576;
      border-color: #007576;
    }
  }
}
